import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8d84ba2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "character" }
const _hoisted_2 = { class: "sheet-grid" }
const _hoisted_3 = { class: "overview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CharacterOverview = _resolveComponent("CharacterOverview")!
  const _component_CharacterTabUICollectionViewCell = _resolveComponent("CharacterTabUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_CharacterAttributes = _resolveComponent("CharacterAttributes")!
  const _component_CharacterSpells = _resolveComponent("CharacterSpells")!
  const _component_CharacterInventory = _resolveComponent("CharacterInventory")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CharacterOverview, { character: _ctx.character }, null, 8, ["character"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_UICollectionView, {
          id: 'cvTabs',
          hasItems: _ctx.CharacterTabs.length > 0
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CharacterTabs, (tab, index) => {
              return (_openBlock(), _createBlock(_component_CharacterTabUICollectionViewCell, {
                key: index,
                textLabel: tab,
                isSelected: _ctx.activeTab === index,
                type: 'tab',
                onOnToggle: ($event: any) => (_ctx.onToggle(index))
              }, null, 8, ["textLabel", "isSelected", "onOnToggle"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["hasItems"]),
        _createVNode(_component_UISeparatorView),
        (_ctx.isActiveTab(0))
          ? (_openBlock(), _createBlock(_component_CharacterOverview, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.isActiveTab(1))
          ? (_openBlock(), _createBlock(_component_CharacterAttributes, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.isActiveTab(2))
          ? (_openBlock(), _createBlock(_component_CharacterSpells, { key: 2 }))
          : _createCommentVNode("", true),
        (_ctx.isActiveTab(3))
          ? (_openBlock(), _createBlock(_component_CharacterInventory, { key: 3 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}