<template>
  <div class="UIOnboardView">
    <UILabel :text="title" :type="'h1'" />
    <UILabel :text="body" />
    <UIButton
      v-if="buttonTitle !== '' && !hasOptions"
      :id="'btnOnboard'"
      :title="buttonTitle"
      @click="btnOnboard"
    />
    <div class="flex">
      <UIButton
        v-if="hasOptions"
        :id="'btnOnboard'"
        :title="buttonTitle1"
        @click="btnOnboard1"
      />
      <UIButton
        v-if="hasOptions"
        :id="'btnOnboard'"
        :title="buttonTitle2"
        @click="btnOnboard2"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";

export default defineComponent({
  name: "UIOnboardView",
  components: {
    UILabel,
    UIButton,
  },
  emits: [
    "ui-onboard-view",
    "ui-onboard-view-1-submit",
    "ui-onboard-view-2-submit",
  ],
  props: {
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
    hasOptions: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    buttonTitle1: {
      type: String,
      default: "",
    },
    buttonTitle2: {
      type: String,
      default: "",
    },
  },
  methods: {
    btnOnboard() {
      this.$emit("ui-onboard-view");
    },
    btnOnboard1() {
      this.$emit("ui-onboard-view-1-submit");
    },
    btnOnboard2() {
      this.$emit("ui-onboard-view-2-submit");
    },
  },
});
</script>

<style lang="scss" scoped>
.UIOnboardView {
  max-width: 325px;
  margin: auto;
  text-align: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>