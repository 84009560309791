import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_menu@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-1f93236d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DieUICollectionViewCell" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.diceIcon,
        width: "24"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _imports_0,
            width: "24",
            style: {"cursor":"pointer","margin-right":"8px"},
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDiceMenu && _ctx.onDiceMenu(...args)), ["stop"]))
          })
        ]),
        (_ctx.shouldShowDieMenu)
          ? (_openBlock(), _createBlock(_component_UIMenuView, {
              key: 0,
              items: ['Edit Dice', 'Delete Dice'],
              onMenuItemSelectedAt: _ctx.onMenuDieSelect,
              onMenuViewDismiss: _ctx.dismissDieMenu
            }, null, 8, ["onMenuItemSelectedAt", "onMenuViewDismiss"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_UISeparatorView),
    _createVNode(_component_UILabel, {
      text: `${_ctx.die.count}d${_ctx.die.sides}+${_ctx.die.modifier}`,
      type: 'h3'
    }, null, 8, ["text"]),
    _createVNode(_component_UILabel, {
      text: _ctx.die.name
    }, null, 8, ["text"])
  ]))
}