export const categories = [
  {
    label: "General",
    key: "general",
  },
  {
    label: "Bug Reports",
    key: "bug_reports",
  },
  {
    label: "Development",
    key: "development",
  },
];