<template>
  <div class="ModificationBonusUICollectionViewCell">
    <UILabel :id="'lblName'" :text="keyLabel" />
    <UILabel :id="'lblValue'" :text="valueLabel" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import { convertToTitleCase } from "@/store/definitions/modifications";
import { isEmpty } from "@/utility/global";

export default defineComponent({
  name: "ModificationBonusUICollectionViewCell",
  components: {
    UILabel,
    UISeparatorView,
  },
  props: {
    mod: {
      type: Object,
      default: {},
    },
  },
  computed: {
    keyLabel() {
      return convertToTitleCase(this.mod.key)
    },
    valueLabel() {
      return isEmpty(this.mod.value) ? "" : this.mod.value;
    },
  },
});
</script>

<style lang="scss" scoped>
.ModificationBonusUICollectionViewCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;
  height: 44px;
  cursor: pointer;

  box-sizing: border-box;

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(odd) {
    background: #ffffff;
  }
}

</style>