<template>
  <div class="inventory">
    <CharacterInventoryStatistics />
    <UISeparatorView />
    <CharacterInventoryBags />
  </div>
</template>

<script lang="ts">
import CharacterInventoryStatistics from "@/components/character/CharacterInventoryStatistics.vue";
import CharacterInventoryBags from "@/components/character/CharacterInventoryBags.vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "CharacterInventory",
  components: {
    CharacterInventoryStatistics,
    CharacterInventoryBags,
    UISeparatorView,
  },
});
</script>