import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vBody" }
const _hoisted_2 = { class: "vInput" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UIDropdownGameSystem = _resolveComponent("UIDropdownGameSystem")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogMessageView = _resolveComponent("UIDialogMessageView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: 'Submit for Review',
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_UILabel, {
            text: _ctx.body,
            type: 'body',
            justify: 'left',
            font: 14
          }, null, 8, ["text"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UITextField, {
            id: 'txtSubmission',
            type: 'text',
            modelValue: _ctx.description,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.description) = $event)),
            textLabel: 'Description your submission',
            placeholder: 'Submission description',
            hasError: _ctx.submissionError !== '',
            errorMessage: _ctx.submissionError
          }, null, 8, ["modelValue", "hasError", "errorMessage"]),
          _createVNode(_component_UIDropdownGameSystem, {
            selectedValue: _ctx.gameSystemKey,
            hasError: _ctx.gameSystemError !== '',
            errorMessage: _ctx.gameSystemError,
            onUiDropdownGameSystemSelect: _ctx.onGameSystemCellDropdownMenuSelect
          }, null, 8, ["selectedValue", "hasError", "errorMessage", "onUiDropdownGameSystemSelect"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_UITextField, {
              id: 'txtMajor',
              type: 'number',
              modelValue: _ctx.major,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.major) = $event)),
              textLabel: 'Major',
              placeholder: 'Major'
            }, null, 8, ["modelValue"]),
            _createVNode(_component_UITextField, {
              id: 'txtMinor',
              type: 'number',
              modelValue: _ctx.minor,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.minor) = $event)),
              textLabel: 'Minor',
              placeholder: 'Minor'
            }, null, 8, ["modelValue"]),
            _createVNode(_component_UITextField, {
              id: 'txtPatch',
              type: 'number',
              modelValue: _ctx.patch,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.patch) = $event)),
              textLabel: 'Patch',
              placeholder: 'Patch'
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowMessageDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogMessageView, {
            key: 0,
            title: 'Success',
            body: 'Asset successfuly submitted for review.',
            onUiDialogMessageSubmit: _ctx.onDismissMessageDialog
          }, null, 8, ["body", "onUiDialogMessageSubmit"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}