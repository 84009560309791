import IVersion from "@/store/interfaces/AssetBases/IVersion";

export default class Version implements IVersion {
  constructor(major: number, minor: number, patch: number) {
    this.major = major;
    this.minor = minor;
    this.patch = patch;
  }

  major: number;
  minor: number;
  patch: number;

  toDictionary(): Record<string, number> {
    return {
      major: this.major,
      minor: this.minor,
      patch: this.patch,
    };
  }
}