<template>
  <div class="SpellUICollectionViewCell">
    <div style="display: flex;">
      <img src="@/assets/icon_spell_alt@x2.png" width="44" />
      <div id="lblName">{{ spell.name }}</div>
      <!-- <div id="lblLevel">{{ spell.level }}</div> -->
    </div>
    <!-- <UICollectionView :id="'cvSpellExtra'">
      <div v-if="!spell.Extra"></div>
      <div
        class="UICollectionViewCell"
        v-for="(extra, index) in spell.Extra"
        :key="index"
      >
        {{ extra.Name }} - {{ extra.Value }}
      </div>
    </UICollectionView>
    <div style="margin-top: 8px">
      <div id="lblDescription">
        {{ spell.Description }}
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UICollectionView from "@/components/UI/UICollectionView.vue";

export default defineComponent({
  name: "SpellUICollectionViewCell",
  components: {
    UICollectionView,
  },
  props: {
    spell: {
      type: Object,
      default: {},
    },
  },
});
</script>

<style lang="scss" scoped>
.SpellUICollectionViewCell {
  padding: 8px;
  margin: 0 8px;

  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  background: #ffffff;

  cursor: pointer;
}

#lblName {
  flex: 1;
  margin-left: 8px;
}

#cvSpellExtra {
  grid-template-columns: repeat(auto-fit, minmax(75px, 100px));
}
</style>