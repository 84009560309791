<template>
  <UIDialogView
    :title="`${type} Changes`"
    :subTitle="`Set ${type} changes`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <UICollectionView>
      <AssetSelectionUICollectionViewCell
        v-for="(item, index) in this.localFields"
        :key="index"
        :name="item.name"
        :value="localAllowedFields[item.key]"
        @click="onDisplayFormulaDialog(item, index)"
      />
    </UICollectionView>
  </UIDialogView>
  <div>
    <UIDialogFormulaView
      v-if="shouldShowFormulaDialog"
      :formula="formulaData.formula"
      @ui-dialog-formula-submit="onSubmitFormulaDialog"
      @ui-dialog-formula-cancel="onCancelFormulaDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";

import UIDialogFormulaView from "@/components/character/dialogs/UIDialogFormulaView.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import AssetSelectionUICollectionViewCell from "@/components/character/collectionViewCells/AssetSelectionCollectionViewCell.vue";

import QuickView from "@/store/models/QuickView";
import { AssetType } from "@/store/models/Compendium/AssetType";

export default defineComponent({
  name: "UIDialogAssetSelectionView",
  props: {
    type: {
      type: String,
      required: true,
    },
    fields: {
      type: Array as () => QuickView[],
      default: [],
    },
    allowedFields: {
      type: Object as () => Record<string, string>,
      default: [],
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localFields: [] as QuickView[],
      localAllowedFields: {} as Record<string, string>,
      selectedKey: "",
      formulaData: {},
      shouldShowFormulaDialog: false,
      menuItems: ["Remove Cost"],
      hasMenu: false,
    };
  },
  emits: [
    "ui-dialog-asset-selection-submit",
    "ui-dialog-asset-selection-cancel",
  ],
  components: {
    UIButton,
    UILabel,
    UICollectionView,
    UIDialogView,
    UIDialogFormulaView,
    AssetSelectionUICollectionViewCell,
  },
  methods: {
    onSubmit() {
      this.$emit("ui-dialog-asset-selection-submit", this.localAllowedFields);
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-asset-selection-cancel");
    },
    onDisplayFormulaDialog(option: QuickView, index: number) {
      this.formulaData = {
        formula: this.localAllowedFields[option.key] ?? "",
      };
      this.selectedKey = option.key;
      this.shouldShowFormulaDialog = true;
    },
    onCancelFormulaDialog() {
      this.shouldShowFormulaDialog = false;
    },
    onSubmitFormulaDialog(formula: string) {
      this.localAllowedFields[this.selectedKey] = formula;
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.localFields = this.fields;
    this.localAllowedFields = this.allowedFields
  },
});
</script>
