import Attribute from '@/store/models/Attribute';
import Bonus from '@/store/models/Bonus';

import { getFirestore, doc, collection, onSnapshot } from "firebase/firestore";

import { Account } from '@/store/modules/account';

import { newKey, setDatabaseCharacter, setListenerAt, database_save, database_option } from "@/utility/database";
import { convertKeysLocalFormula } from "@/utility/tooltip";
import Item from '@/store/models/Item';
import Resource from '@/store/models/Resource';
import Spell from '@/store/models/Spell';
import CharacterClass from '@/store/models/Character';
import Currency from '@/store/models/Currency';
import Dice from '@/store/models/Dice';
import Veterancy from '@/store/models/Veterancy';

import { attributeActions, attributeGetters, attributeMutations } from '@/store/modules/characters/attributes';
import { overviewActions, overviewGetters, overviewMutations } from './characters/overview';
import { itemActions, itemGetters, itemMutations } from './characters/items';
import { spellActions, spellGetters, spellMutations } from './characters/spells';
import { resourceActions, resourceGetters, resourceMutations } from './characters/resources';
import AttributeBase from '../models/AssetBases/AttributeBase';
import SpellBase from '../models/AssetBases/SpellBase';
import ResourceBase from '../models/AssetBases/ResourceBase';
import ItemBase from '../models/AssetBases/ItemBase';

const state = {
  character: {} as CharacterClass,
}

const getters = {
  ...overviewGetters,
  ...attributeGetters,
  ...resourceGetters,
  ...spellGetters,
  ...itemGetters,
  getBonus: (state: any) => (bonusKey: string): number => {
    if (state.character === null) return 0;
    let bonuses = state.character.bonuses.filter((bonus: Bonus) => bonus.key === bonusKey);

    let bonusValue: number = 0;
    let tests = {
      level: state.character.level,
      main_vital: state.character.main_vital.max,
      value: 0,
    };

    for (let bonus of bonuses) {
      let attribute: Attribute = state.character.attributes.find((attribute: Attribute) => attribute.key === bonus.attribute_key);

      if (!attribute) return bonusValue;

      if (+attribute.value) {
        tests.value = +attribute.value;
      }
      else {
        tests.value = 0;
      }

      bonusValue += +convertKeysLocalFormula(bonus.formula, tests);
    }
    return bonusValue;
  },
  getCharacterId: (state: any): string => {
    return state.character.key;
  },
  getCurrencies: (state: any): Array<Currency> => {
    return state.character.currencies;
  },
  getDices: (state: any): Array<Dice> => {
    return state.character.dices;
  },
  getJournal: (state: any): string => {
    return state.character.journal;
  },
  getStory: (state: any): string => {
    return state.character.story;
  },
  isCampaignCharacter: (state: any): boolean => {
    return state.character.campaign_id !== null;
  },
  getCampaignId: (state: any): string => {
    return state.character.campaign_id;
  },
  getCharacter: (state: any): CharacterClass => {
    return state.character;
  },
  getGameSystemKey: (state: any): string => {
    return state.character?.game_system_key;
  },
}

const mutations = {
  ...overviewMutations,
  ...attributeMutations,
  ...resourceMutations,
  ...spellMutations,
  ...itemMutations,
  setCharacter: (state: any, val: CharacterClass) => {
    state.character = val;
  },
  setCurrency: (state: any, value: Currency) => {
    const index = state.character.currencies.findIndex((c: Currency) => c.key == value.key);

    if (index > -1) {
      state.character.currencies[index] = value;
    }
    else {
      state.character.currencies.push(value);
    }
  },
  removeCurrency: (state: any, value: Currency) => {
    const index = state.character.currencies.findIndex((d: Dice) => d.key === value.key);

    if (index > -1) {
      state.character.currencies.splice(index, 1);
    }
  },
  setDice: (state: any, value: Dice) => {
    const index = state.character.dices.findIndex((c: Dice) => c.key == value.key);

    if (index > -1) {
      state.character.dices[index] = value;
    }
    else {
      state.character.dices.push(value);
    }
  },
  deleteDice: (state: any, index: number) => {
    state.character.dices.splice(index, 1);
  },
}

const actions = {
  ...overviewActions,
  ...attributeActions,
  ...resourceActions,
  ...spellActions,
  ...itemActions,
  setCurrency: ({ commit, dispatch }: any, val: Currency) => {
    return new Promise((resolve, reject) => {
      if (!val.key || val.key === "") {
        val.key = newKey();
      }

      commit("setCurrency", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setDice: ({ commit, dispatch }: any, value: Dice) => {
    return new Promise((resolve, reject) => {
      if (!value.key || value.key === "") {
        value.key = newKey();
      }

      commit("setDice", value);
      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });

  },
  deleteDice: ({ commit, dispatch }: any, value: Dice) => {
    return new Promise((resolve, reject) => {
      const index = state.character.dices.findIndex((d: Dice) => d.key === value.key);

      if (index > -1) {
        commit("deleteDice", index);
        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }

      resolve(true);
    });
  },
  setWorkshopItem: ({ dispatch }: any, payload: any) => {
    return new Promise((resolve, reject) => {
      const item: Item = payload.item;
      const shouldUpdateCharacter = payload.shouldUpdateCharacter;

      if (!item.key) {
        item.key = newKey();
      }

      let data = {
        "shouldUpdateCharacter": shouldUpdateCharacter,
        "item": item,
      };

      dispatch("Account/setWorkshopAssetItem", data, { root: true });
      resolve(true);
    });
  },
  setWorkshopResource: ({ dispatch }: any, payload: any) => {
    return new Promise((resolve, reject) => {
      const resource: Resource = payload.resource;
      const shouldUpdateCharacter = payload.shouldUpdateCharacter;

      if (!resource.key) {
        resource.key = newKey();
      }

      let data = {
        "shouldUpdateCharacter": shouldUpdateCharacter,
        "resource": resource,
      };

      dispatch("Account/setWorkshopAssetResource", data, { root: true });
      resolve(true);
    });
  },
  setWorkshopSpell: ({ dispatch }: any, payload: any) => {
    return new Promise((resolve, reject) => {
      const spell: Spell = payload.spell;
      const shouldUpdateCharacter = payload.shouldUpdateCharacter;

      if (!spell.key) {
        spell.key = newKey();
      }

      let data = {
        "shouldUpdateCharacter": shouldUpdateCharacter,
        "spell": spell,
      };

      dispatch("Account/setWorkshopAssetSpell", data, { root: true });
      resolve(true);
    });
  },
  setWorkshopAttribute: ({ dispatch }: any, payload: any) => {
    return new Promise((resolve, reject) => {
      const attribute: Attribute = payload.attribute;
      const shouldUpdateCharacter = payload.shouldUpdateCharacter;

      if (!attribute.key) {
        attribute.key = newKey();
      }

      let data = {
        "shouldUpdateCharacter": shouldUpdateCharacter,
        "attribute": attribute,
      };

      dispatch("Account/setWorkshopAssetAttribute", data, { root: true });
      resolve(true);
    });
  },
  deleteCurrency: ({ state, dispatch }: any, value: Currency) => {
    return new Promise((resolve, reject) => {
      const index = state.character.currencies.findIndex((c: Currency) => c.key == value.key);

      if (index > -1) {
        state.character.currencies.splice(index, 1);

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
    });
  },
  levelUp: ({ state, dispatch }: any, level: number) => {
    let veterancies: Veterancy[] = state.character.experience.veterancies;
    if (veterancies.length < 1) {
      return;
    }
    let maxLevel: number = state.character.level <= veterancies.length - 1 ? state.character.level : veterancies.length - 1;
    let maxVeterency: Veterancy = veterancies[maxLevel];

    if (level <= maxLevel) {
      var newLevel: number = state.character.level;
      let veterency: Veterancy = veterancies[level];
      state.character.experience.max = veterency.minimum_experience;
      if (state.character.experience.current >= veterency.minimum_experience) {
        newLevel = newLevel + 1;
        state.character.experience.current = state.character.experience.current - veterency.minimum_experience;
        state.character.level = newLevel;

        dispatch("levelUp", newLevel);
      }
    }
    else {
      state.character.experience.max = maxVeterency.minimum_experience;
    }

    //TODO: Maybe notify a save?
  },
  refreshBonuses: async ({ state, dispatch }: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      state.character.bonuses = [];

      let veterancies = state.character.experience.veterancies;
      if (veterancies.length > 0) {
        dispatch("levelUp", state.character.level);

        let maxLevel: number = state.character.level <= veterancies.length - 1 ? state.character.level : veterancies.length - 1;
        for (let index = 0; index < maxLevel; index++) {
          let veterancy: Veterancy = veterancies[index];
          state.character.bonuses.push(new Bonus(veterancy.modification.vitalMaxBonus, "veterency", "vital_maximum_bonus"));
          state.character.bonuses.push(new Bonus(veterancy.modification.vitalMaximumFraction, "veterency", "vital_maximum_fraction"));

          if (veterancy.modification.attributeChanges.length > 0) {
            for (const bonus of veterancy.modification.attributeChanges) {
              state.character.bonuses.push(bonus);
            }
          }

          if (veterancy.modification.resourceChanges.length > 0) {
            for (const bonus of veterancy.modification.resourceChanges) {
              state.character.bonuses.push(bonus);
            }
          }
        }
      }

      for (const attribute of state.character.attributes) {
        if (attribute.bonus.length > 0) {
          for (const bonus of attribute.bonus) {
            state.character.bonuses.push(bonus);
          }
        }

        let modification = attribute.modification;
        if (modification.vitalMaxBonus) {
          state.character.bonuses.push(new Bonus(modification.vitalMaxBonus, attribute.key, "vital_maximum_bonus"))
        }

        if (modification.vitalMaximumFraction) {
          state.character.bonuses.push(new Bonus(modification.vitalMaximumFraction, attribute.key, "vital_maximum_fraction"));
        }

        if (attribute.modification.attributeChanges.length > 0) {
          for (const bonus of attribute.modification.attributeChanges) {
            state.character.bonuses.push(bonus);
          }
        }

        if (attribute.modification?.resourceChanges.length > 0) {
          for (const bonus of attribute.modification.resourceChanges) {
            state.character.bonuses.push(bonus);
          }
        }
      }

      resolve(true);
    });
  },
  async addListenerCampaignCharacter({ commit, dispatch }: any) {
    return new Promise(async (resolve, reject) => {
      if (state.character.type === 'campaign') {
        const db = getFirestore();

        const ref = doc(db, 'characters', state.character.key);
        onSnapshot(ref, (doc) => {
          if (doc.exists()) {
            console.log('Listening', doc);
            const selectedCharacter: CharacterClass = new CharacterClass(doc.data(), doc.id, Account.state.workshop);
            selectedCharacter.loadImage();
            commit('setCharacter', selectedCharacter);

            resolve(selectedCharacter);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  },
}

export const Character = { namespaced: true, state, getters, mutations, actions }