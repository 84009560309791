import IBonus from "@/store/interfaces/IBonus";

export default class Bonus implements IBonus {
    constructor(formula: string, attribute_key: string, key: string) {
        this.key = key;
        this.formula = formula;
        this.attribute_key = attribute_key;
    }

    key: string;
    formula: string;
    attribute_key: string;

    toDictionary(): Record<string, any> {
        let returnedDictionary: Record<string, any> = {};

        returnedDictionary["key"] = this.key;
        returnedDictionary["formula"] = this.formula;
        returnedDictionary["attribute_key"] = this.attribute_key;

        return returnedDictionary
    }

}