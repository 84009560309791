import { ActionTree, Commit, GetterTree, MutationTree } from "vuex";
import CompendiumAssetBase from "../models/Compendium/CompendiumAssetBase";

interface DialogState {
  showDialog: boolean;
  dialogData: {
    title: string;
    query: string;
    id: string;
    asset: CompendiumAssetBase | undefined,
    assetIndex: number;
  };
}

const state: DialogState = {
  showDialog: false,
  dialogData: {
    title: '',
    query: '',
    id: '',
    asset: undefined,
    assetIndex: -1,
  },
};

const getters: GetterTree<DialogState, any> = {
  showDialog: state => state.showDialog,
  dialogData: state => state.dialogData,
};

const mutations: MutationTree<DialogState> = {
  setShowDialog(state, showDialog: boolean) {
    state.showDialog = showDialog;
  },
  setDialogData(state, { title, query, id, asset, assetIndex }: { title: string; query: string; id: string, asset: CompendiumAssetBase, assetIndex: number }) {
    state.dialogData = { title, query, id, asset, assetIndex };
  },
};

const actions: ActionTree<DialogState, any> = {
  openDialog({ commit }: { commit: Commit }, { title, query, id, asset, assetIndex }: { title: string; query: string; id: string,  asset: CompendiumAssetBase, assetIndex: number }) {
    commit('setDialogData', { title, query, id, asset, assetIndex });
    commit('setShowDialog', true);
  },
  closeDialog({ commit }: { commit: Commit }) {
    commit('setShowDialog', false);
  },
};



export const Dialog = { namespaced: true, state, getters, mutations, actions }