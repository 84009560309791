import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickViewView = _resolveComponent("QuickViewView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: '',
    hideHeader: true,
    hideButtons: !_ctx.showActions,
    disableSubmit: !_ctx.actionsDisabled,
    submitTitle: _ctx.submitTitle,
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_QuickViewView, {
        quickView: _ctx.quickView,
        onQuickViewMenu: _ctx.onMenu,
        onQuickViewCancel: _ctx.onCancel
      }, null, 8, ["quickView", "onQuickViewMenu", "onQuickViewCancel"])
    ]),
    _: 1
  }, 8, ["hideButtons", "disableSubmit", "submitTitle", "onUiDialogSubmit", "onUiDialogCancel"]))
}