import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icon_plus@x1.png'


const _hoisted_1 = { class: "AssetView" }
const _hoisted_2 = { class: "vSearch" }
const _hoisted_3 = { class: "vAssets" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "vFooter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UIDialogSpellView = _resolveComponent("UIDialogSpellView")!
  const _component_UIDialogAssetQuickView = _resolveComponent("UIDialogAssetQuickView")!
  const _component_UIDialogGameSystemView = _resolveComponent("UIDialogGameSystemView")!
  const _component_UIDialogItemView = _resolveComponent("UIDialogItemView")!
  const _component_UIDialogResourceView = _resolveComponent("UIDialogResourceView")!
  const _component_UIDialogAttributeView = _resolveComponent("UIDialogAttributeView")!
  const _component_UIDialogModifyVitalView = _resolveComponent("UIDialogModifyVitalView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UILabel, {
        text: `${_ctx.title}s`,
        type: 'h2'
      }, null, 8, ["text"]),
      _createVNode(_component_UITabView, {
        id: 'tvTabs',
        tabs: _ctx.tabs,
        selectedTab: _ctx.selectedTab,
        onTabSelected: _ctx.btnSelectTab
      }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UITextField, {
          id: 'txtSearch',
          textLabel: 'Search',
          modelValue: _ctx.searchText,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
          placeholder: 'Search',
          onKeyup: _withKeys(_ctx.btnSearch, ["enter"])
        }, null, 8, ["modelValue", "onKeyup"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getWorkshopAssets, (asset, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: asset.key,
            class: "asset",
            onClick: ($event: any) => (_ctx.handleAssetSelection(asset, index))
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_UILabel, {
                text: _ctx.assetLabel(asset),
                justify: 'left',
                type: _ctx.isBold(asset)
              }, null, 8, ["text", "type"]),
              _createVNode(_component_UILabel, {
                text: asset.game_system_name,
                font: 12,
                justify: 'left'
              }, null, 8, ["text"]),
              _createVNode(_component_UILabel, {
                text: _ctx.labelAuthor(asset),
                font: 8,
                justify: 'left'
              }, null, 8, ["text"])
            ]),
            _createElementVNode("div", null, [
              (
              (!_ctx.isIn(asset) &&
                !_ctx.isCommunity &&
                _ctx.character?.type !== 'campaign') ||
              (_ctx.character?.type === 'campaign' &&
                _ctx.selectedTab === 'campaign' &&
                !_ctx.isIn(asset))
            )
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    id: 'btnAdd',
                    src: _imports_0,
                    title: "'Add'",
                    onClick: _withModifiers(($event: any) => (_ctx.btnAdd(asset, asset.key)), ["stop"])
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.isIn(asset) && !_ctx.isCommunity)
                ? (_openBlock(), _createBlock(_component_UILabel, {
                    key: 1,
                    text: 'Already added'
                  }))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.shouldShowEffectChooseDialog)
          ? (_openBlock(), _createBlock(_component_UIMenuView, {
              key: 0,
              items: ['Apply Behavior', 'Modify Vital', 'Remove Behavior'],
              onMenuItemSelectedAt: _ctx.onMenuSelect,
              onMenuViewDismiss: _ctx.dissmissMenu
            }, null, 8, ["onMenuItemSelectedAt", "onMenuViewDismiss"]))
          : _createCommentVNode("", true),
        _createVNode(_component_UIButton, {
          id: 'btnCreate',
          title: `Create new ${_ctx.title}`,
          onClick: _ctx.btnAddAsset
        }, null, 8, ["title", "onClick"])
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowSpellDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogSpellView, {
            key: 0,
            spell: _ctx.asset,
            isWorkshop: true,
            isNew: _ctx.isNew,
            readOnly: _ctx.selectedTab === 'community',
            onUiDialogSpellSubmit: _ctx.onSubmitSpellDialog,
            onUiDialogSpellCancel: _ctx.onCancelSpellDialog
          }, null, 8, ["spell", "isNew", "readOnly", "onUiDialogSpellSubmit", "onUiDialogSpellCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowQuickViewDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAssetQuickView, {
            key: 1,
            quickView: _ctx.quickViewDialogData,
            showActions: true,
            submitTitle: _ctx.quickViewSubmitTitle,
            actionsDisabled: true,
            onUiDialogQuickViewSubmit: _ctx.onSaveQuickViewDialog,
            onUiDialogQuickViewCancel: _ctx.onCancelQuickViewDialog,
            onUiDialogQuickViewMenu: _ctx.onMenuQuickViewDialog
          }, null, 8, ["quickView", "submitTitle", "onUiDialogQuickViewSubmit", "onUiDialogQuickViewCancel", "onUiDialogQuickViewMenu"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowGameSystemDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogGameSystemView, {
            key: 2,
            game_system: _ctx.asset,
            isWorkshop: true,
            isNew: _ctx.isNew,
            readOnly: _ctx.selectedTab === 'community',
            onUiDialogGameSystemSubmit: _ctx.onSubmitGameSystemDialog,
            onUiDialogGameSystemCancel: _ctx.onCancelGameSystemDialog
          }, null, 8, ["game_system", "isNew", "readOnly", "onUiDialogGameSystemSubmit", "onUiDialogGameSystemCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowItemDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogItemView, {
            key: 3,
            item: _ctx.asset,
            isWorkshop: true,
            isNew: _ctx.isNew,
            readOnly: _ctx.selectedTab === 'community',
            onUiDialogItemSubmit: _ctx.onSubmitItemDialog,
            onUiDialogItemCancel: _ctx.onCancelItemDialog
          }, null, 8, ["item", "isNew", "readOnly", "onUiDialogItemSubmit", "onUiDialogItemCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowResourceDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogResourceView, {
            key: 4,
            resource: _ctx.asset,
            isWorkshop: true,
            isNew: _ctx.isNew,
            readOnly: _ctx.selectedTab === 'community',
            onUiDialogResourceSubmit: _ctx.onSubmitResourceDialog,
            onUiDialogResourceCancel: _ctx.onCancelResourceDialog
          }, null, 8, ["resource", "isNew", "readOnly", "onUiDialogResourceSubmit", "onUiDialogResourceCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowAttributeDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAttributeView, {
            key: 5,
            attribute: _ctx.asset,
            isWorkshop: true,
            isNew: _ctx.isNew,
            readOnly: _ctx.selectedTab === 'community',
            onUiDialogAttributeSubmit: _ctx.onSubmitAttributeDialog,
            onUiDialogAttributeCancel: _ctx.onCancelAttributeDialog
          }, null, 8, ["attribute", "isNew", "readOnly", "onUiDialogAttributeSubmit", "onUiDialogAttributeCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowModifyVitalDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogModifyVitalView, {
            key: 6,
            effect: _ctx.asset,
            isWorkshop: true,
            isNew: _ctx.isNew,
            readOnly: _ctx.selectedTab === 'community',
            onUiDialogEffectSubmit: _ctx.onSubmitModifyVitalDialog,
            onUiDialogEffectCancel: _ctx.onCancelModifyVitalDialog
          }, null, 8, ["effect", "isNew", "readOnly", "onUiDialogEffectSubmit", "onUiDialogEffectCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}