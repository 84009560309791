import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5357dc84"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "href", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    id: _ctx.id,
    class: "UILink",
    href: _ctx.href,
    disabled: !_ctx.is_enabled
  }, _toDisplayString(_ctx.title), 9, _hoisted_1))
}