<template>
  <div v-if="shouldShowMobileMenu" class="overlay" @click.self="dismissMobileMenu"></div>
  <div v-if="shouldShowMobileMenu" class="mobile-menu">
    <div>
      <a href="/galveon">
        <img
          alt="Galveon logo"
          src="@/assets/galveon@x2.png"
          width="64"
          height="64"
        />
      </a>
    </div>
    <UISeparatorView />
    <div class="vMobileNavContainer">
      <ul class="vMobileNav">
        <router-link to="/galveon/compendium" v-if="isLoggedIn">
          <UILabel :text="'Compendium'" :justify="'left'" />
        </router-link>
        <UISeparatorView v-if="isLoggedIn && isAdmin" />
        <router-link
          to="/galveon/multiplayer"
          v-if="isLoggedIn && isAdmin"
        >
          <UILabel :text="'Multiplayer'" :justify="'left'" />
        </router-link>
        <UISeparatorView v-if="isLoggedIn && isAdmin" />
        <router-link to="/galveon/forums" v-if="isAdmin">
          <UILabel :text="'Forums'" :justify="'left'" />
        </router-link>
        <UISeparatorView v-if="isAdmin" />
        <router-link to="/galveon/admin" v-if="isLoggedIn && isAdmin">
          <UILabel :text="'Admin'" :justify="'left'" />
        </router-link>
        <UISeparatorView v-if="isLoggedIn && isAdmin" />
      </ul>
      <AccountContents
        @clicked="btnMenuAccount(!isMenuShown)"
        @menu-account-dismiss="menuAccountDismiss"
      />
    </div>
  </div>
  <div class="account-placeholder"></div>
  <div class="account">
    <div class="account-container">
      <CharacterSelection
        v-if="shouldShowChracterSelection"
        @dismiss-character-selection="onDismissCharacterSelection"
      />
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div class="vGalveon">
          <a href="/galveon">
            <img alt="Galveon logo" src="@/assets/galveon@x2.png" />
          </a>
        </div>
        <div class="vGalveonMobile" @click="showMobileMenu">
          <img alt="Galveon menu" src="@/assets/icon_menu_alt_2@x2.png" />
        </div>
        <ul class="vNav">
          <router-link to="/galveon/compendium" v-if="isLoggedIn">
            <UILabel :text="'Compendium'" />
          </router-link>
          <router-link
            to="/galveon/multiplayer"
            v-if="isLoggedIn && isAdmin"
          >
            <UILabel :text="'Multiplayer'" />
          </router-link>
          <router-link to="/galveon/forums" v-if="isAdmin">
            <UILabel :text="'Forums'" />
          </router-link>
          <router-link to="/galveon/admin" v-if="isLoggedIn && isAdmin">
            <UILabel :text="'Admin'" />
          </router-link>
        </ul>
      </div>
      <div class="vAccount">
        <MenuAccount
          v-if="isMenuShown"
          @clicked="btnMenuAccount(!isMenuShown)"
          @menu-account-dismiss="menuAccountDismiss"
        />
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
          v-if="hasCharacters"
        >
          <UIActivityView
            style="cursor: pointer"
            :radius="34"
            :progress="mainResourcePercentage"
            :stroke="4"
            :color="mainResourceColor"
            :img="getPortrait.url"
            :hasImage="true"
            @ui-activity-view-portrait-press="pushToCharacter()"
          />
          <UILabel
            style="width: 150px; cursor: pointer; max-height: 44px"
            v-if="isLoggedIn"
            :text="character_name"
            @click="onDisplayCharacterSelection"
          />
        </div>
        <UIButton
          v-if="!hasCharacters && isLoggedIn"
          id="'btnAddFirstCharacter'"
          title="Add Character"
          @click="btnAddCharacter"
        />
        <UIButton
          :id="'btnMenuAccount'"
          title="Account"
          @click="btnMenuAccount(!isMenuShown)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import { getAuth } from "firebase/auth";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import MenuAccount from "@/components/galveon/MenuAccount.vue";
import AccountContents from "@/components/galveon/AccountContents.vue";
import CharacterSelection from "@/components/character/CharacterSelection.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import UIActivityView from "@/components/UI/UIActivityView.vue";
import MainResource from "@/store/models/MainResource";
import { componentToHex } from "@/utility/global";

export default defineComponent({
  name: "Account",
  components: {
    UIButton,
    MenuAccount,
    UILabel,
    CharacterSelection,
    UIActivityView,
    UISeparatorView,
    AccountContents,
  },
  data() {
    return {
      isMenuShown: false,
      shouldShowChracterSelection: false,
      shouldShowMobileMenu: false,
    };
  },
  methods: {
    btnMenuAccount(toggle: boolean) {
      this.isMenuShown = toggle;
    },
    menuAccountDismiss() {
      this.isMenuShown = false;
    },
    onDisplayCharacterSelection() {
      this.shouldShowChracterSelection = true;
    },
    onDismissCharacterSelection() {
      this.shouldShowChracterSelection = false;
    },
    pushToCharacter() {
      this.$router.push("/galveon/character");
    },
    btnAddCharacter() {
      this.$router.push("/galveon/create/character");
    },
    showMobileMenu() {
      this.shouldShowMobileMenu = true;
    },
    dismissMobileMenu() {
      this.shouldShowMobileMenu = false;
    },
  },
  computed: {
    ...mapGetters("Account", ["hasCharacters", "isAdmin"]),
    ...mapGetters("Character", ["getMainVital", "getName", "getPortrait"]),
    isLoggedIn(): boolean {
      return getAuth().currentUser !== null;
    },
    character_name(): string {
      return this.getName;
    },
    mainResource(): MainResource {
      return this.getMainVital;
    },
    mainResourcePercentage(): number {
      let percentage =
        (this.mainResource.current / this.mainResource.max) * 100;
      if (percentage === Infinity || isNaN(percentage)) {
        return 100;
      }
      return Math.trunc(
        (this.mainResource.current / this.mainResource.max) * 100
      );
    },
    mainResourceColor(): string {
      const red = this.mainResource.red;
      const green = this.mainResource.green;
      const blue = this.mainResource.blue;

      return componentToHex(red, green, blue);
    },
  },
});
</script>

<style lang="scss" scoped>
.vMobileNavContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}
.vMobileNav {
  padding: 0;
  padding-left: 8px;
}
.mobile-menu {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;

  transition: 0.4s ease;
}

.account-placeholder {
  height: 70px;
}
.account {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.16);

  background: #ffffff;

  z-index: 1;

  max-height: 70px;
}

.account-container {
  display: flex;
  justify-content: space-between;
  margin: auto;

  max-width: var(--maxWidth);
}

.vAccount {
  position: relative;

  display: flex;

  align-items: center;

  border-left: 1px solid lightgrey;

  height: 70px;
}

.vNav {
  display: flex;
  padding: 0;
  height: 100%;
  align-items: center;
  margin: 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.vNav li {
  list-style: none;
  padding: 0 20px;
}

.vGalveon {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.vGalveonMobile {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
  }
}
</style>