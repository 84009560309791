import Cost from "@/store/models/Cost";
import CostResource from "@/store/models/CostResource";
import Resource from "@/store/models/Resource";
import Spell from "@/store/models/Spell";
import Spellbook from "@/store/models/Spellbook";
import SpellTable from "@/store/models/SpellTable";
import { database_save, newKey, setDatabaseCharacter } from "@/utility/database";
import { convertKeysLocalFormula } from "@/utility/tooltip";

export const spellGetters = {
  getSelectedSpellBookKey: (state: any): string => {
    return state.character.selected_spell_book_key;
  },
  getSpellBooks: (state: any): Array<Spellbook> => {
    return state.character.spell_books;
  },
  getSpellTables: (state: any): Array<SpellTable> => {
    return state.character.spell_tables;
  },
  getSpells: (state: any): Array<Spell> => {
    return state.character.spells;
  },
};

export const spellMutations = {
  setCurrentBook: (state: any, key: string) => {
    state.character.selected_spell_book_key = key;
  },
  setBook: (state: any, value: Spellbook) => {
    if (!value.key) {
      value.key = newKey();
    }

    const index = state.character.spell_books.findIndex((c: Spellbook) => c.key == value.key);

    if (index > -1) {
      //Book exists

      state.character.spell_books[index] = value;
    }
    else {
      //Book doesn't exist
      state.character.spell_books.push(value);
      state.character.selected_spell_book_key = value.key;
    }
  },
  deleteBook: (state: any, index: number) => {
    state.character.spell_books.splice(index, 1);
  },
  setSpellTable: (state: any, value: SpellTable) => {
    if (!value.key) {
      value.key = newKey();
    }

    const index = state.character.spell_tables.findIndex((c: SpellTable) => c.key == value.key);

    if (index > -1) {
      //Tab exists

      state.character.spell_tables[index] = value;
    }
    else {
      state.character.spell_tables.push(value);
    }
  },
  removeSpellTable: (state: any, index: number) => {
    state.character.spell_tables.splice(index, 1);
  },
  setSpell: (state: any, value: Spell) => {
    const index = state.character.spells.findIndex((c: Spell) => c.key == value.key);

    if (index > -1) {
      state.character.spells.splice(index, 1, value);
    }
    else {
      state.character.spells.push(value);
    }
  },
  addSpell: (state: any, value: Spell) => {
    state.character.spells.push(value);
  },
  removeSpell: (state: any, index: number) => {
    state.character.spells.splice(index, 1);
  },
};

export const spellActions = {
  setCurrentBook: ({ commit, state, dispatch }: any, key: string) => {
    return new Promise((resolve, reject) => {
      commit('setCurrentBook', key);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setBook: async ({ commit, state, dispatch }: any, val: Spellbook) => {
    return new Promise((resolve, reject) => {
      commit("setBook", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  deleteBook: async ({ commit, state, dispatch }: any) => {
    return new Promise((resolve, reject) => {
      const index = state.character.spell_books.findIndex((s: Spellbook) => s.key == state.character.selected_spell_book_key);
      if (index > -1) {
        const oldBook: Spellbook = state.character.spell_books[index];

        commit('deleteBook', index);
        let removedTableIndexes: Array<number> = [];
        let removedSpellIndex: Array<number> = [];

        state.character.spell_tables.forEach((table: SpellTable, tableIndex: number) => {
          if (table.key_spell_book === oldBook.key) {
            state.character.spells.forEach((spell: Spell, spellIndex: number) => {
              if (spell.key_spell_table === table.key) {
                removedSpellIndex.push(spellIndex);
              }
            });
            removedTableIndexes.push(tableIndex);
          }
        });
        removedTableIndexes.reverse();
        removedSpellIndex.reverse();

        removedSpellIndex.forEach((n: number) => {
          commit("removeSpell", n);
        });

        removedTableIndexes.forEach((n: number) => {
          commit("removeSpellTable", n);
        });

        let newBook: Spellbook = state.character.spell_books[state.character.spell_books.length - 1];

        state.character.selected_spell_book_key = newBook == null ? "" : newBook.key;

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
  setSpellTable: async ({ commit, state, dispatch }: any, val: SpellTable) => {
    return new Promise((resolve, reject) => {
      commit("setSpellTable", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  removeSpellTable: async ({ commit, state, dispatch }: any, value: SpellTable) => {
    return new Promise((resolve, reject) => {
      const index = state.character.spell_tables.findIndex((s: SpellTable) => s.key == value.key);

      if (index > -1) {
        const oldTable: SpellTable = state.character.spell_tables[index];

        commit('removeSpellTable', index);
        let removedSpellIndex: Array<number> = [];
        state.character.spells.forEach((spell: Spell, spellIndex: number) => {
          if (spell.key_spell_table === oldTable.key) {
            removedSpellIndex.push(spellIndex);
          }
        });

        removedSpellIndex.reverse();

        removedSpellIndex.forEach((n: number) => {
          commit("removeSpell", n);
        });

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
  setSpell: async ({ commit, state, dispatch }: any, val: Spell) => {
    return new Promise((resolve, reject) => {
      commit("setSpell", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  addSpell: async ({ commit, state, dispatch }: any, val: Spell) => {
    return new Promise((resolve, reject) => {
      commit("addSpell", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  removeSpell: async ({ commit, state, dispatch }: any, val: Spell) => {
    return new Promise((resolve, reject) => {
      const index = state.character.spells.findIndex((c: Spell) => c.key == val.key);

      if (index > -1) {
        commit("removeSpell", index);

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
  castSpell: ({ state, dispatch }: any, spell: Spell) => {
    let maxLevel: number = state.character.level
    let cost: Cost = typeof spell.costs[maxLevel] === 'undefined' ? spell.costs[spell.costs.length - 1] : spell.costs[maxLevel];

    const resources: Resource[] = state.character.resources;
    let costResources: CostResource[] = cost.resources;

    for (const costResource of costResources) {
      const index: number = resources.findIndex((r: Resource) => r.key === costResource.key);
      if (index > -1) {
        const tests = {
          level: state.character.level,
          value: "0",
          main_resource: state.character.main_vital.max
        };

        const [value, result] = convertKeysLocalFormula(costResource.value, tests);
        if (value) {
          state.character.resources[index].current = state.character.resources[index].current - (+value);
        }

        dispatch("setResource", state.character.resources[index]);
      }
    }

    let costResourceFractions: CostResource[] = cost.resourceFractions;

    for (const costResourceFraction of costResourceFractions) {
      const index: number = resources.findIndex((r: Resource) => r.key === costResourceFraction.key);
      if (index > -1) {
        const tests = {
          level: state.character.level,
          value: "0",
          main_resource: state.character.main_vital.max
        };

        const value = convertKeysLocalFormula(costResourceFraction.value, tests);
        let subtractValue = state.character.resources[index].max * +value;
        state.character.resources[index].current = state.character.resources[index].current - subtractValue;

        dispatch("setResource", state.character.resources[index]);
      }
    }
  }
};
