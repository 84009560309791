<template>
  <div class="character">
    <Account />
    <UIOnboardView
      v-if="!currentCharacterKey"
      :title="'To get started, add a character'"
      :buttonTitle="'Add Character'"
      @ui-onboard-view="addCharacter"
    />
    <CharacterView v-if="currentCharacterKey" :character="character" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";

import Account from "@/components/galveon/Account.vue";

import CharacterView from "@/components/character/CharacterView.vue";

import UICollectionView from "@/components/UI/UICollectionView.vue"; // @ is an alias to /src
import CharacterTabUICollectionViewCell from "@/components/character/CharacterTabUICollectionViewCell.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "Character",
  components: {
    UICollectionView,
    CharacterTabUICollectionViewCell,
    Account,
    CharacterView,
    UIOnboardView,
    UISeparatorView,
  },
  props: {
    isSelected: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions("Account", ['createCharacter']),
    ...mapActions("Character", ["addListenerCampaignCharacter"]),
    addCharacter() {
      this.createCharacter({});
    },
  },
  computed: {
    ...mapGetters("Character", ["getCharacterKey"]),
    ...mapState("Character", ["character"]),
    currentCharacterKey(): string {
      return this.getCharacterKey;
    },
  },
  mounted() {
    this.addListenerCampaignCharacter();
  }
});
</script>
