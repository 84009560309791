<template>
  <UIDialogView
    :title="'Chen\'s Shop'"
    :submitTitle="hasBlackMarket ? 'Already owned' : 'Purchase'"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <div class="PurchaseChenShop">
      <div>
        <img alt="Galveon logo" src="@/assets/galveon@x2.png" />
      </div>
      <ul>
        <li><UILabel :text="'Unlimited characters'" :justify="'left'" /></li>
        <li>
          <UILabel
            :text="'Unlimited Bags, Tabs, and Books'"
            :justify="'left'"
          />
        </li>
        <li>
          <UILabel :text="'Access to community store'" :justify="'left'" />
        </li>
      </ul>

      <UILabel :text="price" :font="12" :justify="'right'" />
    </div>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import { getFunctions, httpsCallable } from "firebase/functions";

import { loadStripe } from "@stripe/stripe-js";

export default defineComponent({
  name: "UIDialogPurchaseChenShop",
  components: {
    UIDialogView,
    UILabel,
    UIButton,
  },
  data() {
    return {};
  },
  emits: ["ui-dialog-purchase-cancel"],
  computed: {
    ...mapGetters("Account", ["hasBlackMarket"]),
    price(): string {
      return this.hasBlackMarket ? "Already owned" : "$2.99";
    },
  },
  methods: {
    onSubmit() {
      if (this.hasBlackMarket) return;

      const createStripeCheckout = httpsCallable(
        getFunctions(),
        "addBlackMarketStripe"
      );
      createStripeCheckout({ test: false }).then(async (result: any) => {
        const sessionId = result.data.id;

        const stripe = await loadStripe(
          "pk_live_51InUwBLBArjQ1sQlj8CnVlNDrKSSP6HswTWom6wFutBeX2ID3KD8kZBdGroGMkaSYKiVhrpjCA5wpZKJ42gao6S200cuHhZDle"
        );
        stripe?.redirectToCheckout({
          sessionId: sessionId,
        });
      });
    },
    onCancel() {
      this.$emit("ui-dialog-purchase-cancel");
    },
  }
});
</script>

<style lang="scss" scoped>
</style>