<template>
  <div v-if="shouldShowMenu" class="overlay" @click.stop="onMenuDismiss"></div>
  <div class="UIDropDownField" @click="showMenu">
    <div class="UIDropDownSelectedItem">
      <UILabel :text="computedValue" />
    </div>

    <div v-if="shouldShowMenu" class="UIDropDownView">
      <div
        class="UIDropDownItemView"
        v-for="(item, index) in items"
        :key="index"
        @click.stop="dropDownItemSelected(index)"
      >
        <UILabel :text="item" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: "UIDropDownView",
  components: {
    UILabel,
  },
  emits: [
    "drop-down-item-selected-item",
    "drop-down-item-selected-at",
    "drop-down-view-selected",
    "drop-down-view-dismiss",
    "update:modelValue",
  ],
  props: {
    modelValue: {
      type: [Number, String],
      required: true,
      default: "None",
    },
    items: {
      type: Array,
      default: [] as Array<String>,
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      shouldShowMenu: false,
      internalValue: this.modelValue,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string | number) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  methods: {
    showMenu() {
      this.shouldShowMenu = true;
      this.$emit("drop-down-view-selected");
    },
    dismissMenu() {
      this.shouldShowMenu = false;
    },
    dropDownItemSelected(index: number) {
      const selectedItem = this.items[index] as string;
      this.internalValue = selectedItem;
      this.$emit("update:modelValue", selectedItem);
      this.$emit("drop-down-item-selected-item", selectedItem);
      this.$emit("drop-down-item-selected-at", index);
      this.onMenuDismiss();
    },
    onMenuDismiss() {
      this.dismissMenu();
      this.$emit("drop-down-view-dismiss");
    },
  },
  mounted() {},
});
</script>

<style lang="scss">
.UIDropDownField {
  font: 400 16px / 24px "Helvetica Neue", "Helvetica", "Open Sans", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  box-sizing: border-box;

  margin-top: 16px;
  padding-left: 8px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  outline: 0;

  cursor: pointer;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}
.UIDropDownSelectedItem {
  width: 100%;
}
.UIDropDownView {
  position: absolute;
  right: 0;
  left: 0;
  top: 34px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;

  z-index: 2;

  height: 132px;
  overflow: auto;
}

.UIDropDownItemView {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);

  color: var(--textColor);

  padding: 8px 16px;

  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.16);
  }
}

.overlay {
  width: 100%;
  height: 100%;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>