import { User, Auth, getAuth, reauthenticateWithCredential, EmailAuthProvider, updateEmail, updateProfile, updatePassword, deleteUser, sendEmailVerification, signInWithEmailAndPassword, createUserWithEmailAndPassword, getIdTokenResult } from 'firebase/auth';
import { getDatabase, ref, set, child, DatabaseReference, get } from 'firebase/database';
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// const functions = getFunctions();

import router from '@/router';

import { IUser } from '@/store/interfaces/IUser';
import IWorkshop from '@/store/interfaces/IWorkshop';
import { newKey, newFirestoreKey, database_save, database_option, setDatabaseAccount, setDatabaseWorkshop, setFirestore, setDatabaseCharacter, getCommunityGameSystems } from '@/utility/database';

import CharacterClass from '../models/Character';
import GameSystem from '../models/GameSystem';
import CompendiumAssetBase from '../models/Compendium/CompendiumAssetBase';
import AttributeBase from '../models/AssetBases/AttributeBase';
import ItemBase from '../models/AssetBases/ItemBase';
import SpellBase from '../models/AssetBases/SpellBase';
import ResourceBase from '../models/AssetBases/ResourceBase';
import Item from '../models/Item';
import Resource from '../models/Resource';
import Spell from '../models/Spell';
import Attribute from '../models/Attribute';
import { AssetType } from '../models/Compendium/AssetType';
import ModifyVital from '../models/Effects/ModifyVital';
import EffectBase from '../models/AssetBases/EffectBase';
import { EffectType } from '../interfaces/types/EffectType';
import ApplyBehavior from '../models/Effects/ApplyBehavior';
import RemoveBehavior from '../models/Effects/RemoveBehavior';
import ModifyUnit from '../models/Effects/ModifyUnit';
import Set from '../models/Effects/Set';

const state = {
  user: {} as IUser,
  characters: [] as Array<CharacterClass>,
  templateCharacters: [] as Array<CharacterClass>,
  campaignCharacters: [] as Array<CharacterClass>,
  workshop: {} as IWorkshop,
  Selected_Character: "",
  selectedCharacter: {} as CharacterClass || null,
  admin: false,
  galveonTag: undefined,
}

const getters = {
  getGalveonUser: (state: any): IUser => {
    return state.user;
  },
  hasCharacters: (state: any): boolean => {
    return state.characters.length > 0;
  },
  hasBlackMarket: (state: any): boolean => {
    return state.user.MarketPlace;
  },
  countCharacter: (state: any): number => {
    return state.characters.length;
  },
  getCharacters: (state: any): Array<CharacterClass> => {
    return state.characters;
  },
  getTemplateCharacters: (state: any): Array<CharacterClass> => {
    return state.templateCharacters;
  },
  getCampaignCharacters: (state: any): Array<CharacterClass> => {
    return state.campaignCharacters;
  },
  getEmail: (): string | null | undefined => {
    const auth: Auth = getAuth();
    return auth.currentUser?.email;
  },
  getDispalyName: (): string | null | undefined => {
    const auth: Auth = getAuth();
    return auth.currentUser?.displayName || 'Add a Display name';
  },
  getUid: (): string | undefined => {
    const auth: Auth = getAuth();
    return auth.currentUser?.uid;
  },
  getSelectedCharacter: (): CharacterClass | null => {
    if (getters.getCharacterById(state.selectedCharacter)) {
      const index = state.characters.findIndex((c: CharacterClass) => c.key === state.Selected_Character);

      if (index > -1) {
        return state.characters[index];
      }
      if (state.characters.length > 0) {
        let possibleCharacter = state.characters[0];
        if (possibleCharacter.campaign_id === null && possibleCharacter.type !== 'template') {
          return state.characters[0];
        }
      }
    }

    return state.selectedCharacter;
  },
  isAdmin: (): boolean => {
    return state.admin;
  },
  getGalveonTag: (): string | undefined => {
    return state.galveonTag;
  },
  getGalveonUserId: (): string | null => {
    return state.user.galveon_user_id;
  },
  getCharacterByPartyId: (state: any) => (key: string): CharacterClass | null => {
    return state.characters.find((c: CharacterClass) => c.party_id === key && c.type === 'campaign');
  },
  getCharacterById: (state: any) => (key: string): CharacterClass | null => {
    return state.characters.find((c: CharacterClass) => c.key === key);
  },
  getWorkshop: (state: any): IWorkshop => {
    return state.workshop;
  },
  getWorkshopResources: (state: any): ResourceBase[] => {
    return state.workshop.resource;
  },
  getWorkshopGameSystem: (state: any): GameSystem[] => {
    return state.workshop.game_system;
  },
  getGameSystems: (state: any): GameSystem[] => {
    return state.user.game_systems;
  },
  getGameSystemWith: (state: any) => (key: string): GameSystem | undefined => {
    const publicGameSystem = state.user.publicGameSystems.find((gameSystem: GameSystem) => gameSystem.key === key);
    if (publicGameSystem) {
      return publicGameSystem;
    }

    const workshopGameSystem = state.workshop.game_system.find((gameSystem: GameSystem) => gameSystem.key === key);
    if (workshopGameSystem) {
      return workshopGameSystem;
    }

    return undefined;
  }
}

const mutations = {
  setAdmin: (state: any, val: boolean) => {
    state.admin = val;
  },
  setGalveonTag: (state: any, val: boolean) => {
    state.galveonTag = val;
  },
  setGalveonUserId: (state: any, val: string) => {
    state.user.galveon_user_id = val;
  },
  setUserAccount: (state: any, val: IUser) => {
    state.user = val;
    state.Selected_Character = val.SelectedCharacter;
    state.galveon_user_id = val.galveon_user_id;

    mutations.createWorkshop(val);
  },
  setSelectedCharacter: (state: any, val: CharacterClass) => {
    state.selectedCharacter = val;
  },
  setSelectedCharacterKey: (state: any, val: string | null) => {
    state.Selected_Character = val;
  },
  addCharacter: (state: any, val: CharacterClass) => {
    state.characters.push(val);
  },
  setCharacters: (state: any, characters: Array<any>) => {
    for (const index in characters) {
      const selectedCharacter = new CharacterClass(characters[index], characters[index].key, state.workshop);
      selectedCharacter.loadImage();
      state.characters.push(selectedCharacter);
    }
  },
  setTemplateCharacters: (state: any, characters: Array<any>) => {
    for (const index in characters) {
      const selectedCharacter = new CharacterClass(characters[index], characters[index].key, state.workshop);
      selectedCharacter.loadImage();
      state.templateCharacters.push(selectedCharacter);
    }
  },
  setCampaignCharacters: (state: any, characters: Array<any>) => {
    for (const index in characters) {
      const selectedCharacter = new CharacterClass(characters[index], characters[index].key, state.workshop);
      selectedCharacter.loadImage();
      state.campaignCharacters.push();
    }
  },
  createWorkshop(val: IUser) {
    state.workshop = {
      attribute: [],
      spell: [],
      item: [],
      resource: [],
      game_system: [],
      effect: [],
      modify_vital: [],
      modify_unit: [],
      apply_behavior: [],
      remove_behavior: [],
      effect_set: [],
    }


    let workshopAttributes = val.Workshop?.attribute;
    for (var attributeKey in workshopAttributes) {
      let attribute: AttributeBase = new AttributeBase(workshopAttributes[attributeKey], attributeKey);
      state.workshop.attribute.push(attribute);
    }

    let workshopItems = val.Workshop?.item;
    for (var itemKey in workshopItems) {
      let item: ItemBase = new ItemBase(workshopItems[itemKey], itemKey);
      state.workshop.item.push(item);
    }

    let workshopSpells = val.Workshop?.spell;
    for (var spellKey in workshopSpells) {
      let spell: SpellBase = new SpellBase(workshopSpells[spellKey], spellKey);
      state.workshop.spell.push(spell);
    }

    let workshopResources = val.Workshop?.resource;
    for (var resourceKey in workshopResources) {
      let resource: ResourceBase = new ResourceBase(workshopResources[resourceKey], resourceKey);
      state.workshop.resource.push(resource);
    }

    let gameSystems = val.Workshop?.game_system;
    for (var gameSystemKey in gameSystems) {
      let gameSystem: GameSystem = new GameSystem(gameSystems[gameSystemKey], gameSystemKey);
      state.workshop.game_system.push(gameSystem);
    }

    let effects = val.Workshop?.effect;
    for (var effectKey in effects) {
      let effect_type: EffectType = effects[effectKey].effect_type;
      switch (effect_type) {
        case EffectType.apply_behavior:
          let effect_ApplyBehavior: ApplyBehavior = new ApplyBehavior(effects[effectKey], effectKey);
          state.workshop.apply_behavior.push(effect_ApplyBehavior);
          state.workshop.effect.push(effect_ApplyBehavior);
          break;
        case EffectType.effect_set:
          let effect_Set: Set = new Set(effects[effectKey], effectKey);
          state.workshop.effect_set.push(effect_Set);
          state.workshop.effect.push(effect_Set);
          break;
        case EffectType.modify_unit:
          let effect_ModifyUnit: ModifyUnit = new ModifyUnit(effects[effectKey], effectKey);
          state.workshop.modify_unit.push(effect_ModifyUnit);
          state.workshop.effect.push(effect_ModifyUnit);
          break;
        case EffectType.modify_vital:
          let effect_ModifyVital: ModifyVital = new ModifyVital(effects[effectKey], effectKey);
          state.workshop.modify_vital.push(effect_ModifyVital);
          state.workshop.effect.push(effect_ModifyVital);
          break;
        case EffectType.remove_behavior:
          let effect_RemoveBehavior: RemoveBehavior = new RemoveBehavior(effects[effectKey], effectKey);
          state.workshop.remove_behavior.push(effect_RemoveBehavior);
          state.workshop.effect.push(effect_RemoveBehavior);
          break;
      }
      
      
    }
  },
  setWorkshopAssetItem: (state: any, val: ItemBase) => {
    const index = Account.state.workshop.item.findIndex((c: ItemBase) => c.key === val.key);
    if (index > -1) {
      state.workshop.item[index] = val;
    } else {
      state.workshop.item.push(val);
    }
  },
  deleteWorkshopAssetItem: (state: any, val: ItemBase) => {
    const index = state.workshop.item.findIndex((c: ItemBase) => c.key === val.key);

    if (index > -1) {
      state.workshop.item.splice(index, 1);
    }
  },
  setWorkshopAssetAttribute: (state: any, val: AttributeBase) => {
    const index = state.workshop.attribute.findIndex((c: AttributeBase) => c.key === val.key);
    if (index > -1) {
      state.workshop.attribute[index] = val;
    } else {
      state.workshop.attribute.push(val);
    }
  },
  deleteWorkshopAssetAttribute: (state: any, val: AttributeBase) => {
    const index = state.workshop.attribute.findIndex((c: AttributeBase) => c.key === val.key);

    if (index > -1) {
      state.workshop.attribute.splice(index, 1);
    }
  },
  setWorkshopAssetResource: (state: any, val: ResourceBase) => {
    const index = state.workshop.resource.findIndex((c: ResourceBase) => c.key === val.key);
    if (index > -1) {
      state.workshop.resource[index] = val;
    } else {
      state.workshop.resource.push(val);
    }
  },
  deleteWorkshopAssetResource: (state: any, val: ResourceBase) => {
    const index = state.workshop.resource.findIndex((c: ResourceBase) => c.key === val.key);

    if (index > -1) {
      state.workshop.resource.splice(index, 1);
    }
  },
  setWorkshopAssetSpell: (state: any, val: SpellBase) => {
    const index = state.workshop.spell.findIndex((c: SpellBase) => c.key === val.key);
    if (index > -1) {
      state.workshop.spell[index] = val;
    } else {
      state.workshop.spell.push(val);
    }
  },
  deleteWorkshopAssetSpell: (state: any, val: SpellBase) => {
    const index = state.workshop.spell.findIndex((c: SpellBase) => c.key === val.key);

    if (index > -1) {
      state.workshop.spell.splice(index, 1);
    }
  },
  setWorkshopAssetGameSystem: (state: any, val: GameSystem) => {
    const index = state.workshop.game_system.findIndex((c: GameSystem) => c.key === val.key);
    if (index > -1) {
      state.workshop.game_system[index] = val;
    } else {
      state.workshop.game_system.push(val);
    }
  },
  deleteWorkshopAssetGameSystem: (state: any, val: GameSystem) => {
    const index = state.workshop.game_system.findIndex((c: GameSystem) => c.key === val.key);

    if (index > -1) {
      state.workshop.game_system.splice(index, 1);
    }
  },
  setWorkshopAssetModifyVital: (state: any, val: ModifyVital) => {
    const index = state.workshop.modify_vital.findIndex((c: ModifyVital) => c.key === val.key);
    if (index > -1) {
      state.workshop.modify_vital[index] = val;

      const effectIndex = state.workshop.effect.findIndex((e: EffectBase) => e.key === val.key);
      if (effectIndex > -1) {
        state.workshop.effect[effectIndex] = val;
      }
    } else {
      state.workshop.modify_vital.push(val);
      state.workshop.effect.push(val);
    }
  },
  deleteWorkshopAssetModifyVital: (state: any, val: ModifyVital) => {
    const index = state.workshop.modify_vital.findIndex((c: ModifyVital) => c.key === val.key);
    const indexEffect = state.workshop.effect.findIndex((c: ModifyVital) => c.key === val.key);

    if (index > -1) {
      state.workshop.modify_vital.splice(index, 1);
    }
    if (indexEffect > -1) {
      state.workshop.effect.splice(index, 1);
    }
  },
}

const actions = {
  async setEmail({ }: any, value: string) {
    const auth = getAuth();
    return updateEmail(auth.currentUser!, value);
  },
  async setDisplayName({ }: any, value: string) {
    const auth = getAuth();
    return updateProfile(auth.currentUser!, {
      displayName: value
    });
  },
  async setPassword({ }: any, value: string) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) return;

    return updatePassword(user, value);
  },
  async deleteAccount({ }: any) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) return;

    return deleteUser(user);
  },
  async login({ }: any, form: any) {
    //Sign user in
    const auth: Auth = getAuth();
    await signInWithEmailAndPassword(auth, form.email, form.password);

    router.push('/galveon/character');
  },
  async reauthenticate({ }: any, password: string) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      router.push('/login');
      return;
    }

    const credential = EmailAuthProvider.credential(
      user?.email || "",
      password
    );

    return reauthenticateWithCredential(user, credential);

  },
  async signup({ dispatch }: any, form: any) {
    // sign user up
    const auth: Auth = getAuth();
    const { user } = await createUserWithEmailAndPassword(auth, form.email, form.password);
    if (user === null) {
      //TODO add error message
      return;
    }

    // create user profile object in userCollections
    const database: DatabaseReference = ref(getDatabase());
    await set(child(database, `Users/${user?.uid}`), {
      Player_Name: "",
    })
      .then(() => {
        sendEmailVerification(user).then(() => {
          // Email sent.
        }).catch((error) => {
          //TODO: An error happened.
        });
      });

    // // fetch user profile and set in state
    dispatch('Account/fetchUserAccount', user)
    router.push('/galveon/character'); //TODO: This maybe need to be changed because we may not want users to sign in without verification of their email to prevent spammers.
  },
  async logout({ commit }: any) {
    await getAuth().signOut()

    // clear userProfile and redirect to /login
    commit('setUserAccount', {})
    router.push('/login')
  },
  async fetchCharacterTemplates({ }: any, user: User) {
    return new Promise(async (resolve, reject) => {
      const firestore = getFirestore();

      const characterRef = collection(firestore, "characters");
      const q = query(characterRef, where("user_id", "==", user.uid), where("type", "==", "template"));

      const querySnapshot = await getDocs(q);
      let returnedValue: Array<any> = [];
      querySnapshot.forEach((doc) => {
        const characters = doc.data();

        returnedValue.push(characters);

      });
      resolve(returnedValue);
    });
  },
  async setCampaignCharacters({ commit }: any, user: User) {
    return new Promise(async (resolve, reject) => {
      const firestore = getFirestore();

      const characterRef = collection(firestore, "characters");
      const q = query(characterRef, where("user_id", "==", user.uid), where("type", "!=", "template"));

      const querySnapshot = await getDocs(q);
      let returnedValue: Array<any> = [];
      querySnapshot.forEach((doc) => {
        const characters = doc.data();

        returnedValue.push(characters);

      });
      commit("setCampaignCharacters", returnedValue);
      resolve(returnedValue);
    });
  },
  async setUserRoles({ commit }: any) {
    const auth = getAuth();
    const user = auth.currentUser;

    getIdTokenResult(user!).then((idTokenResult: any) => {
      commit('setAdmin', idTokenResult.claims.galveon);
      commit('setGalveonTag', idTokenResult.claims.galveonTag);
    });
  },
  async setGalveonUserId({ commit }: any, val: string) {
    return new Promise(async (resolve, reject) => {
      const createGalveonId = httpsCallable(
        getFunctions(),
        "createGalveonId"
      );

      createGalveonId({ galveon_user_id: val }).then((result: any) => {
        const response = result.data.response;
        const id = result.data.id;
        commit("setGalveonUserId", id);
        resolve(response == 200 ? true : false);
      });
    });
  },
  async setGameSystem({ commit, state }: any, key: string) {
    return new Promise(async (resolve, reject) => {
      console.log(state.user.game_systems);
      let currentGameSystems: any | undefined = state.user.game_systems;
      if (!currentGameSystems) {
        currentGameSystems = {};
      }
      const selectedGameSystem: GameSystem | undefined = currentGameSystems[key];
      if (selectedGameSystem) {
        resolve("You are already apart of this Game System!");
      }
      else {
        const addGameSystemToAccount = httpsCallable(
          getFunctions(),
          "addGameSystemToAccount"
        );
  
        addGameSystemToAccount({ game_system_key: key }).then((result: any) => {
          const response = result.data.response;
          const gameSystem = result.data.gameSystem;
          // commit("setGalveonUserId", id);
          console.log(response, gameSystem);
          resolve(response == 200 ? "Successfully added!" : "Something went wrong, try again later.");
        });
      }
    });
  },
  async fetchUserAccount({ commit, dispatch }: any, user: User) {
    const database: DatabaseReference = ref(getDatabase());

    return new Promise((resolve, reject) => {
      return get(child(database, `Users/${user.uid}`)).then((userAccountRef) => {
        if (!userAccountRef.exists()) {
          return;
        }

        let userAccount = userAccountRef.val();
        userAccount.email = user.email;


        return dispatch('migrateCharacters', userAccount.Characters)
          .then(async (characters: Array<any>) => {
            const charactersObject = { ...characters };
            const charactersArray: Array<any> = [];
            for (const key in charactersObject) {
              charactersArray.push({ ...charactersObject[key] });
            }

            userAccount.publicGameSystems = await getCommunityGameSystems();

            commit('setUserAccount', userAccount);

            const templates = await dispatch('fetchCharacterTemplates', user);
            const campaignCharacters = await dispatch('setCampaignCharacters', user);
            let allCharacters: Array<any> = [];
            allCharacters = allCharacters.concat(
              templates,
              campaignCharacters,
              charactersArray,
            );

            commit('setCharacters', allCharacters);
            commit('setTemplateCharacters', templates);

            return dispatch('setSelectedCharacter', getters.getSelectedCharacter()?.key)
              .then(() => {
                resolve(true);
              });
          });
      });
    });
  },
  async createCharacter({ commit, dispatch }: any, options: any) {
    return new Promise(async (resolve, reject) => {
      const baseCharacter = new CharacterClass(options, options?.campaign_id !== null ? newFirestoreKey() : newKey(), state.workshop);
      const auth = getAuth();
      const user = auth.currentUser;

      baseCharacter.user_id = user?.uid;

      if (options.type === 'campaign') {
        setDatabaseAccount('characters', baseCharacter.toDictionary(), database_save.firestoreAdd).then((key: any) => {
          const newCharacter = new CharacterClass(baseCharacter.toDictionary(), key, state.workshop);
          setDatabaseAccount('characters', newCharacter.toDictionary(), database_save.firestoreSet).then((key: any) => {
            commit('addCharacter', newCharacter);
            resolve(newCharacter);
          });
        });
      } else {
        setDatabaseAccount('Characters', { [baseCharacter.key]: baseCharacter.toDictionary() }, database_save.overwrite);
        commit('addCharacter', baseCharacter);
        resolve(baseCharacter);
      }
    });
  },
  async createCharacterWithTemplateKey({ dispatch }: any, key: string) {
    return new Promise((resolve, reject) => {
      const template: number = state.characters.findIndex((c: CharacterClass) => c.type === 'template' && c.key === key);
      const character: CharacterClass = state.characters[template];

      const newCharacter = new CharacterClass(character.toDictionary(), newKey(), state.workshop);
      newCharacter.type = 'none';
      newCharacter.loadImage();

      state.characters.push(newCharacter);

      setDatabaseAccount("Characters", { [newCharacter.key]: newCharacter.toDictionary() }, database_save.overwrite)
        .then(() => {
          dispatch('Account/setSelectedCharacter', newCharacter.key, { root: true });

          router.push('/galveon/character');
          resolve(true);
        });
    });
  },
  async setSelectedCharacterTemp({ commit, dispatch }: any, char: CharacterClass | null) {
    return new Promise((resolve, reject) => {
      if (char === null) resolve(false);
      commit('setSelectedCharacterKey', char?.key);
      dispatch('Character/setCharacter', char, { root: true });
      resolve(true);
    });
  },
  async setSelectedCharacter({ commit, dispatch }: any, characterId: string) {
    return new Promise((resolve, reject) => {
      if (state.characters.length < 1) {
        commit("setSelectedCharacterKey", "");
        commit("setSelectedCharacter", null);
        dispatch('Character/setCharacter', null, { root: true });
        setDatabaseAccount(null, { "SelectedCharacter": "" }, database_save.overwrite)
          .then(() => {
            resolve(true);
          });
        return;
      }

      const index = state.characters.findIndex((c: CharacterClass) => c.key == characterId);
      if (index == -1) {
        commit("setSelectedCharacterKey", state.characters[0].key);
        commit("setSelectedCharacter", state.characters[0]);
        dispatch('Character/setCharacter', state.characters[0], { root: true });
        //addCharacterListener();
        setDatabaseAccount(null, { "SelectedCharacter": state.characters[0].key }, database_save.overwrite)
          .then(() => {
            resolve(true);
          });
        return;
      }

      commit("setSelectedCharacterKey", state.characters[index].key);
      commit("setSelectedCharacter", state.characters[index]);
      dispatch('Character/setCharacter', state.characters[index], { root: true });
      //addCharacterListener()
      setDatabaseAccount(null, { "SelectedCharacter": state.characters[index].key }, database_save.overwrite)
        .then(() => {
          resolve(true);
        });
    });
  },
  async setSelectedCharacterKey({ commit, dispatch }: any, val: string) {
    return new Promise((resolve, reject) => {
      commit('setSelectedCharacterKey', val);

      setDatabaseAccount(null, { "SelectedCharacter": val }, database_save.overwrite)
        .then(() => {
          resolve(true);
        })
    });
  },
  async deleteCharacter({ commit, dispatch }: any, val: string) {
    const index = state.characters.findIndex((c: CharacterClass) => c.key == val);

    if (index > -1) {
      const deletedCharacter = state.characters.splice(index, 1);
      const type = deletedCharacter[0].type;
      const campaignId = deletedCharacter[0].campaign_id;

      if (type == "template" || campaignId != null) {
        return new Promise((resolve, reject) => {
          setFirestore(["characters", deletedCharacter[0].key], {}, database_save.firestoreRemove)
            .then(() => {
              let newSelectedCharacterKey = "";

              if (state.characters.length < 1) {
                newSelectedCharacterKey = state.characters[0].key;
              }

              dispatch("setSelectedCharacter", newSelectedCharacterKey);
              resolve(true);
            });
        });
      }
      else {
        return new Promise((resolve, reject) => {
          setDatabaseAccount("Characters", { [deletedCharacter[0].key]: {} }, database_save.remove)
            .then(() => {
              dispatch('setSelectedCharacter', "");
              resolve(true);
            });
        });
      }
    }
  },
  async setCharacterTemplateWithName({ state }: any, name: string) {
    return new Promise((resolve, reject) => {
      const index = state.characters.findIndex((c: CharacterClass) => c.key === state.Selected_Character);
      const character: CharacterClass = state.characters[index];

      resolve(setDatabaseAccount('characters', character.toDictionary(), database_save.firestoreSet));
    });
  },
  async migrateCharacters({ }: any, characters: any) {
    return new Promise(async (resolve, reject) => {
      if (characters === undefined || characters.length < 0) resolve([]);

      let migrated = false;

      for (const characterId in characters) {
        const character = characters[characterId];
        if (character.version === 4 && character.version !== undefined) continue;
        console.log("Needs migration...");
        migrated = true;
        break;
      }

      if (migrated) {
        const migrateCharacters = httpsCallable(
          getFunctions(),
          "migrateCharacters"
        );

        migrateCharacters({ characters: characters }).then((data: any) => {
          console.log("Migration complete");
          resolve(data.characters);
        });
      }
      else {
        resolve(characters);
      }
    });
  },
  setWorkshopAssetAttribute: ({ commit, state }: any, data: any) => {
    return new Promise((resolve, reject) => {
      let { attribute, shouldUpdateCharacter } = data;
      attribute.galveon_user_id = state.galveon_user_id;

      const compendiumAttribute: AttributeBase = new AttributeBase({ ...attribute.toDictionary() }, attribute.key);
      commit("setWorkshopAssetAttribute", compendiumAttribute);

      setDatabaseWorkshop(`attribute/${compendiumAttribute.key}`, compendiumAttribute.toDictionary(), database_save.overwrite);

      for (const charIndex in state.characters) {
        let selectedCharacter: CharacterClass = state.characters[charIndex];
        const attributes = selectedCharacter.attributes;
        const index = attributes.findIndex(r => r.key === attribute.key);

        if (index !== -1) {
          const currentAttribute: Attribute = attributes[index];

          if (attribute.key_attribute_group === "") {
            attribute.key_attribute_group = currentAttribute.key_attribute_group;
          }

          let compendiumAttribute: Attribute = new Attribute({ ...attribute.toDictionary() }, attribute.key);

          if (shouldUpdateCharacter) {
            if (selectedCharacter.key != state.Selected_Character) {
              compendiumAttribute.value = currentAttribute.value;
              compendiumAttribute.show_modifier = currentAttribute.show_modifier;
            }
          }
          selectedCharacter.attributes[index] = compendiumAttribute;

          setDatabaseCharacter(selectedCharacter, database_save.overwrite);
        }
      }
      resolve(true);
    });
  },
  deleteWorkshopAssetAttribute: ({ commit }: any, val: CompendiumAssetBase) => {
    return new Promise((resolve, reject) => {
      commit("deleteWorkshopAssetAttribute", val);

      setDatabaseWorkshop(`attribute/${val.key}`, {}, database_save.remove);
      resolve(true);
    });
  },
  setWorkshopAssetResource: ({ commit, state }: any, data: any) => {
    return new Promise((resolve, reject) => {
      let { resource, shouldUpdateCharacter } = data;
      resource.galveon_user_id = state.galveon_user_id;

      const compendiumResource: ResourceBase = new ResourceBase({ ...resource.toDictionary() }, resource.key);
      commit("setWorkshopAssetResource", compendiumResource);

      setDatabaseWorkshop(`resource/${compendiumResource.key}`, compendiumResource.toDictionary(), database_save.overwrite);

      for (const charIndex in state.characters) {
        let selectedCharacter: CharacterClass = state.characters[charIndex];
        const resources = selectedCharacter.resources;
        const index = resources.findIndex(r => r.key === resource.key);

        if (index !== -1) {
          const currentResource: Resource = resources[index];

          let compendiumResource: Resource = new Resource({ ...resource.toDictionary() }, resource.key);

          if (shouldUpdateCharacter) {
            if (selectedCharacter.key != state.Selected_Character) {
              compendiumResource.blue = currentResource.blue;
              compendiumResource.count_down = currentResource.count_down;
              compendiumResource.current = currentResource.current;
              compendiumResource.green = currentResource.green;
              compendiumResource.max = currentResource.max;
              compendiumResource.red = currentResource.red;
              compendiumResource.show_current = currentResource.show_current;
              compendiumResource.show_max = currentResource.show_max;
              compendiumResource.show_name = currentResource.show_name;
              compendiumResource.show_percentage = currentResource.show_percentage;
              compendiumResource.show_value = currentResource.show_value;
            }
          }
          selectedCharacter.resources[index] = compendiumResource;

          setDatabaseCharacter(selectedCharacter, database_save.overwrite);
        }
      }

      resolve(true);
    });
  },
  deleteWorkshopAssetResource: ({ commit }: any, val: CompendiumAssetBase) => {
    return new Promise((resolve, reject) => {
      commit("deleteWorkshopAssetResource", val);

      setDatabaseWorkshop(`resource/${val.key}`, {}, database_save.remove);
      resolve(true);
    });
  },
  setWorkshopAssetSpell: ({ commit, state }: any, data: any) => {
    return new Promise((resolve, reject) => {
      let { spell, shouldUpdateCharacter } = data;
      spell.galveon_user_id = state.galveon_user_id;

      const compendiumSpell: SpellBase = new SpellBase({ ...spell.toDictionary() }, spell.key);
      commit("setWorkshopAssetSpell", compendiumSpell);

      setDatabaseWorkshop(`spell/${compendiumSpell.key}`, compendiumSpell.toDictionary(), database_save.overwrite);

      for (const charIndex in state.characters) {
        let selectedCharacter: CharacterClass = state.characters[charIndex];
        const spells = selectedCharacter.spells;
        const index = spells.findIndex(r => r.key === spell.key);

        if (index !== -1) {
          const currentSpell: Spell = spells[index];

          if (spell.key_spell_table === "") {
            spell.key_spell_table = currentSpell.key_spell_table;
          }

          let compendiumSpell: Spell = new Spell({ ...spell.toDictionary() }, spell.key);

          // if (shouldUpdateCharacter) {
          //   if (selectedCharacter.key != state.Selected_Character) {

          //   }
          // }
          selectedCharacter.spells[index] = compendiumSpell;

          setDatabaseCharacter(selectedCharacter, database_save.overwrite);
        }
      }
      resolve(true);
    });
  },
  deleteWorkshopAssetSpell: ({ commit }: any, val: CompendiumAssetBase) => {
    return new Promise((resolve, reject) => {
      commit("deleteWorkshopAssetSpell", val);

      setDatabaseWorkshop(`spell/${val.key}`, {}, database_save.remove);
      resolve(true);
    });
  },
  setWorkshopAssetItem: ({ commit, state }: any, data: any) => {
    return new Promise((resolve, reject) => {
      let { item, shouldUpdateCharacter } = data;
      item.galveon_user_id = state.galveon_user_id;

      const compendiumItem: ItemBase = new ItemBase({ ...item.toDictionary() }, item.key);
      commit("setWorkshopAssetItem", compendiumItem);

      setDatabaseWorkshop(`item/${compendiumItem.key}`, compendiumItem.toDictionary(), database_save.overwrite);

      for (const charIndex in state.characters) {
        let selectedCharacter: CharacterClass = state.characters[charIndex];
        const items = selectedCharacter.items;
        const index = items.findIndex(i => i.key === item.key);

        if (index !== -1) {
          const currentItem: Item = items[index];

          if (item.key_bag === "") {
            item.key_bag = currentItem.key_bag;
          }

          let compendiumItem: Item = new Item({ ...item.toDictionary() }, item.key);

          if (shouldUpdateCharacter) {
            if (selectedCharacter.key != state.Selected_Character) {
              compendiumItem.count = currentItem.count;
            }
          }
          selectedCharacter.items[index] = compendiumItem;

          setDatabaseCharacter(selectedCharacter, database_save.overwrite);
        }
      }

      resolve(true);
    });
  },
  deleteWorkshopAssetItem: ({ commit }: any, val: CompendiumAssetBase) => {
    return new Promise((resolve, reject) => {
      commit("deleteWorkshopAssetItem", val);

      setDatabaseWorkshop(`item/${val.key}`, {}, database_save.remove);
      resolve(true);
    });
  },
  setWorkshopAssetGameSystem: ({ dispatch, commit, state }: any, game_system: GameSystem) => {
    return new Promise((resolve, reject) => {
      game_system.galveon_user_id = state.galveon_user_id;

      commit("setWorkshopAssetGameSystem", game_system);

      setDatabaseWorkshop(`game_system/${game_system.key}`, game_system.toDictionary(), database_save.overwrite);

      dispatch("setCharacterGameSystems", game_system);
      resolve(true);
    });
  },
  setCharacterGameSystems: ({ dispatch, commit, state }: any, game_system: GameSystem) => {
    let isInStore: boolean = state.user.publicGameSystems.some((g: GameSystem) => g.key === game_system.key);
    if (isInStore) {
      return;
    }

    let isInCompendium: boolean = state.workshop.game_system.findIndex((g: GameSystem) => g.key == game_system.key) > -1;

    let assets: CompendiumAssetBase[][] = [state.workshop.attribute, state.workshop.resource, state.workshop.spell, state.workshop.item];

    for (const assetGroup of assets) {
      for (const asset of assetGroup) {
        if (asset.game_system_key != game_system.key) {
          continue;
        }

        switch (asset.type) {
          case AssetType.attribute:
            let updateAsset_Attribute: Attribute = new Attribute(asset.toDictionary(), asset.key);
            updateAsset_Attribute.game_system_name = isInCompendium ? game_system.name : "";
            updateAsset_Attribute.game_system_key = isInCompendium ? game_system.key : "";

            let payload_Attribute = {
              attribute: updateAsset_Attribute,
              shouldUpdateCharacter: false,
            };

            dispatch("setWorkshopAttribute", payload_Attribute);
            break;
          case AssetType.spell:
            let updateAsset_Spell: Spell = new Spell(asset.toDictionary(), asset.key);
            updateAsset_Spell.game_system_name = isInCompendium ? game_system.name : "";
            updateAsset_Spell.game_system_key = isInCompendium ? game_system.key : "";

            let payload_Spell = {
              spell: updateAsset_Spell,
              shouldUpdateCharacter: false,
            };

            dispatch("setWorkshopAssetSpell", payload_Spell);
            break;
          case AssetType.resource:
            let updateAsset_Resource: Resource = new Resource(asset.toDictionary(), asset.key);
            updateAsset_Resource.game_system_name = isInCompendium ? game_system.name : "";
            updateAsset_Resource.game_system_key = isInCompendium ? game_system.key : "";

            let payload_Resource = {
              resource: updateAsset_Resource,
              shouldUpdateCharacter: false,
            };

            dispatch("setWorkshopAssetResource", payload_Resource);
            break;
          case AssetType.item:
            let updateAsset_Item: Item = new Item(asset.toDictionary(), asset.key)
            if (!isInCompendium || updateAsset_Item.game_system_key != game_system.key) {
              updateAsset_Item.costs = [];
            }

            updateAsset_Item.game_system_name = isInCompendium ? game_system.name : "";
            updateAsset_Item.game_system_key = isInCompendium ? game_system.key : "";

            let payload_Item = {
              item: updateAsset_Item,
              shouldUpdateCharacter: false,
            };

            dispatch("setWorkshopAssetItem", payload_Item);
            break;
          case AssetType.race: break;
          case AssetType.char_class: break;
          case AssetType.game_system: break;
        }
      }
    }
  },
  deleteWorkshopAssetGameSystem: ({ dispatch, commit }: any, val: GameSystem) => {
    return new Promise((resolve, reject) => {

      dispatch("setCharacterGameSystems", val);

      commit("deleteWorkshopAssetGameSystem", val);
      setDatabaseWorkshop(`game_system/${val.key}`, {}, database_save.remove);
      resolve(true);
    });
  },
  setWorkshopAssetModifyVital: ({ dispatch, commit, state }: any, effect: ModifyVital) => {
    return new Promise((resolve, reject) => {
      console.log(effect);
      effect.galveon_user_id = state.galveon_user_id;

      commit("setWorkshopAssetModifyVital", effect);

      setDatabaseWorkshop(`effect/${effect.key}`, effect.toDictionary(), database_save.overwrite);

      dispatch("setCharacterEffects_ModifyVital", effect);
      resolve(true);
    });
  },
  setCharacterEffects_ModifyVital: ({ dispatch, commit, state }: any, effect: ModifyVital) => {
    let assets: CompendiumAssetBase[][] = [state.workshop.spell]
    for (const assetGroup of assets) {
        for (const asset of assetGroup) {
            switch (asset.type) {
            case AssetType.spell:
                var updateAsset: Spell = new Spell(asset.toDictionary(), asset.key);
                for (const index in updateAsset.effects) {
                    let currentEffect: EffectBase = updateAsset.effects[index];

                    if (currentEffect.key == effect.key) {
                        updateAsset.effects[index] = effect
                    }
                }

                dispatch("setWorkshopAssetSpell", {
                  spell: updateAsset,
                  shouldUpdateCharacter: true,
                });
                break;
            case AssetType.resource: break;
            case AssetType.item: break;
            case AssetType.race: break;
            case AssetType.char_class: break;
            case AssetType.game_system: break;
            case AssetType.behavior: break;
            case AssetType.effect: break;
            case AssetType.attribute: break;
            case AssetType.character_stats:
                break
            }
        }
    }
  },
  deleteWorkshopAssetModifyVital: ({ dispatch, commit }: any, val: ModifyVital) => {
    return new Promise((resolve, reject) => {

      dispatch("setCharacterEffects_ModifyVital", val);

      commit("deleteWorkshopAssetModifyVital", val);
      setDatabaseWorkshop(`effect/${val.key}`, {}, database_save.remove);
      resolve(true);
    });
  },
}

export const Account = { namespaced: true, state, getters, mutations, actions }