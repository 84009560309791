import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55ee0ee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "checkbox",
    class: "UISwitchView",
    checked: _ctx.checked,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onToggle($event.target.checked)), ["stop"]))
  }, null, 8, _hoisted_1))
}