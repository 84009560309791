import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1313ee38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "UICharacterActivityView" }
const _hoisted_2 = { class: "avExperience" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIActivityView = _resolveComponent("UIActivityView")!
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UIActivityView, {
      radius: 60,
      progress: _ctx.resourcePercentage,
      stroke: 8,
      color: _ctx.resourceColor,
      img: _ctx.getPortrait.url,
      hasImage: _ctx.hasImg
    }, null, 8, ["progress", "color", "img", "hasImage"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UIActivityView, {
        radius: 30,
        progress: _ctx.experiencePercentage,
        stroke: 4,
        color: _ctx.experienceColor,
        fill: 'white',
        hasImage: false,
        label: _ctx.showLevel ? _ctx.level : '',
        labelSize: 18
      }, null, 8, ["progress", "color", "label"]),
      _createVNode(_component_UILabel, {
        style: {"position":"absolute","font-size":"14px","bottom":"-8px","left":"20%"},
        font: 14,
        text: 'Level'
      })
    ])
  ]))
}