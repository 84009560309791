import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "UIMenuView" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "overlay",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMenuDismiss && _ctx.onMenuDismiss(...args)), ["stop"]))
    }),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "UIMenuItemView",
          key: index,
          onClick: _withModifiers(($event: any) => (_ctx.menuItemSelected(index)), ["stop"])
        }, [
          _createVNode(_component_UILabel, { text: item }, null, 8, ["text"])
        ], 8, _hoisted_2))
      }), 128))
    ])
  ], 64))
}