<template>
  <div class="CostResourceUICollectionViewCell">
    <div>
      <UILabel :text="index" />
    </div>
    <div>
      <UILabel :text="value" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "CostResourceUICollectionViewCell",
  components: {
    UILabel,
    UISeparatorView,
  },
  props: {
    index: {
      type: Number,
      default: 0,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.CostResourceUICollectionViewCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;
  height: 44px;
  cursor: pointer;

  box-sizing: border-box;
}

</style>