import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetSelectionUICollectionViewCell = _resolveComponent("AssetSelectionUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogFormulaView = _resolveComponent("UIDialogFormulaView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.type} Changes`,
      subTitle: `Set ${_ctx.type} changes`,
      canRemove: _ctx.action == 'Edit',
      hasMenu: _ctx.hasMenu,
      menuItems: _ctx.menuItems,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UICollectionView, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.localFields, (item, index) => {
              return (_openBlock(), _createBlock(_component_AssetSelectionUICollectionViewCell, {
                key: index,
                name: item.name,
                value: _ctx.localAllowedFields[item.key],
                onClick: ($event: any) => (_ctx.onDisplayFormulaDialog(item, index))
              }, null, 8, ["name", "value", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title", "subTitle", "canRemove", "hasMenu", "menuItems", "onUiDialogSubmit", "onUiDialogCancel"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowFormulaDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogFormulaView, {
            key: 0,
            formula: _ctx.formulaData.formula,
            onUiDialogFormulaSubmit: _ctx.onSubmitFormulaDialog,
            onUiDialogFormulaCancel: _ctx.onCancelFormulaDialog
          }, null, 8, ["formula", "onUiDialogFormulaSubmit", "onUiDialogFormulaCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}