import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex"
}
const _hoisted_2 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UIDropdownGameSystem = _resolveComponent("UIDropdownGameSystem")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_UICellTextField = _resolveComponent("UICellTextField")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: `Character Biography`,
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UITabView, {
        id: 'tvTabs',
        tabs: _ctx.tabs,
        selectedTab: _ctx.selectedTab,
        onTabSelected: _ctx.btnSelected
      }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
      (_ctx.selectedTab === 'lore')
        ? (_openBlock(), _createBlock(_component_UITextView, {
            key: 0,
            id: 'txtLore',
            modelValue: _ctx.selectedCharacter.lore,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCharacter.lore) = $event)),
            placeholder: 'Lore'
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 'characteristics')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_UIDropdownGameSystem, {
              selectedValue: _ctx.character.game_system_key,
              onUiDropdownGameSystemSelect: _ctx.onGameSystemCellDropdownMenuSelect
            }, null, 8, ["selectedValue", "onUiDropdownGameSystemSelect"]),
            _createVNode(_component_UISeparatorView),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bioData, (field, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (field.type === 'text' || field.type === 'number')
                  ? (_openBlock(), _createBlock(_component_UICellTextField, {
                      key: 0,
                      textLabel: field.label,
                      type: field.type,
                      modelValue: this.selectedCharacter[field.key],
                      "onUpdate:modelValue": ($event: any) => ((this.selectedCharacter[field.key]) = $event),
                      placeholder: field.label
                    }, null, 8, ["textLabel", "type", "modelValue", "onUpdate:modelValue", "placeholder"]))
                  : _createCommentVNode("", true),
                (field.type === 'color')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_UILabel, {
                        text: field.label
                      }, null, 8, ["text"]),
                      _withDirectives(_createElementVNode("input", {
                        class: "color-picker",
                        type: "color",
                        "onUpdate:modelValue": ($event: any) => ((this.selectedCharacter[field.key]) = $event)
                      }, null, 8, _hoisted_2), [
                        [_vModelText, this.selectedCharacter[field.key]]
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]))
}