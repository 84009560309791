import ICostResource from "@/store/interfaces/ICostResource";

export default class CostResource implements ICostResource {
    constructor(value: string, key: string) {
        this.key = key;
        this.value = value;
    }

    key: string;
    value: string;

    toDictionary = (): Record<string, any> => {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["key"] = this.key;
        returnedDictionary["value"] = +this.value;

        return returnedDictionary
    }
}