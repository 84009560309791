import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72629e4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.attribute.key,
    class: "AttributeView"
  }, [
    _createElementVNode("div", null, _toDisplayString(_ctx.attribute.name), 1),
    _createElementVNode("div", null, [
      _createTextVNode(_toDisplayString(_ctx.value) + " ", 1),
      (_ctx.show_modifier && _ctx.modifier)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(_ctx.modifier) + ")", 1))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}