<template>
  <div class="SpellTableUICollectionViewCellContainer">
    <div class="SpellTableUICollectionViewCell">
      <UILabel :text="table.name" :isSelected="true" />
      <div>
        <div>
          <img
            id="btnSpellMenu"
            style="cursor: pointer"
            src="@/assets/icon_menu_alt@x2.png"
            @click="onSpellTableMenu"
          />
        </div>
        <UIMenuView
          v-if="shouldShowSpellTableMenu"
          :items="['Rename Table', 'Add Spell', 'Delete Table']"
          @menu-item-selected-at="onMenuSelect"
          @menu-view-dismiss="dismissSpellTableMenu"
        />
      </div>
    </div>
    <UICollectionView :id="'cvSpells'" :hasItems="selected_spells.length > 0">
      <UIOnboardView
        v-if="selected_spells.length < 1"
        :title="'There are no Spells'"
        :buttonTitle="'Add Spell'"
        @ui-onboard-view="addSpell"
      />
      <SpellUICollectionViewCell
        v-for="(spell, index) in selected_spells"
        :key="index"
        :spell="spell"
        @click="onDisplayQuickViewDialog(spell)"
      />
    </UICollectionView>
  </div>
  <div>
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      :type="inputDialogData.type"
      :title="inputDialogData.name"
      :body="inputDialogData.body"
      :inputPlaceholder="inputDialogData.placeholder"
      :inputTitle="inputDialogData.field"
      :inputValue="inputDialogData.value"
      :buttonTitle="inputDialogData.input"
      @ui-dialog-input-submit="onSaveInputDialog"
      @ui-dialog-input-cancel="onCancelInputDialog"
    />
    <UIDialogAssetView
      v-if="shouldShowAssetDialog"
      :title="'Spell'"
      :query="'spell'"
      :id="table.key"
      @ui-dialog-asset="onDisplaySpellDialog"
      @ui-dialog-asset-cancel="onCancelAssetDialog"
    />
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      :type="promptDialogData.type"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      @ui-dialog-prompt-submit="onSavePromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
    />
    <UIDialogSpellView
      v-if="shouldShowSpellDialog"
      :spell="spellDialogData"
      @ui-dialog-spell-submit="onSaveSpellDialog"
      @ui-dialog-spell-cancel="onCancelSpellDialog"
    />
    <UIDialogAssetQuickView
      v-if="shouldShowQuickViewDialog"
      :quickView="quickViewDialogData"
      :showActions="true"
      :actionsDisabled="
        selectedSpell.canCast($store.state.Account.selectedCharacter)
      "
      :submitTitle="'Cast Spell'"
      @ui-dialog-quick-view-submit="onSaveQuickViewDialog"
      @ui-dialog-quick-view-cancel="onCancelQuickViewDialog"
      @ui-dialog-quick-view-menu="onMenuQuickViewDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogPromptView from "@/components/UI//dialogs/UIDialogPromptView.vue";
import UIDialogAssetView from "@/components/compendium/UIDialogAssetView.vue";
import UIDialogSpellView from "@/components/character/dialogs/UIDialogSpellView.vue";
import UIDialogAssetQuickView from "@/components/character/dialogs/UIDialogAssetQuickView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import SpellUICollectionViewCell from "@/components/character/SpellUICollectionViewCell.vue";
import Spell from "@/store/models/Spell";
import SpellTable from "@/store/models/SpellTable";
import QuickView from "@/store/models/QuickView";
import Cost from "@/store/models/Cost";

export default defineComponent({
  name: "SpellTableUICollectionViewCell",
  components: {
    UILabel,
    UIButton,
    UIMenuView,
    UICollectionView,
    SpellUICollectionViewCell,
    UIDialogInputView,
    UIDialogPromptView,
    UIDialogAssetView,
    UIDialogSpellView,
    UIDialogAssetQuickView,
    UIOnboardView,
  },
  data() {
    return {
      shouldShowSpellTableMenu: false,
      shouldShowInputDialog: false,
      shouldShowAssetDialog: false,
      shouldShowSpellDialog: false,
      shouldShowPromptDialog: false,
      shouldShowQuickViewDialog: false,
      promptDialogData: {
        type: Object,
        default: {},
      },
      inputDialogData: {
        type: Object,
        default: {},
      },
      spellDialogData: {
        type: Object,
        default: {},
      },
      quickViewDialogData: {} as QuickView,
      selectedSpell: {} as Spell,
    };
  },
  props: {
    table: {
      type: Object,
      default: {},
    },
  },
  computed: {
    ...mapGetters("Character", [
      "getSpells",
      "getSpellTables",
      "getSelectedSpellBookKey",
      "isCampaignCharacter",
      "getCampaignId",
      "getLevel",
    ]),
    selected_book_name(): string {
      return this.getSelectedSpellBookKey;
    },
    spell_tables(): any {
      return this.getSpellTables();
    },
    selected_spells(): Array<Spell> {
      let selectedSpells: Array<Spell> = [];
      this.spells.forEach((spell: Spell) => {
        if (spell.key_spell_table == this.table.key) {
          selectedSpells.push(spell);
        }
      });
      return selectedSpells;
    },
    spells(): Array<Spell> {
      return this.getSpells;
    },
  },
  methods: {
    ...mapActions("Campaigns", ["setAssetAt"]),
    ...mapActions("Character", [
      "setWorkshopSpell",
      "setSpell",
      "setSpellTable",
      "removeSpellTable",
      "castSpell",
    ]),
    onMenuSelect(index: number) {
      if (index == 2) {
        this.removeSpellTableDialog();
      } else if (index == 1) {
        this.addSpell();
      } else {
        this.renameSpellTable();
      }
    },
    onSpellTableMenu(key: string) {
      this.shouldShowSpellTableMenu = true;
    },
    dismissSpellTableMenu() {
      this.shouldShowSpellTableMenu = false;
    },
    removeSpellTableDialog() {
      let data: any = {
        name: "Remove Spell Table",
        body: `Are you sure you want delete ${this.table.name}? It cannot be undone.`,
        submitTitle: "Continue",
        type: "removeSpellTable",
      };

      this.onDisplayPromptDialog(data);
    },
    renameSpellTable() {
      let data = {
        name: "Edit Spell Table",
        body: "Edit the name of this Spell Table.",
        field: "Edit Spell Table",
        placeholder: "Edit Spell Table",
        value: this.table.name,
        input: "Save",
        type: "renameTable",
      };
      this.onDisplayInputDialog(data);
    },
    addSpell() {
      let data = {
        key_spell_table: this.table.key,
      };
      this.onDisplayAssetDialog(data);
    },
    onDisplayInputDialog(data: any) {
      this.inputDialogData = data;
      this.shouldShowInputDialog = true;
    },
    onSaveInputDialog(returnedData: any) {
      let data = returnedData;

      if (data.type == "renameTable") {
        const newSpellTable: SpellTable = new SpellTable(
          {
            name: data.value,
            key_spell_book: this.selected_book_name,
          },
          this.table.key
        );

        this.setSpellTable(newSpellTable);
      }
    },
    onCancelInputDialog() {
      this.shouldShowInputDialog = false;
    },
    onDisplayAssetDialog(data: any) {
      this.shouldShowAssetDialog = true;
    },
    onCancelAssetDialog() {
      this.shouldShowAssetDialog = false;
    },
    onDisplaySpellDialog(data: any) {
      this.spellDialogData = data !== undefined ? data : {};
      this.shouldShowSpellDialog = true;
    },
    onSaveSpellDialog(data: any) {
      const asset = data.spell;
      const isNew = data.isNew;

      if (this.isCampaignCharacter) {
        const campaignAsset: Spell = new Spell(
          { ...asset.toDictionary(), campaign_id: this.getCampaignId },
          asset.key
        );

        this.setSpell(campaignAsset);

        const val = {
          path: ["campaigns", this.getCampaignId, "spells"],
          data: campaignAsset.toDictionary(),
        };
        this.setAssetAt(val);
      } else {
        this.setWorkshopSpell({
          spell: asset,
          shouldUpdateCharacter: !isNew,
        });
      }
    },
    onCancelSpellDialog() {
      this.shouldShowSpellDialog = false;
    },
    onDisplayPromptDialog(data: any) {
      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onSavePromptDialog() {
      this.removeSpellTable(this.table);
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onDisplayQuickViewDialog(data: Spell) {
      this.selectedSpell = data;
      const extras: Array<any> = [];

      if (data.costs.length > 0) {
        const characterLevel: number = this.getLevel;

        if (data.costs[characterLevel] !== undefined) {
          const currentCost: Cost = data.costs[characterLevel];

          extras.push({
            name: "Cost",
            value: currentCost.getCostToString(
              this.$store.state.Account.selectedCharacter,
              undefined
            ),
          });
        }
      }

      extras.push({
        name: "Casting Time",
        value:
          data.cast_time === 0
            ? "Instantaneous"
            : `${data.cast_time} ${data.cast_time_measurement}`,
      });

      for (const extra in data.extra) {
        extras.push({
          name: data.extra[extra].name,
          value: data.extra[extra].value,
        });
      }
      const quickView = {
        key: data.key,
        name: data.name,
        description: data.description,
        secondaryAttributes: extras,
        imageName: "spell_alt",
      };

      this.quickViewDialogData = new QuickView(quickView, data.key);
      this.shouldShowQuickViewDialog = true;
    },
    onCancelQuickViewDialog() {
      this.shouldShowQuickViewDialog = false;
    },
    onSaveQuickViewDialog() {
      const key = this.quickViewDialogData.key;
      const index = this.selected_spells.findIndex((c: Spell) => c.key == key);

      if (index < 0) return;

      const spell: Spell = this.selected_spells[index];

      this.castSpell(spell);
    },
    onMenuQuickViewDialog(quickViewData: QuickView) {
      this.onCancelQuickViewDialog();
      const key = quickViewData.key;

      const index = this.selected_spells.findIndex((c: Spell) => c.key == key);

      if (index < 0) return;

      const spell: Spell = this.selected_spells[index];
      this.onDisplaySpellDialog(spell);
    },
  },
});
</script>

<style lang="scss" scoped>
.SpellTableUICollectionViewCellContainer {
  text-align: left;

  border: 1px solid rgba(0, 0, 0, 0.16);

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.SpellTableUICollectionViewCell {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 0 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  background: var(--btn_norm);
  color: var(--galveonTextColor);
}

#cvSpells {
  grid-template-columns: repeat(auto-fit, 325px);
  gap: 18px;
}
</style>
