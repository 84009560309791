<template>
  <UIDialogView
    :title="title"
    :hasDone="true"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onSubmit"
  >
    <div class="vBody">
      <UILabel :text="body" :type="'body'" />
    </div>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "UIDialogMessageView",
  components: {
    UIDialogView,
    UILabel,
    UITextField,
    UIButton,
    UISeparatorView,
  },
  emits: ["ui-dialog-message-submit"],
  props: {
    type: {
      type: String,
      require: true,
      default: "",
    },
    title: {
      type: String
    },
    body: {
      type: String
    }
  },
  methods: {
    onSubmit() {
      this.$emit("ui-dialog-message-submit");
    },
  },
});
</script>

<style lang="scss">
</style>