import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_menu@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-ca0ca47c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CharacterResource" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { style: {"display":"flex","align-items":"center","justify-content":"space-between","position":"relative"} }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_ResourceView = _resolveComponent("ResourceView")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogAssetView = _resolveComponent("UIDialogAssetView")!
  const _component_UIDialogResourceView = _resolveComponent("UIDialogResourceView")!
  const _component_UIDialogAssetQuickView = _resolveComponent("UIDialogAssetQuickView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.resources.length < 1)
        ? (_openBlock(), _createBlock(_component_UIOnboardView, {
            key: 0,
            title: 'You have no Resources',
            body: 'You can track different resources, such as mana or spells per day',
            buttonTitle: 'Add Resource',
            onUiOnboardView: _ctx.addResource
          }, null, 8, ["onUiOnboardView"]))
        : _createCommentVNode("", true),
      (_ctx.resources.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_UILabel, {
                text: 'Resources',
                type: 'h1'
              }),
              _createElementVNode("div", null, [
                _createElementVNode("div", null, [
                  _createElementVNode("img", {
                    id: "btnResourceMenu",
                    style: {"cursor":"pointer","margin-right":"8px"},
                    src: _imports_0,
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onResourceMenu && _ctx.onResourceMenu(...args)))
                  })
                ]),
                (_ctx.shouldShowResourceMenu)
                  ? (_openBlock(), _createBlock(_component_UIMenuView, {
                      key: 0,
                      items: ['Add Resource'],
                      onMenuItemSelectedAt: _ctx.onMenuSelect,
                      onMenuViewDismiss: _ctx.dismissResourceMenu
                    }, null, 8, ["onMenuItemSelectedAt", "onMenuViewDismiss"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createVNode(_component_UICollectionView, {
              id: 'cvResources',
              hasItems: _ctx.resources.length > 0
            }, {
              default: _withCtx(() => [
                (_ctx.resources.length < 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources, (resource, index) => {
                  return (_openBlock(), _createBlock(_component_ResourceView, {
                    key: index,
                    resource: resource,
                    onClick: ($event: any) => (_ctx.onDisplayQuickViewDialog(resource))
                  }, null, 8, ["resource", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["hasItems"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowAssetDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAssetView, {
            key: 0,
            id: '',
            title: 'Resource',
            query: 'resource',
            onUiDialogAsset: _ctx.onDisplayResourceDialog,
            onUiDialogAssetCancel: _ctx.onCancelAssetDialog
          }, null, 8, ["onUiDialogAsset", "onUiDialogAssetCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowResourceDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogResourceView, {
            key: 1,
            resource: _ctx.resourceDialogData,
            onUiDialogResourceSubmit: _ctx.onSaveResourceDialog,
            onUiDialogResourceCancel: _ctx.onCancelResourceDialog
          }, null, 8, ["resource", "onUiDialogResourceSubmit", "onUiDialogResourceCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowQuickViewDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAssetQuickView, {
            key: 2,
            quickView: _ctx.quickViewDialogData,
            onUiDialogQuickViewSubmit: _ctx.onSaveQuickViewDialog,
            onUiDialogQuickViewCancel: _ctx.onCancelQuickViewDialog,
            onUiDialogQuickViewMenu: _ctx.onMenuQuickViewDialog
          }, null, 8, ["quickView", "onUiDialogQuickViewSubmit", "onUiDialogQuickViewCancel", "onUiDialogQuickViewMenu"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}