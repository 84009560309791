import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vBody" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: _ctx.title,
    hasDone: true,
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UILabel, {
          text: _ctx.body,
          type: 'body'
        }, null, 8, ["text"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onUiDialogSubmit", "onUiDialogCancel"]))
}