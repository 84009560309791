import { AssetType } from "../Compendium/AssetType";
import CompendiumAssetBase from "../Compendium/CompendiumAssetBase";
import Cost from "../Cost";
import SecondaryAttribute from "../SecondaryAttribute";
import ISpellBase from "@/store/interfaces/AssetBases/ISpellBase";
import { AreaMeasurement } from "@/store/interfaces/types/AreaMeasurement";
import { RangeMeasurement } from "@/store/interfaces/types/RangeMeasurement";
import { CastingTimeMeasurement } from "@/store/interfaces/types/CastingTimeMeasurement";
import { Target } from "@/store/interfaces/types/Target";
import { AreaShape } from "@/store/interfaces/types/AreaShape";
import EffectBase from "./EffectBase";
import { EffectType } from "@/store/interfaces/types/EffectType";
import ApplyBehavior from "../Effects/ApplyBehavior";
import RemoveBehavior from "../Effects/RemoveBehavior";
import ModifyVital from "../Effects/ModifyVital";
import ModifyUnit from "../Effects/ModifyUnit";
import Set from "../Effects/Set";

export default class SpellBase extends CompendiumAssetBase implements ISpellBase {
  constructor(dictionary: Record<string, any>, key: string) {
    super(dictionary, key, AssetType.spell);

    this.level = +dictionary["level"] || 0;
    this.duration = +dictionary["duration"] || 0;
    this.cast_time = +dictionary["cast_time"] || 0;
    this.cast_time_measurement = dictionary["cast_time_measurement"] || "seconds";
    this.level = +dictionary["level"] || 0;
    this.range = +dictionary["range"] || 0;
    this.range_measurement = dictionary["range_measurement"] || "feet";
    this.area = +dictionary["area"] || 0;
    this.area_measurement = dictionary["area_measurement"] || "feet";
    this.area_shape = dictionary["area_shpae"] || "none";

    this.affected_type = dictionary["affected_type"] || "none";
    this.maximum_affect_count = dictionary["maximum_affect_count"] || "0";

    if (dictionary["extra"]) {
      for (const itemKey in dictionary["extra"]) {
        const selectedExtra: SecondaryAttribute = new SecondaryAttribute(dictionary["extra"][itemKey]);
        this.extra.push(selectedExtra);
      }
    }

    if (dictionary["costs"]) {
      for (const index in dictionary["costs"]) {
        const selectedCost: Cost = new Cost(dictionary["costs"][index]);
        this.costs.push(selectedCost);
      }
    }

    if (dictionary["effects"]) {
      for (const index in dictionary["effects"]) {
        let effect_type: EffectType = dictionary["effects"][index]["effect_type"];
        switch (effect_type) {
          case EffectType.apply_behavior:
            const selectedEffect_ApplyBehavior: ApplyBehavior = new ApplyBehavior(dictionary["effects"][index], dictionary["effects"][index]["key"]);
            this.effects.push(selectedEffect_ApplyBehavior);
            break
          case EffectType.effect_set:
            const selectedEffect_Set: Set = new Set(dictionary["effects"][index], dictionary["effects"][index]["key"]);
            this.effects.push(selectedEffect_Set);
            break
          case EffectType.modify_unit:
            const selectedEffect_ModifyUnit: ModifyUnit = new ModifyUnit(dictionary["effects"][index], dictionary["effects"][index]["key"]);
            this.effects.push(selectedEffect_ModifyUnit);
            break
          case EffectType.modify_vital:
            const selectedEffect_ModifyVital: ModifyVital = new ModifyVital(dictionary["effects"][index], dictionary["effects"][index]["key"]);
            this.effects.push(selectedEffect_ModifyVital);
            break
          case EffectType.remove_behavior:
            const selectedEffect_RemoveBehavior: RemoveBehavior = new RemoveBehavior(dictionary["effects"][index], dictionary["effects"][index]["key"]);
            this.effects.push(selectedEffect_RemoveBehavior);
            break
        }
      }
    }
  }

  extra: SecondaryAttribute[] = [];
  costs: Cost[] = [];
  level: number;
  duration: number;
  cast_time: number;
  cast_time_measurement: CastingTimeMeasurement;
  range: number;
  range_measurement: RangeMeasurement;
  area: number;
  area_measurement: AreaMeasurement;
  area_shape: AreaShape;
  effects: EffectBase[] = [];
  affected_type: Target;
  maximum_affect_count: string;

  toDictionary(): Record<string, any> {
    let returnedDictionary = super.toDictionary();

    if (this.extra.length > 0) {
      var extraDictionary: Record<string, any> = {};
      for (const index in this.extra) {
        extraDictionary[index] = this.extra[index].toDictionary();

      }
      returnedDictionary["extra"] = extraDictionary;
    }

    if (this.costs.length > 0) {
      var costsArray: Record<string, string>[] = [];
      for (const cost of this.costs) {
        costsArray.push(cost.toDictionary());
      }
      returnedDictionary["costs"] = costsArray;
    }

    if (this.effects.length > 0) {
      var effectsArray: Record<string, any>[] = [];
      for (const effect of this.effects) {
        effectsArray.push(effect.toDictionary());
      }
      returnedDictionary["effects"] = effectsArray;
    }

    returnedDictionary["level"] = this.level;
    returnedDictionary["cast_time"] = this.cast_time;
    returnedDictionary["cast_time_measurement"] = this.cast_time_measurement;
    returnedDictionary["range"] = this.range;
    returnedDictionary["range_measurement"] = this.range_measurement;
    returnedDictionary["area"] = this.area;
    returnedDictionary["area_measurement"] = this.area_measurement;
    returnedDictionary["area_shape"] = this.area_shape;
    returnedDictionary["affected_type"] = this.affected_type;
    returnedDictionary["maximum_affect_count"] = this.maximum_affect_count;


    return returnedDictionary;
  }
}