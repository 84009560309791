<template>
  <div class="overlay" @click.stop="onMenuDismiss"></div>
  <div class="UIMenuView">
    <div
      class="UIMenuItemView"
      v-for="(item, index) in items"
      :key="index"
      @click.stop="menuItemSelected(index)"
    >
      <UILabel :text="item" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: "UIMenuView",
  components: {
    UILabel,
  },
  emits: ["menu-item-selected-at", "menu-view-dismiss"],
  props: {
    items: {
      type: Array,
      default: [] as Array<String>,
    },
  },
  methods: {
    menuItemSelected(index: number) {
      this.$emit("menu-item-selected-at", index);
      this.onMenuDismiss();
    },
    onMenuDismiss() {
      this.$emit("menu-view-dismiss");
    },
  },
});
</script>

<style lang="scss">
.UIMenuView {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.16);

  background-color: #ffffff;

  z-index: 2;
}

.UIMenuItemView {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);

  color: var(--textColor);

  padding: 8px 16px;

  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.16);
  }
}

.overlay {
  width: 100%;
  height: 100%;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>