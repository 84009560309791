<template>
  <div class="UICellTextFieldView" :class="{ disabled: disabled }">
    <UILabel v-if="shouldShowLabel" :text="textLabel" :justify="'left'" />
    <input
      :style="{ width: `${width}px` }"
      class="UICellTextField"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :has-error="hasError"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <span v-if="hasError == true" class="UITextFieldError">{{
      errorMessage
    }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: "UICellTextField",
  components: {
    UILabel,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
    textLabel: {
      type: String,
      default: "Label",
    },
    placeholder: {
      required: true,
      type: String,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
    },
    shouldShowLabel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value: string | number) {
      this.$emit("update:modelValue", value);
    },
  },
});
</script>

<style lang="scss" scoped>
.UITextFieldView {
  margin-bottom: 8px;
}

.UITextFieldError {
  color: #f44336;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: left;

  display: block;
}

.UICellTextFieldView {
  border: 1px solid lightgrey;
  border-radius: 8px;

  padding-left: 8px;

  margin-bottom: 8px;

  display: flex;
  align-items: center;

  & input {
    outline: 0;
    border: 0;

    border-left: 1px solid lightgrey;
    border-radius: 0;
  }
  
  & input,
  & div {
    flex: 1;
  }
}

.UICellTextField {
  font: 400 16px / 24px "Helvetica Neue", "Helvetica", "Open Sans", sans-serif;
  background: #fff;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  box-sizing: border-box;

  outline: 0;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &[type="number"] {
    font-size: 24px;
    line-height: 30px;
    text-align: center;

    -moz-appearance: textfield;

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      font-size: 0.5em;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 0.5em;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 0.5em;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      font-size: 0.5em;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.UICellTextField[has-error="true"] {
  border-color: #f44336;
}
</style>