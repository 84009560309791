import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UISwitchView = _resolveComponent("UISwitchView")!
  const _component_ManageAssetView = _resolveComponent("ManageAssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: 'Main Resource',
    canRemove: false,
    hasMenu: false,
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ManageAssetView, {
        asset: _ctx.getMainResource,
        hasCompendium: false
      }, {
        basic: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_UITextField, {
              id: 'txtName',
              textLabel: 'Experience name',
              modelValue: _ctx.getMainResource.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getMainResource.name) = $event)),
              placeholder: 'Experience name'
            }, null, 8, ["modelValue"]),
            _createVNode(_component_UITextField, {
              id: 'txtCurrent',
              textLabel: 'Current',
              modelValue: _ctx.getMainResource.current,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getMainResource.current) = $event)),
              placeholder: 'Current'
            }, null, 8, ["modelValue"]),
            _createVNode(_component_UITextField, {
              id: 'txtMax',
              textLabel: 'Max',
              modelValue: _ctx.getMainResource.max,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getMainResource.max) = $event)),
              placeholder: 'Max'
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_UILabel, { text: 'Color' }),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["color-picker", { disabled: _ctx.readOnly }]),
              type: "color",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.assetColor) = $event))
            }, null, 2), [
              [_vModelText, _ctx.assetColor]
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_UILabel, { text: 'Show Name' }),
              _createVNode(_component_UISwitchView, {
                checked: _ctx.resource.show_name,
                onUiSwitchViewToggle: _ctx.onToggleShowName
              }, null, 8, ["checked", "onUiSwitchViewToggle"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_UILabel, { text: 'Show Value' }),
              _createVNode(_component_UISwitchView, {
                checked: _ctx.resource.show_value,
                onUiSwitchViewToggle: _ctx.onToggleShowValue
              }, null, 8, ["checked", "onUiSwitchViewToggle"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_UILabel, { text: 'Show Percentage' }),
              _createVNode(_component_UISwitchView, {
                checked: _ctx.resource.show_percentage,
                onUiSwitchViewToggle: _ctx.onToggleShowPercentage
              }, null, 8, ["checked", "onUiSwitchViewToggle"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["asset"])
    ]),
    _: 1
  }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]))
}