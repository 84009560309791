import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_menu_alt@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-a76bf468"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "AttributeTabUICollectionViewCellContainer" }
const _hoisted_2 = { class: "AttributeTabUICollectionViewCell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_AttributeUICollectionViewCell = _resolveComponent("AttributeUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogAssetView = _resolveComponent("UIDialogAssetView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogAttributeView = _resolveComponent("UIDialogAttributeView")!
  const _component_UIDialogAssetQuickView = _resolveComponent("UIDialogAssetQuickView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UILabel, {
          text: _ctx.group.name,
          isSelected: true
        }, null, 8, ["text"]),
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              id: "btnAttributeMenu",
              style: {"cursor":"pointer"},
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onAttributeTabMenu(_ctx.group.key)))
            })
          ]),
          (_ctx.shouldShowAttributeTabMenu)
            ? (_openBlock(), _createBlock(_component_UIMenuView, {
                key: 0,
                items: ['Rename Group', 'Add Attribute', 'Delete Group'],
                onMenuItemSelectedAt: _ctx.onMenuSelect,
                onMenuViewDismiss: _ctx.dismissAttributeTabMenu
              }, null, 8, ["onMenuItemSelectedAt", "onMenuViewDismiss"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_UICollectionView, {
        id: 'cvAttributes',
        hasItems: _ctx.selected_attributes.length > 0
      }, {
        default: _withCtx(() => [
          (_ctx.selected_attributes.length < 1)
            ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                key: 0,
                title: 'There are no Attributes',
                buttonTitle: 'Add Attribute',
                onUiOnboardView: _ctx.addAttribute
              }, null, 8, ["onUiOnboardView"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected_attributes, (attribute, index) => {
            return (_openBlock(), _createBlock(_component_AttributeUICollectionViewCell, {
              key: index,
              id: attribute.key,
              attribute: attribute,
              onClick: ($event: any) => (_ctx.onDisplayQuickViewDialog(attribute))
            }, null, 8, ["id", "attribute", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["hasItems"])
    ]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowInputDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
            key: 0,
            onUiDialogInputCancel: _ctx.onCancelInputDialog,
            onUiDialogInputSubmit: _ctx.onSaveInputDialog,
            type: _ctx.inputDialogData.type,
            title: _ctx.inputDialogData.name,
            body: _ctx.inputDialogData.body,
            inputPlaceholder: _ctx.inputDialogData.placeholder,
            inputTitle: _ctx.inputDialogData.field,
            inputValue: _ctx.inputDialogData.value,
            buttonTitle: _ctx.inputDialogData.input
          }, null, 8, ["onUiDialogInputCancel", "onUiDialogInputSubmit", "type", "title", "body", "inputPlaceholder", "inputTitle", "inputValue", "buttonTitle"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowAssetDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAssetView, {
            key: 1,
            onUiDialogAsset: _ctx.onDisplayAttributeDialog,
            onUiDialogAssetCancel: _ctx.onCancelAssetDialog,
            title: 'Attribute',
            query: 'attribute',
            id: _ctx.group.key
          }, null, 8, ["onUiDialogAsset", "onUiDialogAssetCancel", "id"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 2,
            type: _ctx.promptDialogData.type,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            onUiDialogPromptSubmit: _ctx.onSavePromptDialog
          }, null, 8, ["type", "title", "body", "submitTitle", "onUiDialogPromptCancel", "onUiDialogPromptSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowAttributeDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAttributeView, {
            key: 3,
            attribute: _ctx.attributeDialogData,
            onUiDialogAttributeSubmit: _ctx.onSaveAttributeDialog,
            onUiDialogAttributeCancel: _ctx.onCancelAttributeDialog
          }, null, 8, ["attribute", "onUiDialogAttributeSubmit", "onUiDialogAttributeCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowQuickViewDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAssetQuickView, {
            key: 4,
            quickView: _ctx.quickViewDialogData,
            onUiDialogQuickViewSubmit: _ctx.onSaveQuickViewDialog,
            onUiDialogQuickViewCancel: _ctx.onCancelQuickViewDialog,
            onUiDialogQuickViewMenu: _ctx.onMenuQuickViewDialog
          }, null, 8, ["quickView", "onUiDialogQuickViewSubmit", "onUiDialogQuickViewCancel", "onUiDialogQuickViewMenu"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}