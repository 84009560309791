import ISpellTable from "@/store/interfaces/ISpellTable";
import Spell from "./Spell";

export default class SpellTable implements ISpellTable {
    constructor(dictionary: Record<string, any>, key: string) {
        this.key = key;
        this.name = dictionary["name"] || "New Spell Table";
        if (this.name === "") {
            self.name = "New Spell Table";
        }
        this.key_spell_book = dictionary["key_spell_book"] || "";
    }
    key: string;
    name: string;
    spell: Spell[] = [];
    key_spell_book: string;

    toDictionary(): Record<string, any> {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["key"] = this.key;

        returnedDictionary["name"] = this.name;
        returnedDictionary["key_spell_book"] = this.key_spell_book;

        return returnedDictionary;
    }
}