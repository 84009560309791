<template>
  <div class="AttributeTabUICollectionViewCellContainer">
    <div class="AttributeTabUICollectionViewCell">
      <UILabel :text="group.name" :isSelected="true" />
      <div>
        <div>
          <img
            id="btnAttributeMenu"
            style="cursor: pointer"
            src="@/assets/icon_menu_alt@x2.png"
            @click="onAttributeTabMenu(group.key)"
          />
        </div>
        <UIMenuView
          v-if="shouldShowAttributeTabMenu"
          :items="['Rename Group', 'Add Attribute', 'Delete Group']"
          @menu-item-selected-at="onMenuSelect"
          @menu-view-dismiss="dismissAttributeTabMenu"
        />
      </div>
    </div>
    <UICollectionView
      :id="'cvAttributes'"
      :hasItems="selected_attributes.length > 0"
    >
      <UIOnboardView
        v-if="selected_attributes.length < 1"
        :title="'There are no Attributes'"
        :buttonTitle="'Add Attribute'"
        @ui-onboard-view="addAttribute"
      />
      <AttributeUICollectionViewCell
        v-for="(attribute, index) in selected_attributes"
        :key="index"
        :id="attribute.key"
        :attribute="attribute"
        @click="onDisplayQuickViewDialog(attribute)"
      />
      <!-- :draggable="true"
        @dragstart="handleDragStart(index, $event)"
        @dragover="handleDragOver(index, $event)"
        @dragend="handleDragEnd" -->
    </UICollectionView>
  </div>

  <div>
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      @ui-dialog-input-cancel="onCancelInputDialog"
      @ui-dialog-input-submit="onSaveInputDialog"
      :type="inputDialogData.type"
      :title="inputDialogData.name"
      :body="inputDialogData.body"
      :inputPlaceholder="inputDialogData.placeholder"
      :inputTitle="inputDialogData.field"
      :inputValue="inputDialogData.value"
      :buttonTitle="inputDialogData.input"
    />
    <UIDialogAssetView
      v-if="shouldShowAssetDialog"
      @ui-dialog-asset="onDisplayAttributeDialog"
      @ui-dialog-asset-cancel="onCancelAssetDialog"
      :title="'Attribute'"
      :query="'attribute'"
      :id="group.key"
    />
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      :type="promptDialogData.type"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      @ui-dialog-prompt-submit="onSavePromptDialog"
    />
    <UIDialogAttributeView
      v-if="shouldShowAttributeDialog"
      :attribute="attributeDialogData"
      @ui-dialog-attribute-submit="onSaveAttributeDialog"
      @ui-dialog-attribute-cancel="onCancelAttributeDialog"
    />
    <UIDialogAssetQuickView
      v-if="shouldShowQuickViewDialog"
      :quickView="quickViewDialogData"
      @ui-dialog-quick-view-submit="onSaveQuickViewDialog"
      @ui-dialog-quick-view-cancel="onCancelQuickViewDialog"
      @ui-dialog-quick-view-menu="onMenuQuickViewDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import UIDialogAssetView from "@/components/compendium/UIDialogAssetView.vue";
import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogPromptView from "@/components/UI//dialogs/UIDialogPromptView.vue";
import UIDialogAttributeView from "@/components/character/dialogs/UIDialogAttributeView.vue";
import UIDialogAssetQuickView from "@/components/character/dialogs/UIDialogAssetQuickView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import AttributeUICollectionViewCell from "@/components/character/AttributeUICollectionView.vue";

import Attribute from "@/store/models/Attribute";
import AttributeGroup from "@/store/models/AttributeGroup";
import QuickView from "@/store/models/QuickView";

export default defineComponent({
  name: "AttributeGroupUICollectionViewCell",
  components: {
    UILabel,
    UIButton,
    UIMenuView,
    UICollectionView,
    UIDialogAssetView,
    UIDialogInputView,
    UIDialogPromptView,
    AttributeUICollectionViewCell,
    UIDialogAttributeView,
    UIDialogAssetQuickView,
    UIOnboardView,
  },
  props: {
    group: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      shouldShowAttributeDialog: false,
      shouldShowInputDialog: false,
      shouldShowAssetDialog: false,
      shouldShowAttributeTabMenu: false,
      shouldShowPromptDialog: false,
      shouldShowQuickViewDialog: false,
      promptDialogData: {
        type: Object,
        default: {},
      },
      inputDialogData: {
        type: Object,
        default: {},
      },
      attributeDialogData: {
        type: Object,
        default: {},
      },
      quickViewDialogData: {} as QuickView,
      draggingIndex: -1,
    };
  },
  computed: {
    ...mapGetters("Character", [
      "getSelectedAttributeTabKey",
      "getAttributeGroups",
      "getAttributes",
      "isCampaignCharacter",
      "getCampaignId",
    ]),
    selected_section_name(): string {
      return this.getSelectedAttributeTabKey;
    },
    attribute_groups(): any {
      return this.getAttributeGroups;
    },
    selected_attributes(): Array<Attribute> {
      let selectedAttributes: Array<Attribute> = [];
      this.attributes.forEach((attribute: Attribute) => {
        if (attribute.key_attribute_group == this.group.key) {
          selectedAttributes.push(attribute);
        }
      });
      return selectedAttributes.sort(this.compare);
    },
    attributes(): Array<Attribute> {
      return this.getAttributes;
    },
  },
  methods: {
    ...mapActions("Campaigns", ["setAssetAt"]),
    ...mapActions("Character", [
      "setAttributeGroup",
      "setAttribute",
      "removeAttributeGroup",
      "setWorkshopAttribute",
    ]),
    compare(a: Attribute, b: Attribute) {
      // TODO: Make this global
      return a.index - b.index;
    },
    handleDragStart(index: number, event: DragEvent) {
      this.draggingIndex = index;
      event.dataTransfer!.setData("text/plain", ""); // Required for Firefox
      event.dataTransfer!.effectAllowed = "move";
    },
    handleDragOver(index: number, event: DragEvent) {
      event.preventDefault();
      if (index !== this.draggingIndex) {
        // Rearrange the items
        const draggedItem = this.selected_attributes[this.draggingIndex];
        this.selected_attributes.splice(this.draggingIndex, 1);
        this.selected_attributes.splice(index, 0, draggedItem);
        this.draggingIndex = index;
      }
    },
    handleDragEnd() {
      this.draggingIndex = -1;
      console.log(this.selected_attributes);
    },
    onMenuSelect(index: number) {
      if (index == 2) {
        this.removeAttributeTabDialog();
      } else if (index == 1) {
        this.addAttribute();
      } else {
        this.renameAttributeGroup();
      }
    },
    onCancelInputDialog() {
      this.shouldShowInputDialog = false;
    },
    onSaveInputDialog(returnedData: any) {
      let data = returnedData;

      if (data.type == "renameTable") {
        const newTab: AttributeGroup = new AttributeGroup(
          {
            name: data.value,
            key_attribute_tab: this.selected_section_name,
          },
          this.group.key
        );

        this.setAttributeGroup(newTab);
      }
    },
    onDisplayInputDialog(data: any) {
      this.inputDialogData = data;
      this.shouldShowInputDialog = true;
    },
    onDisplayAssetDialog(data: any) {
      this.shouldShowAssetDialog = true;
    },
    onCancelAssetDialog() {
      this.shouldShowAssetDialog = false;
    },
    onDisplayAttributeDialog(data: any) {
      this.attributeDialogData = data !== undefined ? data : {};
      this.shouldShowAttributeDialog = true;
    },
    onCancelAttributeDialog() {
      this.shouldShowAttributeDialog = false;
    },
    onSaveAttributeDialog(data: any) {
      const asset = data.attribute;
      const isNew = data.isNew;

      if (this.isCampaignCharacter) {
        const attribute: Attribute = new Attribute(
          { ...asset.toDictionary(), campaign_id: this.getCampaignId },
          asset.key
        );

        this.setAttribute(attribute);

        const val = {
          path: ["campaigns", this.getCampaignId, "attributes"],
          data: attribute.toDictionary(),
        };
        this.setAssetAt(val);
      } else {
        this.setWorkshopAttribute({
          attribute: asset,
          shouldUpdateCharacter: !isNew,
        });
      }
    },
    onDisplayQuickViewDialog(data: any) {
      const extras: Array<any> = [];
      for (const extra in data.extra) {
        extras.push({
          name: data.extra[extra].name,
          value: data.extra[extra].value,
        });
      }
      const quickView = {
        key: data.key,
        name: data.name + " " + data.value + "",
        description: data.description,
        secondaryAttributes: extras,
        imageName: "attribute_alt",
      };

      this.quickViewDialogData = new QuickView(quickView, data.key);
      this.shouldShowQuickViewDialog = true;
    },
    onCancelQuickViewDialog() {
      this.shouldShowQuickViewDialog = false;
    },
    onSaveQuickViewDialog() {},
    onMenuQuickViewDialog(quickViewData: QuickView) {
      this.onCancelQuickViewDialog();
      const key = quickViewData.key;

      const index = this.selected_attributes.findIndex(
        (c: Attribute) => c.key == key
      );

      if (index < 0) return;

      const attribute: Attribute = this.selected_attributes[index];
      this.onDisplayAttributeDialog(attribute);
    },
    onDisplayPromptDialog(data: any) {
      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onSavePromptDialog() {
      this.removeAttributeGroup(this.group);
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    renameAttributeGroup() {
      const index = this.attribute_groups.findIndex(
        (c: AttributeGroup) => c.key == this.group.key
      );

      if (index > -1) {
        let name = this.attribute_groups[index].name;

        let data = {
          name: "Edit Attribute Group",
          body: "Edit the name of this Attribute Group.",
          field: "Edit Attribute Group",
          value: name,
          placeholder: "Edit Attribute Group",
          input: "Save",
          type: "renameTable",
        };
        this.onDisplayInputDialog(data);
      }
    },
    removeAttributeTabDialog() {
      let data: any = {
        name: "Delete Attribute Group",
        body: `Are you sure you want delete ${this.group.name}? It cannot be undone?`,
        submitTitle: "Continue",
        type: "removeAttributeTab",
      };

      this.onDisplayPromptDialog(data);
    },
    addAttribute() {
      let data = {
        key_attribute_group: this.group.key,
      };
      this.onDisplayAssetDialog(data);
    },
    onAttributeTabMenu(key: string) {
      this.shouldShowAttributeTabMenu = true;
      this.group.key = key;
    },
    dismissAttributeTabMenu() {
      this.shouldShowAttributeTabMenu = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.AttributeTabUICollectionViewCellContainer {
  padding-bottom: 0;
  text-align: left;

  border: 1px solid rgba(0, 0, 0, 0.16);

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.AttributeTabUICollectionViewCell {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 0 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  background: var(--btn_norm);
  color: var(--galveonTextColor);
}

#cvAttributes {
  grid-template-columns: 1fr;
  gap: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.dragging {
  background-color: lightgray; /* Add a visual indication for dragging */
  opacity: 0.5; /* Reduce opacity while dragging */
}
</style>