<template>
  <div>
    <CharacterResource />
    <UISeparatorView />
    <UIOnboardView
      v-if="spellbook.length < 1"
      :title="'Your Spell Library is empty'"
      :body="'Fill your Library with different Spellbooks'"
      :buttonTitle="'Add Spellbook'"
      @ui-onboard-view="newBook"
    />
    <div v-if="spellbook.length > 0">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        "
      >
        <UILabel :text="'Spells'" :type="'h1'" />
        <div>
          <div>
            <img
              id="btnSpellMenu"
              style="cursor: pointer; margin-right: 8px"
              src="@/assets/icon_menu@x2.png"
              @click="onSpellMenu"
            />
          </div>
          <UIMenuView
            v-if="shouldShowSpellMenu"
            :items="[
              'Add Table',
              'Rename Book',
              spellbook.length < 1 || hasBlackMarket
                ? 'Add Book'
                : 'Unlock unlimited books',
              'Delete Book',
            ]"
            @menu-item-selected-at="onMenuSelect"
            @menu-view-dismiss="dismissSpellMenu"
          />
        </div>
      </div>
      <UITabView
        :id="'tvBooks'"
        :tabs="spellbook"
        :selectedTab="selected_book_name"
        @tab-selected="btnBook"
      />
      <div class="section-scroll">
        <UICollectionView
          :id="'cvSpellbook'"
          :hasItems="selected_spell_tables.length > 0"
        >
          <UIOnboardView
            v-if="selected_spell_tables.length < 1"
            :title="'This Spellbook has no Tables'"
            :body="'Tables can be used to group similar spells'"
            :buttonTitle="'Add Spell Table'"
            @ui-onboard-view="addSpellTab"
          />
          <SpellTableUICollectionViewCell
            v-for="(spell_tab, index) in selected_spell_tables"
            :key="index"
            :table="spell_tab"
          />
        </UICollectionView>
      </div>
    </div>
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      @ui-dialog-input-cancel="onCancelInputDialog"
      @ui-dialog-input-submit="onSaveInputDialog"
      :type="inputDialogData.type"
      :title="inputDialogData.name"
      :body="inputDialogData.body"
      :inputTitle="inputDialogData.field"
      :inputPlaceholder="inputDialogData.placeholder"
      :inputValue="inputDialogData.value"
      :buttonTitle="inputDialogData.input"
    />
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      :type="promptDialogData.type"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      @ui-dialog-prompt-submit="onSavePromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
    />
    <UIDialogPurchaseChenShop
      v-if="shouldShowChenDialog"
      @ui-dialog-purchase-cancel="dismissChenDialog"
    />
    <UIDialogAssetView
      v-if="showDialog"
      :title="'Effect'"
      :query="'effect'"
      :id="''"
      :isCommunity="true"
      @ui-dialog-asset-cancel="closeDialog()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";

import UIDialogPurchaseChenShop from "@/components/galveon/dialogs/UIDialogPurchaseChenShop.vue";
import UIDialogAssetView from "@/components/compendium/UIDialogAssetView.vue";

import UITabView from "@/components/UI/UITabView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import CharacterResource from "@/components/character/CharacterResources.vue";

import SpellTableUICollectionViewCell from "@/components/character/SpellTableUICollectionViewCell.vue";

import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogPromptView from "@/components/UI//dialogs/UIDialogPromptView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import Spellbook from "@/store/models/Spellbook";
import SpellTable from "@/store/models/SpellTable";

export default defineComponent({
  name: "CharacterSpells",
  components: {
    UITabView,
    UILabel,
    UIMenuView,
    UIButton,
    UISeparatorView,
    UICollectionView,
    UIDialogInputView,
    UIDialogPromptView,
    UIOnboardView,
    SpellTableUICollectionViewCell,
    CharacterResource,
    UIDialogPurchaseChenShop,
    UIDialogAssetView,
  },
  data() {
    return {
      shouldShowSpellDialog: false,
      shouldShowInputDialog: false,
      shouldShowSpellMenu: false,
      shouldShowPromptDialog: false,
      shouldShowChenDialog: false,
      promptDialogData: {
        type: Object,
        default: {},
      },
      inputDialogData: {
        type: Object,
        default: {},
      },
      spellDialogData: {
        type: Object,
        default: {},
      },
    };
  },
  computed: {
    ...mapState("Dialog", ["showDialog"]),
    ...mapGetters("Account", ["hasBlackMarket"]),
    ...mapGetters("Character", [
      "getSelectedSpellBookKey",
      "getSpellBooks",
      "getSpellTables",
    ]),
    selected_book_name(): string {
      return this.getSelectedSpellBookKey;
    },
    spellbook(): Array<Spellbook> {
      return this.getSpellBooks;
    },
    spell_tables(): Array<SpellTable> {
      return this.getSpellTables;
    },
    selected_spell_tables(): Array<SpellTable> {
      let selectedTabs: Array<SpellTable> = [];
      this.spell_tables.forEach((tab: SpellTable) => {
        if (tab.key_spell_book == this.selected_book_name) {
          selectedTabs.push(tab);
        }
      });
      return selectedTabs;
    },
  },
  methods: {
    ...mapActions("Dialog", ["closeDialog"]),
    ...mapActions("Character", [
      "setCurrentBook",
      "setBook",
      "setSpellTable",
      "deleteBook",
    ]),
    showChenDialog() {
      this.shouldShowChenDialog = true;
    },
    dismissChenDialog() {
      this.shouldShowChenDialog = false;
    },
    btnBook(key: string) {
      this.setCurrentBook(key);
    },
    onMenuSelect(index: number) {
      if (index == 3) {
        this.removeBook();
      } else if (index == 2) {
        if (this.spellbook.length < 1 || this.hasBlackMarket) {
          this.newBook();
        } else {
          this.showChenDialog();
        }
      } else if (index == 1) {
        this.renameBook();
      } else {
        this.addSpellTab();
      }
    },
    onCancelInputDialog() {
      this.shouldShowInputDialog = false;
    },
    onSaveInputDialog(returnedData: any) {
      let data = returnedData;

      if (data.type == "renameBook" || data.type == "newBook") {
        const newBook: Spellbook = new Spellbook(
          {
            name: data.value,
          },
          data.type == "renameBook" ? this.selected_book_name : ""
        );

        this.setBook(newBook);
      } else if (data.type == "newSpellTab") {
        const newTable: SpellTable = new SpellTable(
          {
            name: data.value,
            key_spell_book: this.selected_book_name,
          },
          ""
        );

        this.setSpellTable(newTable);
      }
    },
    onDisplayInputDialog(data: any) {
      this.inputDialogData = data;
      this.shouldShowInputDialog = true;
    },
    onDisplayPromptDialog(data: any) {
      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onSavePromptDialog() {
      this.deleteBook();
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    newBook() {
      let data = {
        name: "Add Spellbook",
        body: "Type the name of a new Spellbook.",
        field: "Add Spellbook",
        placeholder: "Add Spellbook",
        input: "Save",
        type: "newBook",
      };
      this.onDisplayInputDialog(data);
    },
    renameBook() {
      const index = this.spellbook.findIndex(
        (c: Spellbook) => c.key == this.selected_book_name
      );

      if (index > -1) {
        let name = this.spellbook[index].name;

        let data = {
          name: "Edit Spellbook name",
          body: "Edit the name of this Spellbook.",
          field: "Edit Spellbook",
          placeholder: "Edit Spellbook",
          value: name,
          input: "Save",
          type: "renameBook",
        };
        this.onDisplayInputDialog(data);
      }
    },
    removeBook() {
      let data: any = {
        name: "Remove Spellbook",
        body: `Are you sure you want to delete ${name}? It cannot be undone?`,
        submitTitle: "Continue",
        type: "removeBook",
      };

      this.onDisplayPromptDialog(data);
    },
    addSpellTab() {
      let data = {
        name: "Add Spell Table",
        body: "Type the name of a new Spell Table.",
        field: "Add Spell Table",
        placeholder: "Add Spell Table",
        input: "Save",
        type: "newSpellTab",
      };
      this.onDisplayInputDialog(data);
    },
    onSpellMenu() {
      this.shouldShowSpellMenu = true;
    },
    dismissSpellMenu() {
      this.shouldShowSpellMenu = false;
    },
  },
});
</script>

<style lang="scss" scoped>
#cvSpellbook {
  grid-template-columns: 1fr;
}

.section-scroll {
  overflow: auto;
  height: calc(100vh - 484px);
}
</style>