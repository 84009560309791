import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_menu@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-1fbe191e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "UIDialogView" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = {
  key: 0,
  class: "menu"
}
const _hoisted_4 = { style: {"max-height":"600px","overflow":"auto"} }
const _hoisted_5 = {
  key: 3,
  class: "footer flex"
}
const _hoisted_6 = {
  key: 4,
  class: "footer-single"
}
const _hoisted_7 = {
  key: 5,
  class: "footer-single"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "UIDialogViewContainer",
    onMousedown: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.hideHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_UILabel, {
              text: _ctx.title,
              type: 'h1'
            }, null, 8, ["text"]),
            (_ctx.hasMenu)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    style: {"cursor":"pointer","margin-right":"8px"},
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onShowMenu && _ctx.onShowMenu(...args)))
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.shouldShowMenu)
              ? (_openBlock(), _createBlock(_component_UIMenuView, {
                  key: 1,
                  items: _ctx.menuItems,
                  onMenuItemSelectedAt: _ctx.onMenuSelect,
                  onMenuViewDismiss: _ctx.onDismissMenu
                }, null, 8, ["items", "onMenuItemSelectedAt", "onMenuViewDismiss"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_UILabel, {
        text: _ctx.subTitle,
        justify: 'left'
      }, null, 8, ["text"]),
      (!_ctx.hideHeader)
        ? (_openBlock(), _createBlock(_component_UISeparatorView, { key: 1 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (!_ctx.hideButtons)
        ? (_openBlock(), _createBlock(_component_UISeparatorView, { key: 2 }))
        : _createCommentVNode("", true),
      (!_ctx.hasDone && !_ctx.hideButtons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_UIButton, {
              id: 'btnCancel',
              title: 'Cancel',
              onClick: _ctx.onCancel
            }, null, 8, ["onClick"]),
            _createVNode(_component_UIButton, {
              id: 'btnSubmit',
              title: _ctx.submitTitle,
              disabled: _ctx.disableSubmit,
              onClick: _ctx.onSubmit
            }, null, 8, ["title", "disabled", "onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasDone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_UIButton, {
              id: 'btnDone',
              title: 'Continue',
              onClick: _ctx.onCancel
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.removeTitle !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_UIButton, {
              id: 'btnRemove',
              title: _ctx.removeTitle,
              onClick: _ctx.onRemove
            }, null, 8, ["title", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 32))
}