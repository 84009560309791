import AbilityClass from "@/store/models/AbilityClass";
import { Timestamp } from "firebase/firestore";

export const replaceSpacesWithUnderscores = (input: string): string => {
  return input.replace(/\s/g, '_');
}

export const getRandomHexColor = (): string => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16); // 16777215 is FFFFFF in hexadecimal

  // Ensure the color code has six digits
  return '#' + ('000000' + randomColor).slice(-6);
}

export const componentToHex = (r: number, g: number, b: number): string => {
  if (r === undefined) {
    return "";
  }

  //Remove these after checking database on why number can be 256
  if (r == 256) {
    r = 255;
  }
  if (g == 256) {
    g = 255;
  }
  if (b == 256) {
    b = 255;
  }

  return (
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x?.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
}

export const hexToRgb = (hex: string): { red: number; green: number; blue: number } => {
  if (!hex || hex.length !== 7) {
    throw new Error('Invalid hex color format');
  }

  const strippedHex = hex.replace('#', '');
  const bigint = parseInt(strippedHex, 16);
  const red = (bigint >> 16) & 255;
  const green = (bigint >> 8) & 255;
  const blue = bigint & 255;

  return { red, green, blue };
};

export const calculateDarkerColor = (hexColor: string): string => {
  // Remove the '#' symbol from the hex color code
  hexColor = hexColor.replace("#", "");

  // Parse the hex color components (RRGGBB)
  let r = parseInt(hexColor.substring(0, 2), 16); // Red
  let g = parseInt(hexColor.substring(2, 4), 16); // Green
  let b = parseInt(hexColor.substring(4, 6), 16); // Blue

  // Define the brightness reduction percentage
  const brightnessReductionPercentage = 0.3;

  // Calculate the darker color by reducing each RGB component based on brightness reduction percentage
  r = Math.round(r * (1 - brightnessReductionPercentage));
  g = Math.round(g * (1 - brightnessReductionPercentage));
  b = Math.round(b * (1 - brightnessReductionPercentage));

  // Convert the RGB components back to hexadecimal
  const darkerHexColor =
    "#" +
    ("0" + r.toString(16)).slice(-2) +
    ("0" + g.toString(16)).slice(-2) +
    ("0" + b.toString(16)).slice(-2);

  return darkerHexColor;
}

export const newDate = (): Timestamp => {
  let now = new Date();

  return Timestamp.fromDate(now);
}

export const setValue = (value: string | Object | Array<any>): string | Object | Array<any> | Record<string, any> | AbilityClass[] | null => {
  if (value === "0" ||
    value === "" ||
    Object.keys(value).length === 0 ||
    (Array.isArray(value) && value.length === 0)) {
    return null;
  }
  return value;
}

export const delimit = (value: number, counter: number = 0): string => {
  var value = Math.trunc( value );
  let dividened: number = (counter > 0) ? 999 : 9999;
  let thousandthLetter: Array<string> = ["", "k", "m", "b", "t", "p"];
  if (value > dividened) {
    value = value / 1000;
    return delimit(value, counter + 1);
  }
  if (counter >= thousandthLetter.length) {
    return `${value}${thousandthLetter[thousandthLetter.length - 1]}`;
}
  
  return `${value}${thousandthLetter[counter]}`;
}

export const compare = ( a: any, b: any ): number => {
  if ( a.last_nom < b.last_nom ){
    return -1;
  }
  if ( a.last_nom > b.last_nom ){
    return 1;
  }
  return 0;
}

export const isEmpty = (obj: any): Boolean => {
  return Object.entries(obj).length === 0;
}

