import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d77c60c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "onboard-currency" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_UISwitchView = _resolveComponent("UISwitchView")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogManageCurrency = _resolveComponent("UIDialogManageCurrency")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: 'Currency',
      hasMenu: true,
      menuItems: ['Add Currency'],
      hasDone: true,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onCurrencyMenuSelect
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_UICollectionView, { id: 'cvCurrency' }, {
            default: _withCtx(() => [
              (_ctx.getCurrencies.length < 1)
                ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                    key: 0,
                    title: 'No Currencies',
                    body: 'Add a currency to keep track of things like gold, silver, or other countable things you might find in your pocket.',
                    buttonTitle: 'Add Currency',
                    onUiOnboardView: _ctx.btnAddCurrency
                  }, null, 8, ["body", "onUiOnboardView"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCurrencies, (currency, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("div", {
                    class: "CurrencyUICollectionViewCell",
                    onClick: ($event: any) => (_ctx.onShowCurrencyDialog(currency))
                  }, [
                    _createVNode(_component_UISwitchView, {
                      disabled: !currency.is_favorite && _ctx.currenctFavorites >= 3,
                      checked: currency.is_favorite,
                      onUiSwitchViewToggle: ($event: any) => (_ctx.onCurrencyFavoriteToggle(index, $event))
                    }, null, 8, ["disabled", "checked", "onUiSwitchViewToggle"]),
                    _createVNode(_component_UILabel, {
                      id: 'lblExtraName',
                      text: currency.name
                    }, null, 8, ["text"]),
                    _createVNode(_component_UILabel, {
                      id: 'lblExtraValue',
                      text: currency.value
                    }, null, 8, ["text"])
                  ], 8, _hoisted_2)
                ]))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowCurrencyDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogManageCurrency, {
            key: 0,
            selectedCurrency: _ctx.selectedCurrency,
            onUiDialogCurrencySubmit: _ctx.onSubmitCurrencyDialog,
            onUiDialogCurrencyDelete: _ctx.onDeleteCurrencyDialog,
            onUiDialogCurrencyCancel: _ctx.onHideCurrencyDialog
          }, null, 8, ["selectedCurrency", "onUiDialogCurrencySubmit", "onUiDialogCurrencyDelete", "onUiDialogCurrencyCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}