import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/icon_plus@x1.png'


const _hoisted_1 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_ManageAssetView = _resolveComponent("ManageAssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogPublishView = _resolveComponent("UIDialogPublishView")!
  const _component_UIDialogMessageView = _resolveComponent("UIDialogMessageView")!
  const _component_UIDialogManageCurrency = _resolveComponent("UIDialogManageCurrency")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.action} Game System`,
      canRemove: _ctx.action == 'Edit',
      hasMenu: _ctx.hasMenu,
      menuItems: _ctx.menuItems,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onGameSystemDialogMenuSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ManageAssetView, {
          asset: _ctx.selectedGameSystem,
          hasCompendium: false,
          hasAdvanced: false
        }, {
          basic: _withCtx(() => [
            _createVNode(_component_UITextField, {
              id: 'txtName',
              title: 'System name',
              textLabel: 'System name',
              modelValue: _ctx.selectedGameSystem.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGameSystem.name) = $event)),
              placeholder: 'System name',
              disabled: _ctx.readOnly
            }, null, 8, ["modelValue", "disabled"]),
            _createVNode(_component_UITextView, {
              id: 'txtDescription',
              modelValue: _ctx.selectedGameSystem.description,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedGameSystem.description) = $event)),
              placeholder: 'Description',
              disabled: _ctx.readOnly
            }, null, 8, ["modelValue", "disabled"]),
            (
            _ctx.selectedGameSystem.currencies &&
            _ctx.selectedGameSystem.currencies?.length > 0
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_UILabel, {
                    text: 'Currencies',
                    type: 'h2'
                  }),
                  (!_ctx.readOnly)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _imports_0,
                        style: {"cursor":"pointer"},
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDisplayCurrencyDialog()))
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_UICollectionView, { id: 'cvCurrencies' }, {
              default: _withCtx(() => [
                (
              !_ctx.selectedGameSystem.currencies ||
              _ctx.selectedGameSystem.currencies?.length < 1
            )
                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                      key: 0,
                      title: 'No Currencies',
                      buttonTitle: 'Add Currency',
                      onUiOnboardView: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDisplayCurrencyDialog()))
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedGameSystem.currencies, (currency, index) => {
                  return (_openBlock(), _createBlock(_component_UILabel, {
                    key: index,
                    text: currency.name,
                    onClick: ($event: any) => (_ctx.onDisplayCurrencyDialog(index, currency))
                  }, null, 8, ["text", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          advanced: _withCtx(() => []),
          _: 1
        }, 8, ["asset"])
      ]),
      _: 1
    }, 8, ["title", "canRemove", "hasMenu", "menuItems", "onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 0,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPublishDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPublishView, {
            key: 1,
            asset: _ctx.game_system,
            body: 'Submitting this Game System will share it with the entire community. Are you sure you want to submit this Game System?',
            onUiDialogPublishCancel: _ctx.onCancelPublishDialog
          }, null, 8, ["asset", "body", "onUiDialogPublishCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowMessageDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogMessageView, {
            key: 2,
            title: 'Success',
            body: 'Asset successfuly submitted for review.',
            onUiDialogMessageSubmit: _ctx.onDismissMessageDialog
          }, null, 8, ["body", "onUiDialogMessageSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowCurrencyDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogManageCurrency, {
            key: 3,
            selectedCurrency: _ctx.currencyDialogData.inputValue,
            onUiDialogCurrencySubmit: _ctx.onSubmitCurrencyDialog,
            onUiDialogCurrencyDelete: _ctx.onDeleteCurrencyDialog,
            onUiDialogCurrencyCancel: _ctx.onHideCurrencyDialog
          }, null, 8, ["selectedCurrency", "onUiDialogCurrencySubmit", "onUiDialogCurrencyDelete", "onUiDialogCurrencyCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}