import IBonus from "../../interfaces/IBonus";
import SecondaryAttribute from "../SecondaryAttribute";
import Bonus from "../Bonus";
import Modification from "../Modification";
import IAttributeBase from "@/store/interfaces/AssetBases/IAttributeBase";
import FormulaItem from "../FormulaItem";
import CompendiumAssetBase from "../Compendium/CompendiumAssetBase";
import { AssetType } from "../Compendium/AssetType";

export default class AttributeBase extends CompendiumAssetBase implements IAttributeBase {
  constructor(dictionary: Record<string, any>, key: string) {
    super(dictionary, key, AssetType.attribute);

    if (dictionary["extra"]) {
      for (const itemKey in dictionary["extra"]) {
        const selectedExtra: SecondaryAttribute = new SecondaryAttribute(dictionary["extra"][itemKey]);
        this.extra.push(selectedExtra);
      }
    }

    this.is_primary_attribute = dictionary["is_primary_attribute"] === undefined ? true : dictionary["is_primary_attribute"];
    this.maximum_points = dictionary["maximum_points"] || 255;
    this.minimum_points = dictionary["minimum_points"] || 0;

    this.modifier_formula = dictionary["modifier_formula"] || "";

    const modificationValue = dictionary["modification"] || {};
    this.modification = new Modification(modificationValue);
    if (modificationValue) {
      for (const bonus in this.modification.attributeChanges) {
        const selectedBonus = this.modification.attributeChanges[bonus];
        const newBonus: Bonus = new Bonus(selectedBonus.formula, selectedBonus.attribute_key, selectedBonus.key);
        this.bonus.push(newBonus);
      }

      for (const bonus in this.modification.resourceChanges) {
        const selectedBonus = this.modification.resourceChanges[bonus];
        const newBonus: Bonus = new Bonus(selectedBonus.formula, selectedBonus.attribute_key, selectedBonus.key);
        this.bonus.push(newBonus);
      }
    }

    const table_modifier_formula = dictionary["table_modifier_formula"] || {};
    if (table_modifier_formula) {
      for (const itemKey in table_modifier_formula) {
        const selectedFormula: FormulaItem = new FormulaItem(table_modifier_formula[itemKey]);
        this.table_modifier_formula.push(selectedFormula);
      }
    }

    if (dictionary["bonus"]) {
      for (const key in dictionary["bonus"]) {
        const formula = dictionary["bonus"][key];
        const selectedBonus: Bonus = new Bonus(formula, this.key, key);

        this.bonus.push(selectedBonus);
      }
    }
  }

  is_primary_attribute: boolean;
  maximum_points: number;
  minimum_points: number;

  table_modifier_formula: FormulaItem[] = [];
  extra: SecondaryAttribute[] = [];
  modification: Modification;
  bonus: IBonus[] = [];
  modifier_formula: string;

  toDictionary(): Record<string, any> {
    let returnedDictionary: Record<string, any> = super.toDictionary();

    if (this.extra.length > 0) {
      var extraDictionary: Record<string, any> = {};
      for (const index in this.extra) {
        extraDictionary[index] = this.extra[index].toDictionary()
      }
      returnedDictionary["extra"] = extraDictionary;
    }

    if (this.table_modifier_formula.length > 0) {
      var formulaDictionary: Record<string, any> = {};
      for (const index in this.table_modifier_formula) {
        formulaDictionary[index] = this.table_modifier_formula[index].toDictionary()
      }
      returnedDictionary["table_modifier_formula"] = formulaDictionary;
    }

    if (this.bonus.length > 0) {
      var bonusDictionary: Record<string, any> = {};
      for (const aBonus of this.bonus) {
        bonusDictionary[aBonus.key] = aBonus.formula;
      }
      returnedDictionary["bonus"] = bonusDictionary;
    }

    returnedDictionary["modifier_formula"] = this.modifier_formula;
    returnedDictionary["modification"] = this.modification.toDictionary();

    returnedDictionary["is_primary_attribute"] = this.is_primary_attribute;

    return returnedDictionary;
  }

};