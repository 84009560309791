import IAttribute from "@/store/interfaces/IAttribute";
import AttributeBase from "./AssetBases/AttributeBase";

export default class Attribute extends AttributeBase implements IAttribute {
    constructor(dictionary: Record<string, any>, key: string) {
        super(dictionary, key);

        this.value = dictionary["value"] || "";

        if (+this.value) {
            this.intValue = +this.value;
        }

        this.index = dictionary["index"] ?? 0;
        this.key_attribute_group = dictionary["key_attribute_group"] || "";

        this.show_modifier = dictionary["show_modifier"] === undefined ? true : dictionary["show_modifier"];

    }

    value: string;
    intValue: number = 0;
    index: number;
    key_attribute_group: string;
    show_modifier: boolean;

    toDictionary(): Record<string, any> {
        let returnedDictionary: Record<string, any> = super.toDictionary();
        
        returnedDictionary["value"] = this.value;
        returnedDictionary["index"] = this.index;
        returnedDictionary["key_attribute_group"] = this.key_attribute_group;
        returnedDictionary["show_modifier"] = this.show_modifier;

        return returnedDictionary;
    }

}