<template>
  <UIDialogView
    :title="`${action} Extra`"
    :hasMenu="action == 'Edit'"
    :menuItems="['Delete Secondary Attribute']"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onDisplayPromptDialog"
  >
    <div class="vInput">
      <UITextField
        :id="'txtName'"
        :type="'text'"
        :textLabel="'Name'"
        v-model="selectedExtra.name"
        :placeholder="'Name'"
      />
      <UITextField
        :id="'txtValue'"
        :type="'text'"
        :textLabel="'Value'"
        v-model="selectedExtra.value"
        :placeholder="'Value'"
      />
    </div>
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITextField from "@/components/UI/UITextField.vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import SecondaryAttribute from "@/store/models/SecondaryAttribute";

export default defineComponent({
  name: "UIDialogSecondaryAttributeView",
  components: {
    UIDialogView,
    UIDialogPromptView,
    UILabel,
    UITextField,
    UIButton,
    UISeparatorView,
  },
  emits: [
    "ui-dialog-secondary-attribute-submit",
    "ui-dialog-secondary-attribute-cancel",
    "ui-dialog-secondary-attribute-remove",
  ],
  data() {
    return {
      selectedExtra: {} as SecondaryAttribute,
      shouldShowPromptDialog: false,
      promptDialogData: {},
    };
  },
  props: {
    extra: {
      type: Object as () => SecondaryAttribute,
      default: {},
    },
  },
  methods: {
    onCancel() {
      this.$emit("ui-dialog-secondary-attribute-cancel");
    },
    onSubmit() {
      this.selectedExtra.name =
        this.selectedExtra.name || this.selectedExtra.name === ""
          ? this.selectedExtra.name
          : "New attribute";
      this.selectedExtra.value = this.selectedExtra.value
        ? this.selectedExtra.value
        : "";

      this.$emit("ui-dialog-secondary-attribute-submit", this.selectedExtra);
      this.onCancel();
    },
    onDisplayPromptDialog(index: number) {
      if (index === 0) {
        let data: any = {
          name: "Delete Secondary Attribute",
          body: `Are you sure you want to delete ${this.selectedExtra.name} from this Spell?`,
          submitTitle: "Continue",
          type: "removeExtra",
        };

        this.promptDialogData = data;
        this.shouldShowPromptDialog = true;
      }
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog() {
      this.$emit("ui-dialog-secondary-attribute-remove");
      this.onCancel();
    },
  },
  computed: {
    action(): string {
      if (
        this.selectedExtra === undefined
      ) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.selectedExtra = new SecondaryAttribute(this.extra.toDictionary());
  },
});
</script>

<style lang="scss">
</style>@/store/models/SecondaryAttribute