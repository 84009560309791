import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "UIDropDownSelectedItem" }
const _hoisted_2 = {
  key: 0,
  class: "UIDropDownView"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.shouldShowMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "overlay",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMenuDismiss && _ctx.onMenuDismiss(...args)), ["stop"]))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "UIDropDownField",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showMenu && _ctx.showMenu(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UILabel, { text: _ctx.computedValue }, null, 8, ["text"])
      ]),
      (_ctx.shouldShowMenu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "UIDropDownItemView",
                key: index,
                onClick: _withModifiers(($event: any) => (_ctx.dropDownItemSelected(index)), ["stop"])
              }, [
                _createVNode(_component_UILabel, { text: item }, null, 8, ["text"])
              ], 8, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}