<template>
  <UIDialogView
    :title="`Character Biography`"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <UITabView
      :id="'tvTabs'"
      :tabs="tabs"
      :selectedTab="selectedTab"
      @tab-selected="btnSelected"
    />
    <template v-if="selectedTab === 'lore'">
      <UITextView
        :id="'txtLore'"
        v-model="selectedCharacter.lore"
        :placeholder="'Lore'"
      />
    </template>
    <template v-if="selectedTab === 'characteristics'">
      <UIDropdownGameSystem
        :selectedValue="character.game_system_key"
        @ui-dropdown-game-system-select="onGameSystemCellDropdownMenuSelect"
      />
      <UISeparatorView />
      <template v-for="(field, index) in bioData" :key="index">
        <UICellTextField
          v-if="field.type === 'text' || field.type === 'number'"
          :textLabel="field.label"
          :type="field.type"
          v-model="this.selectedCharacter[field.key]"
          :placeholder="field.label"
        />
        <div class="flex" v-if="field.type === 'color'">
          <UILabel :text="field.label" />
          <input
            class="color-picker"
            type="color"
            v-model="this.selectedCharacter[field.key]"
          />
        </div>
      </template>
    </template>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";
import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";
import UITabView from "@/components/UI/UITabView.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UICellTextField from "@/components/UI/UICellTextField.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import UIDropdownGameSystem from "@/components/compendium/UIDropdownGameSystem.vue";
import CharacterClass from "@/store/models/Character";

export default defineComponent({
  name: "UIDialogBioView",
  props: {
    character: {
      type: Object as () => CharacterClass,
      require: true,
    },
    lore: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedCharacter: {} as CharacterClass,
      tabs: [
        {
          name: "Lore",
          key: "lore",
        },
        // {
        //   name: "Characteristics",
        //   key: "characteristics",
        // },
      ],
      selectedTab: "lore",
      bioData: [
        {
          label: "Height",
          type: "text",
          key: "height",
        },
        {
          label: "Weight",
          type: "number",
          key: "weight",
        },
        {
          label: "Size",
          type: "text",
          key: "size",
        },
        {
          label: "Age",
          type: "number",
          key: "age",
        },
        {
          label: "Eye Color",
          type: "color",
          key: "eye_color",
        },
        {
          label: "Hair Color",
          type: "color",
          key: "hair_color",
        },
      ],
    };
  },
  emits: ["ui-dialog-lore-submit", "ui-dialog-lore-cancel"],
  components: {
    UITabView,
    UITextView,
    UIDialogView,
    UIOnboardView,
    UIDropdownGameSystem,
    UICellTextField,
    UILabel,
    UISeparatorView,
  },
  methods: {
    ...mapActions("Character", ["setCharacterStory"]),
    onSubmit() {
      this.setCharacterStory(this.selectedCharacter.story);
      this.$emit("ui-dialog-lore-submit");
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-lore-cancel");
    },
    btnSelected(key: string) {
      this.selectedTab = key;
    },
    onGameSystemCellDropdownMenuSelect(key: string, name: string) {
      this.selectedCharacter.game_system_key = key;
      this.selectedCharacter.game_system_name = name;
    },
    addCharacteristics() {},
  },
  mounted() {
    this.selectedCharacter = this.character!;
  },
});
</script>

<style lang="scss">
</style>