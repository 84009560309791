import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.newCurrency.key === '' ? 'New' : 'Edit'} Currency`,
      hasMenu: _ctx.newCurrency.key !== '',
      menuItems: ['Delete Currency'],
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onCurrencyMenuSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UITextField, {
          id: 'txtName',
          placeholder: 'Name',
          textLabel: 'Name',
          modelValue: _ctx.newCurrency.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newCurrency.name) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_UITextField, {
          id: 'txtValue',
          placeholder: 'Value',
          textLabel: 'Value',
          type: 'number',
          modelValue: _ctx.newCurrency.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCurrency.value) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["title", "hasMenu", "onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 0,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}