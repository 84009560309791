<template>
  <div class="QuickViewUICollectionViewCell">
    <UILabel :text="extra.name" :justify="'left'" :type="'bold'" />
    <UILabel :text="extra.value" :justify="'left'" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "QuickViewUICollectionViewCell",
  components: {
    UILabel,
    UISeparatorView,
  },
  props: {
    extra: {
      type: Object,
      default: {},
    },
  },
});
</script>

<style lang="scss" scoped>
.QuickViewUICollectionViewCell {
  padding: 8px;
  height: 44px;
  cursor: pointer;

  box-sizing: border-box;
}

</style>