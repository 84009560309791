export enum AreaMeasurement {
  feet = "feet",
  square_feet = "square_feet",
  cubic_feet = "cubic_feet",
  meters = "meters",
  square_meters = "square_meters",
  cubic_meters = "cubic_meters",
  yards = "yards",
  square_yards = "square_yards",
  cubic_yards = "cubic_yards",
}