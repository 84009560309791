import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DescriptionView = _resolveComponent("DescriptionView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: 'Description',
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DescriptionView, {
        description: _ctx.newDescription,
        onDescriptionViewChange: _ctx.onDescriptionViewChange
      }, null, 8, ["description", "onDescriptionViewChange"])
    ]),
    _: 1
  }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]))
}