import IModifyVital from "@/store/interfaces/Effects/IModifyVital";
import EffectBase from "../AssetBases/EffectBase";
import { EffectType } from "@/store/interfaces/types/EffectType";
import { DamageType } from "@/store/interfaces/Effects/DamageType";
import { DisplayFlags } from "@/store/interfaces/Effects/DisplayFlags";

export default class ModifyVital extends EffectBase implements IModifyVital {
  constructor(dictionary: Record<string, any>, key: string) {
    super(dictionary, key, EffectType.modify_vital);

    this.minimum = dictionary["minimum"] || 0;
    this.maximum = dictionary["maximum"] || 1000;
    this.modify_type = dictionary["modify_type"] || "none";
    this.amount = dictionary["amount"] || "";
    this.killConjure = dictionary["kill_conjure"] || false;
    this.chance = dictionary["chance"] || 100;
    //TODO: Display flags
  }

  minimum: number;
  maximum: number;
  modify_type: DamageType;
  amount: string;
  killConjure: boolean;
  chance: number;
  // display_flags: DisplayFlags[] = [];

  toDictionary(): Record<string, any> {
    var returnedDictionary: Record<string, any> = super.toDictionary();

    returnedDictionary["minimum"] = this.minimum;
    returnedDictionary["maximum"] = this.maximum;
    returnedDictionary["modify_type"] = this.modify_type;
    returnedDictionary["amount"] = this.amount;
    returnedDictionary["kill_conjure"] = this.killConjure;
    returnedDictionary["chance"] = this.chance;

    return returnedDictionary;
  }
}