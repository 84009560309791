<template>
  <div class="CharacterResource">
    <UIOnboardView
      v-if="resources.length < 1"
      :title="'You have no Resources'"
      :body="'You can track different resources, such as mana or spells per day'"
      :buttonTitle="'Add Resource'"
      @ui-onboard-view="addResource"
    />
    <div v-if="resources.length > 0">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        "
      >
        <UILabel :text="'Resources'" :type="'h1'" />
        <div>
          <div>
            <img
              id="btnResourceMenu"
              style="cursor: pointer; margin-right: 8px"
              src="@/assets/icon_menu@x2.png"
              @click="onResourceMenu"
            />
          </div>
          <UIMenuView
            v-if="shouldShowResourceMenu"
            :items="['Add Resource']"
            @menu-item-selected-at="onMenuSelect"
            @menu-view-dismiss="dismissResourceMenu"
          />
        </div>
      </div>
      <UICollectionView :id="'cvResources'" :hasItems="resources.length > 0">
        <div v-if="resources.length < 1"></div>
        <ResourceView
          v-for="(resource, index) in resources"
          :key="index"
          :resource="resource"
          @click="onDisplayQuickViewDialog(resource)"
        />
      </UICollectionView>
    </div>
  </div>
  <div>
    <UIDialogAssetView
      v-if="shouldShowAssetDialog"
      :id="''"
      :title="'Resource'"
      :query="'resource'"
      @ui-dialog-asset="onDisplayResourceDialog"
      @ui-dialog-asset-cancel="onCancelAssetDialog"
    />
    <UIDialogResourceView
      v-if="shouldShowResourceDialog"
      :resource="resourceDialogData"
      @ui-dialog-resource-submit="onSaveResourceDialog"
      @ui-dialog-resource-cancel="onCancelResourceDialog"
    />
    <UIDialogAssetQuickView
      v-if="shouldShowQuickViewDialog"
      :quickView="quickViewDialogData"
      @ui-dialog-quick-view-submit="onSaveQuickViewDialog"
      @ui-dialog-quick-view-cancel="onCancelQuickViewDialog"
      @ui-dialog-quick-view-menu="onMenuQuickViewDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import UICollectionView from "@/components/UI/UICollectionView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";

import UIDialogAssetView from "@/components/compendium/UIDialogAssetView.vue";
import UIDialogResourceView from "@/components/character/dialogs/UIDialogResourceView.vue";
import UIDialogAssetQuickView from "@/components/character/dialogs/UIDialogAssetQuickView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import ResourceView from "@/components/character/ResourceView.vue";

import Resource from "@/store/models/Resource";
import QuickView from "@/store/models/QuickView";

export default defineComponent({
  name: "CharacterResource",
  components: {
    UICollectionView,
    UILabel,
    UIButton,
    UIMenuView,
    ResourceView,
    UIDialogAssetView,
    UIDialogResourceView,
    UIDialogAssetQuickView,
    UIOnboardView,
  },
  data() {
    return {
      shouldShowResourceMenu: false,
      shouldShowAssetDialog: false,
      shouldShowResourceDialog: false,
      shouldShowQuickViewDialog: false,
      resourceDialogData: {
        type: Object,
        default: {},
      },
      quickViewDialogData: {} as QuickView,
    };
  },
  computed: {
    ...mapGetters("Character", [
      "getResources",
      "isCampaignCharacter",
      "getCampaignId",
    ]),
    resources(): Array<Resource> {
      return this.getResources;
    },
  },
  methods: {
    ...mapActions("Campaigns", ["setAssetAt"]),
    ...mapActions("Character", ["setWorkshopResource", "setResource"]),
    onMenuSelect(index: number) {
      this.addResource();
    },
    onResourceMenu() {
      this.shouldShowResourceMenu = true;
    },
    dismissResourceMenu() {
      this.shouldShowResourceMenu = false;
    },
    addResource() {
      this.onDisplayAssetDialog();
    },
    onDisplayAssetDialog(data: any = {}) {
      this.shouldShowAssetDialog = true;
    },
    onCancelAssetDialog() {
      this.shouldShowAssetDialog = false;
    },
    onDisplayResourceDialog(data: any) {
      this.resourceDialogData = data !== undefined ? data : {};
      this.shouldShowResourceDialog = true;
    },
    onSaveResourceDialog(data: any) {
      const asset = data.resource;
      const isNew = data.isNew;

      if (this.isCampaignCharacter) {
        const resource: Resource = new Resource({ ...asset.toDictionary(), campaign_id: this.getCampaignId }, asset.key);

        this.setResource(resource);

        const val = {
          path: ["campaigns", this.getCampaignId, "resources"],
          data: resource.toDictionary(),
        };
        this.setAssetAt(val);
      } else {
        this.setWorkshopResource({
          resource: asset,
          shouldUpdateCharacter: !isNew,
        });
      }
    },
    onCancelResourceDialog() {
      this.shouldShowResourceDialog = false;
    },
    onDisplayQuickViewDialog(data: any) {
      const extras: Array<any> = [];
      for (const extra in data.extra) {
        extras.push({
          name: data.extra[extra].name,
          value: data.extra[extra].value,
        });
      }
      const quickView = {
        key: data.key,
        name: data.name + " (" + data.current + "/" + data.max + ")",
        description: data.description,
        secondaryAttributes: extras,
        imageName: "resource",
      };

      this.quickViewDialogData = new QuickView(quickView, data.key);
      this.shouldShowQuickViewDialog = true;
    },
    onCancelQuickViewDialog() {
      this.shouldShowQuickViewDialog = false;
    },
    onSaveQuickViewDialog() {},
    onMenuQuickViewDialog(quickViewData: QuickView) {
      this.onCancelQuickViewDialog();
      const key = quickViewData.key;

      const index = this.resources.findIndex((c: Resource) => c.key == key);

      if (index < 0) return;

      const resource: Resource = this.resources[index];
      this.onDisplayResourceDialog(resource);
    },
  },
});
</script>

<style lang="scss" scoped>
#cvResources {
  grid-template-columns: repeat(auto-fit, 120px);
  column-gap: 24px;
  row-gap: 16px;
  max-height: 225px;
  overflow: auto;

  & > .ResourceView {
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }
}
</style>