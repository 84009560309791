<template>
  <UIDialogView :title="'Dice'" :hasDone="true" @ui-dialog-cancel="onCancel">
    <div class="DiceView">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <UITextField
          :id="'txtCount'"
          :textLabel="'Count'"
          v-model="numOfDice"
          :saveOldValue="true"
          :placeholder="'Count'"
          :width="textFieldWidth"
        />
        <UILabel :text="'d'" :type="'h2'" />
        <UITextField
          :id="'txtSides'"
          :textLabel="'Sides'"
          v-model="numOfSides"
          :saveOldValue="true"
          :placeholder="'Sides'"
          :width="textFieldWidth"
        />
        <UILabel :text="'+'" :type="'h2'" />
        <UITextField
          :id="'txtMode'"
          :textLabel="'Modifier'"
          v-model="mod"
          :saveOldValue="true"
          :placeholder="'Modifier'"
          :width="textFieldWidth"
        />
      </div>
      <div>
        <UILabel :id="'lblRoll'" :text="currentRoll" :type="'h1'" />
        <UIButton :id="'btnRoll'" :title="'Roll'" @click="roll(this.numOfDice, this.numOfSides, this.mod)" />
      </div>
      <UISeparatorView />
      <UITabView
        :id="'tvPresets'"
        :tabs="preset_tabs"
        :selectedTab="selectedTab"
        @tab-selected="btnSelectTab"
      />
      <div v-if="selectedTab === 'presets'">
        <UICollectionView :id="'cvDie'" :hasItems="presets.length > 0">
          <div
            v-for="(die, index) in presets"
            :key="index"
            class="die"
            @click="roll(1, die.value, 0)"
          >
            <UILabel :text="die.text" />
          </div>
        </UICollectionView>
      </div>
      <div v-if="selectedTab === 'custom'">
        <div
          v-if="getDices && getDices?.length > 0"
        >
          <img
            src="@/assets/icon_plus@x2.png"
            style="cursor: pointer; display: block; margin-left: auto;"
            width="22"
            @click="onDiceMenuSelect(0)"
          />
        </div>
        <UICollectionView :id="'cvCustomDie'" :hasItems="getDices?.length > 0">
          <UIOnboardView
            v-if="!getDices || getDices?.length < 1"
            :title="'No custom dice'"
            :buttonTitle="'Add Dice'"
            @ui-onboard-view="onDiceMenuSelect(0)"
          />
          <DieUICollectionViewCell
            v-for="(die, index) in getDices"
            :key="index"
            :die="die"
            @click.stop="roll(die.count, die.sides, die.modifier)"
            @die-ui-collection-view-cell-edit="onDisplayManageDiceDialog(die)"
            @die-ui-collection-view-cell-delete="onDisplayPromptDialog"
          />
        </UICollectionView>
      </div>
      <UISeparatorView />
      <UITextView
        :id="'txtHistory'"
        v-model="log"
        :placeholder="'Output'"
        :disabled="true"
      />
    </div>
    <UIDialogManageDie
      v-if="shouldShowManageDiceDialog"
      :selectedDice="selectedDice"
      @ui-dialog-dice-cancel="onDismissManageDiceDialog"
    />

    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      @ui-dialog-prompt-submit="onSavePromptDialog"
      :type="promptDialogData.type"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
    />
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UITabView from "@/components/UI/UITabView.vue";
import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import UIDialogManageDie from "@/components/character/dialogs/UIDialogManageDie.vue";
import UIDialogPromptView from "@/components/UI//dialogs/UIDialogPromptView.vue";

import DieUICollectionViewCell from "@/components/character/collectionViewCells/DieUICollectionViewCell.vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import Dice from "@/store/models/Dice";

export default defineComponent({
  name: "UIDialogDiceView",
  components: {
    UIDialogView,
    UILabel,
    UITextField,
    UITextView,
    UIButton,
    UISeparatorView,
    UICollectionView,
    UITabView,
    UIOnboardView,
    UIDialogManageDie,
    UIDialogPromptView,
    DieUICollectionViewCell,
  },
  data() {
    return {
      numOfDice: 0,
      numOfSides: 0,
      mod: 0,
      currentRoll: "0",
      log: "",
      textFieldWidth: 72,
      presets: [
        {
          text: "d%",
          value: 100,
        },
        {
          text: "d4",
          value: 4,
        },
        {
          text: "d6",
          value: 6,
        },
        {
          text: "d8",
          value: 8,
        },
        {
          text: "d10",
          value: 10,
        },
        {
          text: "d12",
          value: 12,
        },
        {
          text: "d20",
          value: 20,
        },
      ],
      preset_tabs: [
        {
          name: "Presets",
          key: "presets",
        },
        {
          name: "Custom",
          key: "custom",
        },
      ],
      selectedTab: "presets",
      shouldShowManageDiceDialog: false,
      shouldShowPromptDialog: false,
      selectedDice: {} as Dice,
      promptDialogData: {
        type: Object,
        default: {},
      },
    };
  },
  emits: ["ui-dialog-dice-cancel"],
  computed: {
    ...mapGetters("Character", ["getDices"]),
  },
  methods: {
    ...mapActions('Character', [
      'deleteDice',
    ]),
    onDisplayPromptDialog(data: any, die: Dice) {
      this.promptDialogData = data;
      this.selectedDice = die;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSavePromptDialog() {
      this.deleteDice(this.selectedDice);

      this.onCancelPromptDialog();
    },
    btnSelectTab(tab: string) {
      this.selectedTab = tab;
    },
    onCancel() {
      this.$emit("ui-dialog-dice-cancel");
    },
    onDiceMenuSelect(index: number) {
      if (index === 0) {
        const data: Dice = new Dice({}, "");
        this.onDisplayManageDiceDialog(data);
      }
    },
    onDisplayManageDiceDialog(data: Dice) {
      this.selectedDice = data;
      this.shouldShowManageDiceDialog = true;
    },
    onDismissManageDiceDialog() {
      this.shouldShowManageDiceDialog = false;
    },
    roll(count: number, sides: number, mod: number) {
      let sumOfRolls = 0;
      let max = +sides + +mod;
      let min = 1 + +mod;
      let rollText = `${count}d${sides} + ${mod}: (`;
      for (let k = 0; k < count; k++) {
        const roll: number = Math.floor(Math.random() * (max - min + 1)) + min;
        sumOfRolls += roll;
        rollText += `${roll}`;
        if (count > 1 && k < count - 1) {
          rollText += ", ";
        }
      }
      rollText += `) = ${sumOfRolls}\n`;
      this.currentRoll = `${sumOfRolls}`;
      this.log = rollText + this.log;
    },
  },
});
</script>

<style lang="scss" scoped>
#cvDie {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  flex: 1;
  justify-items: center;
}

#cvCustomDie {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 132px;
  gap: 8px;
  flex: 1;
  justify-items: center;
}

#cvCustomDie[is-empty="true"] {
  grid-template-columns: 1fr;
}

.die {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  height: 44px;
  width: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:first-child {
    grid-row: span 2;
    height: 88px + 16px;
  }
}
</style>