<template>
  <UIDialogView
    :title="'Formula'"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <UITabView
      :id="'tvTabs'"
      :tabs="tabs"
      :selectedTab="selectedTab"
      @tab-selected="btnSelectTab"
    />
    <FormulaView
      v-if="selectedTab === 'formula'"
      :formula="newFormula"
      @formula-view-change="onFormulaViewChange"
    />
    <FormulaTableView
      v-if="selectedTab === 'table'"
      :formulaItems="newFormulaTable"
      @formula-table-view-change="onFormulaTableViewChange"
    />
  </UIDialogView>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UITabView from "@/components/UI/UITabView.vue";

import FormulaView from "@/components/character/FormulaView.vue";
import FormulaTableView from "@/components/character/FormulaTableView.vue";
import FormulaItem from "@/store/models/FormulaItem";

export default defineComponent({
  name: "UIDialogFormulaView",
  components: {
    UIDialogView,
    FormulaView,
    FormulaTableView,
    UITabView,
  },
  emits: ["ui-dialog-formula-submit", "ui-dialog-formula-cancel"],
  data() {
    return {
      newFormula: this.formula,
      newFormulaTable: this.formulaTable,
      tabs: [
        {
          name: "Formula",
          key: "formula",
        },
      ],
      selectedTab: "formula",
    };
  },
  props: {
    canHaveTable: {
      type: Boolean,
      default: false,
    },
    formula: {
      type: String,
      default: "",
    },
    formulaTable: {
      type: Array<FormulaItem>,
      default: [],
    },
  },
  methods: {
    onSubmit() {
      this.$emit(
        "ui-dialog-formula-submit",
        this.newFormula,
        this.newFormulaTable
      );
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-formula-cancel");
    },
    onFormulaViewChange(formula: string) {
      this.newFormula = formula;
    },
    onFormulaTableViewChange(formula: FormulaItem[]) {
      this.newFormulaTable = formula;
    },
    btnSelectTab(tab: string) {
      this.selectedTab = tab;
    },
  },
  mounted() {
    if (this.formulaTable.length > 0) {
      this.selectedTab = "table";
    }
    if (this.canHaveTable) {
      this.tabs.push({
        name: "Table",
        key: "table",
      });
    }
  },
});
</script>