import ICampaign from "../interfaces/ICampaign";

export default class Campaign implements ICampaign {
  constructor(dictionary: Record<string, any>, key: string) {
    this.key = key;

    this.name = dictionary["name"] || "New Campaign";
    if (this.name === "") {
      this.name = "New Campaign"
    }
    this.description = dictionary["description"] || "";
    this.summary = dictionary["summary"] || "";
    this.campaign_owner = dictionary["campaign_owner"] || "";
    this.campaign_owner_id = dictionary["campaign_owner_id"] || "";
  }


  name: string;
  description: string;
  summary: string;
  campaign_owner: string;
  campaign_owner_id: string;
  key: string;

  toDictionary(): Record<string, any> {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["key"] = this.key;

    returnedDictionary["name"] = this.name;
    returnedDictionary["description"] = this.description;
    returnedDictionary["summary"] = this.summary;
    returnedDictionary["campaign_owner"] = this.campaign_owner;
    returnedDictionary["campaign_owner_id"] = this.campaign_owner_id;
    
    return returnedDictionary
  }
  
}