<template>
  <div class="UITextFieldView" :class="{ disabled: disabled }">
    <UILabel v-if="shouldShowLabel" :text="textLabel" :justify="'left'" />
    <span v-if="hasError == true" class="UITextFieldError">{{
      errorMessage
    }}</span>
    <input
      class="UITextField"
      :style="{ width: `${width}px` }"
      :type="type"
      :value="modelValue"
      :placeholder="saveOldValue ? originalValue : placeholder"
      :has-error="hasError"
      :disabled="disabled"
      @input="updateValue($event)"
      @keydown="verifyValue($event)"
      @focus="clearValue($event.target.value)"
      @blur="returnValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: "UITextField",
  components: {
    UILabel,
  },
  data() {
    return {
      originalValue: "",
    };
  },
  props: {
    type: {
      type: String,
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
    textLabel: {
      type: String,
      default: "Label",
      required: true,
    },
    placeholder: {
      required: true,
      type: String,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
    },
    shouldShowLabel: {
      type: Boolean,
      default: true,
    },
    saveOldValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(event: InputEvent) {
      const value = (event.currentTarget as HTMLInputElement).value;
      if (this.saveOldValue) {
        this.originalValue = value as string;
      }
      this.$emit("update:modelValue", value);
    },
    verifyValue(event: KeyboardEvent) {
      if (this.type === "number") {
        const isBackspace = event.key === "Backspace";

        if (!isBackspace && isNaN(Number(event.key))) {
          event.preventDefault();
          return;
        }
      }
      return true;
    },
    clearValue(previousValue: string) {
      if (this.saveOldValue) {
        this.originalValue = previousValue;
        this.$emit("update:modelValue", null);
      }
    },
    returnValue() {
      if (this.saveOldValue) {
        this.$emit("update:modelValue", this.originalValue);
      }
    },
  },
  mounted() {
    if (this.saveOldValue) {
      this.originalValue = this.modelValue as string;
    }
  },
});
</script>

<style lang="scss" scoped>
.UITextFieldView {
  margin-bottom: 8px;
}

.UITextField {
  font: 400 16px / 24px "Helvetica Neue", "Helvetica", "Open Sans", sans-serif;
  background: #fff;
  border: 1px solid lightgrey;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  border-radius: 10px;
  box-sizing: border-box;

  outline: 0;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &[type="number"] {
    font-size: 24px;
    line-height: 30px;
    text-align: center;

    -moz-appearance: textfield;

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      font-size: 0.5em;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 0.5em;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 0.5em;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      font-size: 0.5em;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>