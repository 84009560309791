<template>
  <UIDialogView
    :title="`${action} Cost`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onCostDialogMenuSelect"
  >
    <CostView
      :cost="selectedCost"
      :assetType="assetType"
      :gameSystem="gameSystem"
      :isCompendium="isCompendium"
      @cost-update="onCostUpdate"
    />
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";

import CostView from "@/components/character/CostView.vue";

import Cost from "@/store/models/Cost";
import GameSystem from "@/store/models/GameSystem";

export default defineComponent({
  name: "UIDialogCostView",
  props: {
    cost: {
      type: Object as () => Cost,
      default: {},
    },
    gameSystem: {
      type: Object as () => GameSystem,
      default: {},
      required: true,
    },
    isCompendium: {
      type: Boolean,
      default: true,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    assetType: {
      type: String,
      default: "",
      required: true,
    },
    campaignId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedCost: {} as Cost,
      shouldShowPromptDialog: false,
      promptDialogData: {},
      menuItems: ["Remove Cost"],
      hasMenu: true,
    };
  },
  emits: [
    "ui-dialog-cost-submit",
    "ui-dialog-cost-delete",
    "ui-dialog-cost-cancel",
  ],
  components: {
    UIDialogView,
    UIDialogPromptView,
    CostView,
  },
  methods: {
    ...mapActions("Campaigns", ["fetchCampaignAsset"]),
    onSubmit() {
      this.$emit("ui-dialog-cost-submit", this.selectedCost);
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-cost-cancel");
    },
    onCostDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onRemoveDialog();
      }
    },
    onRemoveDialog() {
      let data = {
        name: `Delete Cost`,
        body: `Are you sure you want to delete this cost from this Spell? This cannot be undone.`,
        submitTitle: "Continue",
        type: "deleteCost",
      };

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog() {
      this.$emit("ui-dialog-cost-delete");
      this.onCancel();
    },
    onCostUpdate(cost: Cost) {
      this.selectedCost = cost;
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  beforeMount() {
    this.selectedCost = new Cost(this.cost.toDictionary());

    this.hasMenu = !this.isNew;
  },
});
</script>
