<template>
  <UIDialogView
    :title="`${action} Spell`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onSpellDialogMenuSelect"
  >
    <ManageAssetView
      :asset="selectedSpell"
      :isCompendium="isWorkshop"
      :basicFields="basicFields"
      :hasCost="true"
      :hasEffect="true"
    >
      <template v-slot:basic>
      </template>
      <template v-slot:advanced></template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      :type="'text'"
      :title="submissionDialogData.title"
      :body="submissionDialogData.body"
      :inputTitle="submissionDialogData.inputTitle"
      :inputPlaceholder="'Description of game system'"
      @ui-dialog-input-submit="onPublish"
      @ui-dialog-input-cancel="onDismissPublishDialog"
    />
    <UIDialogPublishView
      v-if="shouldShowPublishDialog"
      :asset="spell"
      :body="'Submitting this Spell will share it with the entire community. Are you sure you want to submit this Spell?'"
      @ui-dialog-publish-cancel="onDismissPublishDialog"
    />
    <UIDialogMessageView
      v-if="shouldShowMessageDialog"
      :title="'Success'"
      :body="'Asset successfuly submitted for review.'"
      @ui-dialog-message-submit="onDismissMessageDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";
import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogMessageView from "@/components/UI/dialogs/UIDialogMessageView.vue";

import UIDialogPublishView from "@/components/compendium/UIDialogPublishView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UIDropDownView from "@/components/UI/UIDropDownView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import { newKey } from "@/utility/database";

import Spell from "@/store/models/Spell";

import { RangeMeasurement } from "@/store/interfaces/types/RangeMeasurement";
import { AreaMeasurement } from "@/store/interfaces/types/AreaMeasurement";
import { CastingTimeMeasurement } from "@/store/interfaces/types/CastingTimeMeasurement";
import { Target } from "@/store/interfaces/types/Target";
import { AreaShape } from "@/store/interfaces/types/AreaShape";

export default defineComponent({
  name: "UIDialogSpellView",
  components: {
    UIDialogView,
    UIDialogPromptView,
    UIDialogInputView,
    UIDialogMessageView,
    UILabel,
    UIButton,
    UITextField,
    UITextView,
    UISeparatorView,
    UICollectionView,
    ManageAssetView,
    UIOnboardView,
    UIDialogPublishView,
    UIDropDownView,
  },
  emits: ["ui-dialog-spell-submit", "ui-dialog-spell-cancel"],
  props: {
    spell: {
      type: Object as () => Spell,
      default: {},
      require: true,
    },
    isWorkshop: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSpell: {} as Spell,
      shouldShowPromptDialog: false,
      shouldShowMessageDialog: false,
      shouldShowDescriptionDialog: false,
      shouldShowInputDialog: false,
      shouldShowCostDialog: false,
      shouldShowPublishDialog: false,
      promptDialogData: {},
      submissionDialogData: {},
      menuItems: ["Publish Spell", "Duplicate Spell", "Remove Spell"],
      hasMenu: true,
      basicFields: [
        {
          header: null,
          fields: [
            {
              key: "name",
              label: "Name",
              placeholder: "Name",
              type: "textfield",
              valueType: "text",
            },
            {
              key: "level",
              label: "Level",
              placeholder: "Level",
              type: "textfield",
              valueType: "number",
            },
            {
              key: "cast_time",
              label: "Cast Time",
              placeholder: "Cast Time",
              type: "cell-dropdown",
              valueType: "number",
              dataSource: Object.keys(CastingTimeMeasurement).map(
                (key) =>
                  CastingTimeMeasurement[
                    key as keyof typeof CastingTimeMeasurement
                  ]
              ),
              dropdown_key: "cast_time_measurement",
            },
            {
              key: "range",
              label: "Range",
              placeholder: "Range",
              type: "cell-dropdown",
              valueType: "number",
              dataSource: Object.keys(RangeMeasurement).map(
                (key) => RangeMeasurement[key as keyof typeof RangeMeasurement]
              ),
              dropdown_key: "range_measurement",
            },
            {
              key: "area",
              label: "Area",
              placeholder: "Area",
              type: "cell-dropdown",
              valueType: "number",
              dataSource: Object.keys(AreaMeasurement).map(
                (key) => AreaMeasurement[key as keyof typeof AreaMeasurement]
              ),
              dropdown_key: "area_measurement",
            },
            {
              key: "area_shape",
              label: "Area Shape",
              placeholder: "Area Shape",
              type: "dropdown",
              valueType: "text",
              dataSource: Object.keys(AreaShape).map(
                (key) => AreaShape[key as keyof typeof AreaShape]
              ),
            },
            {
              key: "maximum_affect_count",
              label: "Number of Targets",
              placeholder: "Target",
              type: "formula",
              valueType: "text",
            },
            {
              key: "affected_type",
              label: "Target type",
              placeholder: "Targets",
              type: "dropdown",
              valueType: "text",
              dataSource: Object.keys(Target).map(
                (key) => Target[key as keyof typeof Target]
              ),
            },
            {
              key: "extra",
              label: "Secondary Attributes",
              placeholder: "Secondary Attributes",
              type: "secondary",
              valueType: "text",
            },
          ],
        },
        {
          header: null,
          fields: [
            {
              key: "description",
              label: "Description",
              placeholder: "Description",
              type: "textview",
              valueType: "text",
            },
          ],
        },
      ],
      spellTarget: Object.keys(Target).map(
        (key) => Target[key as keyof typeof Target]
      ),
    };
  },
  methods: {
    ...mapActions("Character", ["removeSpell"]),
    ...mapActions("Publish", ["publish"]),
    ...mapActions("Account", ["deleteWorkshopAssetSpell"]),
    onSubmit() {
      this.$emit("ui-dialog-spell-submit", {
        spell: this.selectedSpell,
        isNew: this.isNew,
      });
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-spell-cancel");
    },
    onSpellDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onShowPublishDialog();
      } else if (index === 1) {
        this.onDuplicateDialog();
      } else if (index === 2) {
        this.onRemoveDialog();
      }
    },
    onDuplicateDialog() {
      let newDuplicateSpell: Spell = new Spell(
        this.selectedSpell.toDictionary(),
        newKey()
      );
      newDuplicateSpell.name = newDuplicateSpell.name + " (Copy)";
      this.$emit("ui-dialog-spell-submit", {
        spell: newDuplicateSpell,
        isNew: true,
      });
      this.onCancel();
    },
    onRemoveDialog() {
      let data: any = {};

      if (this.isWorkshop) {
        data = {
          name: `Delete ${this.selectedSpell.name}`,
          body: `Are you sure you want to delete ${this.selectedSpell.name} from your Compendium? This cannot be undone.`,
          submitTitle: "Continue",
          type: "deleteAsset",
        };
      } else {
        data = {
          name: `Remove ${this.selectedSpell.name}`,
          body: `Are you sure you want to remove ${this.selectedSpell.name} from your Character? Your spell will continue to exist in your Compendium.`,
          submitTitle: "Continue",
          type: "removeAsset",
        };
      }

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog(data: any) {
      if (data.type === "removeAsset") {
        this.removeSpell(this.selectedSpell);
      } else if (data.type === "deleteAsset") {
        this.deleteWorkshopAssetSpell(this.selectedSpell);
      }

      this.onCancel();
    },
    onShowPublishDialog() {
      this.shouldShowPublishDialog = true;
    },
    onDismissPublishDialog() {
      this.shouldShowPublishDialog = false;
    },
    onPublish(data: any) {
      const payload = {
        asset: this.selectedSpell,
        description: data.value,
      };

      this.publish(payload).then(() => {
        this.onShowMessageDialog();
      });

      this.onDismissPublishDialog();
    },
    onShowMessageDialog() {
      this.shouldShowMessageDialog = true;
    },
    onDismissMessageDialog() {
      this.shouldShowMessageDialog = false;
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.selectedSpell = new Spell(
      { ...this.spell.toDictionary(), campaign_id: this.spell?.campaign_id },
      this.spell.key
    );

    if (this.isWorkshop) {
      this.menuItems = [
        "Publish Attribute",
        "Duplicate Spell",
        "Delete Attribute",
      ];
    }

    this.hasMenu = !this.isNew;
  },
});
</script>

<style lang="scss" scoped>
#cvExtra {
  max-height: 150px;
  overflow: auto;
  gap: 0;
}
</style>