import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vBody" }
const _hoisted_2 = { class: "vInput" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: _ctx.title,
    removeTitle: _ctx.removeTitle,
    onUiDialogRemove: _ctx.onRemove,
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UILabel, {
          text: _ctx.body,
          type: 'body',
          justify: 'left',
          font: 14
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UITextField, {
          id: 'txtValue',
          type: 'text',
          modelValue: _ctx.value1,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value1) = $event)),
          textLabel: _ctx.inputTitle1,
          placeholder: _ctx.inputPlaceholder1
        }, null, 8, ["modelValue", "textLabel", "placeholder"]),
        _createVNode(_component_UITextField, {
          id: 'txtValue',
          type: 'text',
          modelValue: _ctx.value2,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value2) = $event)),
          textLabel: _ctx.inputTitle2,
          placeholder: _ctx.inputPlaceholder2
        }, null, 8, ["modelValue", "textLabel", "placeholder"])
      ])
    ]),
    _: 1
  }, 8, ["title", "removeTitle", "onUiDialogRemove", "onUiDialogSubmit", "onUiDialogCancel"]))
}