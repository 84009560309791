<template>
  <svg height="2" width="100%">
    <line
      x1="0"
      y1="0"
      x2="100%"
      y2="0"
      style="stroke: rgba(0, 0, 0, 0.16); stroke-width: 1"
    />
    Sorry, your browser does not support inline SVG.
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "UISeparatorView",
})
</script>