import ICurrency from "../interfaces/ICurrency";

export default class Currency implements ICurrency {
    constructor(dictionary: Record<string, any>, key: string) {
        this.key = key;

        this.name = dictionary["name"] || "New Currency";
        const dictionaryValue = dictionary["value"];
        if (
            isNaN(dictionaryValue) ||
            !dictionary ||
            !+dictionaryValue ||
            dictionaryValue === undefined
        ) {
            this.value = 0;
        }
        else {
            this.value = dictionaryValue;
        }
        this.formula = dictionary["formula"] || "";
        this.is_favorite = dictionary["is_favorite"] || false;
    }

    key: string;

    name: string;
    value: number;
    formula: string;
    is_favorite: boolean;

    toDictionary = (): Record<string, any> => {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["key"] = this.key;

        returnedDictionary["name"] = this.name;
        returnedDictionary["value"] = +this.value;
        returnedDictionary["formula"] = this.formula;
        returnedDictionary["is_favorite"] = this.is_favorite;

        return returnedDictionary
    }
}