<template>
  <UIDialogView
    :title="`${action} Resource`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onResourceDialogMenuSelect"
  >
    <ManageAssetView :asset="selectedResource" :hasAdvanced="false" :isCompendium="isWorkshop">
      <template v-slot:basic>
        <UITextField
          :id="'txtName'"
          v-model="selectedResource.name"
          :disabled="readOnly"
          :textLabel="'Resource name'"
          :placeholder="'Resource name'"
        />
        <UITextField
          :style="{ 'margin-right': '8px' }"
          :id="'txtCurrent'"
          v-model="selectedResource.current"
          :textLabel="'Current'"
          :placeholder="'Current'"
          :disabled="isWorkshop || readOnly"
        />
        <UITextField
          :id="'txtMax'"
          v-model="selectedResource.max"
          :textLabel="'Max'"
          :placeholder="'Max'"
          :disabled="isWorkshop || readOnly"
        />
        <div class="flex">
          <UILabel
            :text="'Color'"
            :disabled="isWorkshop || readOnly"
          />
          <div class="flex">
            <input class="color-picker" type="color" v-model="assetColor" :class="{ disabled: isWorkshop || readOnly}" />
          </div>
        </div>
        <div class="flex">
          <UILabel :text="'Show Name'" :disabled="isWorkshop || readOnly" />
          <UISwitchView
            :checked="showName"
            :disabled="isWorkshop || readOnly"
            @ui-switch-view-toggle="onToggleShowName"
          />
        </div>
        <div class="flex">
          <UILabel :text="'Show Value'" :disabled="isWorkshop || readOnly" />
          <UISwitchView
            :checked="showValue"
            :disabled="isWorkshop || readOnly"
            @ui-switch-view-toggle="onToggleShowValue"
          />
        </div>
        <div class="flex">
          <UILabel
            :text="'Show Percentage'"
            :disabled="isWorkshop || readOnly"
          />
          <UISwitchView
            :checked="showPercentage"
            :disabled="isWorkshop || readOnly"
            @ui-switch-view-toggle="onToggleShowPercentage"
          />
        </div>
        <UITextView
          :id="'txtDescription'"
          v-model="descriptionPreview"
          :disabled="true"
          :placeholder="'Description'"
          @click="onDisplayDescriptionDialog"
        />
      </template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogDescription
      v-if="shouldShowDescriptionDialog"
      :description="description"
      @ui-dialog-description-submit="onSubmitDescriptionDialog"
      @ui-dialog-description-cancel="onDismissDescriptionDialog"
    />
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      :type="'text'"
      :title="submissionDialogData.title"
      :body="submissionDialogData.body"
      :inputTitle="submissionDialogData.inputTitle"
      :inputPlaceholder="'Description of game system'"
      @ui-dialog-input-submit="onPublish"
      @ui-dialog-input-cancel="onDismissPublishDialog"
    />
    <UIDialogPublishView
      v-if="shouldShowPublishDialog"
      :asset="resource"
      :body="'Submitting this Resource will share it with the entire community. Are you sure you want to submit this Resource?'"
      @ui-dialog-publish-cancel="onDismissPublishDialog"
    />
    <UIDialogMessageView
      v-if="shouldShowMessageDialog"
      :title="'Success'"
      :body="'Asset successfuly submitted for review.'"
      @ui-dialog-message-submit="onDismissMessageDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";
import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogMessageView from "@/components/UI/dialogs/UIDialogMessageView.vue";
import UIDialogDescription from "@/components/character/dialogs/UIDialogDescription.vue";

import UIDialogPublishView from "@/components/compendium/UIDialogPublishView.vue";

import UISwitchView from "@/components/UI/UISwitchView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import Resource from "@/store/models/Resource";

import { newKey } from "@/utility/database";
import { convertKeysLocalDescription } from "@/utility/tooltip";
import { componentToHex, hexToRgb } from "@/utility/global";

export default defineComponent({
  name: "UIDialogResourceView",
  components: {
    UIDialogView,
    UISwitchView,
    UILabel,
    UITextField,
    UITextView,
    ManageAssetView,
    UIDialogPromptView,
    UIDialogInputView,
    UIDialogMessageView,
    UIDialogDescription,
    UIDialogPublishView,
  },
  emits: ["ui-dialog-resource-submit", "ui-dialog-resource-cancel"],
  data() {
    return {
      shouldShowPromptDialog: false,
      shouldShowMessageDialog: false,
      shouldShowInputDialog: false,
      shouldShowDescriptionDialog: false,
      shouldShowPublishDialog: false,
      selectedResource: {} as Resource,
      promptDialogData: {},
      submissionDialogData: {},
      menuItems: ["Publish Resource", "Duplicate Resource", "Remove Resource"],
      description: "",
      hasMenu: true,
    };
  },
  props: {
    resource: {
      type: Object as () => Resource,
      default: {},
    },
    isWorkshop: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("Character", ["removeResource"]),
    ...mapActions("Publish", ["publish"]),
    ...mapActions("Account", ["deleteWorkshopAssetResource"]),
    onDisplayDescriptionDialog() {
      if (this.readOnly) return;

      this.shouldShowDescriptionDialog = true;
    },
    onSubmitDescriptionDialog(newDescription: string) {
      this.description = newDescription;
      this.onDismissDescriptionDialog();
    },
    onDismissDescriptionDialog() {
      this.shouldShowDescriptionDialog = false;
    },
    onToggleShowName() {
      if (this.readOnly) return;
      this.selectedResource.show_name = !this.selectedResource.show_name;
    },
    onToggleShowValue() {
      if (this.readOnly) return;
      this.selectedResource.show_value = !this.selectedResource.show_value;
    },
    onToggleShowPercentage() {
      if (this.readOnly) return;
      this.selectedResource.show_percentage =
        !this.selectedResource.show_percentage;
    },
    onCancel() {
      this.$emit("ui-dialog-resource-cancel");
    },
    onResourceDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onShowPublishDialog();
      } else if (index === 1) {
        this.onDuplicateDialog();
      } else if (index === 2) {
        this.onRemoveDialog();
      }
    },
    onDuplicateDialog() {
      this.selectedResource.description = this.description;

      let newDuplicateResource: Resource = new Resource(
        this.selectedResource.toDictionary(),
        newKey()
      );
      newDuplicateResource.name = newDuplicateResource.name + " (Copy)";
      this.$emit("ui-dialog-resource-submit", {
        resource: newDuplicateResource,
        isNew: true,
      });
      this.onCancel();
    },
    onSubmit() {
      this.selectedResource.description = this.description;

      this.$emit("ui-dialog-resource-submit", {
        resource: this.selectedResource,
        isNew: this.isNew,
      });
      this.onCancel();
    },
    onRemoveDialog() {
      let data: any = {};

      if (this.isWorkshop) {
        data = {
          name: `Delete ${this.selectedResource.name}`,
          body: `Are you sure you want to delete ${this.selectedResource.name} from your Compendium? This cannot be undone.`,
          submitTitle: "Continue",
          type: "deleteAsset",
        };
      } else {
        data = {
          name: `Remove ${this.selectedResource.name}`,
          body: `Are you sure you want to remove ${this.selectedResource.name} from your Character? Your resource will continue to exist in your Compendium.`,
          submitTitle: "Continue",
          type: "removeAsset",
        };
      }

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog(data: any) {
      if (data.type === "removeAsset") {
        this.removeResource(this.selectedResource);
      } else if (data.type === "deleteAsset") {
        this.deleteWorkshopAssetResource(this.selectedResource);
      }
      
      this.onCancel();
    },
    onShowPublishDialog() {
      this.shouldShowPublishDialog = true;
    },
    onDismissPublishDialog() {
      this.shouldShowPublishDialog = false;
    },
    onPublish(data: any) {
      const payload = {
        asset: this.selectedResource,
        description: data.value,
      };

      this.publish(payload).then(() => {
        this.onShowMessageDialog();
      });

      this.onDismissPublishDialog();
    },
    onShowMessageDialog() {
      this.shouldShowMessageDialog = true;
    },
    onDismissMessageDialog() {
      this.shouldShowMessageDialog = false;
    },
  },
  computed: {
    showName(): boolean {
      return this.selectedResource.show_name;
    },
    showValue(): boolean {
      return this.selectedResource.show_value;
    },
    showPercentage(): boolean {
      return this.selectedResource.show_percentage;
    },
    assetColor: {
      get(): string {
        return componentToHex(this.selectedResource.red, this.selectedResource.green, this.selectedResource.blue);
      },
      set(hexColor: string): void {
        const { red, green, blue } = hexToRgb(hexColor);
        this.selectedResource.red = red;
        this.selectedResource.green = green;
        this.selectedResource.blue = blue;
      }
    },
    descriptionPreview(): string {
      return convertKeysLocalDescription(this.description);
    },
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.selectedResource = new Resource(
      this.resource.toDictionary(),
      this.resource.key
    );

    if (this.isWorkshop) {
      this.menuItems = ["Publish Resource", "Duplicate Resource", "Delete Resource"];
    }

    this.hasMenu = !this.isNew;
  },
});
</script>

<style lang="scss" scoped>
.UIDialogResourceViewContainer {
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;

  background-color: rgba(0, 0, 0, 0.16);

  display: grid;
  justify-content: center;
  align-items: center;
}

.UIDialogResourceView {
  min-width: 300px;

  padding: 24px 8px;

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  background-color: #ffffff;
}

.vFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>