<template>
    <div class="CharacterTabUICollectionViewCell">
        <UILabel :text="textLabel" :isSelected="isSelected" @click="toggleActive" :type="type" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: 'CharacterTabUICollectionViewCell',
  props: {
    textLabel: {
      type: String
    },
    id: {
      type: String
    },
    type: {
      type: String
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UILabel
  },
  methods: {
    toggleActive() {
      this.$emit('onToggle');
    }
  }
});

</script>

<style lang="scss" scoped>
.CharacterTabUICollectionViewCell {
  cursor: pointer;
}
</style>