<template>
  <UIDialogView
    :title="'Experience'"
    :canRemove="false"
    :hasMenu="false"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <ManageAssetView :asset="getExperience" :hasCompendium="false">
      <template v-slot:basic>
        <div>
          <UITextField
            :id="'txtName'"
            :textLabel="'Experience name'"
            v-model="getExperience.name"
            :placeholder="'Experience name'"
          />
          <UITextField
            :id="'txtCurrent'"
            :textLabel="'Current'"
            v-model="getExperience.current"
            :placeholder="'Current'"
          />
          <UITextField
            :id="'txtMax'"
            :textLabel="'Max'"
            v-model="getExperience.max"
            :placeholder="'Max'"
          />
        </div>
        <div>
          <UITextField
            :id="'txtLevel'"
            :textLabel="'Current level'"
            v-model="characterLevel"
            :placeholder="'Current level'"
          />
        </div>
        <div class="flex">
          <UILabel :text="'Color'" />
          <input class="color-picker" type="color" v-model="assetColor" :class="{ disabled: readOnly}" />
        </div>
        <div>
          <div class="flex">
            <UILabel :text="'Show Name'" />
            <UISwitchView
              :checked="resource.show_name"
              @ui-switch-view-toggle="onToggleShowName"
            />
          </div>
          <div class="flex">
            <UILabel :text="'Show Value'" />
            <UISwitchView
              :checked="resource.show_value"
              @ui-switch-view-toggle="onToggleShowValue"
            />
          </div>
          <div class="flex">
            <UILabel :text="'Show Percentage'" />
            <UISwitchView
              :checked="resource.show_percentage"
              @ui-switch-view-toggle="onToggleShowPercentage"
            />
          </div>
        </div>
      </template>
      <template v-slot:advanced>
        <div
          class="flex"
          v-if="resource.veterancies && resource.veterancies?.length > 0"
        >
          <UILabel :text="'Veterancy'" :type="'h2'" />
          <img
            v-if="!readOnly"
            src="@/assets/icon_plus@x1.png"
            style="cursor: pointer"
            @click="onDisplayVeterancyDialog()"
          />
        </div>
        <UICollectionView :id="'cvExtra'">
          <UIOnboardView
            v-if="!resource.veterancies || resource.veterancies?.length < 1"
            :title="'No Veterancies'"
            :buttonTitle="'Add Veterancy'"
            @ui-onboard-view="onDisplayVeterancyDialog()"
          />
          <SecondaryAttributeUICollectionViewCell
            v-for="(veterancy, index) in resource.veterancies"
            :key="index"
            :extra="getVeterancyLabel(veterancy, index)"
            @click="onDisplayVeterancyDialog(index, veterancy)"
          />
        </UICollectionView>
      </template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogVeterancyView
      v-if="shouldShowVeterancyDialog"
      :veterancy="veterancyDialogData"
      @ui-dialog-veterancy-submit="onSubmitVeterancyDialog"
      @ui-dialog-veterancy-remove="onRemoveVeterancyDialog"
      @ui-dialog-veterancy-cancel="onCancelVeterancyDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { componentToHex, hexToRgb } from "@/utility/global";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UISwitchView from "@/components/UI/UISwitchView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import SecondaryAttributeUICollectionViewCell from "@/components/character/SecondaryAttributeUICollectionViewCell.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import UIDialogVeterancyView from "@/components/character/dialogs/UIDialogVeterancyView.vue";

import Experience from "@/store/models/Experience";
import Veterancy from "@/store/models/Veterancy";

export default defineComponent({
  name: "UIDialogResourceView",
  components: {
    UIDialogView,
    UISwitchView,
    UILabel,
    UITextField,
    ManageAssetView,
    UIOnboardView,
    SecondaryAttributeUICollectionViewCell,
    UICollectionView,
    UIDialogVeterancyView,
  },
  emits: ["ui-dialog-experience-submit", "ui-dialog-experience-cancel"],
  data() {
    return {
      shouldShowVeterancyDialog: false,
      resource: {} as Experience,
      characterLevel: 0,
      veterancyDialogData: {} as Veterancy,
      veterancyIndex: -1,
    };
  },
  props: {
    experience: {
      type: Object as () => Experience,
      require: true,
      default: {},
    },
    level: {
      type: Number,
      required: true,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getVeterancyLabel(veterancy: Veterancy, index: number): any {
      return { name: `Level: ${index}`, value: veterancy.minimum_experience };
    },
    onToggleShowName() {
      this.resource.show_name = !this.resource.show_name;
    },
    onToggleShowValue() {
      this.resource.show_value = !this.resource.show_value;
    },
    onToggleShowPercentage() {
      this.resource.show_percentage = !this.resource.show_percentage;
    },
    onDisplayVeterancyDialog(index: number = -1, data: Veterancy | undefined) {
      if (this.readOnly) return;

      this.veterancyDialogData = data !== undefined ? data : new Veterancy({});
      this.veterancyIndex = index;
      this.shouldShowVeterancyDialog = true;
    },
    onSubmitVeterancyDialog(veterancy: Veterancy) {
      if (this.veterancyIndex === -1) {
        if (!this.resource.veterancies) {
          this.resource.veterancies = [];
        }
        this.resource.veterancies.push(veterancy);
      } else {
        const index = this.veterancyIndex;
        this.resource.veterancies[index] = veterancy;
      }
    },
    onRemoveVeterancyDialog() {
      this.resource.veterancies.splice(this.veterancyIndex, 1);
    },
    onCancelVeterancyDialog() {
      this.shouldShowVeterancyDialog = false;
    },
    onCancel() {
      this.$emit("ui-dialog-experience-cancel");
    },
    onSubmit() {
      this.$emit("ui-dialog-experience-submit", {
        experience: this.resource,
        level: +this.characterLevel,
      });
      this.onCancel();
    },
  },
  computed: {
    getExperience(): Experience {
      return this.resource;
    },
    assetColor: {
      get(): string {
        return componentToHex(this.resource.red, this.resource.green, this.resource.blue);
      },
      set(hexColor: string): void {
        const { red, green, blue } = hexToRgb(hexColor);
        this.resource.red = red;
        this.resource.green = green;
        this.resource.blue = blue;
      }
    },
  },
  mounted() {
    this.resource = this.experience;
    this.characterLevel = this.level;
  },
});
</script>