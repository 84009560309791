import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43d5a14c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "value", "placeholder", "has-error", "disabled"]
const _hoisted_2 = {
  key: 1,
  class: "UITextFieldError"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["UICellTextFieldView", { disabled: _ctx.disabled }])
  }, [
    (_ctx.shouldShowLabel)
      ? (_openBlock(), _createBlock(_component_UILabel, {
          key: 0,
          text: _ctx.textLabel,
          justify: 'left'
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      style: _normalizeStyle({ width: `${_ctx.width}px` }),
      class: "UICellTextField",
      type: _ctx.type,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      "has-error": _ctx.hasError,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 44, _hoisted_1),
    (_ctx.hasError == true)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 2))
}