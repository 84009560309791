import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2882cf72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UISwitchView = _resolveComponent("UISwitchView")!
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_ManageAssetView = _resolveComponent("ManageAssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogDescription = _resolveComponent("UIDialogDescription")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogPublishView = _resolveComponent("UIDialogPublishView")!
  const _component_UIDialogMessageView = _resolveComponent("UIDialogMessageView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.action} Resource`,
      canRemove: _ctx.action == 'Edit',
      hasMenu: _ctx.hasMenu,
      menuItems: _ctx.menuItems,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onResourceDialogMenuSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ManageAssetView, {
          asset: _ctx.selectedResource,
          hasAdvanced: false,
          isCompendium: _ctx.isWorkshop
        }, {
          basic: _withCtx(() => [
            _createVNode(_component_UITextField, {
              id: 'txtName',
              modelValue: _ctx.selectedResource.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedResource.name) = $event)),
              disabled: _ctx.readOnly,
              textLabel: 'Resource name',
              placeholder: 'Resource name'
            }, null, 8, ["modelValue", "disabled"]),
            _createVNode(_component_UITextField, {
              style: { 'margin-right': '8px' },
              id: 'txtCurrent',
              modelValue: _ctx.selectedResource.current,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedResource.current) = $event)),
              textLabel: 'Current',
              placeholder: 'Current',
              disabled: _ctx.isWorkshop || _ctx.readOnly
            }, null, 8, ["modelValue", "disabled"]),
            _createVNode(_component_UITextField, {
              id: 'txtMax',
              modelValue: _ctx.selectedResource.max,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedResource.max) = $event)),
              textLabel: 'Max',
              placeholder: 'Max',
              disabled: _ctx.isWorkshop || _ctx.readOnly
            }, null, 8, ["modelValue", "disabled"]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_UILabel, {
                text: 'Color',
                disabled: _ctx.isWorkshop || _ctx.readOnly
              }, null, 8, ["disabled"]),
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["color-picker", { disabled: _ctx.isWorkshop || _ctx.readOnly}]),
                  type: "color",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.assetColor) = $event))
                }, null, 2), [
                  [_vModelText, _ctx.assetColor]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_UILabel, {
                text: 'Show Name',
                disabled: _ctx.isWorkshop || _ctx.readOnly
              }, null, 8, ["disabled"]),
              _createVNode(_component_UISwitchView, {
                checked: _ctx.showName,
                disabled: _ctx.isWorkshop || _ctx.readOnly,
                onUiSwitchViewToggle: _ctx.onToggleShowName
              }, null, 8, ["checked", "disabled", "onUiSwitchViewToggle"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_UILabel, {
                text: 'Show Value',
                disabled: _ctx.isWorkshop || _ctx.readOnly
              }, null, 8, ["disabled"]),
              _createVNode(_component_UISwitchView, {
                checked: _ctx.showValue,
                disabled: _ctx.isWorkshop || _ctx.readOnly,
                onUiSwitchViewToggle: _ctx.onToggleShowValue
              }, null, 8, ["checked", "disabled", "onUiSwitchViewToggle"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_UILabel, {
                text: 'Show Percentage',
                disabled: _ctx.isWorkshop || _ctx.readOnly
              }, null, 8, ["disabled"]),
              _createVNode(_component_UISwitchView, {
                checked: _ctx.showPercentage,
                disabled: _ctx.isWorkshop || _ctx.readOnly,
                onUiSwitchViewToggle: _ctx.onToggleShowPercentage
              }, null, 8, ["checked", "disabled", "onUiSwitchViewToggle"])
            ]),
            _createVNode(_component_UITextView, {
              id: 'txtDescription',
              modelValue: _ctx.descriptionPreview,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.descriptionPreview) = $event)),
              disabled: true,
              placeholder: 'Description',
              onClick: _ctx.onDisplayDescriptionDialog
            }, null, 8, ["modelValue", "onClick"])
          ]),
          _: 1
        }, 8, ["asset", "isCompendium"])
      ]),
      _: 1
    }, 8, ["title", "canRemove", "hasMenu", "menuItems", "onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowDescriptionDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogDescription, {
            key: 0,
            description: _ctx.description,
            onUiDialogDescriptionSubmit: _ctx.onSubmitDescriptionDialog,
            onUiDialogDescriptionCancel: _ctx.onDismissDescriptionDialog
          }, null, 8, ["description", "onUiDialogDescriptionSubmit", "onUiDialogDescriptionCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 1,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowInputDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
            key: 2,
            type: 'text',
            title: _ctx.submissionDialogData.title,
            body: _ctx.submissionDialogData.body,
            inputTitle: _ctx.submissionDialogData.inputTitle,
            inputPlaceholder: 'Description of game system',
            onUiDialogInputSubmit: _ctx.onPublish,
            onUiDialogInputCancel: _ctx.onDismissPublishDialog
          }, null, 8, ["title", "body", "inputTitle", "onUiDialogInputSubmit", "onUiDialogInputCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPublishDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPublishView, {
            key: 3,
            asset: _ctx.resource,
            body: 'Submitting this Resource will share it with the entire community. Are you sure you want to submit this Resource?',
            onUiDialogPublishCancel: _ctx.onDismissPublishDialog
          }, null, 8, ["asset", "body", "onUiDialogPublishCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowMessageDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogMessageView, {
            key: 4,
            title: 'Success',
            body: 'Asset successfuly submitted for review.',
            onUiDialogMessageSubmit: _ctx.onDismissMessageDialog
          }, null, 8, ["body", "onUiDialogMessageSubmit"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}