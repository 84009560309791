import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_menu@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-6e5f8508"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"space-between","position":"relative"} }
const _hoisted_3 = { class: "section-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_ItemUICollectionViewCell = _resolveComponent("ItemUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogAssetView = _resolveComponent("UIDialogAssetView")!
  const _component_UIDialogItemView = _resolveComponent("UIDialogItemView")!
  const _component_UIDialogPurchaseChenShop = _resolveComponent("UIDialogPurchaseChenShop")!
  const _component_UIDialogAssetQuickView = _resolveComponent("UIDialogAssetQuickView")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.bags.length < 1)
      ? (_openBlock(), _createBlock(_component_UIOnboardView, {
          key: 0,
          title: 'Your Inventory is empty',
          body: 'Bags are used to group items',
          buttonTitle: 'Add Bag',
          onUiOnboardView: _ctx.newBag
        }, null, 8, ["onUiOnboardView"]))
      : _createCommentVNode("", true),
    (_ctx.bags.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_UILabel, {
              text: 'Bags',
              type: 'h1'
            }),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  id: "btnItemMenu",
                  style: {"cursor":"pointer","margin-right":"8px"},
                  src: _imports_0,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onItemMenu && _ctx.onItemMenu(...args)))
                })
              ]),
              (_ctx.shouldShowItemMenu)
                ? (_openBlock(), _createBlock(_component_UIMenuView, {
                    key: 0,
                    items: [
              'Add Item',
              'Rename Bag',
              this.bags.length < 1 || this.hasBlackMarket
                ? 'Add Bag'
                : 'Unlock unlimited bags',
              'Delete Bag',
              'Remove All Items',
            ],
                    onMenuItemSelectedAt: _ctx.onMenuSelect,
                    onMenuViewDismiss: _ctx.dismissItemMenu
                  }, null, 8, ["items", "onMenuItemSelectedAt", "onMenuViewDismiss"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_UITabView, {
            id: 'tvBags',
            tabs: _ctx.bags,
            selectedTab: _ctx.selected_bag_name,
            onTabSelected: _ctx.btnBag
          }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_UICollectionView, {
              id: 'cvItems',
              hasitems: _ctx.getSelectedItems.length > 0
            }, {
              default: _withCtx(() => [
                (_ctx.getSelectedItems.length < 1)
                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                      key: 0,
                      title: 'There are no items',
                      buttonTitle: 'Add Item',
                      onUiOnboardView: _ctx.addItem
                    }, null, 8, ["onUiOnboardView"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSelectedItems, (item, index) => {
                  return (_openBlock(), _createBlock(_component_ItemUICollectionViewCell, {
                    key: index,
                    item: item,
                    onClick: ($event: any) => (_ctx.onDisplayQuickViewDialog(item))
                  }, null, 8, ["item", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["hasitems"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowInputDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
          key: 2,
          onUiDialogInputCancel: _ctx.onCancelInputDialog,
          onUiDialogInputSubmit: _ctx.onSaveInputDialog,
          type: _ctx.inputDialogData.type,
          title: _ctx.inputDialogData.name,
          body: _ctx.inputDialogData.body,
          inputPlaceholder: _ctx.inputDialogData.placeholder,
          inputTitle: _ctx.inputDialogData.field,
          inputValue: _ctx.inputDialogData.value,
          buttonTitle: _ctx.inputDialogData.input
        }, null, 8, ["onUiDialogInputCancel", "onUiDialogInputSubmit", "type", "title", "body", "inputPlaceholder", "inputTitle", "inputValue", "buttonTitle"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowPromptDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
          key: 3,
          onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
          onUiDialogPromptSubmit: _ctx.onSavePromptDialog,
          type: _ctx.promptDialogData.type,
          title: _ctx.promptDialogData.name,
          body: _ctx.promptDialogData.body,
          submitTitle: _ctx.promptDialogData.submitTitle
        }, null, 8, ["onUiDialogPromptCancel", "onUiDialogPromptSubmit", "type", "title", "body", "submitTitle"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowAssetDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogAssetView, {
          key: 4,
          onUiDialogAsset: _ctx.onDisplayItemDialog,
          onUiDialogAssetCancel: _ctx.onCancelAssetDialog,
          title: 'Item',
          query: 'item',
          id: _ctx.selected_bag_name
        }, null, 8, ["onUiDialogAsset", "onUiDialogAssetCancel", "id"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowItemDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogItemView, {
          key: 5,
          item: _ctx.itemDialogData,
          onUiDialogItemSubmit: _ctx.onSubmitItemDialog,
          onUiDialogItemCancel: _ctx.onCancelItemDialog
        }, null, 8, ["item", "onUiDialogItemSubmit", "onUiDialogItemCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowChenDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogPurchaseChenShop, {
          key: 6,
          onUiDialogPurchaseCancel: _ctx.dismissChenDialog
        }, null, 8, ["onUiDialogPurchaseCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowQuickViewDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogAssetQuickView, {
          key: 7,
          quickView: _ctx.quickViewDialogData,
          onUiDialogQuickViewSubmit: _ctx.onSaveQuickViewDialog,
          onUiDialogQuickViewCancel: _ctx.onCancelQuickViewDialog,
          onUiDialogQuickViewMenu: _ctx.onMenuQuickViewDialog
        }, null, 8, ["quickView", "onUiDialogQuickViewSubmit", "onUiDialogQuickViewCancel", "onUiDialogQuickViewMenu"]))
      : _createCommentVNode("", true)
  ]))
}