<template>
  <UIDialogView
    :title="title"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <div class="vBody">
      <UILabel :text="body" :type="'body'" />
    </div>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "UIDialogPromptView",
  components: {
    UIDialogView,
    UILabel,
    UIButton,
    UISeparatorView,
  },
  emits: ["ui-dialog-prompt-submit", "ui-dialog-prompt-cancel"],
  props: {
    type: {
      type: String,
      require: true,
      default: "",
    },
    title: String,
    body: String,
    buttonTitle: {
      type: String,
      default: "Save",
    },
  },
  methods: {
    onCancel() {
      this.$emit("ui-dialog-prompt-cancel");
    },
    onSubmit() {
      let returnedData = {
        type: this.type,
      };
      this.$emit("ui-dialog-prompt-submit", returnedData);
      this.onCancel();
    },
  },
});
</script>

<style lang="scss">
</style>