import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09b9d6dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DescriptionView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UIDialogChooseField = _resolveComponent("UIDialogChooseField")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UITextView, {
        id: 'txtPreview',
        modelValue: _ctx.preview,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.preview) = $event)),
        placeholder: 'Preview',
        disabled: true
      }, null, 8, ["modelValue"]),
      _createVNode(_component_UITextView, {
        id: 'txtDescription',
        modelValue: _ctx.newDescription,
        placeholder: 'Description',
        onUiTextViewChange: _ctx.onTextViewChange
      }, null, 8, ["modelValue", "onUiTextViewChange"]),
      _createVNode(_component_UIButton, {
        id: 'btnChooseField',
        title: 'Choose Field',
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.btnChooseField()))
      })
    ]),
    (_ctx.shouldShowChooseFieldDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogChooseField, {
          key: 0,
          onUiDialogChooseFieldSubmit: _ctx.onChooseFieldSubmit,
          onUiDialogChooseFieldCancel: _ctx.onChooseFieldCancel
        }, null, 8, ["onUiDialogChooseFieldSubmit", "onUiDialogChooseFieldCancel"]))
      : _createCommentVNode("", true)
  ], 64))
}