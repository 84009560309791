import IAssetSubmit from "@/store/interfaces/Submission/ISubmitAsset";
import CompendiumAssetBase from "../Compendium/CompendiumAssetBase";

export default class AssetSubmit implements IAssetSubmit {
  constructor(dictionary: Record<string, any>, assets: CompendiumAssetBase[], key: string) {
    this.key = key;

    this.name = dictionary["name"];
    this.description = dictionary["description"];
    this.date_created = dictionary["date_created"];
    this.is_community = dictionary["is_community"] || false;
    this.game_system_key = dictionary["game_system_key"];
    this.game_system_name = dictionary["game_system_name"];
    this.galveon_user_id = dictionary["galveon_user_id"];
    this.assets = assets;
  }
  key: string;
  name: string;
  description: string;
  date_created: Date;
  is_community: Boolean;
  game_system_key: string | null | undefined;
  game_system_name: string | null | undefined;
  galveon_user_id: string;
  assets: CompendiumAssetBase[];

  toDictionary(): Record<string, any> {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["key"] = this.key;
    returnedDictionary["name"] = this.name;
    returnedDictionary["description"] = this.description;
    returnedDictionary["date_created"] = this.date_created;
    returnedDictionary["is_community"] = this.is_community;
    returnedDictionary["game_system_key"] = this.game_system_key;
    returnedDictionary["game_system_name"] = this.game_system_name;
    returnedDictionary["galveon_user_id"] = this.galveon_user_id;

    var assetsDictionary: Record<string, any> = {};
    for (const asset of this.assets) {
      assetsDictionary[asset.key] = asset.toDictionary();
    }
    returnedDictionary["assets"] = assetsDictionary;

    return returnedDictionary;
  }
  
}