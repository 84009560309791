import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c3a65a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "FormulaView" }
const _hoisted_2 = { class: "vTest" }
const _hoisted_3 = { class: "vButtons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UIDialogInsertView = _resolveComponent("UIDialogInsertView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UITextField, {
          id: 'txtTestValue',
          modelValue: _ctx.tests.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tests.value) = $event)),
          textLabel: 'Test Value',
          placeholder: 'Test Value'
        }, null, 8, ["modelValue"]),
        _createVNode(_component_UITextField, {
          id: 'txtTestLevel',
          modelValue: _ctx.tests.level,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tests.level) = $event)),
          textLabel: 'Test Level',
          placeholder: 'Test Level'
        }, null, 8, ["modelValue"]),
        _createVNode(_component_UITextField, {
          id: 'txtTestHealth',
          modelValue: _ctx.tests.main_resource,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tests.main_resource) = $event)),
          textLabel: 'Test Health',
          placeholder: 'Test Health'
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_UITextView, {
        id: 'txtPreview',
        disabled: true,
        modelValue: _ctx.preview,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.preview) = $event)),
        placeholder: 'Preview'
      }, null, 8, ["modelValue"]),
      _createVNode(_component_UITextView, {
        id: 'txtDescription',
        modelValue: _ctx.newFormula,
        placeholder: 'Formula',
        onUiTextViewChange: _ctx.onTextViewChange
      }, null, 8, ["modelValue", "onUiTextViewChange"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UIButton, {
          id: 'btnMath',
          title: 'Math',
          onClick: _ctx.btnMath
        }, null, 8, ["onClick"]),
        _createVNode(_component_UIButton, {
          id: 'btnInsert',
          title: 'Insert',
          onClick: _ctx.btnInsert
        }, null, 8, ["onClick"])
      ])
    ]),
    (_ctx.shouldShowInsertDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogInsertView, {
          key: 0,
          type: _ctx.selectedType,
          onUiDialogInsertSelect: _ctx.onSelectInsertDialog,
          onUiDialogInsertCancel: _ctx.onCancelInsertDialog
        }, null, 8, ["type", "onUiDialogInsertSelect", "onUiDialogInsertCancel"]))
      : _createCommentVNode("", true)
  ], 64))
}