<template>
  <div
    class="UILabel"
    v-bind:style="{ 'font-size': `${font}px` }"
    :is-selected="isSelected"
    :justify="justify"
    :type="type"
    :disabled="disabled"
  >
    <span>{{ text }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UILabel",
  props: {
    text: {
      type: [Number, String],
      default: "Label",
    },
    type: {
      type: String,
      default: "body",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    justify: {
      type: String,
      default: "center",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    font: {
      type: Number,
      default: 16
    }
  },
});
</script>

<style lang="scss" scoped>
.UILabel {
  font: 400 16px / 24px "Helvetica Neue", "Helvetica", "Open Sans", sans-serif;

  max-width: 325px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.UILabel[type="tab"][is-selected="true"] {
  font-weight: 700;
}

.UILabel[type="tab"][is-selected="false"] {
  opacity: 0.75;
}

.UILabel[is-selected="true"] {
  font-weight: 700;
}

.UILabel[disabled="true"] {
  opacity: 0.4;
}

.UILabel[justify="left"] {
  text-align: left;
  display: block;
}
.UILabel[justify="center"] {
  text-align: center;
  display: block;
}
.UILabel[justify="right"] {
  text-align: right;
  display: block;
}

.UILabel[type="bold"] {
  font-weight: 800;
}

.UILabel[type="tab"] {
  font-size: 24px !important;
  line-height: 32px !important;
}

.UILabel[type="link"] {
  color: blue;
  cursor: pointer;
}

.UILabel[type="title"] {
  font-size: 48px !important;
  line-height: 54px !important;

  font-weight: 800;
}

.UILabel[type="h1"] {
  font-size: 36px !important;
  line-height: 44px !important;

  font-weight: 800;
}

.UILabel[type="h2"] {
  font-size: 28px !important;
  line-height: 36px !important;

  font-weight: 600;
}

.UILabel[type="h3"] {
  font-size: 24px !important;
  line-height: 28px !important;

  font-weight: 600;
}

.UILabel[type="h4"] {
  font-size: 22px !important;
  line-height: 26px !important;

  font-weight: 600;
}

.UILabel[type="h5"] {
  font-size: 20px !important;
  line-height: 24px !important;

  font-weight: 600;
}
</style>