<template>
  <div class="account-contents">
    <template v-if="UserAccount.email">
      <div id="lblDisplayName" class="menu-account-display-name">
        {{ UserAccount.Player_Name }}
      </div>
      <UILabel :text="UserAccount.galveon_user_id" :justify="'left'" :type="'h5'" />
      <div id="lblAccount" class="menu-account-display-email">
        {{ UserAccount.email }}
      </div>
      <div v-for="(item, index) in menuData" :key="index">
        <UISeparatorView />
        <a @click="item.action">{{ item.label }}</a>
      </div>
    </template>

    <a id="btnSignIn" v-if="!UserAccount.email" @click="login()">
      <div>Sign In</div>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UICollectionView from "@/components/UI/UICollectionViewCell.vue";

import { IUser } from "@/store/interfaces/IUser";

export default defineComponent({
  name: "MenuAccount",
  components: {
    UISeparatorView,
    UILabel,
    UICollectionView,
  },
  emits: ["menu-account-dismiss", "clicked"],
  data() {
    return {
      menuData: [
        {
          label: "Account Settings",
          action: this.btnSettings,
        },
        {
          label: "Sign Out",
          action: this.logout,
        },
      ],
    };
  },
  methods: {
    onCancel() {
      this.$emit("menu-account-dismiss");
    },
    btnShow() {
      this.$emit("clicked", false);
    },
    logout() {
      this.$store.dispatch("Account/logout");
      this.$emit("clicked", false);
    },
    login() {
      this.$router.push("/login");
      this.$emit("clicked", false);
    },
    btnSettings() {
      this.$router.push("/settings");
    },
  },
  computed: {
    UserAccount(): IUser | null {
      return this.$store.state.Account.user;
    },
  },
});
</script>

<style lang="scss" scoped>
.account-contents {
  bottom: 8px;
}
.menu-account-display-name {
  display: flex;
  font-size: 17px;
}
.menu-account-display-email {
  display: flex;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.menu-account-logout {
  display: flex;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  border-top: solid 1px rgba(0, 0, 0, 0.08);
  padding: 0px 20px;
}

#btnSignIn {
  display: flex;
  justify-content: center;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  padding: 0px 20px;
}
</style>