import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManageAssetView = _resolveComponent("ManageAssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogPublishView = _resolveComponent("UIDialogPublishView")!
  const _component_UIDialogMessageView = _resolveComponent("UIDialogMessageView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.action} Item`,
      canRemove: _ctx.action == 'Edit',
      hasMenu: _ctx.hasMenu,
      menuItems: _ctx.menuItems,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onItemDialogMenuSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ManageAssetView, {
          asset: _ctx.selectedItem,
          basicFields: _ctx.basicFields,
          advancedFields: _ctx.advancedFields,
          hasCost: true,
          isCompendium: _ctx.isWorkshop
        }, {
          basic: _withCtx(() => []),
          advanced: _withCtx(() => []),
          _: 1
        }, 8, ["asset", "basicFields", "advancedFields", "isCompendium"])
      ]),
      _: 1
    }, 8, ["title", "canRemove", "hasMenu", "menuItems", "onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 0,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowInputDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
            key: 1,
            type: 'text',
            title: _ctx.submissionDialogData.title,
            body: _ctx.submissionDialogData.body,
            inputTitle: _ctx.submissionDialogData.inputTitle,
            inputPlaceholder: 'Description of game system',
            onUiDialogInputSubmit: _ctx.onPublish,
            onUiDialogInputCancel: _ctx.onDismissPublishDialog
          }, null, 8, ["title", "body", "inputTitle", "onUiDialogInputSubmit", "onUiDialogInputCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPublishDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPublishView, {
            key: 2,
            asset: _ctx.item,
            body: 'Submitting this Item will share it with the entire community. Are you sure you want to submit this Item?',
            onUiDialogPublishCancel: _ctx.onDismissPublishDialog
          }, null, 8, ["asset", "body", "onUiDialogPublishCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowMessageDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogMessageView, {
            key: 3,
            title: 'Success',
            body: 'Asset successfuly submitted for review.',
            onUiDialogMessageSubmit: _ctx.onDismissMessageDialog
          }, null, 8, ["body", "onUiDialogMessageSubmit"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}