import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "UIDropdownGameSystem" }
const _hoisted_2 = {
  key: 0,
  class: "UITextFieldError"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UICellDropdownField = _resolveComponent("UICellDropdownField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasError == true)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_UICellDropdownField, {
      textLabel: 'Game System',
      items: _ctx.gameSystems,
      modelValue: _ctx.computedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
      "has-error": _ctx.hasError
    }, null, 8, ["items", "modelValue", "has-error"])
  ]))
}