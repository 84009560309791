<template>
  <div :id="item.id" class="ItemUICollectionViewCell">
    <div style="display: flex">
      <img src="@/assets/icon_item@x3.png" />
      <div>{{ item.name }}</div>
    </div>
    <div>
      <div class="lblItemCount">{{ item.count }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ItemUICollectionView",
  props: {
    item: {
      type: Object,
      default: {},
      require: true,
    }
  },
});
</script>

<style lang="scss" scoped>
.ItemUICollectionViewCell {
  background-color: #ffffff;
  
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  padding: 8px;

  cursor: pointer;
}
.lblItemCount {
  text-align: right;
}
</style>