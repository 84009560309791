import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ChooseField" }
const _hoisted_2 = { style: {"position":"relative"} }
const _hoisted_3 = { style: {"position":"relative"} }
const _hoisted_4 = { style: {"position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UILabel, {
      text: 'Type',
      type: 'h1',
      justify: 'left'
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UILabel, {
        text: _ctx.selectedType,
        onClick: _ctx.btnShowTypeMenu
      }, null, 8, ["text", "onClick"]),
      (_ctx.shouldShowDataMenu)
        ? (_openBlock(), _createBlock(_component_UIMenuView, {
            key: 0,
            items: _ctx.typeData,
            onMenuItemSelectedAt: _ctx.onMenuTypeSelect,
            onMenuViewDismiss: _ctx.dismissTypeMenu
          }, null, 8, ["items", "onMenuItemSelectedAt", "onMenuViewDismiss"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_UILabel, {
      text: 'Asset',
      type: 'h1',
      justify: 'left'
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_UILabel, {
        text: _ctx.selectedAsset,
        onClick: _ctx.btnShowAssetMenu
      }, null, 8, ["text", "onClick"]),
      (_ctx.shouldShowAssetMenu)
        ? (_openBlock(), _createBlock(_component_UIMenuView, {
            key: 0,
            items: _ctx.assetMenuData,
            onMenuItemSelectedAt: _ctx.onMenuAssetSelect,
            onMenuViewDismiss: _ctx.dismissAssetMenu
          }, null, 8, ["items", "onMenuItemSelectedAt", "onMenuViewDismiss"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_UILabel, {
      text: 'Field',
      type: 'h1',
      justify: 'left'
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_UILabel, {
        text: _ctx.selectedField,
        onClick: _ctx.btnShowFieldMenu
      }, null, 8, ["text", "onClick"]),
      (_ctx.shouldShowFieldMenu)
        ? (_openBlock(), _createBlock(_component_UIMenuView, {
            key: 0,
            items: _ctx.fieldData,
            onMenuItemSelectedAt: _ctx.onMenuFieldSelect,
            onMenuViewDismiss: _ctx.dismissFieldMenu
          }, null, 8, ["items", "onMenuItemSelectedAt", "onMenuViewDismiss"]))
        : _createCommentVNode("", true)
    ])
  ]))
}