import IAssetBase from "../../interfaces/AssetBases/IAssetBase";
import Version from "./Version";

export default class AssetBase implements IAssetBase {
  constructor(dictionary: Record<string, any>, key: string, name: string) {
    this.key = key;
    this.name = dictionary["name"] || name;
    if (this.name === "") {
      this.name = name;
    }
    this.description = dictionary["description"] || "";
    this.game_system_key = dictionary["game_system_key"] || "";
    this.game_system_name = dictionary["game_system_name"] || "";
    this.galveon_user_id = dictionary["galveon_user_id"] || "";
    this.is_community = dictionary["is_community"] || false;
    this.campaign_id = dictionary["campaign_id"];

    let versionValue = dictionary["version"] ?? new Version(0, 0, 1);
    const major = versionValue["major"] || 0;
    const minor = versionValue["minor"] || 0;
    const patch = versionValue["patch"] || 1;
    this.version = new Version(+major, +minor, +patch);
  }

  version: Version;
  galveon_user_id: string;
  campaign_id: string | null | undefined;

  key: string;
  name: string;
  description: string;
  game_system_key: string;
  game_system_name: string;
  is_community: Boolean;

  toDictionary(): Record<string, any> {
    let returnedDictionary: Record<string, any> = {};

    returnedDictionary["key"] = this.key;
    returnedDictionary["game_system_key"] = this.game_system_key;
    returnedDictionary["game_system_name"] = this.game_system_name;
    returnedDictionary["name"] = this.name;
    returnedDictionary["description"] = this.description;
    returnedDictionary["is_community"] = this.is_community;
    returnedDictionary["campaign_id"] = this.campaign_id;
    returnedDictionary["galveon_user_id"] = this.galveon_user_id;
    returnedDictionary["version"] = this.version.toDictionary();

    return returnedDictionary;
  }

}