import ICompendiumAssetBase from "@/store/interfaces/AssetBases/ICompendiumAssetBase";
import AssetBase from "../AssetBases/AssetBase";
import { AssetType } from "./AssetType";
import Source from "../AssetBases/Source";

export default class CompendiumAssetBase extends AssetBase implements ICompendiumAssetBase {
  constructor(dictionary: Record<string, any>, key: string, type: AssetType) {
    super(dictionary, key, "New Asset");
    this.compendium_prefix = dictionary["compendium_prefix"] || "";
    this.compendium_suffix = dictionary["compendium_suffix"] || "";
    this.campaign_id = dictionary["campaign_id"] ?? null;

    const sourceValue = dictionary["source"] ?? {};
    this.source = new Source(sourceValue["book"] ?? "", sourceValue["rule"] ?? "");

    this.type = type;
  }
  source: Source;

  type: AssetType;
  compendium_prefix: string;
  compendium_suffix: string;

  toDictionary(): Record<string, any> {
    var returnedDictionary: Record<string, any> = super.toDictionary();

    returnedDictionary["compendium_prefix"] = this.compendium_prefix;
    returnedDictionary["compendium_suffix"] = this.compendium_suffix;
    returnedDictionary["type"] = this.type;
    returnedDictionary["source"] = this.source?.toDictionary();

    return returnedDictionary;
  }

}