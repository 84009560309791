<template>
  <div class="home">
    <Account />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Account from "@/components/galveon/Account.vue";

export default defineComponent({
  name: "Home",
  components: {
    Account,
  },
});
</script>
