import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ModificationView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogAssetSelectionView = _resolveComponent("UIDialogAssetSelectionView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.canAddCost)
        ? (_openBlock(), _createBlock(_component_UILabel, {
            key: 0,
            text: 'You must add this Item to a Character with Currencies or add a Game System with currencies to add a Cost. To add a Game System select the Compendium tab.',
            justify: 'left'
          }, null, 8, ["text"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.costOptions, (option, index) => {
        return (_openBlock(), _createBlock(_component_UICollectionView, {
          key: index,
          hasItems: _ctx.getCostResourceByKey(option.key).length > 0
        }, {
          default: _withCtx(() => [
            (_ctx.getCostResourceByKey(option.key).length < 1 && _ctx.canAddCost)
              ? (_openBlock(), _createBlock(_component_UILabel, {
                  key: 0,
                  text: `+ Add ${option.text}`,
                  justify: 'left',
                  onClick: ($event: any) => (_ctx.onShowChangesDialog(option.key, option.type))
                }, null, 8, ["text", "onClick"]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCostResourceByKey(option.key), (item, index) => {
              return (_openBlock(), _createBlock(_component_UILabel, {
                key: index,
                text: _ctx.getCostResourceNameByKey(item.key, option.key),
                justify: 'left',
                onClick: ($event: any) => (_ctx.onShowChangesDialog(option.key, option.type))
              }, null, 8, ["text", "onClick"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["hasItems"]))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowChangesDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogAssetSelectionView, {
            key: 0,
            type: _ctx.assetSelectionSubTitle,
            fields: _ctx.assetSelectionData,
            allowedFields: _ctx.costSelectionData,
            onUiDialogAssetSelectionSubmit: _ctx.onSubmitChangesDialog,
            onUiDialogAssetSelectionCancel: _ctx.onCancelChangesDialog
          }, null, 8, ["type", "fields", "allowedFields", "onUiDialogAssetSelectionSubmit", "onUiDialogAssetSelectionCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}