<template>
  <div class="AssetSelectionUICollectionViewCell">
    <UILabel :text="name" />
    <UILabel :text="value" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "AssetSelectionUICollectionViewCell",
  components: {
    UILabel,
    UISeparatorView,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.AssetSelectionUICollectionViewCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;
  height: 44px;
  cursor: pointer;

  box-sizing: border-box;
}

</style>