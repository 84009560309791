import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79c8408b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "selected", "disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "UITabView",
      id: _ctx.id
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "UITab",
          key: index,
          id: tab.key,
          selected: _ctx.selectedTab === tab.key,
          disabled: tab.disabled,
          onClick: ($event: any) => (_ctx.tabSelected(tab.key))
        }, _toDisplayString(tab.name), 9, _hoisted_2))
      }), 128))
    ], 8, _hoisted_1),
    _createVNode(_component_UISeparatorView)
  ], 64))
}