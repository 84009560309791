import IResourceBase from "@/store/interfaces/AssetBases/IResourceBase";
import CompendiumAssetBase from "../Compendium/CompendiumAssetBase";
import { AssetType } from "../Compendium/AssetType";

export default class ResourceBase extends CompendiumAssetBase implements IResourceBase {
  constructor(dictionary: Record<string, any>, key: string) {
    super(dictionary, key, AssetType.resource);
  }

  toDictionary(): Record<string, any> {
    var returnedDictionary = super.toDictionary();

    return returnedDictionary;
  }
}