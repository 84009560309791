import ISpell from "@/store/interfaces/ISpell";
import SpellBase from "./AssetBases/SpellBase";
import Cost from "./Cost";
import CharacterClass from "./Character";
import MainResource from "./MainResource";
import CostResource from "./CostResource";
import Experience from "./Experience";
import Resource from "./Resource";

import { convertKeysLocalFormula } from "@/utility/tooltip";

export default class Spell extends SpellBase implements ISpell {
    constructor(dictionary: Record<string, any>, key: string) {
        super(dictionary, key);

        this.key_spell_table = dictionary["key_spell_table"] || "";
    }

    key_spell_table: string;


    canCast(characterClass: CharacterClass): boolean {
        let character: CharacterClass = characterClass;
        let maxLevel: number = character.level
        let cost: Cost | undefined = typeof this.costs[maxLevel] === 'undefined' ? this.costs[this.costs.length - 1] : this.costs[maxLevel];
        if (cost === undefined) {
            return false;
        }
        let characterVital: MainResource = character.main_vital

        // Check vitals
        let vitals: CostResource[] = cost.vitals.concat(cost.vitalFractions);
        for (const vital of vitals) {

            if (+vital.value <= characterVital.current) {
                return false;
            }
        }

        // Check experiences
        let experiences: CostResource[] = cost.experiences.concat(cost.experienceFractions);
        let characterExperience: Experience = character.experience
        for (const experience of experiences) {
            if (+experience.value <= characterExperience.current) {
                return false;
            }
        }

        // Check resources
        let characterResources: Resource[] = character.resources;
        let resources: CostResource[] = cost.resources;
        for (const resource of resources) {
            let selectedResource: Resource | undefined = characterResources.find((r: Resource) => r.key == resource.key);
            if (selectedResource) {
                const [hasBonus, current, max, percentage] = selectedResource.getValueWithBonus(character);

                let tests = {
                    level: characterClass.level,
                    main_resource: characterClass.main_vital.max,
                    value: +current,
                };

                const [value, result, formulaBonuses] = convertKeysLocalFormula(resource.value, tests);
                if (value === undefined || +value > +current) {
                    return false;
                }
            }
        }

        let resourceFractions: CostResource[] = cost.resourceFractions;
        for (const resource of resourceFractions) {
            let selectedResource: Resource | undefined = characterResources.find((r: Resource) => r.key == resource.key);
            if (selectedResource) {
                const [hasBonus, current, max, percentage] = selectedResource.getValueWithBonus(character);

                let tests = {
                    level: characterClass.level,
                    main_resource: characterClass.main_vital.max,
                    value: +current,
                };

                const [value, result, formulaBonuses] = convertKeysLocalFormula(resource.value, tests);
                if (value === undefined || +value * +max > +current) {
                    return false;
                }
            }
        }

        return true;
    }

    toDictionary(): Record<string, any> {
        let returnedDictionary: Record<string, any> = super.toDictionary();

        returnedDictionary["key_spell_table"] = this.key_spell_table;

        return returnedDictionary;
    }
}