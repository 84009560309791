<template>
  <div class="overview">
    <div class="vOverviewContainer" @click="onDisplayOverviewDialog()">
      <UILabel :text="characterName" :type="'h1'" />
      <MainResourceView
        :mainResource="mainResource"
        :experience="experience"
        :level="level"
        :hasImg="true"
        :showLevel="true"
      />
    </div>
    <UISeparatorView />
    <div class="vOptions">
      <div class="vOption" data-pw="btn-lore" @click="onDisplayLoreDialog">
        <img src="@/assets/icon_lore@x3.png" width="44" />
        <UILabel :text="'Bio'" />
      </div>
      <div class="vOption" data-pw="btn-journal" @click="onDisplayJournalDialog">
        <img src="@/assets/icon_journal@x3.png" width="44" />
        <UILabel :text="'Journal'" />
      </div>
      <div class="vOption" data-pw="btn-dice" @click="onDisplayDiceDialog">
        <img src="@/assets/icon_dice_20@x3.png" width="44" />
        <UILabel :text="'Dice'" />
      </div>
    </div>
    <UISeparatorView />
  </div>
  <div>
    <UIDialogOverview
      v-if="shouldShowOverviewDialog"
      :characterName="characterName"
      :resource="mainResource"
      :experience="experience"
      :characterLevel="level"
      @ui-dialog-overview-submit="onSubmitOverviewDialog"
      @ui-dialog-overview-cancel="onCancelOverviewDialog"
    />
    <UIDialogBioView
      v-if="shouldShowLoreDialog"
      :character="character"
      @ui-dialog-lore-cancel="onCancelLoreDialog"
    />
    <UIDialogJournalView
      v-if="shouldShowJournalDialog"
      :journal="journal"
      @ui-dialog-journal-cancel="onCancelJournalDialog"
    />
    <UIDialogDiceView
      v-if="shouldShowDiceDialog"
      @ui-dialog-dice-cancel="onCancelDiceDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UIActivityView from "@/components/UI/UIActivityView.vue";

import UIDialogOverview from "@/components/character/dialogs/UIDialogOverview.vue";
import UIDialogJournalView from "@/components/character/dialogs/UIDialogJournalView.vue";
import UIDialogBioView from "@/components/character/dialogs/UIDialogBioView.vue";
import UIDialogDiceView from "@/components/character/dialogs/UIDialogDiceView.vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import MainResourceView from "@/components/character/MainResourceView.vue";

import IOverview from "@/store/interfaces/IOverview";
import MainResource from "@/store/models/MainResource";
import Experience from "@/store/models/Experience";
import CharacterClass from "@/store/models/Character";

export default defineComponent({
  name: "CharacterOverview",
  components: {
    UILabel,
    UIActivityView,
    MainResourceView,
    UIDialogOverview,
    UIDialogJournalView,
    UIDialogBioView,
    UIDialogDiceView,
    UISeparatorView,
  },
  props: {
    character: {
      type: Object as () => CharacterClass,
      require: true,
    },
  },
  data() {
    return {
      shouldShowOverviewDialog: false,
      shouldShowJournalDialog: false,
      shouldShowDiceDialog: false,
      shouldShowLoreDialog: false,
    };
  },
  computed: {
    ...mapGetters("Character", [
      'getName',
      'getMainVital',
      'getExperience',
      'getLevel',
      'getJournal',
      'getStory',
      'getBonus',
    ]),
    characterName(): string {
      return this.getName;
    },
    mainResource(): MainResource {
      return this.getMainVital;
    },
    experience(): Experience {
      return this.getExperience;
    },
    level(): number {
      const bonus = this.getBonus("level");
      return this.getLevel + bonus;
    },
    journal(): string {
      return this.getJournal;
    },
    lore(): string {
      return this.getStory;
    },
  },
  methods: {
    ...mapActions('Character', [
      'setOverview',
    ]),
    onDisplayDiceDialog() {
      this.shouldShowDiceDialog = true;
    },
    onCancelDiceDialog() {
      this.shouldShowDiceDialog = false;
    },
    onDisplayJournalDialog() {
      this.shouldShowJournalDialog = true;
    },
    onCancelJournalDialog() {
      this.shouldShowJournalDialog = false;
    },
    onDisplayLoreDialog() {
      this.shouldShowLoreDialog = true;
    },
    onCancelLoreDialog() {
      this.shouldShowLoreDialog = false;
    },
    onDisplayOverviewDialog() {
      this.shouldShowOverviewDialog = true;
    },
    onSubmitOverviewDialog(overview: IOverview) {
      this.setOverview(overview);
    },
    onCancelOverviewDialog() {
      this.shouldShowOverviewDialog = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.overview {
  margin-top: 16px;

  height: calc(100% - 32px);

  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);

  background: #ffffff;
  border-radius: 8px;
}

.vOverviewContainer {
  cursor: pointer;
}

.vOptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>