<template>
  <UIDialogView
    :title="title"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <div class="vBody">
      <UILabel :text="body" :type="'body'" :justify="'left'" :font="14" />
    </div>
    <div class="vInput">
      <UITextField
        :id="'txtValue'"
        :type="'text'"
        v-model="value"
        :textLabel="inputTitle"
        :placeholder="inputPlaceholder"
      />
    </div>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "UIDialogInputView",
  components: {
    UIDialogView,
    UILabel,
    UITextField,
    UIButton,
    UISeparatorView,
  },
  data() {
    return {
      value: this.inputValue,
    };
  },
  emits: ["ui-dialog-input-submit", "ui-dialog-input-cancel"],
  props: {
    type: {
      type: String,
      require: true,
      default: "",
    },
    title: String,
    body: String,
    inputTitle: String,
    inputValue: String,
    inputPlaceholder: String,
    buttonTitle: {
      type: String,
      default: "Save",
    },
  },
  methods: {
    onCancel() {
      this.$emit("ui-dialog-input-cancel");
    },
    onSubmit() {
      let returnedData = {
        type: this.type,
        value: this.value,
      };
      this.$emit("ui-dialog-input-submit", returnedData);
      this.onCancel();
    },
  },
});
</script>

<style lang="scss">
</style>