import ISpellbook from "@/store/interfaces/ISpellbook";
import SpellTable from "@/store/models/SpellTable";

export default class Spellbook implements ISpellbook {
    constructor(dictionary: Record<string, any>, key: string) {
        this.key = key;
        this.name = dictionary["name"] || "New Spellbook";
        if (this.name === "") {
            self.name = "New Spellbook";
        }
        this.description = dictionary["description"] || "";
        this.key_spell_table = dictionary["key_spell_table"] || "";
    }
    tab: Array<SpellTable> = [];
    key: string;
    name: string;
    description: string;
    key_spell_table: string;

    toDictionary(): Record<string, any> {
        var returnedDictionary: Record<string, any> = {}

        returnedDictionary["name"] = this.name;
        returnedDictionary["key"] = this.key;

        return returnedDictionary;
    }

}