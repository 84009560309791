<template>
  <UIDialogView
    :title="`${action} Veterancy`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onVeterancyDialogMenuSelect"
  >
    <ManageAssetView :asset="selectedVeterancy" :hasModifications="true" :hasCompendium="false" :hasAdvanced="true">
      <template v-slot:basic>
        <div>
          <UITextField
            :id="'txtMinimumExperiance'"
            :title="'Minimum Experience'"
            :textLabel="'Minimum Experience'"
            :type="'number'"
            v-model="selectedVeterancy.minimum_experience"
            :placeholder="'Minimum Experience'"
            :disabled="readOnly"
          />
        </div>
        <div>
          <UITextField
            :id="'txtAttributePointsGained'"
            :title="'Attribute Points Gained'"
            :textLabel="'Attribute Points Gained'"
            :type="'number'"
            v-model="selectedVeterancy.points_gained_attribute"
            :placeholder="'Attribute Points Gained'"
            :disabled="readOnly"
          />
        </div>
      </template>
      <template v-slot:advanced></template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import Veterancy from "@/store/models/Veterancy";

export default defineComponent({
  name: "UIDialogVeterancyView",
  props: {
    veterancy: {
      type: Object as () => Veterancy,
      default: {},
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedVeterancy: {} as Veterancy,
      shouldShowPromptDialog: false,
      promptDialogData: {},
      menuItems: ["Remove Veterancy"],
      hasMenu: true,
    };
  },
  emits: ["ui-dialog-veterancy-submit", "ui-dialog-veterancy-remove", "ui-dialog-veterancy-cancel"],
  components: {
    UIButton,
    UILabel,
    UITextField,
    UITextView,
    ManageAssetView,
    UIDialogView,
    UIDialogPromptView,
  },
  methods: {
    onSubmit() {
      this.$emit("ui-dialog-veterancy-submit", this.selectedVeterancy);
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-veterancy-cancel");
    },
    onVeterancyDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onRemoveDialog();
      }
    },
    onRemoveDialog() {
      let data: any = {};

      data = {
        name: "Remove veterancy",
        body: `Are you sure you want to remove this veterancy from your Character? This cannot be undone.`,
        submitTitle: "Continue",
        type: "removeVeterancy",
      };

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog() {
      this.$emit("ui-dialog-veterancy-remove", this.selectedVeterancy);
      this.onCancel();
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.selectedVeterancy = new Veterancy(this.veterancy.toDictionary());

    this.hasMenu = !this.isNew;
  },
});
</script>
