<template>
  <div class="UICellDropdownView" :class="{ disabled: disabled }">
    <UILabel v-if="shouldShowLabel" :text="textLabel" :justify="'left'" />
    <UIDropDownView :items="items" v-model="computedValue" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";

import UIDropDownView from "@/components/UI/UIDropDownView.vue";

export default defineComponent({
  name: "UICellDropdownField",
  components: {
    UILabel,
    UIDropDownView,
  },
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
  emits: [
    "drop-down-item-selected-at",
    "drop-down-selected",
    "update:modelValue",
  ],
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
      default: "None",
    },
    items: {
      type: Array,
      default: [] as Array<String>,
    },
    textLabel: {
      type: String,
      default: "Label",
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    shouldShowLabel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string | number) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
});
</script>

<style lang="scss">
.UICellDropdownView {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding-left: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  > .UILabel {
    width: 30%;
  }

  .UIDropDownField {
    margin: 0;
    border: 0;
    border-radius: 0;
    border-top-right-radius: 8xp;
    -webkit-border-top-right-radius: 8px;
    border-bottom-right-radius: 8xp;
    -webkit-border-bottom-right-radius: 8px;
    border-left: 1px solid lightgrey;
  }
}
</style>