<template>
  <div class="DieUICollectionViewCell">
    <div class="flex">
      <img :src="diceIcon" width="24" />
      <div style="position: relative">
        <div>
          <img
            src="@/assets/icon_menu@x2.png"
            width="24"
            style="cursor: pointer; margin-right: 8px"
            @click.stop="onDiceMenu"
          />
        </div>
        <UIMenuView
          v-if="shouldShowDieMenu"
          :items="['Edit Dice', 'Delete Dice']"
          @menu-item-selected-at="onMenuDieSelect"
          @menu-view-dismiss="dismissDieMenu"
        />
      </div>
    </div>
    <UISeparatorView />
    <UILabel :text="`${die.count}d${die.sides}+${die.modifier}`" :type="'h3'" />
    <UILabel :text="die.name" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIMenuView from "@/components/UI/UIMenuView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import Dice from "@/store/models/Dice";

export default defineComponent({
  name: "DieUICollectionViewCell",
  components: {
    UIMenuView,
    UILabel,
    UISeparatorView,
  },
  props: {
    die: {
      type: Object as () => Dice,
      default: {},
    },
  },
  emits: ["die-ui-collection-view-cell-edit", "die-ui-collection-view-cell-delete"],
  data() {
    return {
      shouldShowDieMenu: false,
    };
  },
  computed: {
    diceIcon() {
      let acceptedSides = [4, 6, 8, 10, 12, 20];
      if (!acceptedSides.includes(this.die.sides)) {
        return require(`@/assets/icon_dice_20@x2.png`);
      }
      return require(`@/assets/icon_dice_${this.die.sides}@x2.png`);
    }
  },
  methods: {
    onDiceMenu() {
      this.shouldShowDieMenu = true;
    },
    onMenuDieSelect(index: number) {
      if (index === 0) {
        this.$emit("die-ui-collection-view-cell-edit");
      } else if (index === 1) {
        let data: any = {
          name: "Delete Die",
          body: `Are you sure you want delete ${this.die.name}? It cannot be undone?`,
          submitTitle: "Continue",
          type: "deleteDie",
        };
        this.$emit("die-ui-collection-view-cell-delete", data, this.die);
      }
    },
    dismissDieMenu() {
      this.shouldShowDieMenu = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.DieUICollectionViewCell {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  padding: 4px;

  cursor: pointer;
}
</style>