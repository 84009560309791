import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_menu@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-f8952338"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"space-between","position":"relative"} }
const _hoisted_3 = { class: "section-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CharacterResource = _resolveComponent("CharacterResource")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_SpellTableUICollectionViewCell = _resolveComponent("SpellTableUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogPurchaseChenShop = _resolveComponent("UIDialogPurchaseChenShop")!
  const _component_UIDialogAssetView = _resolveComponent("UIDialogAssetView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CharacterResource),
    _createVNode(_component_UISeparatorView),
    (_ctx.spellbook.length < 1)
      ? (_openBlock(), _createBlock(_component_UIOnboardView, {
          key: 0,
          title: 'Your Spell Library is empty',
          body: 'Fill your Library with different Spellbooks',
          buttonTitle: 'Add Spellbook',
          onUiOnboardView: _ctx.newBook
        }, null, 8, ["onUiOnboardView"]))
      : _createCommentVNode("", true),
    (_ctx.spellbook.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_UILabel, {
              text: 'Spells',
              type: 'h1'
            }),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  id: "btnSpellMenu",
                  style: {"cursor":"pointer","margin-right":"8px"},
                  src: _imports_0,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSpellMenu && _ctx.onSpellMenu(...args)))
                })
              ]),
              (_ctx.shouldShowSpellMenu)
                ? (_openBlock(), _createBlock(_component_UIMenuView, {
                    key: 0,
                    items: [
              'Add Table',
              'Rename Book',
              _ctx.spellbook.length < 1 || _ctx.hasBlackMarket
                ? 'Add Book'
                : 'Unlock unlimited books',
              'Delete Book',
            ],
                    onMenuItemSelectedAt: _ctx.onMenuSelect,
                    onMenuViewDismiss: _ctx.dismissSpellMenu
                  }, null, 8, ["items", "onMenuItemSelectedAt", "onMenuViewDismiss"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_UITabView, {
            id: 'tvBooks',
            tabs: _ctx.spellbook,
            selectedTab: _ctx.selected_book_name,
            onTabSelected: _ctx.btnBook
          }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_UICollectionView, {
              id: 'cvSpellbook',
              hasItems: _ctx.selected_spell_tables.length > 0
            }, {
              default: _withCtx(() => [
                (_ctx.selected_spell_tables.length < 1)
                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                      key: 0,
                      title: 'This Spellbook has no Tables',
                      body: 'Tables can be used to group similar spells',
                      buttonTitle: 'Add Spell Table',
                      onUiOnboardView: _ctx.addSpellTab
                    }, null, 8, ["onUiOnboardView"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected_spell_tables, (spell_tab, index) => {
                  return (_openBlock(), _createBlock(_component_SpellTableUICollectionViewCell, {
                    key: index,
                    table: spell_tab
                  }, null, 8, ["table"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["hasItems"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowInputDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
          key: 2,
          onUiDialogInputCancel: _ctx.onCancelInputDialog,
          onUiDialogInputSubmit: _ctx.onSaveInputDialog,
          type: _ctx.inputDialogData.type,
          title: _ctx.inputDialogData.name,
          body: _ctx.inputDialogData.body,
          inputTitle: _ctx.inputDialogData.field,
          inputPlaceholder: _ctx.inputDialogData.placeholder,
          inputValue: _ctx.inputDialogData.value,
          buttonTitle: _ctx.inputDialogData.input
        }, null, 8, ["onUiDialogInputCancel", "onUiDialogInputSubmit", "type", "title", "body", "inputTitle", "inputPlaceholder", "inputValue", "buttonTitle"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowPromptDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
          key: 3,
          type: _ctx.promptDialogData.type,
          title: _ctx.promptDialogData.name,
          body: _ctx.promptDialogData.body,
          submitTitle: _ctx.promptDialogData.submitTitle,
          onUiDialogPromptSubmit: _ctx.onSavePromptDialog,
          onUiDialogPromptCancel: _ctx.onCancelPromptDialog
        }, null, 8, ["type", "title", "body", "submitTitle", "onUiDialogPromptSubmit", "onUiDialogPromptCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowChenDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogPurchaseChenShop, {
          key: 4,
          onUiDialogPurchaseCancel: _ctx.dismissChenDialog
        }, null, 8, ["onUiDialogPurchaseCancel"]))
      : _createCommentVNode("", true),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogAssetView, {
          key: 5,
          title: 'Effect',
          query: 'effect',
          id: '',
          isCommunity: true,
          onUiDialogAssetCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog()))
        }))
      : _createCommentVNode("", true)
  ]))
}