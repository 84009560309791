<template>
  <div class="UITextViewContainer">
    <UILabel v-if="!hideLabel" :text="placeholder" :justify="'left'" />
    <span v-if="hasError == true" class="UITextViewError">
      {{ errorMessage }}
    </span>
    <textarea
      class="UITextView"
      :value="textViewPreview"
      :placeholder="placeholder"
      :has-error="hasError"
      :disabled="disabled"
      @input="onInput"
    > 
    <!-- TODO: Will need to capture all events that could trigger a selection change so that it's accurate. -->
    </textarea>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { convertKeysLocalDescription } from "@/utility/tooltip";

import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: "UITextView",
  emits: ['ui-text-view-change', 'update:modelValue'],
  components: {
    UILabel,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: "",
    },
    placeholder: {
      required: true,
      type: String,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput(event: any) {
      this.$emit("ui-text-view-change", event.target.value, event.target.selectionStart, event.target.selectionEnd);
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    textViewPreview(): string {
      return convertKeysLocalDescription(this.modelValue as string);
    },
  }
});
</script>

<style lang="scss" scoped>
.UITextViewContainer {
  padding: 0 10px;
  margin-bottom: 8px;
}

.UITextViewError {
  color: #f44336;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: left;

  padding-left: 8px;

  display: block;
}

.UITextView {
  font: 400 16px / 24px "Helvetica Neue", "Helvetica", "Open Sans", sans-serif;
  background: #fff;
  border: 1px solid lightgrey;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  border-radius: 10px;
  box-sizing: border-box;

  outline: 0;

  height: 200px;
  resize: none;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}

.UITextView[has-error="true"] {
  border-color: #f44336;
}
</style>