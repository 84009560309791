import Campaign from "../models/Campaign";


//TODO: Add this to it's own database area
import { doc, setDoc, getDoc, addDoc, updateDoc, arrayUnion, collection, query, where, getDocs, getFirestore, onSnapshot, DocumentSnapshot, DocumentData, QuerySnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Party from "@/store/models/Campaign/Party";
import CharacterClass from "@/store/models/Character";

import { newKey, database_save, database_option, setFirestore } from "@/utility/database";
import Attribute from "@/store/models/Attribute";
import Resource from "@/store/models/Resource";
import Spell from "@/store/models/Spell";
import Item from "@/store/models/Item";
import { Account } from "./account";
import AttributeBase from "../models/AssetBases/AttributeBase";
import ResourceBase from "../models/AssetBases/ResourceBase";
import SpellBase from "../models/AssetBases/SpellBase";

const state = {
  campaigns: [] as Array<Campaign>,
  campaignsPlayer: [] as Array<Campaign>,
  parties: [] as Array<Party>,
  playerParties: [] as Array<Party>,
  campaignCharacters: [] as Array<CharacterClass>,
  selectedPartyId: "",
  selectedPlayerPartyId: null,
  selectedCampaign: {} as Campaign | null,
}

const getters = {
  getSelectedCampaign: (state: any): Campaign | null => {
    return state.selectedCampaign;
  },
  getCampaigns: (state: any): Array<Campaign> => {
    return state.campaigns;
  },
  getCampaignCharacters: (state: any): Array<CharacterClass> => {
    return state.campaignCharacters;
  },
  getCampaignById: (state: any) => (val: string) => {
    const index = state.campaigns.findIndex(
      (c: Campaign) => c.key === val
    );
    if (index > -1) {
      return state.campaigns[index];
    }
    return null;
  },
  getPlayerCampaignById: (state: any) => (val: string) => {
    const index = state.campaignsPlayer.findIndex(
      (c: Campaign) => c.key === val
    );
    if (index > -1) {
      return state.campaignsPlayer[index];
    }
    return null;
  },
  getParties: (state: any): Array<Party> => {
    return state.parties || [];
  },
  getPlayerParties: (state: any): Array<Party> => {
    return state.playerParties || [];
  },
  getSelectedParty: (state: any): Party | null => {
    const index = state.parties.findIndex(
      (c: Party) => c.key === state.selectedPartyId
    );

    if (index > -1) {
      return state.parties[index];
    }
    return null;
  },
  getSelectedPlayerParty: (state: any): Party | null => {
    const index = state.playerParties.findIndex(
      (c: Party) => c.key === state.selectedPlayerPartyId
    );

    if (index > -1) {
      return state.playerParties[index];
    }
    return null;
  },
  getCampaign: (state: any) => (key: string) => {
    const index = state.campaigns.findIndex(
      (c: any) => c.key == key
    );
    if (index > -1) {
      return state.campaigns[index];
    }
  },
}

const mutations = {
  setSelectedCampaign: (state: any, val: Campaign | null) => {
    console.log('Testing', val);
    state.selectedCampaign = val;
  },
  setCampaign: (state: any, val: any) => {
    const index = state.campaigns.findIndex(
      (c: any) => c.key == val.key
    );

    if (index > -1) {
      state.campaigns[index] = val;
    }
    else {
      state.campaigns.push(val);
    }
  },
  setPlayerCampaign: (state: any, val: Campaign) => {
    const index = state.campaignsPlayer.findIndex(
      (c: any) => c.key == val.key
    );

    if (index > -1) {
      state.campaignsPlayer[index] = val;
    }
    else {
      state.campaignsPlayer.push(val);
    }
  },
  setPartyCharacter: (state: any, val: any) => {
    const index = state.campaignCharacters.findIndex(
      (c: any) => c.key == val.key
    );

    if (index > -1) {
      state.campaignCharacters[index] = val;
    }
    else {
      state.campaignCharacters.push(val);
    }
  },
  setPartyCharacters: (state: any, val: any) => {
    state.campaignCharacters = val;
  },
  setCampaignKeys: (state: any, obj: any) => {
    const { key, val } = obj;
    const index = state.campaigns.findIndex(
      (c: any) => c.key == key
    );
    if (index > -1) {
      const campaign = state.campaigns[index];
      for (const key in val) {
        campaign[key] = val[key];
      }
      state.campaigns[index] = campaign;
    }
  },
  setParty: (state: any, val: any) => {
    const index = state.parties.findIndex(
      (c: Party) => c.key == val.key
    );

    if (index > -1) {
      state.parties[index] = val;
    }
    else {
      state.parties.push(val);
    }
  },
  setPlayerParty: (state: any, val: any) => {
    const index = state.playerParties.findIndex(
      (c: Party) => c.key == val.key
    );

    if (index > -1) {
      state.playerParties[index] = val;
    }
    else {
      state.playerParties.push(val);
    }
  },
  setSelectedParty: (state: any, val: string) => {
    state.selectedPartyId = val;
  },
  setSelectedPlayerParty: (state: any, val: string) => {
    state.selectedPlayerPartyId = val;
  },
  addCharacterIdToSelectedParty: (state: any, val: string) => {
    const party: Party = state.playerParties.find((p: Party) => p.key === state.selectedPlayerPartyId);

    if (party) {
      if (!party.characters.includes(val)) {
        party.characters.push(val);
      }
    }
  },
  setCampaignAttribute: (state: any, val: Attribute) => {
    console.log("Campaign");
    const asset: AttributeBase = new AttributeBase(val.toDictionary(), val.key);
    // setDatabaseAt(asset.key, asset.toDictionary(), database_option.attribute_campaign, database_save.firestoreSet);
  },
  setCampaignResource: (state: any, val: Resource) => {
    console.log("Campaign");
    const asset: ResourceBase = new ResourceBase(val.toDictionary(), val.key);
    // setDatabaseAt(asset.key, asset.toDictionary(), database_option.attribute_campaign, database_save.firestoreSet);
  },
  setCampaignSpell: (state: any, val: Spell) => {
    console.log("Campaign");
    const asset: SpellBase = new SpellBase(val.toDictionary(), val.key);
    // setDatabaseAt(asset.key, asset.toDictionary(), database_option.attribute_campaign, database_save.firestoreSet);
  },
  setCampaignItem: (state: any, val: Item) => {
    console.log("Campaign");
    const asset: AttributeBase = new AttributeBase(val.toDictionary(), val.key);
    // setDatabaseAt(asset.key, asset.toDictionary(), database_option.attribute_campaign, database_save.firestoreSet);
  }
}

const actions = {
  async fetchCampaigns({ dispatch, commit }: any) {
    return new Promise(async (resolve, reject) => {
      const db = getFirestore();
      const userUID: string | undefined = getAuth().currentUser?.uid;

      const campaignsRef = collection(db, "campaigns");

      const q = query(campaignsRef, where("campaign_owner_id", "==", userUID));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        const key = doc.id;
        let campaign = doc.data();

        campaign.key = key;

        commit('setCampaign', campaign);

        await dispatch("fetchParties", key);
      });
      resolve(true);
    });
  },
  async fetchPlayerCampaigns({ commit }: any) {
    const db = getFirestore();
    const userUID: string | undefined = getAuth().currentUser?.uid;

    state.playerParties.forEach((p: Party) => {
      const campaignRef = doc(db, "campaigns", `${p.campaign_id}`);

      getDoc(campaignRef).then((documentSnapshot: DocumentSnapshot<DocumentData>) => {
        commit('setPlayerCampaign', new Campaign({ ...documentSnapshot.data() }, documentSnapshot.id));
      });
    });
  },
  async fetchCampaignCharacters({ commit }: any) {

  },
  async fetchParties({ commit }: any, id: string) {
    const db = getFirestore();
    const userUID: string | undefined = getAuth().currentUser?.uid;

    const partyRef = collection(db, "parties");

    const q = query(partyRef, where("campaign_id", "==", id));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      let party = new Party(doc.data(), doc.id);

      commit('setParty', party);
    });
  },
  async fetchPlayerParties({ commit, dispatch }: any) {
    const db = getFirestore();
    const userUid: string | undefined = getAuth().currentUser?.uid;

    const partyRef = collection(db, "parties");

    const q = query(partyRef, where('players', 'array-contains', userUid));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      let party = new Party(doc.data(), doc.id);

      commit('setPlayerParty', party);
    });

    dispatch("fetchPlayerCampaigns");
  },
  setSelectedCampaign({ commit, state }: any, key: string) {
    const index = state.campaigns.findIndex(
      (c: Campaign) => c.key == key
    );
    if (index > -1) {
      const campaign: Campaign = state.campaigns[index];
      commit('setSelectedCampaign', campaign);
    }
  },
  setSelectedParty({ commit }: any, val: any) {
    return new Promise((resolve, reject) => {
      commit('setSelectedParty', val);
      resolve(true);
    });
  },
  setSelectedPlayerParty({ commit }: any, val: any) {
    return new Promise((resolve, reject) => {
      commit('setSelectedPlayerParty', val);
      resolve(true);
    });
  },
  createCampaign({ commit }: any, val: string) {
    return new Promise(async (resolve, reject) => {
      const campaignName = val;
      const userUID: string | undefined = getAuth().currentUser?.uid;
      const displayName: string | null | undefined = getAuth().currentUser?.displayName;
      const db = getFirestore();

      const campaign = {
        name: campaignName,
        campaign_owner: displayName,
        campaign_owner_id: userUID
      };

      try {
        const docRef = await addDoc(collection(db, "campaigns"), campaign);

        resolve(true);
        commit("setCampaign", campaign);
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        reject(e);
        console.error("Error adding document: ", e);
      }
    });
  },
  setCampaignKeys({ commit }: any, obj: any) {
    return new Promise(async (resolve, reject) => {
      const db = getFirestore();
      console.log(obj);
      try {
        await setDoc(doc(db, "campaigns", obj.key), obj);
        commit('setCampaignKeys', obj);
        resolve(true);
      } catch (e) {
        reject(e);
        console.error("Error updating document: ", e);
      }
    });
  },
  createParty({ commit, getters }: any, data: any) {
    return new Promise(async (resolve, reject) => {
      const { name, campaign_id } = data;
      const currentCampaign: Campaign = getters.getCampaign(campaign_id);
      const campaign_owner_id = currentCampaign.campaign_owner_id;

      const newParty = new Party({ name, campaign_id, campaign_owner_id }, newKey());

      const db = getFirestore();

      try {
        const docRef = await addDoc(collection(db, "parties"), newParty.toDictionary());

        resolve(true);
        commit("setParty", newParty);
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        reject(e);
        console.error("Error adding document: ", e);
      }

      resolve(true);

    });
  },
  joinParty({ commit, dispatch }: any, code: string) {
    return new Promise(async (resolve, reject) => {
      const db = getFirestore();
      const userUID: string | undefined = getAuth().currentUser?.uid;

      const partyRef = doc(db, "parties", code);

      await getDoc(partyRef).then(async (querySnapshot: DocumentSnapshot<DocumentData>) => {
        if (querySnapshot.exists()) {
          await updateDoc(querySnapshot.ref, {
            players: arrayUnion(userUID)
          })
            .then(() => {
              let data = new Party(querySnapshot.data(), querySnapshot.id);
              data.players.push(userUID);

              commit('setPlayerParty', data);
              dispatch("fetchPlayerCampaigns");
              resolve(true);
            });
        } else {
          console.log('No such document');
        }
      });
    });
  },
  addCharacterToPartyWithId({ commit }: any, data: any) {
    return new Promise(async (resolve, reject) => {
      const { partyId, charId } = data;
      const db = getFirestore();
      const userUid: string | undefined = getAuth().currentUser?.uid;

      const partyRef = doc(db, "parties", `${partyId}`);

      await updateDoc(partyRef, {
        characters: arrayUnion(charId)
      })
        .then(() => {
          commit('addCharacterIdToSelectedParty', charId);
          resolve(true);
        });
    });
  },
  async addListenerPartyCharactersByPartyId({ commit }: any, partyId: string) {
    commit("setPartyCharacters", []);
    return new Promise((resolve, reject) => {
      const db = getFirestore();
      const q = query(collection(db, "characters"), where("party_id", "==", `${partyId}`));
      const charaters = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const char: CharacterClass = new CharacterClass(doc.data(), doc.id, Account.state.workshop);
          char.loadImage();
          commit('setPartyCharacter', char);
        });
        resolve(true);
      });
    });
  },
  async setAssetAt({ commit }: any, val: any) {
    return new Promise((resolve, reject) => {
      const { path, data } = val;

      setFirestore(path, data, database_save.firestoreSet).then((data: any) => resolve(data));
    });
  },
  async fetchCampaignAsset({ commit }: any, data: any) {
    const { campaignId, assetCategory } = data;

    return new Promise(async (resolve, reject) => {
      const db = getFirestore();

      const querySnapshot = await getDocs(collection(db, 'campaigns', campaignId, assetCategory));
      let returnedData: Array<any> = [];
      querySnapshot.forEach((doc: DocumentData) => {
        switch (assetCategory) {
          case 'attributes':
            returnedData.push(new Attribute(doc.data(), doc.id));
            break;
          case 'resources':
            returnedData.push(new Resource(doc.data(), doc.id));
            break;
          case 'spells':
            returnedData.push(new Spell(doc.data(), doc.id));
            break;
          case 'items':
            returnedData.push(new Item(doc.data(), doc.id));
            break;
          default:
            break;
        }
      });
      resolve(returnedData);
    });
  }
}

export const Campaigns = { namespaced: true, state, getters, mutations, actions }