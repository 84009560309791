import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_plus@x1.png'
import _imports_1 from '@/assets/icon_pencil@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-471a8f00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "manage-asset-view" }
const _hoisted_2 = { class: "manage-asset-view-container" }
const _hoisted_3 = {
  key: 4,
  class: "flex"
}
const _hoisted_4 = {
  key: 0,
  class: "flex"
}
const _hoisted_5 = {
  key: 1,
  class: "comepndium-asset-view"
}
const _hoisted_6 = {
  key: 2,
  class: "advanced-asset-view"
}
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UICellDropdownField = _resolveComponent("UICellDropdownField")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIDropDownView = _resolveComponent("UIDropDownView")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_SecondaryAttributeUICollectionViewCell = _resolveComponent("SecondaryAttributeUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDropdownGameSystem = _resolveComponent("UIDropdownGameSystem")!
  const _component_UICellTextField = _resolveComponent("UICellTextField")!
  const _component_ModificationBonusUICollectionViewCell = _resolveComponent("ModificationBonusUICollectionViewCell")!
  const _component_EffectSectionView = _resolveComponent("EffectSectionView")!
  const _component_CostResourceUICollectionViewCell = _resolveComponent("CostResourceUICollectionViewCell")!
  const _component_UIDialogModificationView = _resolveComponent("UIDialogModificationView")!
  const _component_UIDialogCostView = _resolveComponent("UIDialogCostView")!
  const _component_UIDialogFormulaView = _resolveComponent("UIDialogFormulaView")!
  const _component_UIDialogDescription = _resolveComponent("UIDialogDescription")!
  const _component_UIDialogSecondaryAttributeView = _resolveComponent("UIDialogSecondaryAttributeView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UITabView, {
        id: 'tvTabs',
        tabs: _ctx.tabs,
        selectedTab: _ctx.selectedTab,
        onTabSelected: _ctx.btnSelectTab
      }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.selectedTab === 'basic')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _renderSlot(_ctx.$slots, "basic", {}, undefined, true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.basicFields, (assetValue, indexField) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetValue.fields, (field, indexValue) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (field.type === 'textfield' && !_ctx.readOnly)
                        ? (_openBlock(), _createBlock(_component_UITextField, {
                            key: 0,
                            title: field.label,
                            textLabel: field.label,
                            type: field.valueType,
                            modelValue: _ctx.asset[field.key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event),
                            placeholder: field.placeholder
                          }, null, 8, ["title", "textLabel", "type", "modelValue", "onUpdate:modelValue", "placeholder"]))
                        : _createCommentVNode("", true),
                      (field.type === 'textview' && !_ctx.readOnly)
                        ? (_openBlock(), _createBlock(_component_UITextView, {
                            key: 1,
                            modelValue: _ctx.asset[field.key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event),
                            disabled: true,
                            placeholder: field.placeholder,
                            onClick: ($event: any) => (_ctx.onDisplayDescriptionDialog(_ctx.asset[field.key], field.key))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onClick"]))
                        : _createCommentVNode("", true),
                      (field.type === 'dropdown' && !_ctx.readOnly)
                        ? (_openBlock(), _createBlock(_component_UICellDropdownField, {
                            key: 2,
                            modelValue: _ctx.asset[field.key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event),
                            textLabel: field.label,
                            items: field.dataSource
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "textLabel", "items"]))
                        : _createCommentVNode("", true),
                      (field.type === 'formula' && !_ctx.readOnly)
                        ? (_openBlock(), _createBlock(_component_UILabel, {
                            key: 3,
                            text: `${field.label}: ` + _ctx.asset[field.key],
                            justify: 'left',
                            onClick: ($event: any) => (_ctx.onDisplayFormulaDialog(_ctx.asset[field.key], field.key))
                          }, null, 8, ["text", "onClick"]))
                        : _createCommentVNode("", true),
                      (field.type === 'cell-dropdown' && !_ctx.readOnly)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_UITextField, {
                              placeholder: field.placeholder,
                              textLabel: field.label,
                              type: field.type,
                              disabled: _ctx.readOnly,
                              modelValue: _ctx.asset[field.key],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event)
                            }, null, 8, ["placeholder", "textLabel", "type", "disabled", "modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_UIDropDownView, {
                              items: field.dataSource,
                              modelValue: _ctx.asset[field.dropdown_key],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.dropdown_key]) = $event)
                            }, null, 8, ["items", "modelValue", "onUpdate:modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (field.type === 'secondary')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                            (_ctx.asset[field.key] && _ctx.asset[field.key].length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  _createVNode(_component_UILabel, {
                                    text: 'Secondary',
                                    type: 'h2'
                                  }),
                                  (!_ctx.readOnly)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: _imports_0,
                                        style: {"cursor":"pointer"},
                                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDisplayValueDialog()))
                                      }))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_UISeparatorView),
                            _createVNode(_component_UICollectionView, { id: 'cvExtra' }, {
                              default: _withCtx(() => [
                                (!_ctx.asset[field.key] || _ctx.asset[field.key].length < 1)
                                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                                      key: 0,
                                      title: 'No Spell Attributes',
                                      buttonTitle: 'Add Spell Attribute',
                                      onUiOnboardView: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDisplayValueDialog()))
                                    }))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.asset[field.key], (extra, index) => {
                                  return (_openBlock(), _createBlock(_component_SecondaryAttributeUICollectionViewCell, {
                                    key: index,
                                    extra: extra,
                                    onClick: ($event: any) => (_ctx.onDisplayValueDialog(index, extra))
                                  }, null, 8, ["extra", "onClick"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ], 64))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256)),
                  (indexField !== _ctx.basicFields.length - 1)
                    ? (_openBlock(), _createBlock(_component_UISeparatorView, { key: 0 }))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 'compendium')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_UIDropdownGameSystem, {
                modelValue: _ctx.asset.game_system_name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.asset.game_system_name) = $event)),
                onUiDropdownGameSystemSelect: _ctx.onGameSystemCellDropdownMenuSelect
              }, null, 8, ["modelValue", "onUiDropdownGameSystemSelect"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.compendiumData, (field, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (field.type === 'text')
                    ? (_openBlock(), _createBlock(_component_UICellTextField, {
                        key: 0,
                        textLabel: field.label,
                        modelValue: _ctx.asset[field.key],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event),
                        placeholder: field.label,
                        disabled: _ctx.readOnly
                      }, null, 8, ["textLabel", "modelValue", "onUpdate:modelValue", "placeholder", "disabled"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 'advanced')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "advanced", {}, undefined, true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.advancedFields, (assetValue) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetValue.fields, (field) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (field.type === 'textfield' && !_ctx.readOnly)
                        ? (_openBlock(), _createBlock(_component_UITextField, {
                            key: 0,
                            title: field.label,
                            textLabel: field.label,
                            type: field.valueType,
                            modelValue: _ctx.asset[field.key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event),
                            placeholder: field.placeholder
                          }, null, 8, ["title", "textLabel", "type", "modelValue", "onUpdate:modelValue", "placeholder"]))
                        : _createCommentVNode("", true),
                      (field.type === 'textview' && !_ctx.readOnly)
                        ? (_openBlock(), _createBlock(_component_UITextView, {
                            key: 1,
                            modelValue: _ctx.asset[field.key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event),
                            disabled: true,
                            placeholder: field.placeholder,
                            onClick: ($event: any) => (_ctx.onDisplayDescriptionDialog(_ctx.asset[field.key], field.key))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onClick"]))
                        : _createCommentVNode("", true),
                      (field.type === 'dropdown' && !_ctx.readOnly)
                        ? (_openBlock(), _createBlock(_component_UICellDropdownField, {
                            key: 2,
                            modelValue: _ctx.asset[field.key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.asset[field.key]) = $event),
                            textLabel: field.label,
                            items: field.dataSource
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "textLabel", "items"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ], 64))
              }), 256)),
              (_ctx.hasModifications)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_UILabel, {
                        text: 'Modifications',
                        type: 'h2',
                        justify: 'left'
                      }),
                      _createElementVNode("img", {
                        src: _imports_1,
                        style: {"cursor":"pointer"},
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onDisplayModificationDialog && _ctx.onDisplayModificationDialog(...args)))
                      })
                    ]),
                    _createVNode(_component_UICollectionView, {
                      id: 'cvModification',
                      hasItems: !_ctx.asset.modification.isEmpty()
                    }, {
                      default: _withCtx(() => [
                        (_ctx.asset.modification.isEmpty())
                          ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                              key: 0,
                              title: 'No Modifications',
                              buttonTitle: 'Add Modifications',
                              onUiOnboardView: _ctx.onDisplayModificationDialog
                            }, null, 8, ["onUiOnboardView"]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.asset.modification.toDictionary(), (value, key) => {
                          return (_openBlock(), _createBlock(_component_ModificationBonusUICollectionViewCell, {
                            key: key,
                            mod: { key, value }
                          }, null, 8, ["mod"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["hasItems"])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.hasEffect)
                ? (_openBlock(), _createBlock(_component_EffectSectionView, {
                    key: 1,
                    asset: _ctx.asset,
                    readOnly: _ctx.readOnly
                  }, null, 8, ["asset", "readOnly"]))
                : _createCommentVNode("", true),
              (_ctx.hasCost)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_ctx.asset.costs && _ctx.asset.costs?.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_UILabel, {
                            text: 'Cost',
                            type: 'h2'
                          }),
                          (!_ctx.readOnly)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _imports_0,
                                style: {"cursor":"pointer"},
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onDisplayCostDialog(-1)))
                              }))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_UICollectionView, {
                      id: 'cvCosts',
                      hasItems: _ctx.asset.costs.length > 0
                    }, {
                      default: _withCtx(() => [
                        (_ctx.asset.costs.length < 1)
                          ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                              key: 0,
                              title: 'No costs',
                              buttonTitle: 'Add a Cost',
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDisplayCostDialog(-1)))
                            }))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.asset.costs, (item, index) => {
                          return (_openBlock(), _createBlock(_component_CostResourceUICollectionViewCell, {
                            key: index,
                            index: index,
                            value: 
                item.getCostToString(
                  _ctx.$store.state.Account.selectedCharacter,
                  _ctx.assetGameSystem
                )
              ,
                            onClick: ($event: any) => (_ctx.onDisplayCostDialog(index))
                          }, null, 8, ["index", "value", "onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["hasItems"])
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.shouldShowModificationDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogModificationView, {
          key: 0,
          modification: _ctx.asset.modification.toDictionary(),
          onUiDialogModificationSubmit: _ctx.onSubmitModificationDialog,
          onUiDialogModificationCancel: _ctx.onCancelModificationDialog
        }, null, 8, ["modification", "onUiDialogModificationSubmit", "onUiDialogModificationCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowCostDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogCostView, {
          key: 1,
          isNew: _ctx.selectedCostIndex === -1,
          cost: _ctx.selectedCost,
          assetType: _ctx.asset.type,
          gameSystem: _ctx.assetGameSystem,
          campaignId: _ctx.asset.campaign_id,
          isCompendium: _ctx.isCompendium,
          onUiDialogCostSubmit: _ctx.onSubmitCostDialog,
          onUiDialogCostDelete: _ctx.onDeleteCostDialog,
          onUiDialogCostCancel: _ctx.onDismissCostDialog
        }, null, 8, ["isNew", "cost", "assetType", "gameSystem", "campaignId", "isCompendium", "onUiDialogCostSubmit", "onUiDialogCostDelete", "onUiDialogCostCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowFormulaDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogFormulaView, {
          key: 2,
          formula: _ctx.selectedFormula,
          onUiDialogFormulaSubmit: _ctx.onSubmitFormulaDialog,
          onUiDialogFormulaCancel: _ctx.onCancelFormulaDialog
        }, null, 8, ["formula", "onUiDialogFormulaSubmit", "onUiDialogFormulaCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowDescriptionDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogDescription, {
          key: 3,
          description: _ctx.selectedDescription,
          onUiDialogDescriptionSubmit: _ctx.onSubmitDescriptionDialog,
          onUiDialogDescriptionCancel: _ctx.onDismissDescriptionDialog
        }, null, 8, ["description", "onUiDialogDescriptionSubmit", "onUiDialogDescriptionCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowValueDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogSecondaryAttributeView, {
          key: 4,
          extra: _ctx.extraDialogData,
          onUiDialogSecondaryAttributeSubmit: _ctx.onSubmitValueDialog,
          onUiDialogSecondaryAttributeCancel: _ctx.onCancelValueDialog,
          onUiDialogSecondaryAttributeRemove: _ctx.onRemoveValueDialog
        }, null, 8, ["extra", "onUiDialogSecondaryAttributeSubmit", "onUiDialogSecondaryAttributeCancel", "onUiDialogSecondaryAttributeRemove"]))
      : _createCommentVNode("", true)
  ], 64))
}