<template>
  <div class="flex" v-if="asset.effects && asset.effects?.length > 0">
    <UILabel :text="'Effect'" :type="'h2'" />
    <img
      v-if="!readOnly"
      src="@/assets/icon_plus@x1.png"
      style="cursor: pointer"
      @click="onDisplayAssetDialog(-1)"
    />
  </div>
  <UICollectionView :id="'cvEffects'" :hasItems="asset.effects.length > 0">
    <UIOnboardView
      v-if="asset.effects.length < 1"
      :title="'No effects'"
      :buttonTitle="'Add an Effect'"
      @click="onDisplayAssetDialog(-1)"
    />
    <EffectUICollectionViewCell
      v-for="(item, index) in asset.effects"
      :key="index"
      :index="index"
      :value="
        item.getEffectToString(
          $store.state.Account.selectedCharacter,
          assetGameSystem
        )
      "
      @click="onDisplayAssetDialog(index)"
    />
  </UICollectionView>
  <div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UIOnboardView from "@/components/UI/UIOnboardView.vue";
import EffectUICollectionViewCell from "@/components/character/collectionViewCells/EffectUICollectionViewCell.vue";
import GameSystem from "@/store/models/GameSystem";

export default defineComponent({
  name: "EffectSectionView",
  components: {
    UILabel,
    UICollectionView,
    UIOnboardView,
    EffectUICollectionViewCell,
  },
  props: {
    asset: {
      type: Object,
      default: {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["effect-section-select", "shouldShowModifyVitalDialog"],
  methods: {
    ...mapActions("Dialog", ["openDialog"]),
    onSelect() {
      this.$emit("effect-section-select", null);
    },
    onDisplayAssetDialog(index: number) {
      this.openDialog({ asset: this.asset, assetIndex: index });
    },
  },
  computed: {
    ...mapGetters("Account", ["getGameSystemWith"]),
    assetGameSystem(): GameSystem {
      return this.getGameSystemWith(this.asset.game_system_key);
    },
  }
});
</script>
