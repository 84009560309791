<template>
  <div class="manage-asset-view">
    <UITabView
      :id="'tvTabs'"
      :tabs="tabs"
      :selectedTab="selectedTab"
      @tab-selected="btnSelectTab"
    />
    <div class="manage-asset-view-container">
      <template v-if="selectedTab === 'basic'">
        <slot name="basic"></slot>
        <template v-for="(assetValue, indexField) in basicFields">
          <template v-for="(field, indexValue) in assetValue.fields">
            <UITextField
              v-if="field.type === 'textfield' && !readOnly"
              :title="field.label"
              :textLabel="field.label"
              :type="field.valueType"
              v-model="asset[field.key]"
              :placeholder="field.placeholder"
            />
            <UITextView
              v-if="field.type === 'textview' && !readOnly"
              v-model="asset[field.key]"
              :disabled="true"
              :placeholder="field.placeholder"
              @click="onDisplayDescriptionDialog(asset[field.key], field.key)"
            />
            <UICellDropdownField
              v-if="field.type === 'dropdown' && !readOnly"
              v-model="asset[field.key]"
              :textLabel="field.label"
              :items="field.dataSource"
            />
            <UILabel
              v-if="field.type === 'formula' && !readOnly"
              :text="`${field.label}: ` + asset[field.key]"
              :justify="'left'"
              @click="onDisplayFormulaDialog(asset[field.key], field.key)"
            />
            <div
              class="flex"
              v-if="field.type === 'cell-dropdown' && !readOnly"
            >
              <UITextField
                :placeholder="field.placeholder"
                :textLabel="field.label"
                :type="field.type"
                :disabled="readOnly"
                v-model="asset[field.key]"
              />
              <UIDropDownView
                :items="field.dataSource"
                v-model="asset[field.dropdown_key]"
              />
            </div>
            <template v-if="field.type === 'secondary'">
              <div
                class="flex"
                 v-if="asset[field.key] && asset[field.key].length > 0"
              >
                <UILabel :text="'Secondary'" :type="'h2'" />
                <img
                  v-if="!readOnly"
                  src="@/assets/icon_plus@x1.png"
                  style="cursor: pointer"
                  @click="onDisplayValueDialog()"
                />
              </div>
              <UISeparatorView />
              <UICollectionView :id="'cvExtra'">
                <UIOnboardView
                  v-if="!asset[field.key] || asset[field.key].length < 1"
                  :title="'No Spell Attributes'"
                  :buttonTitle="'Add Spell Attribute'"
                  @ui-onboard-view="onDisplayValueDialog()"
                />
                <SecondaryAttributeUICollectionViewCell
                  v-for="(extra, index) in asset[field.key]"
                  :key="index"
                  :extra="extra"
                  @click="onDisplayValueDialog(index, extra)"
                />
              </UICollectionView>
            </template>
          </template>
          <UISeparatorView v-if="indexField !== basicFields.length - 1" />
        </template>
      </template>

      <div v-if="selectedTab === 'compendium'" class="comepndium-asset-view">
        <UIDropdownGameSystem
          v-model="asset.game_system_name"
          @ui-dropdown-game-system-select="onGameSystemCellDropdownMenuSelect"
        />
        <template v-for="(field, index) in compendiumData">
          <UICellTextField
            v-if="field.type === 'text'"
            :textLabel="field.label"
            v-model="asset[field.key]"
            :placeholder="field.label"
            :disabled="readOnly"
          />
        </template>
      </div>
      <div v-if="selectedTab === 'advanced'" class="advanced-asset-view">
        <slot name="advanced"></slot>
        <template v-for="(assetValue) in advancedFields">
          <template v-for="(field) in assetValue.fields">
            <UITextField
              v-if="field.type === 'textfield' && !readOnly"
              :title="field.label"
              :textLabel="field.label"
              :type="field.valueType"
              v-model="asset[field.key]"
              :placeholder="field.placeholder"
            />
            <UITextView
              v-if="field.type === 'textview' && !readOnly"
              v-model="asset[field.key]"
              :disabled="true"
              :placeholder="field.placeholder"
              @click="onDisplayDescriptionDialog(asset[field.key], field.key)"
            />
            <UICellDropdownField
              v-if="field.type === 'dropdown' && !readOnly"
              v-model="asset[field.key]"
              :textLabel="field.label"
              :items="field.dataSource"
            />
          </template>
        </template>
        <template v-if="hasModifications">
          <div class="flex">
            <UILabel :text="'Modifications'" :type="'h2'" :justify="'left'" />
            <img
              src="@/assets/icon_pencil@x2.png"
              style="cursor: pointer"
              @click="onDisplayModificationDialog"
            />
          </div>
          <UICollectionView
            :id="'cvModification'"
            :hasItems="!asset.modification.isEmpty()"
          >
            <UIOnboardView
              v-if="asset.modification.isEmpty()"
              :title="'No Modifications'"
              :buttonTitle="'Add Modifications'"
              @ui-onboard-view="onDisplayModificationDialog"
            />
            <ModificationBonusUICollectionViewCell
              v-for="(value, key) in asset.modification.toDictionary()"
              :key="key"
              :mod="{ key, value }"
            />
          </UICollectionView>
        </template>
        <template v-if="hasEffect">
          <EffectSectionView :asset="asset" :readOnly="readOnly" />
        </template>
        <template v-if="hasCost">
          <div
            class="flex"
            v-if="asset.costs && asset.costs?.length > 0"
          >
            <UILabel :text="'Cost'" :type="'h2'" />
            <img
              v-if="!readOnly"
              src="@/assets/icon_plus@x1.png"
              style="cursor: pointer"
              @click="onDisplayCostDialog(-1)"
            />
          </div>
          <UICollectionView
            :id="'cvCosts'"
            :hasItems="asset.costs.length > 0"
          >
            <UIOnboardView
              v-if="asset.costs.length < 1"
              :title="'No costs'"
              :buttonTitle="'Add a Cost'"
              @click="onDisplayCostDialog(-1)"
            />
            <CostResourceUICollectionViewCell
              v-for="(item, index) in asset.costs"
              :key="index"
              :index="index"
              :value="
                item.getCostToString(
                  $store.state.Account.selectedCharacter,
                  assetGameSystem
                )
              "
              @click="onDisplayCostDialog(index)"
            />
          </UICollectionView>
        </template>
      </div>
    </div>
  </div>
  <UIDialogModificationView
    v-if="shouldShowModificationDialog"
    :modification="asset.modification.toDictionary()"
    @ui-dialog-modification-submit="onSubmitModificationDialog"
    @ui-dialog-modification-cancel="onCancelModificationDialog"
  />
  <UIDialogCostView
    v-if="shouldShowCostDialog"
    :isNew="selectedCostIndex === -1"
    :cost="selectedCost"
    :assetType="asset.type"
    :gameSystem="assetGameSystem"
    :campaignId="asset.campaign_id"
    :isCompendium="isCompendium"
    @ui-dialog-cost-submit="onSubmitCostDialog"
    @ui-dialog-cost-delete="onDeleteCostDialog"
    @ui-dialog-cost-cancel="onDismissCostDialog"
  />
  <UIDialogFormulaView
    v-if="shouldShowFormulaDialog"
    :formula="selectedFormula"
    @ui-dialog-formula-submit="onSubmitFormulaDialog"
    @ui-dialog-formula-cancel="onCancelFormulaDialog"
  />
  <UIDialogDescription
    v-if="shouldShowDescriptionDialog"
    :description="selectedDescription"
    @ui-dialog-description-submit="onSubmitDescriptionDialog"
    @ui-dialog-description-cancel="onDismissDescriptionDialog"
  />
  <UIDialogSecondaryAttributeView
    v-if="shouldShowValueDialog"
    :extra="extraDialogData"
    @ui-dialog-secondary-attribute-submit="onSubmitValueDialog"
    @ui-dialog-secondary-attribute-cancel="onCancelValueDialog"
    @ui-dialog-secondary-attribute-remove="onRemoveValueDialog"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogDescription from "@/components/character/dialogs/UIDialogDescription.vue";
import UIDialogFormulaView from "@/components/character/dialogs/UIDialogFormulaView.vue";
import UIDialogModificationView from "@/components/character/dialogs/UIDialogModificationView.vue";
import UIDialogCostView from "@/components/character/dialogs/UIDialogCostView.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UIOnboardView from "@/components/UI/UIOnboardView.vue";
import UITabView from "@/components/UI/UITabView.vue";
import SecondaryAttributeUICollectionViewCell from "@/components/character/SecondaryAttributeUICollectionViewCell.vue";
import UICellTextField from "@/components/UI/UICellTextField.vue";
import UIDropDownView from "@/components/UI/UIDropDownView.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";
import UICellDropdownField from "@/components/UI/UICellDropdownField.vue";
import UIDropdownGameSystem from "@/components/compendium/UIDropdownGameSystem.vue";
import ModificationBonusUICollectionViewCell from "@/components/character/ModificationBonusUICollectionViewCell.vue";
import CostResourceUICollectionViewCell from "@/components/character/collectionViewCells/CostResourceUICollectionViewCell.vue";
import UIDialogSecondaryAttributeView from "@/components/character/dialogs/UIDialogSecondaryAttributeView.vue";
import EffectSectionView from "@/components/character/EffectSectionView.vue";
import Modification from "@/store/models/Modification";
import { mapGetters } from "vuex";

import GameSystem from "@/store/models/GameSystem";
import Cost from "@/store/models/Cost";
import SecondaryAttribute from "@/store/models/SecondaryAttribute";

export default defineComponent({
  name: "ManageAssetView",
  components: {
    UIDialogSecondaryAttributeView,
    SecondaryAttributeUICollectionViewCell,
    UISeparatorView,
    UIDialogModificationView,
    UIDialogCostView,
    UILabel,
    UITextField,
    UITextView,
    UICollectionView,
    UIOnboardView,
    UITabView,
    UICellTextField,
    UICellDropdownField,
    ModificationBonusUICollectionViewCell,
    CostResourceUICollectionViewCell,
    UIDropdownGameSystem,
    EffectSectionView,
    UIDropDownView,
    UIDialogFormulaView,
    UIDialogDescription,
  },
  props: {
    asset: {
      type: Object,
      default: {},
    },
    basicFields: {
      type: Array,
      default: [],
    },
    advancedFields: {
      type: Array,
      default: [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isCompendium: {
      type: Boolean,
      default: true,
      required: true,
    },
    hasCompendium: {
      type: Boolean,
      default: true,
    },
    hasAdvanced: {
      type: Boolean,
      default: true,
    },
    hasModifications: {
      type: Boolean,
      default: false,
    },
    hasCost: {
      type: Boolean,
      default: false,
    },
    hasEffect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: `Basic`,
          key: "basic",
        },
      ],
      selectedTab: "basic",
      compendiumData: [
        {
          label: "Compendium Prefix",
          type: "text",
          key: "compendium_prefix",
        },
        {
          label: "Compendium Suffix",
          type: "text",
          key: "compendium_suffix",
        },
      ],
      shouldShowModificationDialog: false,
      shouldShowCostDialog: false,
      shouldShowFormulaDialog: false,
      shouldShowDescriptionDialog: false,
      shouldShowValueDialog: false,
      gameSystems: [] as string[],
      gameSystemsData: [] as GameSystem[],
      selectedCost: {} as Cost,
      selectedCostIndex: -1,
      selectedFormula: "",
      selectedFormulaKey: "",
      selectedDescription: "",
      selectedDescriptionKey: "",
      extraDialogData: {},
      extraIndex: -1,
    };
  },
  methods: {
    onDisplayDescriptionDialog(field: string, key: string) {
      if (this.readOnly) return;
      this.selectedDescription = field;
      this.selectedDescriptionKey = key;
      this.shouldShowDescriptionDialog = true;
    },
    onSubmitDescriptionDialog(newDescription: string) {
      this.asset[this.selectedDescriptionKey] = newDescription;
      this.onDismissDescriptionDialog();
    },
    onDismissDescriptionDialog() {
      this.shouldShowDescriptionDialog = false;
    },
    btnSelectTab(tab: string) {
      this.selectedTab = tab;
    },
    onSubmitModificationDialog(modification: Record<string, any>) {
      this.asset.modification = new Modification(modification);
    },
    onDisplayModificationDialog() {
      this.shouldShowModificationDialog = true;
    },
    onCancelModificationDialog() {
      this.shouldShowModificationDialog = false;
    },
    onGameSystemCellDropdownMenuSelect(key: string, name: string) {
      this.asset.game_system_key = key;
      this.asset.game_system_name = name;
    },
    onDisplayCostDialog(index: number) {
      if (index !== -1) {
        this.selectedCost = this.asset.costs[index];
      } else {
        this.selectedCost = new Cost({});
      }
      this.selectedCostIndex = index;
      this.shouldShowCostDialog = true;
    },
    onDismissCostDialog() {
      this.shouldShowCostDialog = false;
    },
    onDisplayFormulaDialog(field: string, key: string) {
      this.selectedFormula = field;
      this.selectedFormulaKey = key;
      this.shouldShowFormulaDialog = true;
    },
    onSubmitFormulaDialog(formula: string) {
      this.asset[this.selectedFormulaKey] = formula;
    },
    onCancelFormulaDialog() {
      this.shouldShowFormulaDialog = false;
    },
    onDeleteCostDialog() {
      this.asset.costs.splice(this.selectedCostIndex, 1);
    },
    onSubmitCostDialog(data: Cost) {
      if (this.selectedCostIndex !== -1) {
        this.asset.costs[this.selectedCostIndex] = data;
      } else {
        this.asset.costs.push(data);
      }
    },
    onDisplayValueDialog(index: number = -1, data: any) {
      if (this.readOnly) return;

      this.extraDialogData = data !== undefined ? data : new SecondaryAttribute({});
      this.extraIndex = index;
      this.shouldShowValueDialog = true;
    },
    onCancelValueDialog() {
      this.shouldShowValueDialog = false;
    },
    onSubmitValueDialog(extra: SecondaryAttribute) {
      if (this.extraIndex === -1) {
        if (!this.asset.extra) {
          this.asset.extra = [];
        }
        this.asset.extra.push(extra);
      } else {
        const index = this.extraIndex;
        this.asset.extra[index] = extra;
      }
    },
    onRemoveValueDialog() {
      const index = this.extraIndex;
      if (index > -1) {
        this.asset.extra.splice(index, 1);
      }
    },
  },
  computed: {
    ...mapGetters("Account", ["getWorkshopGameSystem", "getGameSystemWith"]),
    shouldShowCustomTab() {
      return this.asset.compendium_system === "Custom";
    },
    assetGameSystem(): GameSystem {
      return this.getGameSystemWith(this.asset.game_system_key);
    },
  },
  mounted() {
    const compendiumTab = {
      name: `Compendium`,
      key: "compendium",
    };

    const advancedTab = {
      name: `Advanced`,
      key: "advanced",
    };

    if (this.hasCompendium) {
      this.tabs.splice(1, 0, compendiumTab);
    }

    if (this.hasAdvanced) {
      this.tabs.splice(this.tabs.length, 0, advancedTab);
    }
  },
});
</script>

<style lang="scss" scoped>
.manage-asset-view-container,
.advanced-asset-view {
  overflow: auto;

  & > :deep(div):not(:first-child) {
    margin: 16px 0;
  }
}
</style>