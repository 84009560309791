import IParty from "../../interfaces/IParty";

export default class Party implements IParty {
  constructor(dictionary: Record<string, any>, key: string) {
    this.key = key;

    this.name = dictionary["name"] || "New Party";
    if (this.name === "") {
      this.name = "New Party"
    }
    this.campaign_id = dictionary["campaign_id"] || "";
    this.campaign_owner_id = dictionary["campaign_owner_id"] || "";
    this.characters = dictionary["characters"] || [];
    this.players = dictionary["players"] || [];
  }

  name: string;
  campaign_id: string;
  campaign_owner_id: string;
  characters: Array<any>;
  players: Array<any>;
  key: string;

  toDictionary = (): Record<string, any> => {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["name"] = this.name;
    returnedDictionary["campaign_id"] = this.campaign_id;
    returnedDictionary["campaign_owner_id"] = this.campaign_owner_id;

    //TODO: Add characters and players

    return returnedDictionary
  }
}