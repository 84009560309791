import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_ManageAssetView = _resolveComponent("ManageAssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.action} Veterancy`,
      canRemove: _ctx.action == 'Edit',
      hasMenu: _ctx.hasMenu,
      menuItems: _ctx.menuItems,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onVeterancyDialogMenuSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ManageAssetView, {
          asset: _ctx.selectedVeterancy,
          hasModifications: true,
          hasCompendium: false,
          hasAdvanced: true
        }, {
          basic: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_UITextField, {
                id: 'txtMinimumExperiance',
                title: 'Minimum Experience',
                textLabel: 'Minimum Experience',
                type: 'number',
                modelValue: _ctx.selectedVeterancy.minimum_experience,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedVeterancy.minimum_experience) = $event)),
                placeholder: 'Minimum Experience',
                disabled: _ctx.readOnly
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_UITextField, {
                id: 'txtAttributePointsGained',
                title: 'Attribute Points Gained',
                textLabel: 'Attribute Points Gained',
                type: 'number',
                modelValue: _ctx.selectedVeterancy.points_gained_attribute,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedVeterancy.points_gained_attribute) = $event)),
                placeholder: 'Attribute Points Gained',
                disabled: _ctx.readOnly
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          advanced: _withCtx(() => []),
          _: 1
        }, 8, ["asset"])
      ]),
      _: 1
    }, 8, ["title", "canRemove", "hasMenu", "menuItems", "onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 0,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}