import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c89501b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "width", "height"]
const _hoisted_2 = { class: "vLabel" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["height", "width"]
const _hoisted_5 = ["fill", "stroke-dasharray", "stroke-width", "r", "cx", "cy", "transform"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["stop-color"]
const _hoisted_8 = ["stop-color"]
const _hoisted_9 = ["stroke", "stroke-dasharray", "stroke-width", "r", "cx", "cy", "transform"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "UIActivityView",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPortraitClick()))
  }, [
    (_ctx.hasImage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "vPortrait",
          style: _normalizeStyle({ top: `${_ctx.stroke * 2}px` })
        }, [
          _createElementVNode("img", {
            src: _ctx.img,
            width: _ctx.normalizedRadius * 2 - _ctx.stroke,
            height: _ctx.normalizedRadius * 2 - _ctx.stroke,
            style: _normalizeStyle({
          width: _ctx.normalizedRadius * 2 - _ctx.stroke + 'px',
          height: _ctx.normalizedRadius * 2 - _ctx.stroke + 'px',
          top: _ctx.stroke / 2 + 'px',
        })
          }, null, 12, _hoisted_1)
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.labelTitle !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.labelTitle), 1))
        : _createCommentVNode("", true),
      (_ctx.label !== '')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "vLabelNumber",
            style: _normalizeStyle({ fontSize: `${_ctx.labelSize}px` })
          }, _toDisplayString(_ctx.label), 5))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(), _createElementBlock("svg", {
      height: _ctx.radius * 2,
      width: _ctx.radius * 2
    }, [
      _createElementVNode("circle", {
        stroke: 'lightgrey',
        fill: _ctx.fill,
        "stroke-dasharray": _ctx.circumference + ' ' + _ctx.circumference,
        style: '{ 100% }',
        "stroke-width": _ctx.stroke,
        r: _ctx.normalizedRadius,
        cx: _ctx.radius,
        cy: _ctx.radius,
        transform: _ctx.transform
      }, null, 8, _hoisted_5),
      _createElementVNode("defs", null, [
        _createElementVNode("linearGradient", {
          id: _ctx.gradientId,
          x1: "1",
          y1: "1",
          x2: "0.5",
          y2: "1"
        }, [
          _createElementVNode("stop", {
            "stop-color": _ctx.darkerColor,
            offset: "0%"
          }, null, 8, _hoisted_7),
          _createElementVNode("stop", {
            "stop-color": _ctx.color,
            offset: "100%"
          }, null, 8, _hoisted_8)
        ], 8, _hoisted_6)
      ]),
      _createElementVNode("circle", {
        stroke: _ctx.gradientStroke,
        fill: "transparent",
        "stroke-dasharray": _ctx.circumference + ' ' + _ctx.circumference,
        style: _normalizeStyle({ strokeDashoffset: _ctx.strokeDashoffset }),
        "stroke-width": _ctx.stroke,
        r: _ctx.normalizedRadius,
        cx: _ctx.radius,
        cy: _ctx.radius,
        transform: _ctx.transform
      }, null, 12, _hoisted_9)
    ], 8, _hoisted_4))
  ]))
}