<template>
  <div class="UITabView" :id="id">
    <div
      class="UITab"
      v-for="(tab, index) in tabs"
      :key="index"
      :id="tab.key"
      :selected="selectedTab === tab.key"
      :disabled="tab.disabled"
      @click="tabSelected(tab.key)"
    >
      {{ tab.name }}
    </div>
  </div>
  <UISeparatorView />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "UITabView",
  components: {
    UISeparatorView,
  },
  emits: ["tab-selected"],
  props: {
    tabs: {
      type: Array,
      default: [],
    },
    selectedTab: String,
    id: String,
  },
  methods: {
    tabSelected(key: string) {
      this.$emit("tab-selected", key);
    },
  },
});
</script>

<style lang="scss" scoped>
.UITab[disabled="true"] {
  opacity: .5;
  pointer-events: none;
}

.UITabView {
  display: flex;
  min-height: 44px;
  overflow: auto;
}

.UITab:first-child {
  padding-left: 0;
}

.UITab[selected="true"] {
  font-weight: 600;
}

.UITab {
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.68);
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>