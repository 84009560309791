import IQuickView from "../interfaces/IQuickView";
import EffectBase from "./AssetBases/EffectBase";
import ItemBase from "./AssetBases/ItemBase";
import { AssetType } from "./Compendium/AssetType";
import QuickViewSecondaryAttribute from "./QuickViewSecondaryAttribute";

export default class QuickView implements IQuickView {
  constructor(dictionary: Record<string, any>, key: string) {
    this.key = key;

    this.name = dictionary["name"] || "";
    this.description = dictionary["description"] || "";
    if (dictionary["secondaryAttributes"]) {
      for (const itemKey in dictionary["secondaryAttributes"]) {
        const secondaryAttributes = dictionary["secondaryAttributes"][itemKey];
        const selectedExtra: QuickViewSecondaryAttribute = new QuickViewSecondaryAttribute(secondaryAttributes.name, secondaryAttributes.value);
        this.secondaryAttributes.push(selectedExtra);
      }
    }
    this.imageName = dictionary["imageName"] || "";
    this.type = dictionary["type"];
  }

  key: string;
  name: string;
  description: string;
  secondaryAttributes: Array<QuickViewSecondaryAttribute> = [];
  imageName: string;
  type: AssetType;

  toDictionary = (): Record<string, any> => {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["key"] = this.key;
    returnedDictionary["name"] = this.name;
    returnedDictionary["description"] = this.description;
    if (this.secondaryAttributes.length > 0) {
      var extraDictionary: Record<string, any> = {};
      for (const index in this.secondaryAttributes) {
        extraDictionary[index] = this.secondaryAttributes[index].toDictionary();
      }
      returnedDictionary["extra"] = extraDictionary;
    }
    returnedDictionary["imageName"] = this.imageName;
    returnedDictionary["type"] = this.type;

    return returnedDictionary;
  }

  static quickViewItem(item: ItemBase): QuickView {
    const extras: Array<any> = [];

    //TODO: Add Item Cost

    extras.push({
      name: "Level",
      value: item.level,
    });

    extras.push({
      name: "Rarity",
      value: item.rarity,
    });

    extras.push({
      name: "Type",
      value: item.item_type,
    });
    
    const quickView = {
      key: item.key,
      name: item.name,
      description: item.description,
      secondaryAttributes: extras,
      imageName: "item",
    };

    return new QuickView(quickView, item.key);
  }

  static quickViewEffect(effect: EffectBase) : QuickView {
    const quickView = {
      key: effect.key,
      name: effect.name,
      description: effect.description,
      secondaryAttributes: [],
      imageName: "resource",
      type: effect.type,
    };

    return new QuickView(quickView, effect.key);
  }
}