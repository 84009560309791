<template>
  <div class="email-verification">
    <h1>Your email is not verified</h1>
    <UIButton
      :id="'btnSendEmailVerification'"
      title="Resend email verification"
      @click="btnSendEmailVerification()"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { getAuth, sendEmailVerification, User } from "firebase/auth";

import UIButton from "@/components/UI/UIButton.vue";

export default defineComponent({
  name: "Email_Verification",
  components: {
    UIButton,
  },
  methods: {
    btnSendEmailVerification() {
      const user: User | null = getAuth().currentUser;
      if (user) {
        sendEmailVerification(user)
          .then(() => {
            //TODO: email sent message
          })
          .catch((error) => {
            //TODO: error message
          });
      }
    },
  },
});
</script>