export enum DamageType {
    none = "none",
    unknown = "unknown",
    acid = "acid",
    cold = "cold",
    death = "death",
    defensive = "defensive",
    demolition = "demolition",
    disease = "disease",
    divine = "divine",
    enhanced = "enhanced",
    fire = "fire",
    force = "force",
    lightning = "lightning",
    magic = "magic",
    mind = "mind",
    normal = "normal",
    plant = "plant",
    poison = "poison",
    shadow = "shadow",
    sonic = "sonic",
    spirit = "spirit",
    universal = "universal",
}
