import Resource from "@/store/models/Resource";
import { database_save, setDatabaseCharacter } from "@/utility/database";

export const resourceGetters = {
  getResources: (state: any): Array<Resource> => {
    return state.character.resources;
  },
};

export const resourceMutations = {
  setResource: (state: any, value: Resource) => {
    const index = state.character.resources.findIndex((c: Resource) => c.key == value.key);

    if (index > -1) {
      state.character.resources[index] = value;
    }
    else {
      state.character.resources.push(value);
    }
  },
  removeResource: (state: any, resource: Resource) => {
    const index = state.character.resources.findIndex((c: Resource) => c.key == resource.key);

    if (index > -1) {
      state.character.resources.splice(index, 1);
    }
  },
  addResource: (state: any, value: Resource) => {
    state.character.resources.push(value);
  },
}

export const resourceActions = {
  addResource: ({ commit, state, dispatch }: any, val: Resource) => {
    return new Promise((resolve, reject) => {
      commit("addResource", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setResource: ({ commit, state, dispatch }: any, val: Resource) => {
    return new Promise((resolve, reject) => {
      commit("setResource", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  removeResource: ({ commit, state, dispatch }: any, val: Resource) => {
    return new Promise((resolve, reject) => {
      commit("removeResource", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
}