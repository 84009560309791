import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: `Journal`,
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UITextView, {
        id: 'txtJournal',
        modelValue: _ctx.characterJournal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.characterJournal) = $event)),
        placeholder: 'Journal'
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]))
}