<template>
  <UIDialogView
    :title="`${action} Item`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onItemDialogMenuSelect"
  >
    <ManageAssetView
      :asset="selectedItem"
      :basicFields="basicFields"
      :advancedFields="advancedFields"
      :hasCost="true"
      :isCompendium="isWorkshop"
    >
      <template v-slot:basic> </template>
      <template v-slot:advanced> </template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      :type="'text'"
      :title="submissionDialogData.title"
      :body="submissionDialogData.body"
      :inputTitle="submissionDialogData.inputTitle"
      :inputPlaceholder="'Description of game system'"
      @ui-dialog-input-submit="onPublish"
      @ui-dialog-input-cancel="onDismissPublishDialog"
    />
    <UIDialogPublishView
      v-if="shouldShowPublishDialog"
      :asset="item"
      :body="'Submitting this Item will share it with the entire community. Are you sure you want to submit this Item?'"
      @ui-dialog-publish-cancel="onDismissPublishDialog"
    />
    <UIDialogMessageView
      v-if="shouldShowMessageDialog"
      :title="'Success'"
      :body="'Asset successfuly submitted for review.'"
      @ui-dialog-message-submit="onDismissMessageDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";
import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogMessageView from "@/components/UI/dialogs/UIDialogMessageView.vue";

import UICellDropdownField from "@/components/UI/UICellDropdownField.vue";

import UIDialogPublishView from "@/components/compendium/UIDialogPublishView.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UIOnboardView from "@/components/UI/UIOnboardView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UIDropDownView from "@/components/UI/UIDropDownView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import Item from "@/store/models/Item";

import { newKey } from "@/utility/database";
import { Rarity } from "@/store/interfaces/types/Rarirty";
import { ItemType } from "@/store/interfaces/types/ItemType";

export default defineComponent({
  name: "UIDialogItemView",
  props: {
    item: {
      type: Object as () => Item,
      default: {},
    },
    isWorkshop: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: {} as Item,
      shouldShowPromptDialog: false,
      shouldShowInputDialog: false,
      shouldShowMessageDialog: false,
      shouldShowPublishDialog: false,
      promptDialogData: {},
      submissionDialogData: {},
      menuItems: ["Publish Item", "Duplicate Item", "Remove Item"],
      hasMenu: true,
      basicFields: [
        {
          header: null,
          fields: [
            {
              key: "name",
              label: "Name",
              placeholder: "Name",
              type: "textfield",
              valueType: "text",
            },
            {
              key: "count",
              label: "Quantity",
              placeholder: "Quantity",
              type: "textfield",
              valueType: "number",
            },
            {
              key: "level",
              label: "Level",
              placeholder: "Level",
              type: "textfield",
              valueType: "number",
            },
            {
              key: "rarity",
              label: "Rarity",
              placeholder: "Rarity",
              type: "dropdown",
              valueType: "text",
              dataSource: Object.keys(Rarity).map(
                (key) => Rarity[key as keyof typeof Rarity]
              ),
            },
          ],
        },
        {
          header: null,
          fields: [
            {
              key: "description",
              label: "Description",
              placeholder: "Description",
              type: "textview",
              valueType: "text",
            },
          ],
        },
      ],
      advancedFields: [
        {
          header: null,
          fields: [
            {
              key: "item_type",
              label: "Type",
              placeholder: "Type",
              type: "dropdown",
              valueType: "text",
              dataSource: Object.keys(ItemType).map(
                (key) => ItemType[key as keyof typeof ItemType]
              ),
            },
          ],
        },
      ],
    };
  },
  emits: ["ui-dialog-item-submit", "ui-dialog-item-cancel"],
  components: {
    UIButton,
    UILabel,
    UITextField,
    UITextView,
    ManageAssetView,
    UIDialogView,
    UIDialogPromptView,
    UIDialogInputView,
    UIDialogMessageView,
    UIDialogPublishView,
    UIOnboardView,
    UICollectionView,
    UICellDropdownField,
    UIDropDownView,
  },
  methods: {
    ...mapActions("Character", ["removeItem"]),
    ...mapActions("Publish", ["publish"]),
    ...mapActions("Account", ["deleteWorkshopAssetItem"]),
    onSubmit() {
      this.$emit("ui-dialog-item-submit", {
        item: this.selectedItem,
        isNew: this.isNew,
      });
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-item-cancel");
    },
    onItemDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onShowPublishDialog();
      } else if (index === 1) {
        this.onDuplicateDialog();
      } else if (index === 2) {
        this.onRemoveDialog();
      }
    },
    onDuplicateDialog() {
      let newDuplicateItem: Item = new Item(
        this.selectedItem.toDictionary(),
        newKey()
      );
      newDuplicateItem.name = newDuplicateItem.name + " (Copy)";
      this.$emit("ui-dialog-item-submit", {
        item: newDuplicateItem,
        isNew: true,
      });
      this.onCancel();
    },
    onRemoveDialog() {
      let data: any = {};

      if (this.isWorkshop) {
        data = {
          name: `Delete ${this.selectedItem.name}`,
          body: `Are you sure you want to delete ${this.selectedItem.name} from your Compendium? This cannot be undone.`,
          submitTitle: "Continue",
          type: "deleteAsset",
        };
      } else {
        data = {
          name: `Remove ${this.selectedItem.name}`,
          body: `Are you sure you want to remove ${this.selectedItem.name} from your Character? Your item will continue to exist in your Compendium.`,
          submitTitle: "Continue",
          type: "removeAsset",
        };
      }

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog(data: any) {
      if (data.type === "removeAsset") {
        this.removeItem(this.selectedItem);
      } else if (data.type === "deleteAsset") {
        this.deleteWorkshopAssetItem(this.selectedItem);
      }

      this.onCancel();
    },
    onShowPublishDialog() {
      this.shouldShowPublishDialog = true;
    },
    onDismissPublishDialog() {
      this.shouldShowPublishDialog = false;
    },
    onPublish(data: any) {
      const payload = {
        asset: this.selectedItem,
        description: data.value,
      };

      this.publish(payload).then(() => {
        this.onShowMessageDialog();
      });

      this.onDismissPublishDialog();
    },
    onShowMessageDialog() {
      this.shouldShowMessageDialog = true;
    },
    onDismissMessageDialog() {
      this.shouldShowMessageDialog = false;
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.selectedItem = new Item(this.item.toDictionary(), this.item.key);

    if (this.isWorkshop) {
      this.menuItems = ["Publish Item", "Duplicate Item", "Delete Item"];
    }

    this.hasMenu = !this.isNew;
  },
});
</script>
