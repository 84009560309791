import ISecondaryAttribute from "@/store/interfaces/ISecondaryAttribute";

export default class SecondaryAttribute implements ISecondaryAttribute {
    constructor(dictionary: Record<string, any>) {
        this.name = dictionary["name"] || "New Secondary Attribute";
        if (this.name === "") {
            this.name = "New Secondary Attribute";
        }
        this.value = dictionary["value"] || "";
    }

    name: string;
    value: string;

    toDictionary(): Record<string, any> {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["name"] = this.name;
        returnedDictionary["value"] = this.value;

        return returnedDictionary;
    }
}