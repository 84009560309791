<template>
    <ul class="UIListView">
        <UIListViewItem v-for="item in numberOfItems" v-bind:key="item.id" />
    </ul>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import UIListViewItem from '@/components/UI/UIListView.vue'

@Options({
  prop: {
    numberOfItems: Number
  }
})

export default class UIListView extends Vue {
  numberOfItems: number = 0
}
</script>

<style lang="scss" scoped>
  .UIListView {
      list-style: none;
      padding: 0;
  }
</style>