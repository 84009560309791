import IPortrait from "@/store/interfaces/IPortrait";
import { Timestamp } from "firebase/firestore";

export default class Portrait implements IPortrait {
  constructor(dictionary: Record<string, any>) {
    this.url = dictionary["url"] || require("@/assets/icon_overview.png");
    this.last_updated = dictionary["last_updated"] || Timestamp.fromDate(new Date());
  }

  last_updated: Timestamp;
  url: string;

  toDictionary = (): Record<string, any> => {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["last_updated"] = this.last_updated;
    returnedDictionary["url"] = this.url;
    
    return returnedDictionary
  }
}