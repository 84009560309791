export enum AreaShape {
  none = "none",
  point = "point",
  circle = "circle",
  sphere = "sphere",
  rectangle = "rectangle",
  square = "square",
  cube = "cube",
  triangle = "triangle",
  pyramid = "pyramid",
  arc = "arc",
  cone = "cone",
  line = "line",
}