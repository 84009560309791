<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>

<style lang="scss">
:root {
  --textColor: #333;
  --galveonTextColor: #ffe9c7;

  // Button backgrounds
  --btn_norm: linear-gradient(rgb(0, 0, 0), rgb(127, 54, 0));
  --btn_click: linear-gradient(
    rgb(0, 0, 0),
    rgb(32, 18, 0),
    rgb(128, 52, 0),
    rgb(127, 54, 0)
  );
  --btn_dis: linear-gradient(rgb(178, 152, 117), rgb(210, 180, 140));

  --maxWidth: 1400px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", "Helvetica", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;
  line-height: 24px;
  background: #fafafa;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #2c3e50;
}

.vNav {

  a {
    font-weight: bold;
    text-decoration: none;
    padding: 8px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabled {
  opacity: .5;
  cursor: default !important;
  pointer-events: none;
}

#app {
  max-width: var(--maxWidth);
  margin: auto;
}

.deactive {
  opacity: .5;
  cursor: default !important;
  pointer-events: none;
}

.vOption {
  cursor: pointer;
  margin: 0 8px;

  &:hover {
    background: #eeeeee;
  }
}

.color-picker {
  /* Hide the default appearance */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  width: 50px; /* Set the width of the circle */
  height: 50px; /* Set the height of the circle */
  border-radius: 50%; /* Make it a circle */
  outline: none;
}

.color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

//TODO: Make an error component and make a tooltip component
.UITextFieldError {
  color: #f44336;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: left;

  display: block;
}

*[has-error="true"] {
  border-color: #f44336 !important;
}

</style>
