import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.newDice.key === '' ? 'New' : 'Edit'} Dice`,
      hasMenu: _ctx.newDice.key !== '',
      menuItems: ['Delete Dice'],
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onDiceMenuSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UILabel, {
          text: 'Preview',
          justify: 'left'
        }),
        _createVNode(_component_UILabel, {
          text: _ctx.dicePreview,
          justify: 'left',
          type: 'h2'
        }, null, 8, ["text"]),
        _createVNode(_component_UITextField, {
          id: 'txtName',
          placeholder: 'Name',
          textLabel: 'Name',
          modelValue: _ctx.newDice.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newDice.name) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_UITextField, {
          id: 'txtCount',
          placeholder: 'Count',
          textLabel: 'Number of Dice',
          type: 'number',
          modelValue: _ctx.newDice.count,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newDice.count) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_UITextField, {
          id: 'txtSides',
          placeholder: 'Sides',
          textLabel: 'Number of Sides',
          type: 'number',
          modelValue: _ctx.newDice.sides,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newDice.sides) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_UITextField, {
          id: 'txtMod',
          placeholder: 'Modifier',
          textLabel: 'Modifier',
          type: 'number',
          modelValue: _ctx.newDice.modifier,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newDice.modifier) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["title", "hasMenu", "onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 0,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}