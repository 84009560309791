<template>
  <div class="UIDialogViewContainer" @mousedown.self="onCancel">
    <div class="UIDialogView">
      <div class="header" v-if="!hideHeader">
        <UILabel :text="title" :type="'h1'" />
        <div
          v-if="hasMenu"
          class="menu"  
        >
          <img src="@/assets/icon_menu@x2.png"
            style="cursor: pointer; margin-right: 8px;"
            @click="onShowMenu"
          />
        </div>
        <UIMenuView
          v-if="shouldShowMenu"
          :items="menuItems"
          @menu-item-selected-at="onMenuSelect"
          @menu-view-dismiss="onDismissMenu"
        />
      </div>
      <UILabel :text="subTitle" :justify="'left'" />
      <UISeparatorView v-if="!hideHeader" />
      <div 
        style="max-height: 600px; overflow: auto;"
      >
        <slot></slot>
      </div>
      <UISeparatorView v-if="!hideButtons" />
      <div class="footer flex" v-if="!hasDone && !hideButtons">
        <UIButton :id="'btnCancel'" :title="'Cancel'" @click="onCancel" />
        <UIButton :id="'btnSubmit'" :title="submitTitle" :disabled="disableSubmit" @click="onSubmit" />
      </div>
      <div class="footer-single" v-if="hasDone">
        <UIButton :id="'btnDone'" :title="'Continue'" @click="onCancel" />
      </div>
      <div class="footer-single" v-if="removeTitle !== ''">
        <UIButton :id="'btnRemove'" :title="removeTitle" @click="onRemove" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "UIDialogView",
  components: {
    UIButton,
    UILabel,
    UIMenuView,
    UISeparatorView,
  },
  emits: ["ui-dialog-submit", "ui-dialog-menu-select", "ui-dialog-remove", "ui-dialog-cancel"],
  props: {
    title: {
      type: String,
      require: true,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    submitTitle: {
      type: String,
      default: "Save",
    },
    removeTitle: {
      type: String,
      default: "",
    },
    hasDone: {
      type: Boolean,
      default: false,
    },
    hasMenu: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: [],
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shouldShowMenu: false,
    }
  },
  methods: {
    onShowMenu() {
      this.shouldShowMenu = true;
    },
    onDismissMenu() {
      this.shouldShowMenu = false;
    },
    onMenuSelect(index: number) {
      this.$emit("ui-dialog-menu-select", index);
    },
    onCancel() {
      this.$emit("ui-dialog-cancel");
    },
    onSubmit() {
      this.$emit("ui-dialog-submit");
    },
    onRemove() {
      this.$emit("ui-dialog-remove");
    },
  },
});
</script>


<style lang="scss" scoped>
.menu {
  display: flex;
  justify-content: flex-end;
  height: 100%;

  & img {
    width: 44px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.UIDialogViewContainer {
  width: 100%;
  height: 100%;

  position: fixed;
  left: 0;
  top: 0;

  background-color: rgba(0, 0, 0, 0.16);

  display: grid;
  justify-content: center;
  align-items: center;

  z-index: 1;
}

.UIDialogView {
  min-width: 300px;
  max-width: 375px;

  padding: 24px 8px;

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  background-color: #ffffff;

  overflow: auto;
  max-height: 100vh;
  box-sizing: border-box;

  position: relative;
}
</style>