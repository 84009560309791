<template>
  <div>
    <UIOnboardView
      v-if="attribute_tabs.length < 1"
      :title="'You have no Attribute Tabs'"
      :body="'Add different stat groupings to Tabs'"
      :buttonTitle="'Add Tab'"
      @ui-onboard-view="newSection"
    />
    <div v-if="attribute_tabs.length > 0">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        "
      >
        <UILabel :text="'Attributes'" :type="'h1'" />
        <div>
          <div>
            <img
              src="@/assets/icon_menu@x2.png"
              style="cursor: pointer; margin-right: 8px"
              @click="onAttributeMenu"
            />
          </div>
          <UIMenuView
            v-if="shouldShowAttributeMenu"
            :items="[
              'Add Group',
              'Rename Tab',
              this.attribute_tabs.length < 1 || this.hasBlackMarket
                ? 'Add Tab'
                : 'Unlock unlimited tabs',
              'Delete Tab',
            ]"
            @menu-item-selected-at="onMenuSelect"
            @menu-view-dismiss="dismissAttributeMenu"
          />
        </div>
      </div>
      <UITabView
        :id="'tvSections'"
        :tabs="attribute_tabs"
        :selectedTab="selected_section_name"
        @tab-selected="btnSection"
      />
      <div class="section-scroll">
        <UICollectionView :id="'cvSections'">
          <UIOnboardView
            v-if="selected_attribute_groups.length < 1"
            :title="'You have no Attribute Groups'"
            :body="'Group similar attributes such as your stats, proficiencies, or skills'"
            :buttonTitle="'Add Attribute Group'"
            @ui-onboard-view="addAttributeTab"
          />
          <AttributeGroupUICollectionViewCell
            v-for="(attribute_group, index) in selected_attribute_groups"
            :key="index"
            :group="attribute_group"
          />
        </UICollectionView>
      </div>
    </div>

    <UIDialogInputView
      v-if="shouldShowInputDialog"
      @ui-dialog-input-cancel="onCancelInputDialog"
      @ui-dialog-input-submit="onSaveInputDialog"
      :type="inputDialogData.type"
      :title="inputDialogData.name"
      :body="inputDialogData.body"
      :inputPlaceholder="inputDialogData.placeholder"
      :inputTitle="inputDialogData.field"
      :inputValue="inputDialogData.value"
      :buttonTitle="inputDialogData.input"
    />
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      :type="promptDialogData.type"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      @ui-dialog-prompt-submit="onSavePromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
    />
    <UIDialogPurchaseChenShop
      v-if="shouldShowChenDialog"
      @ui-dialog-purchase-cancel="dismissChenDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import UIDialogPurchaseChenShop from "@/components/galveon/dialogs/UIDialogPurchaseChenShop.vue";

import CharacterTabUICollectionViewCell from "@/components/character/CharacterTabUICollectionViewCell.vue";
import AttributeGroupUICollectionViewCell from "@/components/character/AttributeGroupUICollectionViewCell.vue";

import UITabView from "@/components/UI/UITabView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogPromptView from "@/components/UI//dialogs/UIDialogPromptView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import AttributeTab from "@/store/models/AttributeTab";
import AttributeGroup from "@/store/models/AttributeGroup";

export default defineComponent({
  name: "CharacterAttributes",
  components: {
    CharacterTabUICollectionViewCell,
    AttributeGroupUICollectionViewCell,
    UITabView,
    UILabel,
    UIMenuView,
    UIButton,
    UICollectionView,
    UIDialogInputView,
    UIDialogPromptView,
    UIOnboardView,
    UIDialogPurchaseChenShop,
  },
  data() {
    return {
      shouldShowInputDialog: false,
      shouldShowAttributeMenu: false,
      shouldShowPromptDialog: false,
      shouldShowChenDialog: false,
      promptDialogData: {
        type: Object,
        default: {},
      },
      inputDialogData: {
        type: Object,
        default: {},
      },
      attributeDialogData: {
        type: Object,
        default: {},
      },
    };
  },
  computed: {
    ...mapGetters("Account", ["hasBlackMarket"]),
    ...mapGetters("Character", [
      "getSelectedAttributeTabKey",
      "getAttributeTabs",
      "getAttributeGroups",
    ]),
    selected_section_name(): string {
      return this.getSelectedAttributeTabKey;
    },
    attribute_tabs(): any {
      return this.getAttributeTabs;
    },
    attribute_groups(): any {
      return this.getAttributeGroups;
    },
    selected_attribute_groups(): Array<AttributeGroup> {
      let selectedGroups: Array<AttributeGroup> = [];
      this.attribute_groups.forEach((tab: AttributeGroup) => {
        if (tab.key_attribute_tab == this.selected_section_name) {
          selectedGroups.push(tab);
        }
      });
      return selectedGroups;
    },
  },
  methods: {
    ...mapActions("Character", [
      "setCurrentTab",
      "setAttributeTab",
      "setAttributeGroup",
      "deleteTab",
    ]),
    showChenDialog() {
      this.shouldShowChenDialog = true;
    },
    dismissChenDialog() {
      this.shouldShowChenDialog = false;
    },
    btnSection(key: string) {
      this.setCurrentTab(key);
    },
    onMenuSelect(index: number) {
      if (index == 3) {
        this.removeSection();
      } else if (index == 2) {
        if (this.attribute_tabs.length < 1 || this.hasBlackMarket) {
          this.newSection();
        } else {
          this.showChenDialog();
        }
      } else if (index == 1) {
        this.renameSection();
      } else {
        this.addAttributeTab();
      }
    },
    onCancelInputDialog() {
      this.shouldShowInputDialog = false;
    },
    onSaveInputDialog(returnedData: any) {
      let data = returnedData;

      if (data.type == "renameSection" || data.type == "newSection") {
        const newSection: AttributeTab = new AttributeTab(
          {
            name: data.value,
          },
          data.type == "renameSection" ? this.selected_section_name : ""
        );

        this.setAttributeTab(newSection);
      } else if (data.type == "newAttributeGroup") {
        const newTab: AttributeGroup = new AttributeGroup(
          {
            name: data.value,
            key_attribute_tab: this.selected_section_name,
          },
          ""
        );

        this.setAttributeGroup(newTab);
      }
    },
    onDisplayInputDialog(data: any) {
      this.inputDialogData = data;
      this.shouldShowInputDialog = true;
    },
    onDisplayPromptDialog(data: any) {
      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onSavePromptDialog() {
      this.deleteTab();
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    newSection() {
      let data = {
        name: "Add Tab",
        body: "Type the name of a new Tab.",
        field: "Add Tab",
        placeholder: "Add Tab",
        input: "Save",
        type: "newSection",
      };
      this.onDisplayInputDialog(data);
    },
    renameSection() {
      const index = this.attribute_tabs.findIndex(
        (c: AttributeTab) => c.key == this.selected_section_name
      );

      if (index > -1) {
        let name = this.attribute_tabs[index].name;

        let data = {
          name: "Edit Tab Name",
          body: "Type the new name of the Tab.",
          field: "Edit Tab Name",
          placeholder: "Tab Name",
          value: name,
          input: "Save",
          type: "renameSection",
        };
        this.onDisplayInputDialog(data);
      }
    },
    removeSection() {
      let data: any = {
        name: "Remove Tab",
        body: "Are you sure you want to do this? This cannot be undone?",
        submitTitle: "Continue",
        type: "removeCategory",
      };

      this.onDisplayPromptDialog(data);
    },
    addAttributeTab() {
      let data = {
        name: "Add Attribute Group",
        body: "Type the name of a new Attribute Group.",
        field: "Add Attribute Group",
        placeholder: "Add Attribute Group",
        input: "Save",
        type: "newAttributeGroup",
      };
      this.onDisplayInputDialog(data);
    },
    onAttributeMenu() {
      this.shouldShowAttributeMenu = true;
    },
    dismissAttributeMenu() {
      this.shouldShowAttributeMenu = false;
    },
    // startDrag: (evt: any, item: any) => {
    //   evt.dataTransfer.dropEffect = 'move';
    //   evt.dataTransfer.effectAllowed = 'move';
    //   evt.dataTransfer.setData('itemID', item.id);
    // },
    // onDrop (evt: any, list: any) {
    //   const itemID = evt.dataTransfer.getData('itemID');
    //   const item = this.items.find(item => item.id == itemID);
    //   item.list = list;
    // },
  },
});
</script>

<style lang="scss" scoped>
#cvSections,
#cvAttributes {
  grid-template-columns: 1fr;
}

.section-scroll {
  overflow: auto;
  height: calc(100vh - 280px);
}
</style>