import IMainResource from "@/store/interfaces/IMainResource";
import CharacterClass from "./Character";
import { delimit } from "@/utility/global";

export default class MainResource implements IMainResource {
    constructor(dictionary: Record<string, any>) {
        this.blue = dictionary["blue"] ?? (Math.random() * 256) | 0;
        this.count_down = dictionary["should_count_down"] !== undefined ? dictionary["should_count_down"] : false;
        this.current = dictionary["current"] || 6;
        this.green = dictionary["green"] ?? (Math.random() * 256) | 0;
        this.max = dictionary["max"] || 6;
        this.name = dictionary["name"] || "Health";
        if (this.name === "") {
            this.name = "Health";
        }
        
        this.red = dictionary["red"] ?? (Math.random() * 256) | 0;
        this.show_current = dictionary["should_show_current"] !== undefined ? dictionary["should_show_current"] : true;
        this.show_max = dictionary["should_show_max"] !== undefined ? dictionary["should_show_max"] : true;
        this.show_name = dictionary["should_show_name"] !== undefined ? dictionary["should_show_name"] : true;
        this.show_percentage = dictionary["should_show_percentage"] !== undefined ? dictionary["should_show_percentage"] : true;
        this.show_value = dictionary["should_show_value"] !== undefined ? dictionary["should_show_value"] : true;
        this.description = dictionary["description"] || "";
    }
    
    blue: number
    count_down: boolean
    current: number
    green: number
    max: number
    name: string
    red: number
    show_current: boolean
    show_max: boolean
    show_name: boolean
    show_percentage: boolean
    show_value: boolean
    description: string

    getValueWithBonus(character: CharacterClass): [boolean, string, string, number] {
        const [values, result] = character.getBonuses(["vital_maximum_bonus", "vital_maximum_fraction"]);

        let currentInt: number = +this.current;
        let maxInt: number = +this.max;
        
        let bonusValue: number = values[0] ? values[0]! : 0;
        let percentageValue: number = values.length > 1 ? values[1]! : 0;
        
        let currentBonus: number = currentInt + bonusValue;
        let maxBonus: number = maxInt + bonusValue;
        
        let current: number = currentBonus + (currentInt * percentageValue);
        let max: number = maxBonus + (maxInt * percentageValue);
        
        let delimitedCurrent = delimit(current);
        let delimitedMax = delimit(max);
        
        let mainResourcePercentage: number = current / max;
        
        return [result, delimitedCurrent, delimitedMax, mainResourcePercentage];
    }
    
    toDictionary = (): Record<string, any> => {
        let returnedDictionary: Record<string, any> = {};

        returnedDictionary["blue"] = +this.blue;
        returnedDictionary["should_count_down"] = this.count_down;
        returnedDictionary["current"] = +this.current;
        returnedDictionary["green"] = +this.green;
        returnedDictionary["max"] = +this.max;
        returnedDictionary["name"] = this.name;
        returnedDictionary["red"] = +this.red;
        returnedDictionary["should_show_current"] = this.show_current;
        returnedDictionary["should_show_max"] = this.show_max;
        returnedDictionary["should_show_name"] = this.show_name;
        returnedDictionary["should_show_percentage"] = this.show_percentage;
        returnedDictionary["should_show_value"] = this.show_value;
        
        return returnedDictionary
    }
}