import ICost from "@/store/interfaces/ICost";
import CostResource from "./CostResource";
import CharacterClass from "./Character";
import Resource from "./Resource";
import Currency from "./Currency";
import GameSystem from "./GameSystem";

export default class Cost implements ICost {
  constructor(dictionary: Record<string, any>) {
    this.chargeCountMax = isNaN(+dictionary["charge_count_max"]) ? 0 : +dictionary["charge_count_max"];
    this.chargeCountStart = isNaN(+dictionary["charge_count_start"]) ? 0 : +dictionary["charge_count_start"]
    this.chargeUseStart = isNaN(+dictionary["charge_use_start"]) ? 0 : +dictionary["charge_use_start"]

    this.chargeEnableChargeTimeQueuing = dictionary["charge_enable_charge_time_queuing"] ?? false;
    this.chargeIgnoreTimeDelay = dictionary["charge_ignore_time_delay"] ?? false;
    this.chargeRestoreAllChargesOnCooldown = dictionary["charge_restore_all_charges_on_cooldown"] ?? false;

    this.chargeHideCount = dictionary["charge_hide_count"] ?? false;
    this.chargeTimeStart = isNaN(+dictionary["charge_time_start"]) ? 0 : +dictionary["charge_time_start"]
    this.chargeTimeUse = isNaN(+dictionary["charge_time_use"]) ? 0 : +dictionary["charge_time_use"]

    // Cooldown
    this.timeStart = isNaN(+dictionary["time_start"]) ? 0 : +dictionary["time_start"]
    this.timeUse = isNaN(+dictionary["time_use"]) ? 0 : +dictionary["time_use"]

    // Cost
    const resourceKeys = ["vitals", "vital_fractions", "experiences", "experience_fractions", "resources", "resource_fractions", "currencies"];

    const combinedResources: Record<string, CostResource[]> = {};

    for (const key of resourceKeys) {
      const resourceDict = dictionary[key] as Record<string, string>;
      if (resourceDict) {
        const resources: CostResource[] = [];
        for (const resourceKey in resourceDict) {
          const currentResourceValue = resourceDict[resourceKey];
          resources.push(new CostResource(currentResourceValue, resourceKey));
        }

        combinedResources[key] = resources;
      }
    }

    // Assign the combined resources to the appropriate properties
    this.vitals = combinedResources["vitals"] || [];
    this.vitalFractions = combinedResources["vital_fractions"] || [];
    this.experiences = combinedResources["experiences"] || [];
    this.experienceFractions = combinedResources["experience_fractions"] || [];
    this.resources = combinedResources["resources"] || [];
    this.resourceFractions = combinedResources["resource_fractions"] || [];
    this.currencies = combinedResources["currencies"] || [];

  }

  chargeCountMax: number;
  chargeCountStart: number;
  chargeUseStart: number;
  chargeEnableChargeTimeQueuing: boolean;
  chargeIgnoreTimeDelay: boolean;
  chargeRestoreAllChargesOnCooldown: boolean;
  chargeHideCount: boolean;
  chargeTimeStart: number;
  chargeTimeUse: number;
  timeStart: number;
  timeUse: number;
  vitals: CostResource[];
  vitalFractions: CostResource[];
  experiences: CostResource[];
  experienceFractions: CostResource[];
  resources: CostResource[];
  resourceFractions: CostResource[];
  currencies: CostResource[];

  getCostToString(characterClass: CharacterClass | undefined, gameSystem: GameSystem | undefined): string {
    var returnedString: string = "";

    const costResources: CostResource[] = this.resources.concat(this.resourceFractions);
    for (const costResource of costResources) {
      let resource: Resource | undefined = characterClass?.getResourceWithId(costResource.key);
      if (resource !== undefined) {
        returnedString += resource.name + ": " + costResource.value + "\n";
      }
    }

    if (gameSystem !== undefined) {
      const currencyResources: CostResource[] = this.currencies;
      for (const costResource of currencyResources) {
        let resource: Currency | undefined = gameSystem.getCurrencyWithId(costResource.key);
        if (resource !== undefined) {
          returnedString += resource.name + ": " + costResource.value + "\n";
        }
      }
    }
    else if (characterClass !== undefined) {
      const currencyResources: CostResource[] = this.currencies;
      for (const costResource of currencyResources) {
        let resource: Currency | undefined = characterClass.getCurrencyWithId(costResource.key);
        if (resource !== undefined) {
          returnedString += resource.name + ": " + costResource.value + "\n";
        }
      }
    }

    return returnedString;
  }

  toDictionary = (): Record<string, any> => {
    var returnedDictionary: Record<string, any> = {};
    //TODO: Only save those that are not default (like 0)
    returnedDictionary["charge_count_max"] = this.chargeCountMax;
    returnedDictionary["charge_count_start"] = this.chargeCountStart;
    returnedDictionary["charge_use_start"] = this.chargeUseStart;
    returnedDictionary["charge_enable_charge_time_queuing"] = this.chargeEnableChargeTimeQueuing;
    returnedDictionary["charge_ignore_time_delay"] = this.chargeIgnoreTimeDelay;
    returnedDictionary["charge_restore_all_charges_on_cooldown"] = this.chargeRestoreAllChargesOnCooldown;
    returnedDictionary["charge_hide_count"] = this.chargeHideCount;
    returnedDictionary["charge_time_start"] = this.chargeTimeStart;
    returnedDictionary["charge_time_use"] = this.chargeTimeUse;
    returnedDictionary["time_start"] = this.timeStart;
    returnedDictionary["time_use"] = this.timeUse;

    const resourceKeys = ["vitals", "vital_fractions", "experiences", "experience_fractions", "resources", "resource_fractions", "currencies"];

    for (const key of resourceKeys) {
      const resourceArray =
        key === "resources" ? this.resources :
          key === "vitals" ? this.vitals :
            key === "vital_fractions" ? this.vitalFractions :
              key === "experiences" ? this.experiences :
                key === "experience_fractions" ? this.experienceFractions :
                  key === "resource_fractions" ? this.resourceFractions :
                    key === "currencies" ? this.currencies :
                      [];
      let costResourceDict: Record<string, string> = {};
      for (const resourceCost of resourceArray) {
        costResourceDict[resourceCost.key] = resourceCost.value;
      }

      returnedDictionary[key] = costResourceDict;
    }

    return returnedDictionary
  }
}