<template>
  <a :id="id" class="UILink" :href="href" :disabled="!is_enabled">{{
    title
  }}</a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UILink",
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      type: String,
    },
    href: {
      type: String,
      required: true,
    },
    is_enabled: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.UILink {
  display: block;
  font: 400 16px / 24px "Helvetica Neue", "Helvetica", "Open Sans", sans-serif;

  text-decoration: none;
  outline: 0;
}
</style>