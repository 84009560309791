import IExperience from "@/store/interfaces/IExperience";
import Veterancy from "@/store/models/Veterancy";
import CharacterClass from "./Character";
import { delimit } from "@/utility/global";

export default class Experience implements IExperience {
    constructor(dictionary: Record<string, any>) {
        this.blue = dictionary["blue"] ?? (Math.random() * 256) | 0;
        this.count_down = dictionary["should_count_down"] !== undefined ? dictionary["should_count_down"] : false;
        this.current = dictionary["current"] ?? 0;
        this.green = dictionary["green"] || (Math.random() * 256) | 0;
        this.max = dictionary["max"] ?? 0;
        this.name = dictionary["name"] || "Experience";
        if (this.name === "") {
            this.name = "Experience";
        }

        this.red = dictionary["red"] ?? (Math.random() * 256) | 0;
        this.show_current = dictionary["should_show_current"] !== undefined ? dictionary["should_show_current"] : true;
        this.show_max = dictionary["should_show_max"] !== undefined ? dictionary["should_show_max"] : true;
        this.show_name = dictionary["should_show_name"] !== undefined ? dictionary["should_show_name"] : true;
        this.show_percentage = dictionary["should_show_percentage"] !== undefined ? dictionary["should_show_percentage"] : true;
        this.show_value = dictionary["should_show_value"] !== undefined ? dictionary["should_show_value"] : true;

        this.veterancies = [];
        const veterenciesValue = dictionary["veterancies"];
        if (veterenciesValue) {
            for (const currencyKey in veterenciesValue) {
                const currencyValue = veterenciesValue[currencyKey];
                this.veterancies.push(new Veterancy(currencyValue));
            }
        }
    }

    blue: number;
    count_down: boolean;
    current: number;
    green: number;
    max: number;
    name: string;
    red: number;
    show_current: boolean;
    show_max: boolean;
    show_percentage: boolean;
    show_value: boolean;
    show_name: boolean;
    veterancies: Veterancy[] = [];

    getValueWithBonus(character: CharacterClass): [boolean, string, string, number] {
        const [values, result] = character.getBonuses(["experience_maximum_bonus", "experience_maximum_fraction"]);

        let currentInt: number = +this.current;
        let maxInt: number = +this.max;
        
        let bonusValue: number = values[0] ? values[0]! : 0;
        let percentageValue: number = values.length > 1 ? values[1]! : 0;
        
        let currentBonus: number = currentInt + bonusValue;
        let maxBonus: number = maxInt + bonusValue;
        
        let current: number = currentBonus + (currentInt * percentageValue);
        let max: number = maxBonus + (maxInt * percentageValue);

        let delimitedCurrent = delimit(current);
        let delimitedMax = delimit(max);
        
        let mainResourcePercentage: number = current / max;
        
        return [result, delimitedCurrent, delimitedMax, mainResourcePercentage];
    }

    toDictionary = (): Record<string, any> => {
        let returnedDictionary: Record<string, any> = {};

        returnedDictionary["blue"] = +this.blue;
        returnedDictionary["should_count_down"] = this.count_down;
        returnedDictionary["current"] = +this.current;
        returnedDictionary["green"] = +this.green;
        returnedDictionary["max"] = +this.max;
        returnedDictionary["name"] = this.name;
        returnedDictionary["red"] = +this.red;
        returnedDictionary["should_show_current"] = this.show_current;
        returnedDictionary["should_show_max"] = this.show_max;
        returnedDictionary["should_show_name"] = this.show_name;
        returnedDictionary["should_show_percentage"] = this.show_percentage;
        returnedDictionary["should_show_value"] = this.show_value;

        var veterenciesValue: Record<string, any>[] = [];
        for (const veterancy of this.veterancies) {
            veterenciesValue.push(veterancy.toDictionary());
        }
        returnedDictionary["veterancies"] = veterenciesValue;

        return returnedDictionary
    }
}