import IItem from "@/store/interfaces/IItem";
import ItemBase from "./AssetBases/ItemBase";

export default class Item extends ItemBase implements IItem {
    constructor(dictionary: Record<string, any>, key: string) {
        super(dictionary, key);

        this.key_bag = dictionary["key_bag"] || "";
        this.count = dictionary["count"] ?? 1;
    }

    count: number;
    key_bag: string;

    toDictionary = (): Record<string, any> => {
        var returnedDictionary: Record<string, any> = super.toDictionary();

        returnedDictionary["key_bag"] = this.key_bag;
        returnedDictionary["count"] = +this.count;

        return returnedDictionary
    }
}