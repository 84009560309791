import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetView = _resolveComponent("AssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: 'Compendium',
    hasDone: true,
    onUiDialogSubmit: _ctx.onCancel,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AssetView, {
        title: _ctx.title,
        queryId: _ctx.query,
        id: _ctx.id,
        isCommunity: _ctx.isCommunity,
        selectOnly: true
      }, null, 8, ["title", "queryId", "id", "isCommunity"])
    ]),
    _: 1
  }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]))
}