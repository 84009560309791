<template>
  <div 
    :id="attribute.key"
    class="AttributeView"
  >
    <div>{{ attribute.name }}</div>
    <div>
      {{ value }}
      <span v-if="show_modifier && modifier">({{ modifier }})</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from 'vuex';

import { convertKeysLocalFormula } from "@/utility/tooltip";

import Attribute from "@/store/models/Attribute";
import FormulaItem from "@/store/models/FormulaItem";

export default defineComponent({
  name: "AttributeView",
  props: {
    attribute: {
      type: Object as () => Attribute,
      default: {},
    },
  },
  computed: {
    ...mapGetters('Character', [
      'getBonus',
      'getMainVital',
      'getLevel'
    ]),
    show_modifier(): boolean {
      return this.attribute.show_modifier;
    },
    value(): string {
      let bonus = this.getBonus(this.attribute.key);
      if (!isNaN(+this.attribute.value) && this.attribute.value !== '') {
        return `${+this.attribute.value + +bonus}`;
      }
      return this.attribute.value;
    },
    modifier(): string | undefined {
      let formula = this.attribute.modifier_formula;
      let formula_table = this.attribute.table_modifier_formula;

      if (formula_table.length > 0) {
        const forumlaValue = formula_table.find((f: FormulaItem) => f.value === this.attribute.value);
        if (forumlaValue) {
          formula = forumlaValue?.result;
        }
      }
      let tests = {
        level: this.getLevel,
        main_resource: this.getMainVital.max,
        value: +this.attribute.value,
      };
      return +this.attribute.value ? convertKeysLocalFormula(formula, tests)[0] : ""; //0 being the first return type which is the formula
    }
  }
});
</script>

<style lang="scss" scoped>
.AttributeView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;

  min-height: 44px;

  cursor: pointer;

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(odd) {
    background: #ffffff;
  }
}
</style>