import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "character" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Account = _resolveComponent("Account")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_CharacterView = _resolveComponent("CharacterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Account),
    (!_ctx.currentCharacterKey)
      ? (_openBlock(), _createBlock(_component_UIOnboardView, {
          key: 0,
          title: 'To get started, add a character',
          buttonTitle: 'Add Character',
          onUiOnboardView: _ctx.addCharacter
        }, null, 8, ["onUiOnboardView"]))
      : _createCommentVNode("", true),
    (_ctx.currentCharacterKey)
      ? (_openBlock(), _createBlock(_component_CharacterView, {
          key: 1,
          character: _ctx.character
        }, null, 8, ["character"]))
      : _createCommentVNode("", true)
  ]))
}