import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { User, getAuth } from 'firebase/auth'

import store from '@/store'

import Home from '../views/Home.vue'
import Login from '@/views/Login.vue'
import Character from '@/views/Character.vue'
import Signup from '@/views/Signup.vue'
import Forgot_Password from '@/views/Forgot_Password.vue'
import Email_Verification from '@/views/Email_Verification.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/galveon/gamesystem',
    name: "GameSystem",
    component: () => import(/* webpackChunkName: "gamesystem" */ '../views/GameSystem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/character',
    name: 'Character',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Character,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/create/character',
    name: 'Create Character',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "characters" */ '@/views/CreateCharacter.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/characters',
    name: 'Characters',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "characters" */ '@/views/Characters.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/compendium',
    name: 'Compendium',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "compendium" */ '@/views/Compendium.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/multiplayer',
    name: 'Multiplayer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "multiplayer" */ '@/views/Multiplayer.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from) => {
      await store.dispatch('Campaigns/fetchCampaigns');
      return true;
    },
  },
  {
    path: '/galveon/multiplayer/campaign',
    name: 'Campaign',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "campaign" */ '@/views/Campaign.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from) => {
      await store.dispatch('Campaigns/fetchCampaigns');
      return true;
    },
  },
  {
    path: '/galveon/multiplayer/campaign/party',
    name: 'Party',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "party" */ '@/views/Party.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/multiplayer/campaign/compendium',
    name: 'Campaign Compendium',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "compendium_campaign" */ '@/views/multiplayer/campaign/Compendium.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/compendium/category',
    name: 'Compendium Category',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "compendium_category" */ '@/views/Compendium_Category.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon',
    name: 'Galveon',
    component: () => import("@/views/Galveon.vue"),
  },
  {
    path: '/galveon/purchases',
    name: 'Purchases',
    component: () => import(/* webpackChunkName: "purchases" */ '@/views/galveon/Purchases.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chenshop/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "chenshop/success" */ '@/views/galveon/Success.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/galveon/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "chenshop/success" */ '@/views/Admin.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from) => {
      await store.dispatch('Admin/fetchPendingPublishing');
      return true;
    }
  },
  {
    path: '/galveon/forums',
    name: 'Forums',
    component: () => import(/* webpackChunkName: "chenshop/success" */ '@/views/Forums.vue'),
  },
  {
    path: '/galveon/forums/forum',
    name: 'Forum',
    component: () => import(/* webpackChunkName: "chenshop/success" */ '@/views/Forum.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/login/forgot_password',
    name: 'Forgot_Password',
    component: Forgot_Password
  },
  {
    path: '/email_verification',
    name: 'Email_Verification',
    component: Email_Verification,
  }// UNCOMMENT CODE / C/P IF YOU NEED A NEW VIEW THAT's PROTECTED BY AUTHENTICATION,
  //{
  //path: '/name',
  //name: 'Name',
  //component: () => import(/* webpackChunkName: "about" */ //'../views/About.vue'),
  //meta: {
  //  requiresAuth: true
  //}
  //}
]

const router = createRouter({
  // process.env.BASE_URL---V
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth: boolean = to.matched.some(x => x.meta.requiresAuth)
  const user: User | null = getAuth().currentUser
  const emailVerified: boolean | undefined = user?.emailVerified

  if (requiresAuth && !user) {
    next('/login')
  } else if (requiresAuth && !emailVerified) {
    next('/email_verification')
  } else {
    next();
  }
})

export default router;
