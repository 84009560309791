<template>
  <UIDialogView
    :title="'Modifications'"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <ModificationView
      :modification="modification"
      @modifications-update="onModificationUpdate"
    />
  </UIDialogView>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import Modification from "@/store/models/Modification";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import ModificationView from "@/components/character/ModificationView.vue";

export default defineComponent({
  name: "UIDialogModificationView",
  components: {
    UIDialogView,
    ModificationView,
  },
  props: {
    modification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      returnedData: {}
    }
  },
  emits: ["ui-dialog-modification-submit", "ui-dialog-modification-cancel"],
  methods: {
    onSubmit() {
      this.$emit("ui-dialog-modification-submit", this.returnedData);
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-modification-cancel");
    },
    onModificationUpdate(modifications: any) {
      this.returnedData = modifications;
    },
  },
  mounted() {
    this.returnedData = this.modification;
  }
});
</script>