<template>
  <input
    type="checkbox"
    class="UISwitchView"
    :checked="checked"
    @click.stop="onToggle($event.target.checked)"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UISwitchView",
  props: {
    checked: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  emits: ["ui-switch-view-toggle"],
  methods: {
    onToggle(toggle: Boolean) {
      this.$emit("ui-switch-view-toggle", toggle);
    },
  },
});
</script>

<style lang="scss" scoped>
$green: #4cd964;
$blue: #3b5998;
$pink: #c4656f;
$red: #fa3311;
$color-active: $green;

.UISwitchView {
  appearance: none;
  outline: 0;
  border: none;
  cursor: pointer;
  position: relative;
  display: block;
  width: 51px;
  height: 31px;
  border-radius: 15px;
  background-color: #ddd;
  transition: 0.4s ease;
  z-index: 1;

  &:before {
    content: "";
    z-index: 100;
    display: block;
    width: 47px;
    height: 27px;
    position: absolute;
    right: 2px;
    top: 2px;
    border-radius: 15px;
    background-color: white;
    transition: 0.4s ease;
  }

  &:after {
    content: "";
    z-index: 200;
    position: absolute;
    left: 2px;
    top: 2px;
    width: 27px;
    height: 27px;
    background-color: white;
    border-radius: 29px;
    box-shadow: 0px 0px 4px rgba(141, 103, 103, 0.25),
      0px 4px 5px rgba(0, 0, 0, 0.15);
    transition: 0.4s ease;
  }

  &:checked {
    background-color: $color-active;

    &.default {
      background-color: $color-active;
    }
    &.green {
      background-color: $green;
    }
    &.blue {
      background-color: $blue;
    }
    &.pink {
      background-color: $pink;
    }
    &.red {
      background-color: $red;
    }

    &:before {
      width: 12px;
      height: 0px;
      top: 15px;
      right: 15px;
    }

    &:after {
      left: 22px;
    }

    &:active:after {
      margin-left: -5px;
    }
  }

  &:active:after {
    padding-left: 5px;
  }

  &:disabled {
    opacity: .4;
  }
}

// Modernizr dependent for applying these rules only on non touch interfaces
html.no-touch .ui-switch:hover:after {
  padding-left: 5px;
}
html.no-touch .ui-switch:checked:hover:after {
  margin-left: -5px;
}
</style>