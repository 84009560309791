<template>
  <div class="character">
    <div class="sheet-grid">
      <div class="overview">
        <CharacterOverview :character="character" />
      </div>

      <div>
        <UICollectionView :id="'cvTabs'" :hasItems="CharacterTabs.length > 0">
          <CharacterTabUICollectionViewCell
            v-for="(tab, index) in CharacterTabs"
            :key="index"
            :textLabel="tab"
            :isSelected="activeTab === index"
            :type="'tab'"
            @onToggle="onToggle(index)"
          />
        </UICollectionView>
        <UISeparatorView />
        <!-- Overview -->
        <CharacterOverview v-if="isActiveTab(0)" />
        <!-- Attributes -->
        <CharacterAttributes v-if="isActiveTab(1)" />
        <!-- Spellbook -->
        <CharacterSpells v-if="isActiveTab(2)" />
        <!-- Inventory -->
        <CharacterInventory v-if="isActiveTab(3)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue"; // @ is an alias to /src
import CharacterTabUICollectionViewCell from "@/components/character/CharacterTabUICollectionViewCell.vue";

import CharacterOverview from "@/components/character/CharacterOverview.vue";
import CharacterAttributes from "@/components/character/CharacterAttributes.vue";
import CharacterSpells from "@/components/character/CharacterSpells.vue";
import CharacterInventory from "@/components/character/CharacterInventory.vue";

import CharacterClass from "@/store/models/Character";

export default defineComponent({
  name: "CharacterView",
  components: {
    UISeparatorView,
    UICollectionView,
    CharacterTabUICollectionViewCell,
    CharacterOverview,
    CharacterAttributes,
    CharacterSpells,
    CharacterInventory,
  },
  props: {
    character: {
      type: Object as () => CharacterClass,
      require: true,
    },
  },
  data() {
    return {
      activeTab: 1,
      CharacterTabs: ["Overview", "Attributes", "Spells", "Items"],
    };
  },
  methods: {
    onToggle(index: number) {
      if (this.activeTab !== index) {
        this.activeTab = index;
      }
    },
    isActiveTab(index: number): boolean {
      return this.activeTab === index;
    },
  },
  mounted() {
    this.onToggle(1);
  },
});
</script>

<style lang="scss" scoped>
#cvTabs {
  grid-template-columns: 1fr 1fr 1fr;

  & > :first-child {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    & > :first-child {
      display: block;
    }
  }
}

.sheet-grid {
  display: grid;
  grid-template-columns: 325px 1fr;
  gap: 24px;

  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.overview {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
</style>