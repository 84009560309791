<template>
  <div class="AssetView">
    <UILabel :text="`${title}s`" :type="'h2'" />
    <UITabView
      :id="'tvTabs'"
      :tabs="tabs"
      :selectedTab="selectedTab"
      @tab-selected="btnSelectTab"
    />
    <div class="vSearch">
      <UITextField
        :id="'txtSearch'"
        :textLabel="'Search'"
        v-model="searchText"
        :placeholder="'Search'"
        @keyup.enter="btnSearch"
      />
    </div>
    <div class="vAssets">
      <div
        v-for="(asset, index) in getWorkshopAssets"
        :key="asset.key"
        class="asset"
        @click="handleAssetSelection(asset, index)"
      >
        <div>
          <UILabel :text="assetLabel(asset)" :justify="'left'" :type="isBold(asset)" />
          <UILabel
            :text="asset.game_system_name"
            :font="12"
            :justify="'left'"
          />
          <UILabel :text="labelAuthor(asset)" :font="8" :justify="'left'" />
        </div>
        <div>
          <img
            v-if="
              (!isIn(asset) &&
                !isCommunity &&
                character?.type !== 'campaign') ||
              (character?.type === 'campaign' &&
                selectedTab === 'campaign' &&
                !isIn(asset))
            "
            :id="'btnAdd'"
            src="@/assets/icon_plus@x1.png"
            title="'Add'"
            @click.stop="btnAdd(asset, asset.key)"
          />
          <UILabel v-if="isIn(asset) && !isCommunity" :text="'Already added'" />
        </div>
      </div>
    </div>
    <div class="vFooter">
      <UIMenuView
        v-if="shouldShowEffectChooseDialog"
        :items="['Apply Behavior', 'Modify Vital', 'Remove Behavior']"
        @menu-item-selected-at="onMenuSelect"
        @menu-view-dismiss="dissmissMenu"
      />
      <UIButton
        :id="'btnCreate'"
        :title="`Create new ${title}`"
        @click="btnAddAsset"
      />
    </div>
  </div>
  <div>
    <UIDialogSpellView
      v-if="shouldShowSpellDialog"
      :spell="asset"
      :isWorkshop="true"
      :isNew="isNew"
      :readOnly="selectedTab === 'community'"
      @ui-dialog-spell-submit="onSubmitSpellDialog"
      @ui-dialog-spell-cancel="onCancelSpellDialog"
    />
    <UIDialogAssetQuickView
      v-if="shouldShowQuickViewDialog"
      :quickView="quickViewDialogData"
      :showActions="true"
      :submitTitle="quickViewSubmitTitle"
      :actionsDisabled="true"
      @ui-dialog-quick-view-submit="onSaveQuickViewDialog"
      @ui-dialog-quick-view-cancel="onCancelQuickViewDialog"
      @ui-dialog-quick-view-menu="onMenuQuickViewDialog"
    />
    <!-- TODO: Replace Community Views with QuickViews like the app. Should not need the same view -->
    <UIDialogGameSystemView
      v-if="shouldShowGameSystemDialog"
      :game_system="asset"
      :isWorkshop="true"
      :isNew="isNew"
      :readOnly="selectedTab === 'community'"
      @ui-dialog-game-system-submit="onSubmitGameSystemDialog"
      @ui-dialog-game-system-cancel="onCancelGameSystemDialog"
    />
    <UIDialogItemView
      v-if="shouldShowItemDialog"
      :item="asset"
      :isWorkshop="true"
      :isNew="isNew"
      :readOnly="selectedTab === 'community'"
      @ui-dialog-item-submit="onSubmitItemDialog"
      @ui-dialog-item-cancel="onCancelItemDialog"
    />
    <UIDialogResourceView
      v-if="shouldShowResourceDialog"
      :resource="asset"
      :isWorkshop="true"
      :isNew="isNew"
      :readOnly="selectedTab === 'community'"
      @ui-dialog-resource-submit="onSubmitResourceDialog"
      @ui-dialog-resource-cancel="onCancelResourceDialog"
    />
    <UIDialogAttributeView
      v-if="shouldShowAttributeDialog"
      :attribute="asset"
      :isWorkshop="true"
      :isNew="isNew"
      :readOnly="selectedTab === 'community'"
      @ui-dialog-attribute-submit="onSubmitAttributeDialog"
      @ui-dialog-attribute-cancel="onCancelAttributeDialog"
    />
    <UIDialogModifyVitalView
      v-if="shouldShowModifyVitalDialog"
      :effect="asset"
      :isWorkshop="true"
      :isNew="isNew"
      :readOnly="selectedTab === 'community'"
      @ui-dialog-effect-submit="onSubmitModifyVitalDialog"
      @ui-dialog-effect-cancel="onCancelModifyVitalDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITabView from "@/components/UI/UITabView.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";

import UIDialogSpellView from "@/components/character/dialogs/UIDialogSpellView.vue";
import UIDialogItemView from "@/components/character/dialogs/UIDialogItemView.vue";
import UIDialogResourceView from "@/components/character/dialogs/UIDialogResourceView.vue";
import UIDialogAttributeView from "@/components/character/dialogs/UIDialogAttributeView.vue";
import UIDialogGameSystemView from "@/components/character/dialogs/UIDialogGameSystemView.vue";
import UIDialogModifyVitalView from "../character/dialogs/UIDialogModifyVitalView.vue";
import UIDialogAssetQuickView from "@/components/character/dialogs/UIDialogAssetQuickView.vue";

import {
  getCommunityAssets,
  newKey,
  newFirestoreKey,
} from "@/utility/database";
import Item from "@/store/models/Item";
import Resource from "@/store/models/Resource";
import Spell from "@/store/models/Spell";
import Attribute from "@/store/models/Attribute";
import GameSystem from "@/store/models/GameSystem";
import ModifyVital from "@/store/models/Effects/ModifyVital";
import QuickView from "@/store/models/QuickView";

import AttributeBase from "@/store/models/AssetBases/AttributeBase";
import SpellBase from "@/store/models/AssetBases/SpellBase";
import ResourceBase from "@/store/models/AssetBases/ResourceBase";
import ItemBase from "@/store/models/AssetBases/ItemBase";
import CompendiumAssetBase from "@/store/models/Compendium/CompendiumAssetBase";
import { AssetType } from "@/store/models/Compendium/AssetType";
import { replaceSpacesWithUnderscores } from "@/utility/global";
import EffectBase from "@/store/models/AssetBases/EffectBase";
import { EffectType } from "@/store/interfaces/types/EffectType";

export default defineComponent({
  name: "AssetView",
  components: {
    UIButton,
    UILabel,
    UITextField,
    UITabView,
    UIMenuView,
    UIDialogSpellView,
    UIDialogItemView,
    UIDialogResourceView,
    UIDialogAttributeView,
    UIDialogGameSystemView,
    UIDialogModifyVitalView,
    UIDialogAssetQuickView,
  },
  emits: ["ui-dialog-asset", "ui-dialog-asset-cancel"],
  data() {
    return {
      shouldShowSpellDialog: false,
      shouldShowItemDialog: false,
      shouldShowResourceDialog: false,
      shouldShowAttributeDialog: false,
      shouldShowGameSystemDialog: false,
      shouldShowModifyVitalDialog: false,
      shouldShowQuickViewDialog: false,
      shouldShowEffectChooseDialog: false,
      tabs: [
        {
          name: `Personal`,
          key: "asset",
          disabled: false,
        },
      ],
      selectedTab: "asset",
      asset: {} as CompendiumAssetBase,
      hasBlackMarketData: false,
      hasCampaignData: false,
      blackMarket: [],
      campaignData: [] as Array<any>,
      dataSet: [] as Array<any>,
      searchText: "",
      isNew: false,
      query: "",
      quickViewDialogData: {} as QuickView,
      quickViewSubmitTitle: "",
    };
  },
  props: {
    title: String,
    queryId: String,
    id: {
      type: String,
      default: "",
      required: true,
    },
    isCommunity: {
      type: Boolean,
      default: false,
    },
    isCampaign: {
      type: Boolean,
      default: false,
    },
    gameSystemKey: {
      type: String,
      default: null,
    },
    selectOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("Account", [
      "setWorkshopAssetSpell",
      "setWorkshopAssetAttribute",
      "setWorkshopAssetResource",
      "setWorkshopAssetItem",
      "setWorkshopAssetGameSystem",
      "setWorkshopAssetModifyVital",
    ]),
    ...mapActions("Campaigns", [
      "addCampaignAttribute",
      "fetchCampaignAsset",
      "setAssetAt",
    ]),
    ...mapActions("Character", [
      "addAttribute",
      "addItem",
      "setWorkshopItem",
      "setWorkshopAttribute",
      "addSpell",
      "setWorkshopSpell",
      "addResource",
      "setWorkshopResource",
    ]),
    ...mapActions("Dialog", [
      "closeDialog",
    ]),
    onMenuSelect(index: number) {
      if (index == 0) {
      } else if (index == 1) {
        this.asset = new ModifyVital({}, newFirestoreKey());
        this.shouldShowModifyVitalDialog = true;
      } else if (index == 2) {
      }
    },
    dissmissMenu() {
      this.shouldShowEffectChooseDialog = false;
    },
    btnSearch() {
      if (this.selectedTab === "community") {
        this.loadSortedBlackMarket();
      } else if (this.selectedTab === "campaign") {
        this.loadSortedCampaign();
      } else {
        this.dataSet = this.loadSortedCompendium;
      }
      if (this.searchText === "") {
        this.btnSelectTab(this.selectedTab);
        return;
      }
      this.dataSet = this.dataSet.filter((asset: any) =>
        asset.name.includes(this.searchText)
      );
    },
    btnSelectTab(tab: string) {
      if (!this.blackMarket) {
        return;
      }
      this.selectedTab = tab;
      if (this.selectedTab === "community") {
        if (!this.hasBlackMarketData) {
          let assetType: AssetType = this.query!.toLowerCase() as AssetType;
          getCommunityAssets(assetType, "game_system", this.gameSystemKey)
            .then((result_data) => {
              this.dataSet = result_data;
              this.blackMarket = result_data;
              this.hasBlackMarketData = result_data !== null;
            })
            .catch((error) => {
              this.dataSet = [];
              this.blackMarket = [];
              this.hasBlackMarketData = true;
            });
        } else {
          this.loadSortedBlackMarket();
        }
      } else if (this.selectedTab === "asset") {
        this.dataSet = this.loadSortedCompendium;
      } else if (this.selectedTab === "campaign") {
        this.loadSortedCampaign();
      } else {
        this.dataSet = [];
      }
    },
    btnAddAsset() {
      this.onDisplayAssetDialog();
      if (this.query?.toLowerCase() == "spell") {
        this.asset = new Spell(
          { campaign_id: this.character?.campaign_id },
          this.character?.campaign_id !== null ? newFirestoreKey() : newKey()
        );
      } else if (this.query?.toLowerCase() == "item") {
        this.asset = new Item(
          { campaign_id: this.character?.campaign_id },
          this.character?.campaign_id !== null ? newFirestoreKey() : newKey()
        );
      } else if (this.query?.toLowerCase() == "attribute") {
        this.asset = new Attribute(
          { campaign_id: this.character?.campaign_id },
          this.character?.campaign_id !== null ? newFirestoreKey() : newKey()
        );
      } else if (this.query?.toLowerCase() == "resource") {
        this.asset = new Resource(
          { campaign_id: this.character?.campaign_id },
          this.character?.campaign_id !== null ? newFirestoreKey() : newKey()
        );
      } else if (this.query?.toLowerCase() == "game_system") {
        this.asset = new GameSystem({}, newFirestoreKey());
      } else if (this.query?.toLowerCase() == "race") {
        // this.asset = new GameSystem({}, newFirestoreKey());
      } else if (this.query?.toLowerCase() == "class") {
        // this.asset = new GameSystem({}, newFirestoreKey());
      }

      this.isNew = true;
    },
    onDisplayAssetDialog() {
      if (this.selectedTab == "community") {
        this.shouldShowQuickViewDialog = true;
      } else {
        if (this.query?.toLowerCase() == "spell") {
          this.shouldShowSpellDialog = true;
        } else if (this.query?.toLowerCase() == "item") {
          this.shouldShowItemDialog = true;
        } else if (this.query?.toLowerCase() == "attribute") {
          this.shouldShowAttributeDialog = true;
        } else if (this.query?.toLowerCase() == "resource") {
          this.shouldShowResourceDialog = true;
        } else if (this.query?.toLowerCase() == "game_system") {
          this.shouldShowGameSystemDialog = true;
        } else if (this.query?.toLowerCase() == "effect") {
          const effectAsset: EffectBase = this.asset as EffectBase;
          switch (effectAsset.effect_type) {
            case EffectType.modify_vital:
              this.shouldShowModifyVitalDialog = true;
              break;
            default:
              this.shouldShowEffectChooseDialog = true;
          }
        }
      }
    },
    onCancel() {
      this.$emit("ui-dialog-asset-cancel");
    },
    onSubmitSpellDialog(data: any) {
      const asset: Spell = data.spell;
      const isNew = data.isNew;
      if (this.selectedTab === "asset") {
        this.setWorkshopSpell({
          spell: asset,
          shouldUpdateCharacter: !isNew,
          fromWorkshop: true,
        });
      } else if (this.selectedTab === "campaign") {
        const attr: Spell = new Spell(
          { ...asset.toDictionary(), campaign_id: this.getCampaignId },
          asset.key
        );
        const val = {
          path: ["campaigns", this.getCampaignId, "spells"],
          data: attr.toDictionary(),
        };
        this.setAssetAt(val).then((newData: any) => {
          if (newData) {
            this.campaignData.push(attr);
            this.loadSortedCampaign();
          }
        });
      }

      this.onCancelSpellDialog();
    },
    onCancelSpellDialog() {
      this.shouldShowSpellDialog = false;
    },
    onSubmitItemDialog(data: any) {
      const asset = data.item;
      const isNew = data.isNew;
      if (this.selectedTab === "asset") {
        this.setWorkshopItem({
          item: asset,
          shouldUpdateCharacter: !isNew,
          fromWorkshop: true,
        });
      } else if (this.selectedTab === "campaign") {
        const attr: Item = new Item(
          { ...asset.toDictionary(), campaign_id: this.getCampaignId },
          asset.key
        );
        const val = {
          path: ["campaigns", this.getCampaignId, "items"],
          data: attr.toDictionary(),
        };
        this.setAssetAt(val).then((newData: any) => {
          if (newData) {
            this.campaignData.push(attr);
            this.loadSortedCampaign();
          }
        });
      }

      this.onCancelItemDialog();
    },
    onCancelItemDialog() {
      this.shouldShowItemDialog = false;
    },
    onSubmitResourceDialog(data: any) {
      const asset = data.resource;
      const isNew = data.isNew;
      if (this.selectedTab === "asset") {
        this.setWorkshopResource({
          resource: asset,
          shouldUpdateCharacter: !isNew,
          fromWorkshop: true,
        });
      } else if (this.selectedTab === "campaign") {
        const attr: Resource = new Resource(
          { ...asset.toDictionary(), campaign_id: this.getCampaignId },
          asset.key
        );
        const val = {
          path: ["campaigns", this.getCampaignId, "resources"],
          data: attr.toDictionary(),
        };
        this.setAssetAt(val).then((newData: any) => {
          if (newData) {
            this.campaignData.push(attr);
            this.loadSortedCampaign();
          }
        });
      }

      this.onCancelResourceDialog();
    },
    onCancelResourceDialog() {
      this.shouldShowResourceDialog = false;
    },
    onSubmitAttributeDialog(data: any) {
      const asset: Attribute = data.attribute;
      const isNew: boolean = data.isNew;
      if (this.selectedTab === "asset") {
        this.setWorkshopAttribute({
          attribute: asset,
          shouldUpdateCharacter: !isNew,
          fromWorkshop: true,
        });
      } else if (this.selectedTab === "campaign") {
        const attr: Attribute = new Attribute(
          { ...asset.toDictionary(), campaign_id: this.getCampaignId },
          asset.key
        );
        const val = {
          path: ["campaigns", this.getCampaignId, "attributes"],
          data: attr.toDictionary(),
        };
        this.setAssetAt(val).then((newData: any) => {
          if (newData) {
            this.campaignData.push(attr);
            this.loadSortedCampaign();
          }
        });
      }
    },
    onCancelAttributeDialog() {
      this.shouldShowAttributeDialog = false;
    },
    onSubmitGameSystemDialog(data: any) {
      const { game_system, isNew } = data;
      this.setWorkshopAssetGameSystem(game_system).then(() => {
        this.onCancelGameSystemDialog();
      });
    },
    onCancelGameSystemDialog() {
      this.shouldShowGameSystemDialog = false;
    },
    onSubmitModifyVitalDialog(data: any) {
      const { effect, isNew } = data;
      this.setWorkshopAssetModifyVital(effect).then(() => {
        this.onCancelModifyVitalDialog();
      });
    },
    onCancelModifyVitalDialog() {
      this.shouldShowModifyVitalDialog = false;
    },
    onCancelQuickViewDialog() {
      this.shouldShowQuickViewDialog = false;
    },
    onSaveQuickViewDialog(quickViewData: QuickView) {
      let spell: Spell = this.dialogData.asset as Spell;
      let effectCompendium: EffectBase | undefined = this.dataSet.find((e: EffectBase) => e.key === quickViewData.key);
      if (effectCompendium === undefined) return;

      if (this.quickViewSubmitTitle === "Add") {
        spell.effects.push(effectCompendium);
      }
      else if (this.quickViewSubmitTitle === "Replace") {
        spell.effects[this.dialogData.assetIndex] = effectCompendium;
      }
      else if (this.quickViewSubmitTitle === "Remove") {
        spell.effects.splice(this.dialogData.assetIndex, 1);
      }
      const data = {
        spell: spell,
        shouldUpdateCharacter: true,
      };
      
      this.onCancelQuickViewDialog();
      this.closeDialog();
    },
    onDisplayQuickViewDialog(data: any, index: number) {
      //TODO: This only allows Effects to be selected so maybe make it more general
      this.quickViewDialogData = QuickView.quickViewEffect(data);
      let spell: Spell = this.dialogData.asset as Spell;
      let selectedAsset: EffectBase | undefined = spell.effects[this.dialogData.assetIndex];
      let effectCompendium: EffectBase = this.dataSet[index] as EffectBase;

      if (selectedAsset === undefined) {
        this.quickViewSubmitTitle = "Add";
      }
      else if (selectedAsset.key !== effectCompendium.key) {
        this.quickViewSubmitTitle = "Replace";
      }
      else if (selectedAsset.key === effectCompendium.key) {
        this.quickViewSubmitTitle = "Remove";
      }
      
      this.shouldShowQuickViewDialog = true;
    },
    onMenuQuickViewDialog(quickViewData: QuickView) {
      this.onCancelQuickViewDialog();
      const key = quickViewData.key;
      switch(quickViewData.type) {
        case AssetType.effect:
          const index = this.workshop.effect.findIndex((e: EffectBase) => e.key === key);
          
          if (index > -1) {
            const effect: EffectBase = this.workshop.effect[index];
            switch (effect.effect_type) {
              case EffectType.modify_vital:
                this.shouldShowModifyVitalDialog = true;
                break;
              default:
                this.shouldShowEffectChooseDialog = true;
            }
          }
          break;
      }
    },
    btnAdd(asset: any, key: string) {
      let newAsset: CompendiumAssetBase = asset as CompendiumAssetBase;

      if (this.title?.toLowerCase() == "item") {
        if (this.selectedTab === "asset" || this.selectedTab === "campaign") {
          const item: Item = new Item(
            {
              ...newAsset.toDictionary(),
              key_bag: this.id,
              campaign_id: this.character.campaign_id,
            },
            key
          );
          this.addItem(item);
        } else if (this.selectedTab === "community") {
          const workshopItem: ItemBase = new ItemBase(
            {
              ...newAsset.toDictionary(),
            },
            key
          );

          this.setWorkshopItem({
            item: workshopItem,
            shouldUpdateCharacter: false,
          });
        }
      } else if (this.title?.toLowerCase() == "attribute") {
        if (this.selectedTab === "asset" || this.selectedTab === "campaign") {
          const attribute: Attribute = new Attribute(
            {
              ...newAsset.toDictionary(),
              key_attribute_group: this.id,
              campaign_id: this.character.campaign_id,
            },
            key
          );
          this.addAttribute(attribute);
        } else if (this.selectedTab === "community") {
          const workshopAttribute: AttributeBase = new AttributeBase(
            {
              ...newAsset.toDictionary(),
            },
            key
          );

          this.setWorkshopAttribute({
            attribute: workshopAttribute,
            shouldUpdateCharacter: false,
          });
        }
      } else if (this.title?.toLowerCase() == "spell") {
        if (this.selectedTab === "asset" || this.selectedTab === "campaign") {
          const spell: Spell = new Spell(
            {
              ...newAsset.toDictionary(),
              key_spell_table: this.id,
              campaign_id: this.character.campaign_id,
            },
            key
          );
          this.addSpell(spell);
        } else if (this.selectedTab === "community") {
          const workshopSpell: SpellBase = new SpellBase(
            {
              ...newAsset.toDictionary(),
            },
            key
          );

          this.setWorkshopSpell({
            spell: workshopSpell,
            shouldUpdateCharacter: false,
          });
        }
      } else if (this.title?.toLowerCase() == "resource") {
        if (this.selectedTab === "asset" || this.selectedTab === "campaign") {
          const resource: Resource = new Resource(
            {
              ...newAsset.toDictionary(),
              campaign_id: this.character.campaign_id,
            },
            key
          );
          this.addResource(resource);
        } else if (this.selectedTab === "community") {
          const workshopResource: ResourceBase = new ResourceBase(
            {
              ...newAsset.toDictionary(),
            },
            key
          );

          this.setWorkshopResource({
            resource: workshopResource,
            shouldUpdateCharacter: false,
          });
        }
      }
    },
    handleAssetSelection(asset: CompendiumAssetBase, index: number) {
      if (asset) {
        this.asset = asset;
        if (this.selectOnly) {
          this.onDisplayQuickViewDialog(asset as EffectBase, index);
        }
        else {
          this.onDisplayAssetDialog();
        }
      }
      this.isNew = false;
    },
    isIn(asset: CompendiumAssetBase): boolean {
      if (this.isCommunity) return false;
      if (this.selectedTab === "community") return false;
      const index = this.queryAsset.findIndex(
        (c: CompendiumAssetBase) => c.key == asset.key
      );

      return index > -1;
    },
    loadSortedBlackMarket() {
      if (this.hasBlackMarketData) {
        this.dataSet = this.blackMarket;
      }
    },
    loadSortedCampaign() {
      if (!this.hasCampaignData) {
        const campaign_id = this.$route.query.c;
        console.log(campaign_id, this.character.campaign_id);
        this.fetchCampaignAsset({
          campaignId:
            campaign_id !== undefined
              ? campaign_id
              : this.character.campaign_id,
          assetCategory: this.query?.toLowerCase() + "s",
        }).then((data: Array<any>) => {
          this.dataSet = data;
        });
      } else {
        this.dataSet = this.campaignData;
      }
    },
    assetLabel(asset: CompendiumAssetBase): string {
      let name = asset.name;
      if (this.selectedTab === "asset") {
        let newAsset: CompendiumAssetBase = asset;

        let prefix = newAsset.compendium_prefix || "";
        let suffix = newAsset.compendium_suffix || "";

        return prefix + name + suffix;
      }
      return name;
    },
    isBold(asset: CompendiumAssetBase): string {
      if (!this.dialogData.asset) return '';
      let spell: Spell = this.dialogData.asset as Spell;
      let selectedAsset: EffectBase | undefined = spell.effects[this.dialogData.assetIndex];

      return selectedAsset?.key === asset.key ? 'bold' : '';
    },
    labelAuthor(asset: CompendiumAssetBase): string {
      if (asset.galveon_user_id) {
        let you = this.getGalveonUserId;
        if (asset.galveon_user_id === you) {
          return "You";
        }
      }
      return asset.galveon_user_id || "";
    },
  },
  computed: {
    ...mapState("Character", ["character"]),
    ...mapState("Account", ["workshop"]),
    ...mapGetters("Account", ["hasBlackMarket", "getGalveonUserId"]),
    ...mapGetters("Character", [
      "getAttributes",
      "getSpells",
      "getResources",
      "getItems",
      "getCampaignId",
    ]),
    ...mapGetters("Dialog", ["dialogData"]),
    loadSortedCompendium(): Array<any> {
      switch (this.query?.toLowerCase()) {
        case "spell":
          const sortedSpells = this.workshop.spell.sort((a: Spell, b: Spell) =>
            a.name.localeCompare(b.name)
          );
          return sortedSpells;
        case "item":
          const sortedItems = this.workshop.item.sort((a: Item, b: Item) =>
            a.name.localeCompare(b.name)
          );
          return sortedItems;
        case "resource":
          const sortedResources = this.workshop.resource.sort(
            (a: Resource, b: Resource) => a.name.localeCompare(b.name)
          );
          return sortedResources;
        case "attribute":
          const sortedAttributes = this.workshop.attribute.sort(
            (a: Attribute, b: Attribute) => a.name.localeCompare(b.name)
          );
          return sortedAttributes;
        case "game_system":
          const sortedGameSystems = this.workshop.game_system.sort(
            (a: GameSystem, b: GameSystem) => a.name.localeCompare(b.name)
          );
          return sortedGameSystems;
        case "effect":
          const sortedEffects = this.workshop.effect.sort(
            (a: EffectBase, b: EffectBase) => a.name.localeCompare(b.name)
          );
          return sortedEffects;
        default:
          return [];
      }
    },
    getWorkshopAssets(): Array<any> {
      let assets = [];
      for (let key in this.dataSet) {
        const item = this.dataSet[key];

        assets.push(item);
      }
      return assets;
    },
    queryAsset(): Array<any> {
      if (this.query?.toLowerCase() == "item") {
        return this.getItems;
      } else if (this.query?.toLowerCase() == "attribute") {
        return this.getAttributes;
      } else if (this.query?.toLowerCase() == "spell") {
        return this.getSpells;
      } else if (this.query?.toLowerCase() == "resource") {
        return this.getResources;
      }

      return [];
    },
  },
  mounted() {
    this.query = replaceSpacesWithUnderscores(this.queryId ?? "");

    this.dataSet = this.loadSortedCompendium;

    if (this.query !== "Effect") {
      this.tabs.push({
        name: `Community`,
        key: "community",
        disabled: !this.hasBlackMarket,
      });
    }

    if (
      !this.isCommunity &&
      (this.character.type === "campaign" || this.isCampaign) &&
      this.query !== "Effect"
    ) {
      this.tabs = []; //Clear tabs to avoid personal assets from being added temporarily
      this.tabs.push({
        name: `Campaign`,
        key: "campaign",
        disabled: false,
      });
      this.selectedTab = "campaign";
      this.loadSortedCampaign();
    }
  },
});
</script>

<style lang="scss">
.vFooter {
  position: relative;
}

.vFooter .UIMenuView {
  right: 108px;
  bottom: 44px;
  top: unset;
}

.vTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vAssets {
  height: 300px;

  overflow: auto;
}

.asset {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.16);

  cursor: pointer;
}
</style>