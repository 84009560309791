<template>
  <UIDialogView
    :title="`${newDice.key === '' ? 'New' : 'Edit'} Dice`"
    :hasMenu="newDice.key !== ''"
    :menuItems="['Delete Dice']"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onDiceMenuSelect"
  >
    <UILabel :text="'Preview'" :justify="'left'" />
    <UILabel :text="dicePreview" :justify="'left'" :type="'h2'" />
    <UITextField
      :id="'txtName'"
      :placeholder="'Name'"
      :textLabel="'Name'"
      v-model="newDice.name"
    />
    <UITextField
      :id="'txtCount'"
      :placeholder="'Count'"
      :textLabel="'Number of Dice'"
      :type="'number'"
      v-model="newDice.count"
    />
    <UITextField
      :id="'txtSides'"
      :placeholder="'Sides'"
      :textLabel="'Number of Sides'"
      :type="'number'"
      v-model="newDice.sides"
    />
    <UITextField
      :id="'txtMod'"
      :placeholder="'Modifier'"
      :textLabel="'Modifier'"
      :type="'number'"
      v-model="newDice.modifier"
    />
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UILabel from "@/components/UI/UILabel.vue";

import Dice from "@/store/models/Dice";

export default defineComponent({
  name: "UIDialogManageDie",
  components: {
    UIDialogView,
    UIDialogPromptView,
    UITextField,
    UILabel,
  },
  emits: ["ui-dialog-dice-cancel"],
  props: {
    selectedDice: {
      type: Object as () => Dice,
      default: {},
    },
  },
  data() {
    return {
      newDice: {} as Dice,
      shouldShowPromptDialog: false,
      promptDialogData: {},
    };
  },
  methods: {
    ...mapActions('Character', [
      'setDice',
      'deleteDice',
    ]),
    onSubmit() {
      const newDice: Dice = new Dice(this.newDice, this.newDice.key);

      this.setDice(newDice);

      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-dice-cancel");
    },
    onDiceMenuSelect(index: number) {
      if (index === 0) {
        this.onShowDeleteDialog();
      }
    },
    onShowDeleteDialog() {
      this.shouldShowPromptDialog = true;

      this.promptDialogData = {
        name: "Delete Dice",
        body: `Are you sure you want to delete ${this.newDice.name}? It cannot be undone?`,
        submitTitle: "Continue",
      };
    },
    onSubmitPromptDialog() {
      this.deleteDice(this.newDice);

      this.onCancel();
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
  },
  computed: {
    dicePreview() {
      return `${this.newDice.count}d${this.newDice.sides}+${this.newDice.modifier}`;
    }
  },
  mounted() {
    this.newDice = new Dice(this.selectedDice.toDictionary(), this.selectedDice.key);
  },
});
</script>