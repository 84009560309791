import IModification from "@/store/interfaces/IModification";
import AbilityClass from "@/store/models/AbilityClass";
import Bonus from "@/store/models/Bonus";

import { setValue } from "@/utility/global";

export default class Modification implements IModification {
  constructor(dictionary: Record<string, any>) {
    this.damageTakenFraction = dictionary["damage_taken_fraction"] || "";
    this.damageTakenMaximum = dictionary["damage_taken_maximum"] || "";
    this.damageTakenMinimum = dictionary["damage_taken_minimum"] || "";
    this.damageTakenScaled = dictionary["damage_taken_scaled"] || "";
    this.damageTakenUnscaled = dictionary["damage_taken_unscaled"] || "";
    this.killXpBonus = dictionary["kill_xp_bonus"] || "";
    this.moveSpeedBaseMaximumBonus = dictionary["move_speed_base_maximum_bonus"] || "";
    this.unifiedDamageDealtFraction = dictionary["unified_damage_dealt_fraction"] || "";
    this.unifiedDamageTakenFraction = dictionary["unified_damage_taken_fraction"] || "";
    this.abilitiesDisabled = dictionary["abilities_disabled"] || {};
    this.abilitiesEnabled = dictionary["abilities_enabled"] || {};
    // this.abilityClassesDisabled = [];
    // if (dictionary["ability_classes_disabled"]) {
    //   for (let abilityClassDisabled of dictionary["ability_classes_disabled"]) {
    //     if (abilityClassDisabled in AbilityClass) {
    //       this.abilityClassesDisabled.push(abilityClassDisabled);
    //     }
    //   }
    // }
    // this.abilityClassesEnabled = [];
    // if (dictionary["ability_classes_enabled"] && dictionary["ability_classes_enabled"].length > 0) {
    //   for (let abilityClassEnabled of dictionary["ability_classes_enabled"]) {
    //     if (abilityClassEnabled in AbilityClass) {
    //       this.abilityClassesEnabled.push(abilityClassEnabled);
    //     }
    //   }
    // }
    this.accelerationBonus = dictionary["acceleration_bonus"] || "";
    this.accelerationMultiplier = dictionary["acceleration_multiplier"] || "";
    this.additiveAttackSpeedFactor = dictionary["additive_attack_speed_factor"] || ""
    this.additiveMoveSpeedFactor = dictionary["additive_move_speed_factor"] || ""
    this.decelerationBonus = dictionary["deceleration_bonus"] || ""
    this.decelerationMultiplier = dictionary["deceleration_multiplier"] || ""
    this.movementSpeedBonus = dictionary["movement_speed_bonus"] || ""
    this.movementSpeedMaximum = dictionary["movement_speed_maximum"] || ""
    this.movementSpeedMinimum = dictionary["movement_speed_minimum"] || ""
    this.movementSpeedMultiplier = dictionary["movement_speed_multiplier"] || ""
    this.snareMultiplier = dictionary["snare_multiplier"] || "";
    this.unifiedAttackSpeedFactor = dictionary["unified_attack_speed_factor"] || "";
    this.unifiedMoveSpeedFactor = dictionary["unified_move_speed_factor"] || "";
    this.attackSpeedMultiplier = dictionary["attack_speed_multiplier"] || "";
    this.criticalAttackChanceMultiplier = dictionary["critical_attack_chance_multiplier"] || "";
    this.criticalAttackChanceScaledBonus = dictionary["critical_attack_chance_scaled_bonus"] || "";
    this.criticalAttackChanceUnscaledBonus = dictionary["critical_attack_chance_unscaled_bonus"] || "";
    this.damageDealtScaled = dictionary["damage_dealt_scaled"] || "";
    this.damageDealtUnscaled = dictionary["damage_dealt_unscaled"] || "";
    this.dealtFraction = dictionary["dealt_fraction"] || "";
    this.damageDealtMaximum = dictionary["damage_dealt_maximum"] || "";
    this.damageDealtMinimum = dictionary["damage_dealt_minimum"] || "";
    this.healDealtAdditiveMultiplier = dictionary["heal_dealt_additive_multiplier"] || "";
    this.healDealtMultiplier = dictionary["heal_dealt_multiplier"] || "";
    this.healTakenAdditiveMultiplier = dictionary["heal_taken_additive_multiplier"] || "";
    this.healTakenMultiplier = dictionary["heal_taken_multiplier"] || "";
    this.totalDamageMultiplier = dictionary["total_damage_multiplier"] || "";
    this.vitalGainedFromDamageTaken = dictionary["vital_gained_from_damage_taken"] || "";
    this.vitalLeechedFromDamageTaken = dictionary["vital_leeched_from_damage_taken"] || "";
    const attributeChanges = dictionary["attribute_changes"] as Record<string, string> || [];
    for (const attributeChangeKey in attributeChanges) {
      const attributeChange = attributeChanges[attributeChangeKey];
      this.attributeChanges.push(new Bonus(attributeChange, "", attributeChangeKey));
    }
    const resourceChanges = dictionary["resource_changes"] as Record<string, string> || [];
    for (const resourceChangeKey in resourceChanges) {
      let resourceChange = resourceChanges[resourceChangeKey];
      this.resourceChanges.push(new Bonus(resourceChange, "", resourceChangeKey));
    }
    this.experienceMultiplier = dictionary["experience_multiplier"] || "";
    this.experienceScaledBonus = dictionary["experience_scaled_bonus"] || "";
    this.experienceUnscaledBonus = dictionary["experience_unscaled_bonus"] || "";
    this.sightBonus = dictionary["sight_bonus"] || "";
    this.sightMaximum = dictionary["sight_maximum"] || "";
    this.sightMinimum = dictionary["sight_minimum"] || "";
    this.timeScale = dictionary["time_scale"] || "";
    this.vitalMaxAdditiveMultiplier = dictionary["vital_max_additive_multiplier"] || "";
    this.vitalMaxBonus = dictionary["vital_maximum_bonus"] || "";
    this.vitalMaximumFraction = dictionary["vital_maximum_fraction"] || "";
    this.vitalRegenerationBonusScaled = dictionary["vital_regeneration_bonus_scaled"] || "";
    this.vitalRegenerationMultiplier = dictionary["vital_regeneration_multiplier"] || "";
    this.weaponMinimumRangeBonus = dictionary["weapon_minimum_range_bonus"] || "";
    this.weaponRangeBonus = dictionary["weapon_range_bonus"] || "";
    this.weaponMeleeBonus = dictionary["weapon_melee_bonus"] || "";
    this.weaponNonMeleeRangeBonus = dictionary["weapon_non_melee_range_bonus"] || "";
  }

  damageTakenFraction: string;
  damageTakenMaximum: string;
  damageTakenMinimum: string;
  damageTakenScaled: string;
  damageTakenUnscaled: string;
  killXpBonus: string;
  moveSpeedBaseMaximumBonus: string;
  unifiedDamageDealtFraction: string;
  unifiedDamageTakenFraction: string;
  abilitiesDisabled: Record<string, any> = {};
  abilitiesEnabled: Record<string, any> = {};
  abilityClassesDisabled: AbilityClass[] = [];
  abilityClassesEnabled: AbilityClass[] = [];
  accelerationBonus: string;
  accelerationMultiplier: string;
  additiveAttackSpeedFactor: string;
  additiveMoveSpeedFactor: string;
  decelerationBonus: string;
  decelerationMultiplier: string;
  movementSpeedBonus: string;
  movementSpeedMaximum: string;
  movementSpeedMinimum: string;
  movementSpeedMultiplier: string;
  snareMultiplier: string;
  unifiedAttackSpeedFactor: string;
  unifiedMoveSpeedFactor: string;
  attackSpeedMultiplier: string;
  criticalAttackChanceMultiplier: string;
  criticalAttackChanceScaledBonus: string;
  criticalAttackChanceUnscaledBonus: string;
  damageDealtScaled: string;
  damageDealtUnscaled: string;
  dealtFraction: string;
  damageDealtMaximum: string;
  damageDealtMinimum: string;
  healDealtAdditiveMultiplier: string;
  healDealtMultiplier: string;
  healTakenAdditiveMultiplier: string;
  healTakenMultiplier: string;
  totalDamageMultiplier: string;
  vitalGainedFromDamageTaken: string;
  vitalLeechedFromDamageTaken: string;
  attributeChanges: Bonus[] = [];
  resourceChanges: Bonus[] = [];
  experienceMultiplier: string;
  experienceScaledBonus: string;
  experienceUnscaledBonus: string;
  sightBonus: string;
  sightMaximum: string;
  sightMinimum: string;
  timeScale: string;
  vitalMaxAdditiveMultiplier: string;
  vitalMaxBonus: string;
  vitalMaximumFraction: string;
  vitalRegenerationBonusScaled: string;
  vitalRegenerationMultiplier: string;
  weaponMinimumRangeBonus: string;
  weaponRangeBonus: string;
  weaponMeleeBonus: string;
  weaponNonMeleeRangeBonus: string;

  isEmpty(): Boolean {
    return Object.entries(this.toDictionary()).length === 0;
  }

  toDictionary(): Record<string, any> {
    var returnedDictionary: Record<string, string | Record<string, any> | null> = {};

    const damage_taken_fraction = setValue(this.damageTakenFraction);
    if (damage_taken_fraction !== null) {
      returnedDictionary["damage_taken_fraction"] = damage_taken_fraction;
    }

    const damage_taken_maximum = setValue(this.damageTakenMaximum);
    if (damage_taken_maximum !== null) {
      returnedDictionary["damage_taken_maximum"] = damage_taken_maximum;
    }

    const damage_taken_minimum = setValue(this.damageTakenMinimum);
    if (damage_taken_minimum !== null) {
      returnedDictionary["damage_taken_minimum"] = damage_taken_minimum;
    }

    const damage_taken_scaled = setValue(this.damageTakenScaled);
    if (damage_taken_scaled !== null) {
      returnedDictionary["damage_taken_scaled"] = damage_taken_scaled;
    }

    const damage_taken_unscaled = setValue(this.damageTakenUnscaled);
    if (damage_taken_unscaled !== null) {
      returnedDictionary["damage_taken_unscaled"] = damage_taken_unscaled;
    }

    const kill_xp_bonus = setValue(this.killXpBonus);
    if (kill_xp_bonus !== null) {
      returnedDictionary["kill_xp_bonus"] = kill_xp_bonus;
    }

    const move_speed_base_maximum_bonus = setValue(this.moveSpeedBaseMaximumBonus);
    if (move_speed_base_maximum_bonus !== null) {
      returnedDictionary["move_speed_base_maximum_bonus"] = move_speed_base_maximum_bonus;
    }

    const unified_damage_dealt_fraction = setValue(this.unifiedDamageDealtFraction);
    if (unified_damage_dealt_fraction !== null) {
      returnedDictionary["unified_damage_dealt_fraction"] = unified_damage_dealt_fraction;
    }

    const unified_damage_taken_fraction = setValue(this.unifiedDamageTakenFraction);
    if (unified_damage_taken_fraction !== null) {
      returnedDictionary["unified_damage_taken_fraction"] = unified_damage_taken_fraction;
    }

    const abilities_disabled = setValue(this.abilitiesDisabled);
    if (abilities_disabled !== null) {
      returnedDictionary["abilities_disabled"] = abilities_disabled;
    }

    const abilities_enabled = setValue(this.abilitiesEnabled);
    if (abilities_enabled !== null) {
      returnedDictionary["abilities_enabled"] = abilities_enabled;
    }

    // var abilityClassesDisabledArray: Array<String> = []
    // for (const ability in this.abilityClassesDisabled) {
    //   abilityClassesDisabledArray.push(ability);
    // }
    // returnedDictionary["ability_classes_disabled"] = setValue(abilityClassesDisabledArray;);

    // var abilityClassesEnabledArray: Array<String> = []
    // for (const ability in this.abilityClassesEnabled) {
    //   abilityClassesEnabledArray.push(ability);
    // }
    // returnedDictionary["ability_classes_enabled"] = setValue(abilityClassesEnabledArray);

    const acceleration_bonus = setValue(this.accelerationBonus);
    if (acceleration_bonus !== null) {
      returnedDictionary["acceleration_bonus"] = acceleration_bonus;
    }

    const acceleration_multiplier = setValue(this.accelerationMultiplier);
    if (acceleration_multiplier !== null) {
      returnedDictionary["acceleration_multiplier"] = acceleration_multiplier;
    }

    const additive_attack_speed_factor = setValue(this.additiveAttackSpeedFactor);
    if (additive_attack_speed_factor !== null) {
      returnedDictionary["additive_attack_speed_factor"] = additive_attack_speed_factor;
    }

    const additive_move_speed_factor = setValue(this.additiveMoveSpeedFactor);
    if (additive_move_speed_factor !== null) {
      returnedDictionary["additive_move_speed_factor"] = additive_move_speed_factor;
    }

    const deceleration_bonus = setValue(this.decelerationBonus);
    if (deceleration_bonus !== null) {
      returnedDictionary["deceleration_bonus"] = deceleration_bonus;
    }

    const deceleration_multiplier = setValue(this.decelerationMultiplier);
    if (deceleration_multiplier !== null) {
      returnedDictionary["deceleration_multiplier"] = deceleration_multiplier;
    }

    const movement_speed_bonus = setValue(this.movementSpeedBonus);
    if (movement_speed_bonus !== null) {
      returnedDictionary["movement_speed_bonus"] = movement_speed_bonus;
    }

    const movement_speed_maximum = setValue(this.movementSpeedMaximum);
    if (movement_speed_maximum !== null) {
      returnedDictionary["movement_speed_maximum"] = movement_speed_maximum;
    }

    const movement_speed_minimum = setValue(this.movementSpeedMinimum);
    if (movement_speed_minimum !== null) {
      returnedDictionary["movement_speed_minimum"] = movement_speed_minimum;
    }

    const movement_speed_multiplier = setValue(this.movementSpeedMultiplier);
    if (movement_speed_multiplier !== null) {
      returnedDictionary["movement_speed_multiplier"] = movement_speed_multiplier;
    }

    const snare_multiplier = setValue(this.snareMultiplier);
    if (snare_multiplier !== null) {
      returnedDictionary["snare_multiplier"] = snare_multiplier;
    }

    const unified_attack_speed_factor = setValue(this.unifiedAttackSpeedFactor);
    if (unified_attack_speed_factor !== null) {
      returnedDictionary["unified_attack_speed_factor"] = unified_attack_speed_factor;
    }

    const unified_move_speed_factor = setValue(this.unifiedMoveSpeedFactor);
    if (unified_move_speed_factor !== null) {
      returnedDictionary["unified_move_speed_factor"] = unified_move_speed_factor;
    }

    const attack_speed_multiplier = setValue(this.attackSpeedMultiplier);
    if (attack_speed_multiplier !== null) {
      returnedDictionary["attack_speed_multiplier"] = attack_speed_multiplier;
    }

    const critical_attack_chance_multiplier = setValue(this.criticalAttackChanceMultiplier);
    if (critical_attack_chance_multiplier !== null) {
      returnedDictionary["critical_attack_chance_multiplier"] = critical_attack_chance_multiplier;
    }

    const critical_attack_chance_scaled_bonus = setValue(this.criticalAttackChanceScaledBonus);
    if (critical_attack_chance_scaled_bonus !== null) {
      returnedDictionary["critical_attack_chance_scaled_bonus"] = critical_attack_chance_scaled_bonus;
    }

    const critical_attack_chance_unscaled_bonus = setValue(this.criticalAttackChanceUnscaledBonus);
    if (critical_attack_chance_unscaled_bonus !== null) {
      returnedDictionary["critical_attack_chance_unscaled_bonus"] = critical_attack_chance_unscaled_bonus;
    }

    const damage_dealt_scaled = setValue(this.damageDealtScaled);
    if (damage_dealt_scaled !== null) {
      returnedDictionary["damage_dealt_scaled"] = damage_dealt_scaled;
    }

    const damage_dealt_unscaled = setValue(this.damageDealtUnscaled);
    if (damage_dealt_unscaled !== null) {
      returnedDictionary["damage_dealt_unscaled"] = damage_dealt_unscaled;
    }


    const dealt_fraction = setValue(this.dealtFraction);
    if (dealt_fraction !== null) {
      returnedDictionary["dealt_fraction"] = dealt_fraction;
    }

    const damage_dealt_maximum = setValue(this.damageDealtMaximum);
    if (damage_dealt_maximum !== null) {
      returnedDictionary["damage_dealt_maximum"] = damage_dealt_maximum;
    }

    const damage_dealt_minimum = setValue(this.damageDealtMinimum);
    if (damage_dealt_minimum !== null) {
      returnedDictionary["damage_dealt_minimum"] = damage_dealt_minimum;
    }

    const heal_dealt_additive_multiplier = setValue(this.healDealtAdditiveMultiplier);
    if (heal_dealt_additive_multiplier !== null) {
      returnedDictionary["heal_dealt_additive_multiplier"] = heal_dealt_additive_multiplier;
    }

    const heal_dealt_multiplier = setValue(this.healDealtMultiplier);
    if (heal_dealt_multiplier !== null) {
      returnedDictionary["heal_dealt_multiplier"] = heal_dealt_multiplier;
    }

    const heal_taken_additive_multiplier = setValue(this.healTakenAdditiveMultiplier);
    if (heal_taken_additive_multiplier !== null) {
      returnedDictionary["heal_taken_additive_multiplier"] = heal_taken_additive_multiplier;
    }

    const heal_taken_multiplier = setValue(this.healTakenMultiplier);
    if (heal_taken_multiplier !== null) {
      returnedDictionary["heal_taken_multiplier"] = heal_taken_multiplier;
    }

    const total_damage_multiplier = setValue(this.totalDamageMultiplier);
    if (total_damage_multiplier !== null) {
      returnedDictionary["total_damage_multiplier"] = total_damage_multiplier;
    }

    const vital_gained_from_damage_taken = setValue(this.vitalGainedFromDamageTaken);
    if (vital_gained_from_damage_taken !== null) {
      returnedDictionary["vital_gained_from_damage_taken"] = vital_gained_from_damage_taken;
    }

    const vital_leeched_from_damage_taken = setValue(this.vitalLeechedFromDamageTaken);
    if (vital_leeched_from_damage_taken !== null) {
      returnedDictionary["vital_leeched_from_damage_taken"] = vital_leeched_from_damage_taken;
    }

    var attributeChangesDictionary: Record<string, string> = {};
    for (const bonus of this.attributeChanges) {
      attributeChangesDictionary[bonus.key] = bonus.formula
    }
    const attribute_changes = setValue(attributeChangesDictionary);
    if (attribute_changes != null) {
      returnedDictionary["attribute_changes"] = attribute_changes;
    }

    var resourceChangesDictionary: Record<string, string> = {};
    for (const bonus of this.resourceChanges) {
      resourceChangesDictionary[bonus.key] = bonus.formula
    }
    const resource_changes = setValue(resourceChangesDictionary);
    if (resource_changes != null) {
      returnedDictionary["resource_changes"] = resource_changes;
    }

    const experience_multiplier = setValue(this.experienceMultiplier);
    if (experience_multiplier !== null) {
      returnedDictionary["experience_multiplier"] = experience_multiplier;
    }

    const experience_scaled_bonus = setValue(this.experienceScaledBonus);
    if (experience_scaled_bonus !== null) {
      returnedDictionary["experience_scaled_bonus"] = experience_scaled_bonus;
    }

    const experience_unscaled_bonus = setValue(this.experienceUnscaledBonus);
    if (experience_unscaled_bonus !== null) {
      returnedDictionary["experience_unscaled_bonus"] = experience_unscaled_bonus;
    }

    const sight_bonus = setValue(this.sightBonus);
    if (sight_bonus !== null) {
      returnedDictionary["sight_bonus"] = sight_bonus;
    }

    const sight_maximum = setValue(this.sightMaximum);
    if (sight_maximum !== null) {
      returnedDictionary["sight_maximum"] = sight_maximum;
    }

    const sight_minimum = setValue(this.sightMinimum);
    if (sight_minimum !== null) {
      returnedDictionary["sight_minimum"] = sight_minimum;
    }

    const time_scale = setValue(this.timeScale);
    if (time_scale !== null) {
      returnedDictionary["time_scale"] = time_scale;
    }

    const vital_max_additive_multiplier = setValue(this.vitalMaxAdditiveMultiplier);
    if (vital_max_additive_multiplier !== null) {
      returnedDictionary["vital_max_additive_multiplier"] = vital_max_additive_multiplier;
    }

    const vital_maximum_bonus = setValue(this.vitalMaxBonus);
    if (vital_maximum_bonus !== null) {
      returnedDictionary["vital_maximum_bonus"] = vital_maximum_bonus;
    }

    const vital_maximum_fraction = setValue(this.vitalMaximumFraction);
    if (vital_maximum_fraction !== null) {
      returnedDictionary["vital_maximum_fraction"] = vital_maximum_fraction;
    }

    const vital_regeneration_bonus_scaled = setValue(this.vitalRegenerationBonusScaled);
    if (vital_regeneration_bonus_scaled !== null) {
      returnedDictionary["vital_regeneration_bonus_scaled"] = vital_regeneration_bonus_scaled;
    }

    const vital_regeneration_multiplier = setValue(this.vitalRegenerationMultiplier);
    if (vital_regeneration_multiplier !== null) {
      returnedDictionary["vital_regeneration_multiplier"] = vital_regeneration_multiplier;
    }

    const weapon_minimum_range_bonus = setValue(this.weaponMinimumRangeBonus);
    if (weapon_minimum_range_bonus !== null) {
      returnedDictionary["weapon_minimum_range_bonus"] = weapon_minimum_range_bonus;
    }

    const weapon_range_bonus = setValue(this.weaponRangeBonus);
    if (weapon_range_bonus !== null) {
      returnedDictionary["weapon_range_bonus"] = weapon_range_bonus;
    }

    const weapon_melee_bonus = setValue(this.weaponMeleeBonus);
    if (weapon_melee_bonus !== null) {
      returnedDictionary["weapon_melee_bonus"] = weapon_melee_bonus;
    }

    const weapon_non_melee_range_bonus = setValue(this.weaponNonMeleeRangeBonus);
    if (weapon_non_melee_range_bonus !== null) {
      returnedDictionary["weapon_non_melee_range_bonus"] = weapon_non_melee_range_bonus;
    }

    return returnedDictionary;
  }
};
