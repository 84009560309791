import IQuickViewSecondaryAttribute from "../interfaces/IQuickViewSecondaryAttribute";

export default class QuickViewSecondaryAttribute implements IQuickViewSecondaryAttribute {
  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
  
  name: string;
  value: string;

  toDictionary = (): Record<string, any> => {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["name"] = this.name;
    returnedDictionary["value"] = this.value;

    return returnedDictionary;
  }
}