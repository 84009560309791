<template>
  <div class="overlay" @click.self="onCancel"></div>
  <div class="menu-account">
    <div class="menu-account-box">
      <div class="menu-account-details">
        <AccountContents />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import UICollectionView from "@/components/UI/UICollectionViewCell.vue";

import AccountContents from "@/components/galveon/AccountContents.vue";

import { IUser } from "@/store/interfaces/IUser";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  name: "MenuAccount",
  components: {
    UISeparatorView,
    UICollectionView,
    AccountContents,
  },
  emits: ["menu-account-dismiss", "clicked"],
  methods: {
    onCancel() {
      this.$emit("menu-account-dismiss");
    },
    btnShow() {
      this.$emit("clicked", false);
    },
  },
  computed: {
    ...mapState("Account", ['user']),
    UserAccount(): IUser | null {
      return this.user;
    },
  },
});
</script>

<style lang="scss" scoped>
.overlay {
  background: rgba(0, 0, 0, 0.16);
}
.menu-account {
  position: absolute;
  right: 0;
  top: 60px;

  border-radius: 8px;

  background: #ffffff;

  z-index: 1;
}
.menu-account-box {
  position: relative;
  display: block;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  width: 225px;
}
.menu-account-details {
  padding: 20px;
}
.menu-account-display-name {
  display: flex;
  font-size: 17px;
}
.menu-account-display-email {
  display: flex;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.menu-account-logout {
  display: flex;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  border-top: solid 1px rgba(0, 0, 0, 0.08);
  padding: 0px 20px;
}

#btnSignIn {
  display: flex;
  justify-content: center;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  padding: 0px 20px;
}
</style>