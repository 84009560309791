import IItemBase from "@/store/interfaces/AssetBases/IItemBase";
import CompendiumAssetBase from "../Compendium/CompendiumAssetBase";
import { AssetType } from "../Compendium/AssetType";
import { ItemType } from "@/store/interfaces/types/ItemType";
import { Rarity } from "@/store/interfaces/types/Rarirty";
import Modification from "../Modification";
import Cost from "../Cost";

export default class ItemBase extends CompendiumAssetBase implements IItemBase {
  constructor(dictionary: Record<string, any>, key: string) {
    super(dictionary, key, AssetType.item);

    this.rarity = dictionary["rarity"] || "common";
    this.item_type = dictionary["item_type"] || "miscellaneous";
    this.level = +dictionary["level"] || 0;
    const modificationValue = dictionary["modification"] || {};
    this.modification = new Modification(modificationValue);

    const costDict = dictionary["costs"];
    if (costDict) {
      for (const costKey in costDict) {
        const currentCostItemValue = costDict[costKey];
        this.costs.push(new Cost(currentCostItemValue));
      }
    }
  }

  rarity: Rarity;
  item_type: ItemType;
  level: number;
  modification: Modification;
  costs: Cost[] = [];

  toDictionary(): Record<string, any> {
    var returnedDictionary = super.toDictionary();

    returnedDictionary["rarity"] = this.rarity;
    returnedDictionary["item_type"] = this.item_type;
    returnedDictionary["level"] = this.level;
    returnedDictionary["modification"] = this.modification.toDictionary();
    if (this.costs.length > 0) {
      var costArr: Record<string, any>[] = [];
      for (const cost of this.costs) {
        costArr.push(cost.toDictionary());
      }
      returnedDictionary["costs"] = costArr;
    }

    return returnedDictionary;
  }
}