<template>
  <UIDialogView
    :title="title"
    :removeTitle="removeTitle"
    @ui-dialog-remove="onRemove"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <div class="vBody">
      <UILabel :text="body" :type="'body'" :justify="'left'" :font="14" />
    </div>
    <div class="vInput">
      <UITextField
        :id="'txtValue'"
        :type="'text'"
        v-model="value1"
        :textLabel="inputTitle1"
        :placeholder="inputPlaceholder1"
      />
      <UITextField
        :id="'txtValue'"
        :type="'text'"
        v-model="value2"
        :textLabel="inputTitle2"
        :placeholder="inputPlaceholder2"
      />
    </div>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "UIDialogValueView",
  components: {
    UIDialogView,
    UILabel,
    UITextField,
    UIButton,
    UISeparatorView,
  },
  data() {
    return {
      value1: this.inputValue1,
      value2: this.inputValue2,
    };
  },
  emits: ["ui-dialog-value-submit", "ui-dialog-value-remove", "ui-dialog-value-cancel"],
  props: {
    type: {
      type: String,
      require: true,
      default: "",
    },
    title: String,
    body: String,
    inputTitle1: String,
    inputValue1: String,
    inputPlaceholder1: String,
    inputTitle2: String,
    inputValue2: String,
    inputPlaceholder2: String,
    buttonTitle: {
      type: String,
      default: "Save",
    },
    removeTitle: String,
  },
  methods: {
    onCancel() {
      this.$emit("ui-dialog-value-cancel");
    },
    onSubmit() {
      let returnedData = {
        type: this.type,
        value1: this.value1,
        value2: this.value2,
      };
      this.$emit("ui-dialog-value-submit", returnedData);
      this.onCancel();
    },
    onRemove() {
      this.$emit("ui-dialog-value-remove");
      this.onCancel();
    },
  },
});
</script>

<style lang="scss">
</style>