<template>
  <UIDialogView
    :title="''"
    :hideHeader="true"
    :hideButtons="!showActions"
    :disableSubmit="!actionsDisabled"
    :submitTitle="submitTitle"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <QuickViewView
      :quickView="quickView"
      @quick-view-menu="onMenu"
      @quick-view-cancel="onCancel"
    />
  </UIDialogView>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import QuickViewView from "@/components/compendium/QuickViewView.vue";
import QuickView from "@/store/models/QuickView";

export default defineComponent({
  name: "UIDialogAssetQuickView",
  components: {
    UIDialogView,
    QuickViewView,
  },
  emits: ["ui-dialog-quick-view-submit", "ui-dialog-quick-view-cancel", "ui-dialog-quick-view-menu"],
  data() {
    return {
      newFormula: this.formula,
    };
  },
  props: {
    quickView: {
      type: Object as () => QuickView
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    actionsDisabled: {
      type: Boolean,
      default: false,
    },
    submitTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    onSubmit() {
      this.$emit("ui-dialog-quick-view-submit", this.quickView);
    },
    onCancel() {
      this.$emit("ui-dialog-quick-view-cancel");
    },
    onMenu() {
      this.$emit("ui-dialog-quick-view-menu", this.quickView);
    },
  },
});
</script>