import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inventory" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CharacterInventoryStatistics = _resolveComponent("CharacterInventoryStatistics")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_CharacterInventoryBags = _resolveComponent("CharacterInventoryBags")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CharacterInventoryStatistics),
    _createVNode(_component_UISeparatorView),
    _createVNode(_component_CharacterInventoryBags)
  ]))
}