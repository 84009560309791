<template>
  <div class="UIDropdownGameSystem">
    <span v-if="hasError == true" class="UITextFieldError">{{
      errorMessage
    }}</span>
    <UICellDropdownField
      :textLabel="'Game System'"
      :items="gameSystems"
      v-model="computedValue"
      :has-error="hasError"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { getCommunityGameSystems } from "@/utility/database";
import { mapGetters } from "vuex";

import UICellDropdownField from "@/components/UI/UICellDropdownField.vue";

import GameSystem from "@/store/models/GameSystem";

export default defineComponent({
  name: "UIDropdownGameSystem",
  components: {
    UICellDropdownField,
  },
  emits: ["ui-dropdown-game-system-select", "update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
      default: "None",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      gameSystems: [] as string[],
      gameSystemsData: [] as GameSystem[],
    };
  },
  methods: {
  },
  computed: {
    ...mapGetters("Account", ["getWorkshopGameSystem"]),
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string | number) {
        const selectedGameSystem: GameSystem | undefined =
        this.gameSystemsData.find((g: GameSystem) => g.name === newValue);
      if (selectedGameSystem) {
        this.$emit(
          "ui-dropdown-game-system-select",
          selectedGameSystem.key,
          selectedGameSystem.name
        );
      }
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  mounted() {
    getCommunityGameSystems()
      .then((result_data) => {
        let workshopGameSystem = [...this.getWorkshopGameSystem];
        for (const selectedGameSystem of result_data) {
          this.gameSystems.push(selectedGameSystem.name);
          const index = workshopGameSystem.findIndex(
            (s: GameSystem) => s.key === selectedGameSystem.key
          );

          if (index > -1) {
            workshopGameSystem.splice(index, 1);
          }
        }
        workshopGameSystem.forEach((s: GameSystem) => {
          this.gameSystems.push(s.name);
          this.gameSystemsData.push(s);
        });

        this.gameSystemsData = result_data.concat(this.gameSystemsData);
      })
      .catch((error) => {
        this.gameSystems = [];
        this.gameSystemsData = [];
      });
  },
});
</script>