import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIDropDownView = _resolveComponent("UIDropDownView")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["UICellDropdownView", { disabled: _ctx.disabled }])
  }, [
    (_ctx.shouldShowLabel)
      ? (_openBlock(), _createBlock(_component_UILabel, {
          key: 0,
          text: _ctx.textLabel,
          justify: 'left'
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_UIDropDownView, {
      items: _ctx.items,
      modelValue: _ctx.computedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event))
    }, null, 8, ["items", "modelValue"])
  ], 2))
}