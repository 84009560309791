// Import firebase
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Import analytics
import { getAnalytics } from 'firebase/analytics';

const config = {
  apiKey: "AIzaSyC_BVFYAf_9ikanU5LwepacJy274UUNkuo",
  authDomain: "torewin.com",
  databaseURL: "https://mouria-9b7cd.firebaseio.com",
  projectId: "mouria-9b7cd",
  storageBucket: "mouria-9b7cd.appspot.com",
  messagingSenderId: "278301456505",
  appId: "1:278301456505:web:c682dbb5c289bd4c913ca8",
  measurementId: "G-26NLPGTLQ7"
}

const app = initializeApp(config);

// (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = true;

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LercG4eAAAAAH2X_U-1tkBIpmOUQmi_gplhQjtW'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

//Initialize analytics
getAnalytics();
