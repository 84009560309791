<template>
  <UIDialogView
    :title="`${action} Attribute`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onAttributeDialogMenuSelect"
  >
    <ManageAssetView :asset="selectedAttribute" :hasModifications="true" :isCompendium="isWorkshop">
      <template v-slot:basic>
        <UITextField
          :id="'txtName'"
          :placeholder="'Name'"
          :textLabel="'Name'"
          v-model="selectedAttribute.name"
          :disabled="readOnly"
        />
        <UITextField
          :id="'txtValue'"
          :placeholder="'Value'"
          :textLabel="'Value'"
          v-model="selectedAttribute.value"
          :disabled="isWorkshop || readOnly"
        />
        <UISeparatorView />
        <div
          class="flex"
          v-if="selectedAttribute.extra && selectedAttribute.extra?.length > 0"
        >
          <UILabel :text="'Secondary'" :type="'h2'" />
          <img
            v-if="!readOnly"
            src="@/assets/icon_plus@x1.png"
            style="cursor: pointer"
            @click="onDisplayValueDialog()"
          />
        </div>
        <UICollectionView :id="'cvExtra'">
          <UIOnboardView
            v-if="
              !selectedAttribute.extra || selectedAttribute.extra?.length < 1
            "
            :title="'No Secondary Attributes'"
            :buttonTitle="'Add Secondary Attribute'"
            @ui-onboard-view="onDisplayValueDialog()"
          />
          <SecondaryAttributeUICollectionViewCell
            v-for="(extra, index) in selectedAttribute.extra"
            :key="index"
            :extra="extra"
            @click="onDisplayValueDialog(index, extra)"
          />
        </UICollectionView>
        <UISeparatorView />
        <UITextView
          :id="'txtDescription'"
          v-model="descriptionPreview"
          :disabled="true"
          :placeholder="'Description'"
          @click="onDisplayDescriptionDialog"
        />
      </template>
      <template v-slot:advanced>
        <div class="flex">
          <UILabel :text="'Primary Attribute'" :disabled="readOnly" />
          <UISwitchView
            :checked="selectedAttribute.is_primary_attribute"
            :disabled="readOnly"
            @ui-switch-view-toggle="onTogglePrimaryAttribute"
          />
        </div>
        <div class="flex">
          <UILabel :text="'Modifier Formula'" :type="'h2'" :justify="'left'" />
          <img
            src="@/assets/icon_pencil@x2.png"
            style="cursor: pointer"
            @click="onDisplayFormulaDialog"
          />
        </div>
        <div
          :class="{ disabled: isWorkshop }"
          v-if="modifierFormula.length > 0"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <UILabel :text="'Show Modifier'" />
          <UISwitchView
            :checked="selectedAttribute.show_modifier"
            @ui-switch-view-toggle="onModifierFormulaToggle"
          />
        </div>
        <UICollectionView :id="'cvFormulas'" :hasItems="modifierFormula.length > 0">
          <UIOnboardView
            v-if="modifierFormula.length < 1"
            :title="'No modifier formula'"
            :buttonTitle="'Add Formula'"
            @ui-onboard-view="onDisplayFormulaDialog"
          />
          <FormulaItemUICollectionViewCell
            v-for="(formula, index) in modifierFormula"
            :key="index"
            :formulaItem="formula"
            @click="onDisplayFormulaDialog"
          />
        </UICollectionView>
        <div class="flex" @click="onDisplayFormulaDialog"></div>
        <UISeparatorView />
      </template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
    <UIDialogSecondaryAttributeView
      v-if="shouldShowValueDialog"
      :extra="extraDialogData"
      @ui-dialog-secondary-attribute-submit="onSubmitValueDialog"
      @ui-dialog-secondary-attribute-cancel="onCancelValueDialog"
      @ui-dialog-secondary-attribute-remove="onRemoveValueDialog"
    />
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      :type="'text'"
      :title="submissionDialogData.title"
      :body="submissionDialogData.body"
      :inputTitle="submissionDialogData.inputTitle"
      :inputPlaceholder="'Description of game system'"
      @ui-dialog-input-submit="onPublish"
      @ui-dialog-input-cancel="onDismissPublishDialog"
    />
    <UIDialogMessageView
      v-if="shouldShowMessageDialog"
      :title="'Success'"
      :body="'Asset successfuly submitted for review.'"
      @ui-dialog-message-submit="onDismissMessageDialog"
    />
    <UIDialogDescription
      v-if="shouldShowDescriptionDialog"
      :description="description"
      @ui-dialog-description-submit="onSubmitDescriptionDialog"
      @ui-dialog-description-cancel="onDismissDescriptionDialog"
    />
    <UIDialogPublishView
      v-if="shouldShowPublishDialog"
      :asset="attribute"
      :body="'Submitting this Attribute will share it with the entire community. Are you sure you want to submit this Attribute?'"
      @ui-dialog-publish-cancel="onDismissPublishDialog"
    />
    <UIDialogFormulaView
      v-if="shouldShowFormulaDialog"
      :formula="selectedAttribute.modifier_formula"
      :formulaTable="selectedAttribute.table_modifier_formula"
      :canHaveTable="true"
      @ui-dialog-formula-submit="onSubmitFormulaDialog"
      @ui-dialog-formula-cancel="onCancelFormulaDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UIDialogSecondaryAttributeView from "@/components/character/dialogs/UIDialogSecondaryAttributeView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";
import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogMessageView from "@/components/UI/dialogs/UIDialogMessageView.vue";
import UIDialogDescription from "@/components/character/dialogs/UIDialogDescription.vue";
import UISwitchView from "@/components/UI/UISwitchView.vue";

import UIDialogPublishView from "@/components/compendium/UIDialogPublishView.vue";

import UIDialogFormulaView from "@/components/character/dialogs/UIDialogFormulaView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UITabView from "@/components/UI/UITabView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import SecondaryAttributeUICollectionViewCell from "@/components/character/SecondaryAttributeUICollectionViewCell.vue";
import FormulaItemUICollectionViewCell from "@/components/character/collectionViewCells/FormulaItemUICollectionViewCell.vue";

import Attribute from "@/store/models/Attribute";
import SecondaryAttribute from "@/store/models/SecondaryAttribute";

import { newKey } from "@/utility/database";
import { convertKeysLocalDescription } from "@/utility/tooltip";
import FormulaItem from "@/store/models/FormulaItem";

export default defineComponent({
  name: "UIDialogAttributeView",
  components: {
    UIDialogView,
    UIDialogPromptView,
    UIDialogInputView,
    UIDialogMessageView,
    UIDialogDescription,
    UIDialogFormulaView,
    UIDialogSecondaryAttributeView,
    UILabel,
    UIButton,
    UITextField,
    UITextView,
    UISeparatorView,
    UISwitchView,
    UICollectionView,
    UITabView,
    UIOnboardView,
    SecondaryAttributeUICollectionViewCell,
    FormulaItemUICollectionViewCell,
    ManageAssetView,
    UIDialogPublishView,
  },
  props: {
    attribute: {
      type: Object as () => Attribute,
      default: {},
    },
    isWorkshop: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["ui-dialog-attribute-submit", "ui-dialog-attribute-cancel"],
  data() {
    return {
      selectedAttribute: {} as Attribute,
      shouldShowPromptDialog: false,
      shouldShowValueDialog: false,
      shouldShowInputDialog: false,
      shouldShowMessageDialog: false,
      shouldShowDescriptionDialog: false,
      shouldShowFormulaDialog: false,
      shouldShowPublishDialog: false,
      promptDialogData: {},
      extraDialogData: {},
      extraIndex: -1,
      submissionDialogData: {},
      menuItems: ["Publish Attribute", "Duplicate Attribute", "Remove Attribute"],
      hasMenu: true,
      description: "",
    };
  },
  methods: {
    ...mapActions("Character", ["removeAttribute"]),
    ...mapActions("Publish", ["publish"]),
    ...mapActions("Account", ["deleteWorkshopAssetAttribute"]),
    onDisplayDescriptionDialog() {
      if (this.readOnly) return;
      this.shouldShowDescriptionDialog = true;
    },
    onSubmitDescriptionDialog(newDescription: string) {
      this.description = newDescription;
      this.onDismissDescriptionDialog();
    },
    onDismissDescriptionDialog() {
      this.shouldShowDescriptionDialog = false;
    },
    onSubmit() {
      this.selectedAttribute.description = this.description;

      this.$emit("ui-dialog-attribute-submit", {
        attribute: this.selectedAttribute,
        isNew: this.isNew,
      });
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-attribute-cancel");
    },
    onAttributeDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onShowPublishDialog();
      } else if (index === 1) {
        this.onDuplicateDialog();
      } else if (index === 2) {
        this.onRemoveDialog();
      }
    },
    onDuplicateDialog() {
      this.selectedAttribute.description = this.description;

      let newDuplicateAttribute: Attribute = new Attribute(
        this.selectedAttribute.toDictionary(),
        newKey()
      );
      newDuplicateAttribute.name = newDuplicateAttribute.name + " (Copy)";
      this.$emit("ui-dialog-attribute-submit", {
        attribute: newDuplicateAttribute,
        isNew: true,
      });
      this.onCancel();
    },
    onRemoveDialog() {
      let data: any = {};

      if (this.isWorkshop) {
        data = {
          name: `Delete ${this.attribute.name}`,
          body: `Are you sure you want to delete ${this.attribute.name} from your Compendium? This cannot be undone.`,
          submitTitle: "Continue",
          type: "deleteAsset",
        };
      } else {
        data = {
          name: `Remove ${this.attribute.name}`,
          body: `Are you sure you want to remove ${this.attribute.name} from your Character? Your attribute will continue to exist in your Compendium.`,
          submitTitle: "Continue",
          type: "removeAsset",
        };
      }

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog(data: any) {
      if (data.type === "removeAsset") {
        this.removeAttribute(this.selectedAttribute);
      } else if (data.type === "deleteAsset") {
        this.deleteWorkshopAssetAttribute(this.selectedAttribute);
      }
      
      this.onCancel();
    },
    onShowPublishDialog() {
      this.shouldShowPublishDialog = true;
    },
    onDismissPublishDialog() {
      this.shouldShowPublishDialog = false;
    },
    onPublish(data: any) {
      const payload = {
        asset: this.selectedAttribute,
        description: data.value,
      };

      this.publish(payload).then(() => {
        this.onShowMessageDialog();
      });

      this.onDismissPublishDialog();
    },
    onShowMessageDialog() {
      this.shouldShowMessageDialog = true;
    },
    onDismissMessageDialog() {
      this.shouldShowMessageDialog = false;
    },
    onDisplayFormulaDialog() {
      if (this.readOnly) return;

      this.shouldShowFormulaDialog = true;
    },
    onSubmitFormulaDialog(formula: string, tableFormula: FormulaItem[]) {
      this.selectedAttribute.modifier_formula = formula;
      this.selectedAttribute.table_modifier_formula = tableFormula;
    },
    onCancelFormulaDialog() {
      this.shouldShowFormulaDialog = false;
    },
    onModifierFormulaToggle(value: boolean) {
      this.selectedAttribute.show_modifier = value;
    },
    onDisplayValueDialog(index: number = -1, data: any) {
      if (this.readOnly) return;

      this.extraDialogData = data !== undefined ? data : new SecondaryAttribute({});
      this.extraIndex = index;
      this.shouldShowValueDialog = true;
    },
    onCancelValueDialog() {
      this.shouldShowValueDialog = false;
    },
    onSubmitValueDialog(extra: SecondaryAttribute) {
      if (this.extraIndex === -1) {
        if (!this.selectedAttribute.extra) {
          this.selectedAttribute.extra = [];
        }
        this.selectedAttribute.extra.push(extra);
      } else {
        const index = this.extraIndex;
        this.selectedAttribute.extra[index] = extra;
      }
    },
    onRemoveValueDialog() {
      const index = this.extraIndex;
      if (index > -1) {
        this.selectedAttribute.extra.splice(index, 1);
      }
    },
    onTogglePrimaryAttribute() {
      if (this.readOnly) return;
      this.selectedAttribute.is_primary_attribute = !this.selectedAttribute.is_primary_attribute;
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
    descriptionPreview(): string {
      return convertKeysLocalDescription(this.description);
    },
    modifierFormula(): FormulaItem[] {
      return this.selectedAttribute.modifier_formula
        ? [
            new FormulaItem({
              value: this.selectedAttribute.modifier_formula,
              result: "",
            }),
          ]
        : this.selectedAttribute.table_modifier_formula;
    },
  },
  mounted() {
    this.selectedAttribute = new Attribute(
      this.attribute.toDictionary(),
      this.attribute.key
    );

    this.description = this.selectedAttribute?.description || "";

    if (this.isWorkshop) {
      this.menuItems = ["Publish Attribute", "Duplicate Attribute", "Delete Attribute"];
    }

    this.hasMenu = !this.isNew;
  },
});
</script>

<style lang="scss">
#cvExtra {
  max-height: 150px;
  overflow: auto;
  gap: 0;
}
</style>@/store/models/SecondaryAttribute