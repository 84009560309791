<template>
  <div class="UIActivityView" @click="onPortraitClick()">
    <div class="vPortrait" v-if="hasImage" :style="{ top: `${stroke * 2}px` }">
      <img
        :src="img"
        :width="normalizedRadius * 2 - stroke"
        :height="normalizedRadius * 2 - stroke"
        :style="{
          width: normalizedRadius * 2 - stroke + 'px',
          height: normalizedRadius * 2 - stroke + 'px',
          top: stroke / 2 + 'px',
        }"
      />
    </div>
    <div class="vLabel">
      <div v-if="labelTitle !== ''">{{ labelTitle }}</div>
      <div
        v-if="label !== ''"
        class="vLabelNumber"
        :style="{ fontSize: `${labelSize}px` }"
      >
        {{ label }}
      </div>
    </div>
    <svg :height="radius * 2" :width="radius * 2">
      <circle
        :stroke="'lightgrey'"
        :fill="fill"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="'{ 100% }'"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
        :transform="transform"
      />
      <defs>
        <!-- Define the gradient -->
        <linearGradient :id="gradientId" x1="1" y1="1" x2="0.5" y2="1">
          <stop :stop-color="darkerColor" offset="0%" />
          <stop :stop-color="color" offset="100%" />
        </linearGradient>
      </defs>
      <circle
        :stroke="gradientStroke"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
        :transform="transform"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import { calculateDarkerColor } from "@/utility/global";

export default defineComponent({
  name: "UIActivityView",
  components: {
    UILabel,
  },
  emits: ["ui-activity-view-portrait-press"],
  props: {
    radius: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    stroke: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#000000",
    },
    fill: {
      type: String,
      default: "transparent",
    },
    label: {
      type: [Number, String],
      default: "",
    },
    labelTitle: {
      type: String,
      default: "",
    },
    labelSize: {
      type: Number,
      default: 36,
    },
    img: {
      type: String,
    },
    hasImage: {
      type: Boolean,
      defualt: false,
    },
  },
  methods: {
    onPortraitClick() {
      this.$emit("ui-activity-view-portrait-press");
    },
  },
  computed: {
    normalizedRadius(): number {
      return this.radius - this.stroke * 2;
    },
    circumference(): number {
      return this.normalizedRadius * 2 * Math.PI;
    },
    strokeDashoffset(): number {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    transform(): string {
      return `rotate(-90 ${this.radius} ${this.radius})`;
    },
    darkerColor(): string {
      // Calculate darker version of the color
      return calculateDarkerColor(this.color);
    },
    gradientId(): string {
      return `gradient-${this.color}-${this.darkerColor}`;
    },
    gradientStroke(): string {
      return `url(#${this.gradientId})`;
    },
  },
});
</script>

<style lang="scss" scoped>
.UIActivityView {
  position: relative;
}
.vLabel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.vLabelNumber {
  font-weight: 800;
}

.vPortrait {
  position: absolute;
  display: grid;
  justify-content: center;
  width: 100%;
  height: 100%;

  cursor: pointer;

  img {
    border-radius: 50%;
    position: absolute;
    justify-self: center;
  }
}
</style>