export enum ItemType {
  miscellaneous = "miscellaneous",
  // consumable = "consumable",
  // container = "container",
  // weapon = "weapon",
  // armor = "armor",
  // profession = "profession",
  // enchantment = "enchantment",
  // recipe = "recipe",
  // key = "key",
}
