import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_FormulaView = _resolveComponent("FormulaView")!
  const _component_FormulaTableView = _resolveComponent("FormulaTableView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: 'Formula',
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UITabView, {
        id: 'tvTabs',
        tabs: _ctx.tabs,
        selectedTab: _ctx.selectedTab,
        onTabSelected: _ctx.btnSelectTab
      }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
      (_ctx.selectedTab === 'formula')
        ? (_openBlock(), _createBlock(_component_FormulaView, {
            key: 0,
            formula: _ctx.newFormula,
            onFormulaViewChange: _ctx.onFormulaViewChange
          }, null, 8, ["formula", "onFormulaViewChange"]))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 'table')
        ? (_openBlock(), _createBlock(_component_FormulaTableView, {
            key: 1,
            formulaItems: _ctx.newFormulaTable,
            onFormulaTableViewChange: _ctx.onFormulaTableViewChange
          }, null, 8, ["formulaItems", "onFormulaTableViewChange"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]))
}