<template>
  <div class="FormulaView">
    <div class="vTest">
      <UITextField
        :id="'txtTestValue'"
        v-model="tests.value"
        :textLabel="'Test Value'"
        :placeholder="'Test Value'"
      />
      <UITextField
        :id="'txtTestLevel'"
        v-model="tests.level"
        :textLabel="'Test Level'"
        :placeholder="'Test Level'"
      />
      <UITextField
        :id="'txtTestHealth'"
        v-model="tests.main_resource"
        :textLabel="'Test Health'"
        :placeholder="'Test Health'"
      />
    </div>
    <UITextView
      :id="'txtPreview'"
      :disabled="true"
      v-model="preview"
      :placeholder="'Preview'"
    />
    <UITextView
      :id="'txtDescription'"
      :modelValue="newFormula"
      :placeholder="'Formula'"
      @ui-text-view-change="onTextViewChange"
    />
    <div class="vButtons">
      <UIButton :id="'btnMath'" :title="'Math'" @click="btnMath" />
      <UIButton :id="'btnInsert'" :title="'Insert'" @click="btnInsert" />
    </div>
  </div>

  <UIDialogInsertView
    v-if="shouldShowInsertDialog"
    :type="selectedType"
    @ui-dialog-insert-select="onSelectInsertDialog"
    @ui-dialog-insert-cancel="onCancelInsertDialog"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogInsertView from "@/components/character/dialogs/UIDialogInsertView.vue";

import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";

import UIButton from "@/components/UI/UIButton.vue";

import { convertKeysLocalFormula } from "@/utility/tooltip";

export default defineComponent({
  name: "FormulaView",
  components: {
    UIDialogInsertView,
    UITextField,
    UITextView,
    UIButton,
  },
  data() {
    return {
      tests: {
        value: 0,
        level: 0,
        main_resource: 0,
      },
      newFormula: this.formula,
      startPos: 0,
      endPos: 0,
      shouldShowInsertDialog: false,
      selectedType: 'math',
    };
  },
  emits: ["formula-view-change"],
  props: {
    formula: {
      type: String,
      default: "",
    },
  },
  computed: {
    preview(): string | undefined {
      return convertKeysLocalFormula(this.newFormula, this.tests)[0]; //0 being the first return type which is the formula
    },
  },
  methods: {
    onTextViewChange(
      str: string,
      selectionStart: number,
      selectionEnd: number
    ) {
      this.newFormula = str;
      this.startPos = selectionStart;
      this.endPos = selectionEnd;
      this.$emit("formula-view-change", this.newFormula);
    },
    btnInsert() {
      this.selectedType = "character";
      this.onDisplayInsertDialog();
    },
    btnMath() {
      this.selectedType = "math";
      this.onDisplayInsertDialog();
    },
    addValue(text: string) {
      this.newFormula =
        this.newFormula.substring(0, this.startPos) +
        text +
        this.newFormula.substring(this.endPos, this.newFormula.length);
    },
    onDisplayInsertDialog() {
      this.shouldShowInsertDialog = true;
    },
    onSelectInsertDialog(value: String) {
      this.newFormula =
        this.newFormula.substring(0, this.startPos) +
        value +
        this.newFormula.substring(this.endPos, this.newFormula.length);
      this.onCancelInsertDialog();
    },
    onCancelInsertDialog() {
      this.shouldShowInsertDialog = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.vTest,
.vButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>