<template>
  <div :id="id" class="UICollectionView" :hasItems="hasItems">
    <slot><UICollectionViewCell /></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UICollectionViewCell from "@/components/UI/UICollectionViewCell.vue";

export default defineComponent({
  name: "UICollectionView",
  props: {
    id: {
      type: String,
      default: "",
    },
    hasItems: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UICollectionViewCell,
  },
});
</script>

<style lang="scss" scoped>
.UICollectionView {
  position: relative;
  display: grid;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 16px;

  &[hasItems="false"] {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr !important;
  }
}
</style>