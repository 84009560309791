<template>
  <UIDialogView
    :title="`Journal`"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <UITextView
      :id="'txtJournal'"
      v-model="characterJournal"
      :placeholder="'Journal'"
    />
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";

import UITextView from "@/components/UI/UITextView.vue";

export default defineComponent({
  name: "UIDialogJournalView",
  props: {
    journal: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      characterJournal: "",
    };
  },
  emits: ["ui-dialog-journal-submit", "ui-dialog-journal-cancel"],
  components: {
    UITextView,
    UIDialogView,
  },
  methods: {
    ...mapActions("Character", ["setJournal"]),
    onSubmit() {
      this.setJournal(this.characterJournal);
      this.$emit("ui-dialog-journal-submit");
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-journal-cancel");
    },
  },
  mounted() {
    this.characterJournal = this.journal;
  },
});
</script>

<style lang="scss">
</style>