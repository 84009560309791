<template>
  <div>
    <UIOnboardView
      v-if="bags.length < 1"
      :title="'Your Inventory is empty'"
      :body="'Bags are used to group items'"
      :buttonTitle="'Add Bag'"
      @ui-onboard-view="newBag"
    />
    <div v-if="bags.length > 0">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        "
      >
        <UILabel :text="'Bags'" :type="'h1'" />
        <div>
          <div>
            <img
              id="btnItemMenu"
              style="cursor: pointer; margin-right: 8px"
              src="@/assets/icon_menu@x2.png"
              @click="onItemMenu"
            />
          </div>
          <UIMenuView
            v-if="shouldShowItemMenu"
            :items="[
              'Add Item',
              'Rename Bag',
              this.bags.length < 1 || this.hasBlackMarket
                ? 'Add Bag'
                : 'Unlock unlimited bags',
              'Delete Bag',
              'Remove All Items',
            ]"
            @menu-item-selected-at="onMenuSelect"
            @menu-view-dismiss="dismissItemMenu"
          />
        </div>
      </div>
      <UITabView
        :id="'tvBags'"
        :tabs="bags"
        :selectedTab="selected_bag_name"
        @tab-selected="btnBag"
      />
      <div class="section-scroll">
        <UICollectionView
          :id="'cvItems'"
          :hasitems="getSelectedItems.length > 0"
        >
          <UIOnboardView
            v-if="getSelectedItems.length < 1"
            :title="'There are no items'"
            :buttonTitle="'Add Item'"
            @ui-onboard-view="addItem"
          />
          <ItemUICollectionViewCell
            v-for="(item, index) in getSelectedItems"
            :key="index"
            :item="item"
            @click="onDisplayQuickViewDialog(item)"
          />
        </UICollectionView>
      </div>
    </div>

    <UIDialogInputView
      v-if="shouldShowInputDialog"
      @ui-dialog-input-cancel="onCancelInputDialog"
      @ui-dialog-input-submit="onSaveInputDialog"
      :type="inputDialogData.type"
      :title="inputDialogData.name"
      :body="inputDialogData.body"
      :inputPlaceholder="inputDialogData.placeholder"
      :inputTitle="inputDialogData.field"
      :inputValue="inputDialogData.value"
      :buttonTitle="inputDialogData.input"
    />
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      @ui-dialog-prompt-submit="onSavePromptDialog"
      :type="promptDialogData.type"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
    />
    <UIDialogAssetView
      v-if="shouldShowAssetDialog"
      @ui-dialog-asset="onDisplayItemDialog"
      @ui-dialog-asset-cancel="onCancelAssetDialog"
      :title="'Item'"
      :query="'item'"
      :id="selected_bag_name"
    />
    <UIDialogItemView
      v-if="shouldShowItemDialog"
      :item="itemDialogData"
      @ui-dialog-item-submit="onSubmitItemDialog"
      @ui-dialog-item-cancel="onCancelItemDialog"
    />
    <UIDialogPurchaseChenShop
      v-if="shouldShowChenDialog"
      @ui-dialog-purchase-cancel="dismissChenDialog"
    />
    <UIDialogAssetQuickView
      v-if="shouldShowQuickViewDialog"
      :quickView="quickViewDialogData"
      @ui-dialog-quick-view-submit="onSaveQuickViewDialog"
      @ui-dialog-quick-view-cancel="onCancelQuickViewDialog"
      @ui-dialog-quick-view-menu="onMenuQuickViewDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import UIDialogPurchaseChenShop from "@/components/galveon/dialogs/UIDialogPurchaseChenShop.vue";

import UIDialogInputView from "@/components/UI//dialogs/UIDialogInputView.vue";
import UIDialogPromptView from "@/components/UI//dialogs/UIDialogPromptView.vue";
import UIDialogAssetView from "@/components/compendium/UIDialogAssetView.vue";
import UIDialogItemView from "@/components/character/dialogs/UIDialogItemView.vue";
import UIDialogAssetQuickView from "@/components/character/dialogs/UIDialogAssetQuickView.vue";
import ItemUICollectionViewCell from "@/components/character/ItemUICollectionViewCell.vue";

import UICollectionView from "@/components/UI/UICollectionView.vue";
import UITabView from "@/components/UI/UITabView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";
import UIButton from "@/components/UI/UIButton.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import Cost from "@/store/models/Cost";
import Bag from "@/store/models/Bag";
import Item from "@/store/models/Item";
import GameSystem from "@/store/models/GameSystem";
import QuickView from "@/store/models/QuickView";

export default defineComponent({
  name: "CharacterInventoryBags",
  components: {
    ItemUICollectionViewCell,
    UITabView,
    UILabel,
    UIMenuView,
    UIButton,
    UIOnboardView,
    UIDialogInputView,
    UIDialogPromptView,
    UIDialogAssetView,
    UIDialogItemView,
    UICollectionView,
    UIDialogAssetQuickView,
    UIDialogPurchaseChenShop,
  },
  data() {
    return {
      shouldShowItemDialog: false,
      shouldShowInputDialog: false,
      shouldShowPromptDialog: false,
      shouldShowAssetDialog: false,
      shouldShowItemMenu: false,
      shouldShowChenDialog: false,
      shouldShowQuickViewDialog: false,
      promptDialogData: {
        type: Object,
        default: {},
      },
      inputDialogData: {
        type: Object,
        default: {},
      },
      itemDialogData: {
        type: Object,
        default: {},
      },
      quickViewDialogData: {} as QuickView,
    };
  },
  computed: {
    ...mapGetters("Account", ["hasBlackMarket", "getGameSystemWith"]),
    ...mapGetters("Character", [
      "getSelectedBagKey",
      "getBags",
      "getItems",
      "getSelectedItems",
      "isCampaignCharacter",
      "getCampaignId",
    ]),
    selected_bag_name(): string {
      return this.getSelectedBagKey;
    },
    bags(): Array<Bag> {
      return this.getBags;
    },
  },
  methods: {
    ...mapActions("Campaigns", ["setAssetAt"]),
    ...mapActions("Character", [
      "setBag",
      "setItem",
      "setWorkshopItem",
      "setCurrentBag",
      "deleteBag",
      "removeAllItems",
    ]),
    showChenDialog() {
      this.shouldShowChenDialog = true;
    },
    dismissChenDialog() {
      this.shouldShowChenDialog = false;
    },
    btnBag(key: string) {
      this.setCurrentBag(key);
    },
    onMenuSelect(index: number) {
      switch (index) {
        case 0:
          this.addItem();
          break;
        case 1:
          this.renameBag();
          break;
        case 2:
          if (this.bags.length < 1 || this.hasBlackMarket) {
            this.newBag();
          } else {
            this.showChenDialog();
          }
          break;
        case 3:
          this.removeBag();
          break;
        case 4:
          this.showRemoveAllItems();
          break;
      }
    },
    onCancelInputDialog() {
      this.shouldShowInputDialog = false;
    },
    onSaveInputDialog(returnedData: any) {
      let data = returnedData;

      if (data.type == "renameBag" || data.type == "newBag") {
        const newBag: Bag = new Bag(
          {
            name: data.value,
          },
          data.type == "renameBag" ? this.selected_bag_name : ""
        );

        this.setBag(newBag);
      }
    },
    onDisplayInputDialog(data: any) {
      this.inputDialogData = data;
      this.shouldShowInputDialog = true;
    },
    onDisplayAssetDialog(data: any) {
      this.shouldShowAssetDialog = true;
    },
    onCancelAssetDialog() {
      this.shouldShowAssetDialog = false;
    },
    onDisplayPromptDialog(data: any) {
      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onSavePromptDialog(data: any) {
      if (data.type === "removeBag") {
        this.deleteBag();
      } else if (data.type == "removeAllItems") {
        this.removeAllItems();
      }
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onDisplayItemDialog(data: any) {
      this.itemDialogData = data !== undefined ? data : {};
      this.shouldShowItemDialog = true;
    },
    onSubmitItemDialog(data: any) {
      const asset = data.item;
      const isNew = data.isNew;

      if (this.isCampaignCharacter) {
        const item: Item = new Item(
          { ...asset.toDictionary(), campaign_id: this.getCampaignId },
          asset.key
        );

        this.setItem(item);

        const val = {
          path: ["campaigns", this.getCampaignId, "items"],
          data: item.toDictionary(),
        };
        this.setAssetAt(val);
      } else {
        this.setWorkshopItem({
          item: asset,
          shouldUpdateCharacter: !isNew,
        });
      }
    },
    onCancelItemDialog() {
      this.shouldShowItemDialog = false;
    },
    newBag() {
      let data = {
        name: "Add Bag",
        body: "Type the name of a new Bag.",
        field: "Add Bag",
        placeholder: "Add Bag",
        input: "Save",
        type: "newBag",
      };
      this.onDisplayInputDialog(data);
    },
    renameBag() {
      const index = this.bags.findIndex(
        (c: Bag) => c.key == this.selected_bag_name
      );

      if (index > -1) {
        let name = this.bags[index].name;

        let data = {
          name: "Edit Bag name",
          body: "Edit the name of this Bag.",
          field: "Edit Bag name",
          value: name,
          input: "Save",
          placeholder: "Edit Bag name",
          type: "renameBag",
        };
        this.onDisplayInputDialog(data);
      }
    },
    removeBag() {
      const index = this.bags.findIndex(
        (c: Bag) => c.key == this.selected_bag_name
      );

      if (index > -1) {
        let name = this.bags[index].name;

        let data: any = {
          name: "Delete Bag",
          body: `Are you sure you want to delete ${name}? It cannot be undone?`,
          submitTitle: "Continue",
          type: "removeBag",
        };

        this.onDisplayPromptDialog(data);
      }
    },
    showRemoveAllItems() {
      const index = this.bags.findIndex(
        (c: Bag) => c.key == this.selected_bag_name
      );

      if (index > -1) {
        let data: any = {
          name: "Remove All Items",
          body: `Are you sure you want to remove all items in this Bag? It cannot be undone?`,
          submitTitle: "Continue",
          type: "removeAllItems",
        };

        this.onDisplayPromptDialog(data);
      }
    },
    addItem() {
      let data = {
        key_bag: this.selected_bag_name,
      };

      this.onDisplayAssetDialog(data);
    },
    onItemMenu() {
      this.shouldShowItemMenu = true;
    },
    dismissItemMenu() {
      this.shouldShowItemMenu = false;
    },
    onDisplayQuickViewDialog(data: Item) {
      const extras: Array<any> = [];

      let maxLevel: number = data.level;
      let itemCosts: Cost =
        data.costs.length === maxLevel + 1
          ? data.costs[maxLevel]
          : data.costs.slice(data.costs.length - 1)[0];

      if (itemCosts) {
        let gameSystem: GameSystem | undefined = this.getGameSystemWith(
          data.game_system_key
        );
        let costStr: string = itemCosts.getCostToString(
          this.$store.state.Account.selectedCharacter,
          gameSystem
        );
        extras.push({
          name: "Cost",
          value: costStr,
        });
      }

      extras.push({
        name: "Level",
        value: data.level,
      });

      extras.push({
        name: "Rarity",
        value: data.rarity,
      });

      extras.push({
        name: "Type",
        value: data.item_type,
      });

      const quickView = {
        key: data.key,
        name: data.name + " (" + data.count + ")",
        description: data.description,
        secondaryAttributes: extras,
        imageName: "item",
      };

      this.quickViewDialogData = new QuickView(quickView, data.key);
      this.shouldShowQuickViewDialog = true;
    },
    onCancelQuickViewDialog() {
      this.shouldShowQuickViewDialog = false;
    },
    onSaveQuickViewDialog() {},
    onMenuQuickViewDialog(quickViewData: QuickView) {
      this.onCancelQuickViewDialog();
      const key = quickViewData.key;

      const index = this.getSelectedItems.findIndex((c: Item) => c.key == key);

      if (index < 0) return;

      const item: Item = this.getSelectedItems[index];
      this.onDisplayItemDialog(item);
    },
  },
});
</script>

<style lang="scss" scoped>
#cvItems {
  grid-template-columns: repeat(auto-fill, 200px);
}

.section-scroll {
  overflow: auto;
  height: calc(100vh - 430px);
}
</style>