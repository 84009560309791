import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af62e792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "UIOnboardView" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UILabel, {
      text: _ctx.title,
      type: 'h1'
    }, null, 8, ["text"]),
    _createVNode(_component_UILabel, { text: _ctx.body }, null, 8, ["text"]),
    (_ctx.buttonTitle !== '' && !_ctx.hasOptions)
      ? (_openBlock(), _createBlock(_component_UIButton, {
          key: 0,
          id: 'btnOnboard',
          title: _ctx.buttonTitle,
          onClick: _ctx.btnOnboard
        }, null, 8, ["title", "onClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasOptions)
        ? (_openBlock(), _createBlock(_component_UIButton, {
            key: 0,
            id: 'btnOnboard',
            title: _ctx.buttonTitle1,
            onClick: _ctx.btnOnboard1
          }, null, 8, ["title", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.hasOptions)
        ? (_openBlock(), _createBlock(_component_UIButton, {
            key: 1,
            id: 'btnOnboard',
            title: _ctx.buttonTitle2,
            onClick: _ctx.btnOnboard2
          }, null, 8, ["title", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}