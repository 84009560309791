<template>
  <div class="ModificationView">
    <UITabView
      :id="'tvTabs'"
      :tabs="tabs"
      :selectedTab="selectedTab"
      @tab-selected="btnSelectTab"
    />
    <UICollectionView :id="'cvItems'">
      <div
        v-for="(option, index) in availableOptions"
        :key="index"
        @click="onDisplayFormulaDialog(option, index)"
      >
        {{ option.label }} {{ this.localModification[option.key] }}
      </div>
    </UICollectionView>
  </div>
  <div>
    <UIDialogFormulaView
      v-if="shouldShowFormulaDialog"
      :formula="selectedBonus.formula"
      @ui-dialog-formula-submit="onSubmitFormulaDialog"
      @ui-dialog-formula-cancel="onCancelFormulaDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapState } from "vuex";

import { unitModification } from "@/store/definitions/modifications";

import UITabView from "@/components/UI/UITabView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import UIDialogFormulaView from "@/components/character/dialogs/UIDialogFormulaView.vue";
import Bonus from "@/store/models/Bonus";

export default defineComponent({
  name: "ModificationView",
  components: {
    UITabView,
    UICollectionView,
    UIDialogFormulaView,
  },
  emits: ["modifications-update"],
  props: {
    modification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shouldShowFormulaDialog: false,
      tabs: [
        {
          name: "nonCategory",
          key: "nonCategory",
        },
        {
          name: "Spell",
          key: "Spell",
        },
        {
          name: "Behavior",
          key: "Behavior",
        },
        {
          name: "Movement",
          key: "Movement",
        },
        {
          name: "Combat",
          key: "Combat",
        },
        {
          name: "Unit",
          key: "Unit",
        },
      ],
      nonCategory: [],
      spell: [],
      behavior: [
        {
          name: "Attribute Changes",
          formula: "",
          key: "attribute_changes"
        },
      ],
      movement: [],
      combat: [],
      selectedTab: "Unit",
      selectedBonus: {} as any,
      selectedModification: "",
      localModification: {} as Record<string, any>,
      fields: {} as Record<string, any>,
    };
  },
  computed: {
    ...mapState('Account', [
      'workshop'
    ]),
    availableOptions(): Array<any> {
      return this.fields[this.selectedTab.toLowerCase()];
    },
  },
  methods: {
    btnSelectTab(tab: string) {
      this.selectedTab = tab;
    },
    onDisplayFormulaDialog(option: any, index: number) {
      this.selectedBonus = { formula: this.localModification[option.key], key: option.key };
      this.selectedModification = option.key;
      this.shouldShowFormulaDialog = true;
    },
    onSubmitFormulaDialog(formula: string) {
      const index = this.fields[this.selectedTab.toLowerCase()].findIndex(
        (c: any) => c.key == this.selectedModification
      );
      if (index > -1) {
        let mod = this.fields[this.selectedTab.toLowerCase()][index];

        this.fields[this.selectedTab.toLowerCase()][index].formula = formula;
        this.localModification[mod.key] = formula;
      }
      


      // const index = this.localBonuses.findIndex((b: Bonus) => b.key === this.selectedBonus.key);

      // if (index > -1) {
        // this.localBonuses[index].formula = formula;
      // }
      // else {
        // this.localBonuses.push(new Bonus(formula, "", this.selectedBonus.key));
      // }
      
      this.$emit("modifications-update", this.localModification);
    },
    onCancelFormulaDialog() {
      this.shouldShowFormulaDialog = false;
    },
  },
  mounted() {
    this.localModification = this.modification;

    console.log("MOdificationView", this.localModification);

    this.fields["nonCategory"] = this.nonCategory;
    this.fields["spell"] = this.spell;
    this.fields["behavior"] = this.behavior;
    this.fields["movement"] = this.movement;
    this.fields["combat"] = this.combat;
    this.fields["unit"] = unitModification;
    
    // let attributes = this.workshop.attribute;
    
    // Object.keys(attributes).forEach((key) => {
    //   const attr = attributes[key];

    //   this.behavior.push({
    //     name: attr.name,
    //     formula: "",
    //     key: attr.key,
    //   });
    // });
  }
});
</script>

<style lang="scss" scoped>
</style>