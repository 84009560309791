<template>
  <UIDialogView
    :title="'Description'"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <DescriptionView 
      :description="newDescription"
      @description-view-change="onDescriptionViewChange"
    />
  </UIDialogView>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import DescriptionView from "@/components/character/DescriptionView.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UITextView from "@/components/UI/UITextView.vue";

export default defineComponent({
  name: "UIDialogDescriptionView",
  components: {
    UIDialogView,
    DescriptionView,
    UIButton,
    UITextView,
  },
  emits: ["ui-dialog-description-submit", "ui-dialog-description-cancel"],
  data() {
    return {
      newDescription: this.description,
    };
  },
  props: {
    description: {
      type: String,
      default: "",
    },
  },
  methods: {
    onSubmit() {
      this.$emit("ui-dialog-description-submit", this.newDescription);
    },
    onCancel() {
      this.$emit("ui-dialog-description-cancel");
    },
    onDescriptionViewChange(description: string) {
      this.newDescription = description;
    }
  },
});
</script>