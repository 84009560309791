<template>
  <div class="currency">
    <div class="flex">
      <img src="@/assets/icon_currency@x2.png" width="28" />
      <UILabel :id="'lblCurrency'" :text="'Currency'" :type="'h3'" />  
    </div>
    <UISeparatorView />
    <UILabel
      v-if="getCurrencies.length < 1"
      :text="'Tap to add currencies'"
    />
    <div
      v-for="(currency, index) in getCurrencies"
      :key="index"
    >
      <UILabel
        v-if="currency.is_favorite === true"
        :text="`${currency.name}: ${currency.value}`"
        :justify="'left'"
        :font="14"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "CharacterCurrency",
  components: {
    UILabel,
    UISeparatorView,
  },
  computed: {
    ...mapGetters("Character", [
      "getCurrencies"
    ]),
  }
});
</script>

<style lang="scss" scoped>
.currency {
  background: #fff;
  width: 176px;

  padding: 8px;

  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  cursor: pointer;
}

#lblCurrency {
  margin-left: 8px;
}
</style>