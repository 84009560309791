import IAttributeTab from "@/store/interfaces/IAttributeTab";

export default class AttributeTab implements IAttributeTab {
    constructor(dictionary: Record<string, any>, key: string) {
        this.key = key;
        this.name = dictionary["name"] || "New Tab";
        if (this.name === "") {
            this.name = "New Tab"
        }
    }

    key: string;
    name: string;

    toDictionary(): Record<string, any> {
        let returnedDictionary: Record<string, any> = {};

        returnedDictionary["name"] = this.name;
        returnedDictionary["key"] = this.key;

        return returnedDictionary
    }

}