import IVeterancy from "@/store/interfaces/IVeterancy";
import Modification from "@/store/models/Modification";

export default class Veterancy implements IVeterancy {
  constructor(record: Record<string, any>) {
    this.minimum_experience = record["minimum_experience"] ?? 0;
    this.points_gained_attribute = record["points_gained_attribute"] ?? 0;

    const modificationValue = record["modification"] || {};
    this.modification = new Modification(modificationValue);
  }

  minimum_experience: number;
  points_gained_attribute: number;
  modification: Modification;
  
  toDictionary = (): Record<string, any>  => {
    var returnedDictionary: Record<string, any> = {};
        
    returnedDictionary["minimum_experience"] = +this.minimum_experience;
    returnedDictionary["points_gained_attribute"] = +this.points_gained_attribute;
    returnedDictionary["modification"] = this.modification.toDictionary();
    
    return returnedDictionary
  }
}
