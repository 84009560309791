import Attribute from "@/store/models/Attribute";
import AttributeGroup from "@/store/models/AttributeGroup";
import AttributeTab from "@/store/models/AttributeTab";
import { database_save, newKey, setDatabaseCharacter } from "@/utility/database";

export const attributeGetters = {
  getSelectedAttributeTabKey: (state: any): string => {
    return state.character.selected_attribute_tab_key;
  },
  getAttributeTabs: (state: any): Array<AttributeTab> => {
    return state.character.attribute_tabs;
  },
  getAttributeGroups: (state: any): Array<AttributeGroup> => {
    return state.character.attribute_groups;
  },
  getAttributes: (state: any): Array<Attribute> => {
    return state.character.attributes;
  },
};

export const attributeMutations = {
  setCurrentTab: (state: any, key: string) => {
    state.character.selected_attribute_tab_key = key;
  },
  setAttributeTab: (state: any, value: AttributeTab) => {
    if (!value.key) {
      value.key = newKey();
    }

    const index = state.character.attribute_tabs.findIndex((c: AttributeTab) => c.key == value.key);

    if (index > -1) {
      //Tab exists

      state.character.attribute_tabs[index] = value;
    }
    else {
      //Tab doesn't exist

      state.character.attribute_tabs.push(value);
      state.character.selected_attribute_tab_key = value.key;
    }
  },
  deleteTab: (state: any, index: number) => {
    state.character.attribute_tabs.splice(index, 1);
  },
  setAttributeGroup: (state: any, value: AttributeGroup) => {
    if (!value.key) {
      value.key = newKey();
    }

    const index = state.character.attribute_groups.findIndex((c: AttributeGroup) => c.key == value.key);

    if (index > -1) {
      //Tab exists
      state.character.attribute_groups[index] = value;
    }
    else {
      state.character.attribute_groups.push(value);
    }
  },
  removeAttributeGroup: (state: any, index: number) => {
    state.character.attribute_groups.splice(index, 1);
  },
  setAttribute: (state: any, value: Attribute) => {
    const index = state.character.attributes.findIndex((c: Attribute) => c.key == value.key);
    if (index > -1) {
      state.character.attributes[index] = value;
    }
    else {
      state.character.attributes.push(value);
    }
  },
  addAttribute: (state: any, value: Attribute) => {
    state.character.attributes.push(value);
  },
  removeAttribute: (state: any, index: number) => {
    state.character.attributes.splice(index, 1);
  }
};

export const attributeActions = {
  setCurrentTab: ({ commit, state, dispatch }: any, key: string) => {
    return new Promise((resolve, reject) => {
      commit('setCurrentTab', key);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setAttributeTab: ({ commit, state, dispatch }: any, value: AttributeTab) => {
    return new Promise((resolve, reject) => {
      commit('setAttributeTab', value);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  deleteTab: ({ commit, state, dispatch }: any) => {
    return new Promise((resolve, reject) => {
      const index = state.character.attribute_tabs.findIndex((c: AttributeTab) => c.key == state.character.selected_attribute_tab_key);
      if (index > -1) {
        const oldTab: AttributeTab = state.character.attribute_tabs[index];
        
        commit('deleteTab', index);
        let removedGroupIndexes: Array<number> = [];
        let removedAttributeIndex: Array<number> = [];

        state.character.attribute_groups.forEach((group: AttributeGroup, groupIndex: number) => {
          if (group.key_attribute_tab === oldTab.key) {
            state.character.attributes.forEach((attr: Attribute, attrIndex: number) => {
              if (attr.key_attribute_group === group.key) {
                removedAttributeIndex.push(attrIndex);
              }
            });
            removedGroupIndexes.push(groupIndex);
          }
        });
        removedGroupIndexes.reverse();
        removedAttributeIndex.reverse();

        removedAttributeIndex.forEach((n: number) => {
          commit("removeAttribute", n);
        });

        removedGroupIndexes.forEach((n: number) => {
          commit("removeAttributeGroup", n);
        });

        let newTab: AttributeTab = state.character.attribute_tabs[state.character.attribute_tabs.length - 1];

        state.character.selected_attribute_tab_key = newTab == null ? "" : newTab.key;
        
        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
  setAttributeGroup: ({ commit, state, dispatch }: any, value: AttributeGroup) => {
    return new Promise((resolve, reject) => {
      commit('setAttributeGroup', value);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  removeAttributeGroup: ({ commit, state, dispatch }: any, value: AttributeGroup) => {
    return new Promise((resolve, reject) => {
      const index = state.character.attribute_groups.findIndex((c: AttributeGroup) => c.key == value.key);

      if (index > -1) {
        const oldGroup: AttributeGroup = state.character.attribute_groups[index];
        
        commit('removeAttributeGroup', index);
        let removedAttributeIndex: Array<number> = [];
        state.character.attributes.forEach((attr: Attribute, attrIndex: number) => {
          if (attr.key_attribute_group === oldGroup.key) {
            removedAttributeIndex.push(attrIndex);
          }
        });

        removedAttributeIndex.reverse();

        removedAttributeIndex.forEach((n: number) => {
          commit("removeAttribute", n);
        });

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
  setAttribute: ({ commit, state, dispatch }: any, value: Attribute) => {
    return new Promise((resolve, reject) => {
      commit('setAttribute', value);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  addAttribute: ({ commit, state, dispatch }: any, value: Attribute) => {
    return new Promise((resolve, reject) => {
      commit('addAttribute', value);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  removeAttribute: ({ commit, state, dispatch }: any, attribute: Attribute) => {
    return new Promise((resolve, reject) => {
      const index = state.character.attributes.findIndex((c: Attribute) => c.key == attribute.key);

      if (index > -1) {
        commit('removeAttribute', index);

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  }
};
