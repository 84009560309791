<template>
  <UIDialogView
    :title="`${action} Effect`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onItemDialogMenuSelect"
  >
    <ManageAssetView
      :asset="selectedEffect"
      :basicFields="basicFields"
      :isCompendium="isWorkshop"
      :hasAdvanced="false"
    >
      <template v-slot:basic> </template>
      <template v-slot:advanced> </template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      :type="'text'"
      :title="submissionDialogData.title"
      :body="submissionDialogData.body"
      :inputTitle="submissionDialogData.inputTitle"
      :inputPlaceholder="'Description of game system'"
      @ui-dialog-input-submit="onPublish"
      @ui-dialog-input-cancel="onDismissPublishDialog"
    />
    <UIDialogMessageView
      v-if="shouldShowMessageDialog"
      :title="'Success'"
      :body="'Asset successfuly submitted for review.'"
      @ui-dialog-message-submit="onDismissMessageDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";
import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogMessageView from "@/components/UI/dialogs/UIDialogMessageView.vue";

import UICellDropdownField from "@/components/UI/UICellDropdownField.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UIOnboardView from "@/components/UI/UIOnboardView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UIDropDownView from "@/components/UI/UIDropDownView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import ModifyVital from "@/store/models/Effects/ModifyVital";

import { newKey } from "@/utility/database";
import { DamageType } from "@/store/interfaces/Effects/DamageType";

export default defineComponent({
  name: "UIDialogModifyVitalView",
  props: {
    effect: {
      type: Object as () => ModifyVital,
      default: {},
    },
    isWorkshop: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedEffect: {} as ModifyVital,
      shouldShowPromptDialog: false,
      shouldShowInputDialog: false,
      shouldShowMessageDialog: false,
      promptDialogData: {},
      submissionDialogData: {},
      menuItems: ["Duplicate Effect", "Remove Effect"],
      hasMenu: true,
      basicFields: [
        {
          header: null,
          fields: [
            {
              key: "name",
              label: "Name",
              placeholder: "Name",
              type: "textfield",
              valueType: "text",
            },
            {
              key: "chance",
              label: "Chance",
              placeholder: "Chance",
              type: "textfield",
              valueType: "number",
            },
            {
              key: "amount",
              label: "Amount",
              placeholder: "Amount",
              type: "formula",
              valueType: "text",
            },
            {
              key: "minimum",
              label: "Minimum",
              placeholder: "Minimum",
              type: "textfield",
              valueType: "number",
            },
            {
              key: "maximum",
              label: "Maximum",
              placeholder: "Maximum",
              type: "textfield",
              valueType: "number",
            },
            {
              key: "modify_type",
              label: "Modify Type",
              placeholder: "Modify Type",
              type: "dropdown",
              valueType: "text",
              dataSource: Object.keys(DamageType).map(
                (key) => DamageType[key as keyof typeof DamageType]
              ),
            },
          ],
        },
      ],
    };
  },
  emits: ["ui-dialog-effect-submit", "ui-dialog-effect-cancel"],
  components: {
    UIButton,
    UILabel,
    UITextField,
    UITextView,
    ManageAssetView,
    UIDialogView,
    UIDialogPromptView,
    UIDialogInputView,
    UIDialogMessageView,
    UIOnboardView,
    UICollectionView,
    UICellDropdownField,
    UIDropDownView,
  },
  methods: {
    ...mapActions("Account", ["deleteWorkshopAssetModifyVital"]),
    onSubmit() {
      this.$emit("ui-dialog-effect-submit", {
        effect: this.selectedEffect,
        isNew: this.isNew,
      });
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-effect-cancel");
    },
    onItemDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onDuplicateDialog();
      } else if (index === 1) {
        this.onRemoveDialog();
      }
    },
    onDuplicateDialog() {
      let newDuplicateEffect: ModifyVital = new ModifyVital(
        this.selectedEffect.toDictionary(),
        newKey()
      );
      newDuplicateEffect.name = newDuplicateEffect.name + " (Copy)";
      this.$emit("ui-dialog-effect-submit", {
        effect: newDuplicateEffect,
        isNew: true,
      });
      this.onCancel();
    },
    onRemoveDialog() {
      let data: any = {};

      if (this.isWorkshop) {
        data = {
          name: `Delete ${this.selectedEffect.name}`,
          body: `Are you sure you want to delete ${this.selectedEffect.name} from your Compendium? This cannot be undone.`,
          submitTitle: "Continue",
          type: "deleteAsset",
        };
      }

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog(data: any) {
      if (data.type === "removeAsset") {
        // this.removeEffect(this.selectedEffect);
      } else if (data.type === "deleteAsset") {
        this.deleteWorkshopAssetModifyVital(this.selectedEffect);
      }

      this.onCancel();
    },
    onShowMessageDialog() {
      this.shouldShowMessageDialog = true;
    },
    onDismissMessageDialog() {
      this.shouldShowMessageDialog = false;
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.selectedEffect = new ModifyVital(
      this.effect.toDictionary(),
      this.effect.key
    );

    if (this.isWorkshop) {
      this.menuItems = ["Duplicate Effect", "Delete Effect"];
    }

    this.hasMenu = !this.isNew;
  },
});
</script>
