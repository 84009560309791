import { User, getAuth } from 'firebase/auth';
import { getDatabase, ref, set, child, DatabaseReference, onValue } from 'firebase/database';

import { newDate } from '@/utility/global';

import AssetSubmit from '../models/AssetBases/AssetSubmit';
import CompendiumAssetBase from '../models/Compendium/CompendiumAssetBase';
import { newKey } from '@/utility/database';
import GameSystem from '../models/GameSystem';
import AttributeBase from '../models/AssetBases/AttributeBase';
import ItemBase from '../models/AssetBases/ItemBase';
import ResourceBase from '../models/AssetBases/ResourceBase';
import SpellBase from '../models/AssetBases/SpellBase';
import Version from '../models/AssetBases/Version';

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
    async publishItem({ rootGetters }: any, payload: any) {
        const database: DatabaseReference = ref(getDatabase());
        let asset = payload.asset;
        const preparedAssetDictionary = payload.preparedAssetDictionary;

        const communityAsset: ItemBase = new ItemBase(asset.toDictionary(), asset.key);

        const submitAsset: AssetSubmit = new AssetSubmit(preparedAssetDictionary, [communityAsset], newKey());

        return await set(child(database, `pending_publication/item/${submitAsset.key}`), submitAsset.toDictionary());
    },
    async publishResource({ rootGetters }: any, payload: any) {
        const database: DatabaseReference = ref(getDatabase());
        let asset = payload.asset;

        const preparedAssetDictionary = payload.preparedAssetDictionary;

        const communityAsset: ResourceBase = new ResourceBase(asset.toDictionary(), asset.key);

        const submitAsset: AssetSubmit = new AssetSubmit(preparedAssetDictionary, [communityAsset], newKey());

        return await set(child(database, `pending_publication/resource/${submitAsset.key}`), submitAsset.toDictionary());
    },
    async publishSpell({ rootGetters }: any, payload: any) {
        const database: DatabaseReference = ref(getDatabase());
        let asset = payload.asset;
        const preparedAssetDictionary = payload.preparedAssetDictionary;

        const communityAsset: SpellBase = new SpellBase(asset.toDictionary(), asset.key);

        const submitAsset: AssetSubmit = new AssetSubmit(preparedAssetDictionary, [communityAsset], newKey());

        return await set(child(database, `pending_publication/spell/${submitAsset.key}`), submitAsset.toDictionary());
    },
    async publishAttribute({ rootGetters }: any, payload: any) {
        const database: DatabaseReference = ref(getDatabase());
        let asset = payload.asset;

        const preparedAssetDictionary = payload.preparedAssetDictionary;

        const communityAsset: AttributeBase = new AttributeBase(asset.toDictionary(), asset.key);

        const submitAsset: AssetSubmit = new AssetSubmit(preparedAssetDictionary, [communityAsset], newKey());

        return await set(child(database, `pending_publication/attribute/${submitAsset.key}`), submitAsset.toDictionary());
    },
    async publishGameSystem({ rootGetters }: any, payload: any) {
        const database: DatabaseReference = ref(getDatabase());
        let asset = payload.asset;
        const preparedAssetDictionary = payload.preparedAssetDictionary;

        const communityAsset: GameSystem = new GameSystem(asset.toDictionary(), asset.key);

        const submitAsset: AssetSubmit = new AssetSubmit(preparedAssetDictionary, [communityAsset], newKey());

        return await set(child(database, `pending_publication/game_system/${submitAsset.key}`), submitAsset.toDictionary());
    },
    async publish({ dispatch, rootGetters }: any, payload: any) {
        let { description, version, asset, gameSystemKey, gameSystemName } = payload;
        const { type } = asset;
        const { major, minor, patch } = version;

        let newVersion: Version = new Version(+major, +minor, +patch);
        asset.version = newVersion;

        payload.preparedAssetDictionary = publishAssetHelper(asset, description, gameSystemKey, gameSystemName, rootGetters['Account/getGalveonUserId']);

        switch (type) {
            case "game_system":
                dispatch("publishGameSystem", payload);
                break;
            case "attribute":
                dispatch("publishAttribute", payload);
                break;
            case "spell":
                dispatch("publishSpell", payload);
                break;
            case "resource":
                dispatch("publishResource", payload);
                break;
            case "item":
                dispatch("publishItem", payload);
                break;
        }
    },
}

const publishAssetHelper = (submitAsset: CompendiumAssetBase, description: string, game_system_key: string, game_system_name: string, galveonUserId: string): Record<string, any> => {
    const user: User | null = getAuth().currentUser;

    if (!user) {
        return {};
    }

    const preparedAssetDictionary: Record<string, any> = {
        "name": submitAsset.name,
        "description": description,
        "game_system_key": game_system_key ?? "",
        "game_system_name": game_system_name?? "",
        "galveon_user_id": galveonUserId,
        "date_created": newDate()
    };

    return preparedAssetDictionary;
}



export const Publish = { namespaced: true, state, getters, mutations, actions }