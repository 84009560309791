<template>
  <UIDialogView
    :title="'Insert Key'"
    :hasDone="true"
    @ui-dialog-cancel="onCancel"
  >
    <div class="UIDialogInsertView">
        <UICollectionView :id="'cvInsert'">
            <div
              class="InsertUICollectionViewCellContainer"
              v-for="(value, index) in type === 'math' ? mathValues : characterValues"
              :key="index"
            >
                <div class="InsertUICollectionViewCell">
                    <UILabel :text="value.section" :isSelected="true" />
                </div>
              <UICollectionView :id="'cvOptions'">
                <div
                  class="InsertView"
                  v-for="(option, index) in value.options"
                  :key="index"
                  @click="onSelect(option.value)"
                >
                    <div>{{ option.label }}</div>
                </div>
              </UICollectionView>
            </div>
        </UICollectionView>
    </div>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UICollectionView from "@/components/UI/UICollectionView.vue";
import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: "UIDialogInsertView",
  components: {
    UIDialogView,
    UICollectionView,
    UILabel,
  },
  emits: ["ui-dialog-insert-select", "ui-dialog-insert-cancel"],
  props: {
    type: {
      type: String,
      required: true,
      default: 'math'
    }
  },
  data() {
    return {
      characterValues: [
        {
          section: "Character",
          options: [
            {
              label: "Main Resource", value: "[@Main_Resource]", key: "Main_Resource"
            },
            {
              label: "Level", value: "[@Level]", key: "Level"
            }
          ]
        },
        {
          section: "Attribute",
          options: [
            {
              label: "Value", value: "[@Value]", key: "Value"
            }
          ]
        }
      ],
      mathValues: [
        {
          section: "Statistics",
          options: [
            {
              label: "Average", value: "average({2,2})"
            },
            {
              label: "Sum", value: "sum({2,2})"
            },
            {
              label: "Count", value: "count({2,2})"
            },
            {
              label: "Min", value: "min({2,1})"
            },
            {
              label: "Max", value: "max({1,2})"
            },
            {
              label: "Median", value: "median({2,1,3})"
            },
            {
              label: "Mode", value: "mode({1,3,2})"
            },
            {
              label: "Standard Deviation", value: "stddev({2,2})"
            },
          ]
        },
        {
          section: "Basic Arithmetic",
          options: [
            {
              label: "Absolute Value", value: "abs(1)"
            }
          ]
        },
        {
          section: "Advanced Arithmetic",
          options: [
            {
              label: "Square Root", value: "sqrt(4)"
            },
            {
              label: "log", value: "log(10)"
            },
            {
              label: "ln", value: "ln(1)"
            },
            {
              label: "exp", value: ""
            }
          ]
        },
        {
          section: "Bounding Functions",
          options: [
            {
              label: "ceiling", value: "ceiling(1.1)"
            },
            {
              label: "trunc", value: "trunc(1.8)"
            },
            {
              label: "floor", value: "floor(1.8)"
            }
          ]
        },
        {
          section: "Random Functions",
          options: [
            {
              label: "Random Number", value: "randomn(1)+0"
            }
          ]
        }
      ]
    }
  },
  methods: {
    onSelect(value: String) {
      this.$emit("ui-dialog-insert-select", value);
    },
    onCancel() {
      this.$emit("ui-dialog-insert-cancel");
    },
  }
});
</script>

<style lang="scss" scoped>
#cvInsert {
  height: 250px;
  overflow: auto;
}

.InsertUICollectionViewCellContainer {
  padding-bottom: 0;
  text-align: left;

  border: 1px solid rgba(0, 0, 0, 0.16);

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.InsertUICollectionViewCell {
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: var(--btn_norm);
    color: var(--galveonTextColor);
}

.InsertView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;

  min-height: 44px;
  
  cursor: pointer;

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(odd) {
    background: #ffffff;
  }
}
</style>