import CompendiumAssetBase from '@/store/models/Compendium/CompendiumAssetBase';
import { Account } from '@/store/modules/account';

export const convertKeysLocalFormula = (str: string, tests: any, count: number = 0): [string | undefined, boolean, Array<any>] => {
    let testValue = tests.value;
    let testLevel = tests.level;
    let testMain = tests.main_resource;

    str = str.replaceAll("[@value]", testValue)
        .replaceAll("[@level]", testLevel)
        .replaceAll("[@vital_maximum_bonus]", testMain);
    if (!str) {
        return [undefined, false, []];
    }
    return [calculateExpression(str), true, []];
}

const calculateExpression = (
    expression: string
): any => {
    try {
        const functionDefinitions = Object.entries(customFunctions).map(
            ([name]) => `const ${name} = customFunctions.${name};`
        );

        const func = new Function(
            'customFunctions',
            `
        ${functionDefinitions.join('\n')}
        return ${expression};
      `
        );

        return func(customFunctions);
    } catch (ex) {
        return 'Invalid formula';
    }
};

// const calculateExpression = (obj: string): string => {
//     console.log(obj);
//     try {
//         return Function(`return ${obj}`)();
//     }
//     catch (ex) {
//         return "Invalid formula";
//     }
// }

export const convertKeysLocalDescription = (str: string): string => {
    let newStr = str;
    var regex: RegExp = new RegExp("(?:\\[)(.*?)(?:\\])", "g");
    var allFormulas = newStr.match(regex);

    allFormulas?.forEach(formula => {
        let form = formula.match(new RegExp("[\\w'-]+", "g"));

        if (form) {
            let field = form[0];
            let type = form[1];
            let uid = form[2];

            // if (type === "Spell" ||
            //     type === "Attribute" ||
            //     type === "Item" ||
            //     type === "Resource") {
            //     let workshop = Account.state.workshop[type];
            //     let workshopAsset = workshop[uid];

            //     if (field === "Name") {
            //         let workshopField = workshopAsset[field];

            //         console.log(workshopField);
            //     }

            // }
            // else if (type === "Attribute") {
            //     let workshop = Account.state.workshop[type];
            //     let workshopAsset: IWorkshopAttribute = workshop[uid];
            //     if (field === "Name") {


            //         let workshopField: string = workshopAsset[field];

            //         console.log(workshopField);

            //     }
            // }
            
            switch (type) {
                case "Spell":
                    // let selectedWorkshop = workshop.spells;
                    // if let workshopAsset = selectedWorkshop.first(where: { $0.key == uid }) {
                    //     if (field == "Name") {
                    //         let workshopField = workshopAsset.name
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: workshopField)
                    //     }
                    //     else if (field == "Description") {
                    //         let workshopField = workshopAsset.description
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: workshopField)
                    //     }
                    // }
                    break
                case "attribute":
                    
                    let selectedWorkshop = Account.state.workshop.attribute;
                    const index = selectedWorkshop.findIndex((a: CompendiumAssetBase) => a.key == uid);
                    if (index < 0) {
                        break;
                    }
                    const workshopAsset = selectedWorkshop[index];
                    if (workshopAsset) {
                        if (field == "name") {
                            let workshopField = workshopAsset.name;
                            newStr = newStr.replaceAll(formula, workshopField);
                        }
                        // else if (field == "Value") {
                        //     let workshopField = workshopAsset.value
                        //     newStr = newStr.replaceAll(formula, workshopField);
                        // }
                        else if (field == "description") {
                            let workshopField = workshopAsset.description;
                            newStr = newStr.replaceAll(formula, workshopField);
                        }
                        // else if (field == "Modifier_Formula") {
                        //     let workshopField = workshopAsset.modifier_Formula;
                        //     newStr = newStr.replaceAll(formula, workshopField.toString());
                        // }
                        // else if (field == "Show_Modifier") {
                        //     let workshopField = workshopAsset.show_Modifier;
                        //     newStr = newStr.replaceAll(formula, workshopField.toString());
                        // }
                    }
                    break;
                case "Item":
                    // let selectedWorkshop = Account.state.workshop.Item;
                    // if let workshopAsset = selectedWorkshop.first(where: { $0.key == uid }) {
                    //     if (field == "Name") {
                    //         let workshopField = workshopAsset.name
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: workshopField)
                    //     }
                    //     else if (field == "Description") {
                    //         let workshopField = workshopAsset.description
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: workshopField)
                    //     }
                    //     else if (field == "Count") {
                    //         let workshopField = workshopAsset.count
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: String(describing: workshopField))
                    //     }
                    // }
                    break;
                case "Resource":
                    // let selectedWorkshop = workshop.resources;
                    // if let workshopAsset = selectedWorkshop.first(where: { $0.key == uid }) {
                    //     if (field == "Name") {
                    //         let workshopField = workshopAsset.name
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: workshopField)
                    //     }
                    //     else if (field == "Current") {
                    //         let workshopField = workshopAsset.current
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: String(describing: workshopField))
                    //     }
                    //     else if (field == "Max") {
                    //         let workshopField = workshopAsset.max
                    //         returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: String(describing: workshopField))
                    //     }
                    // }
                    break;
                case "Character_Stats":
                    if (uid.toLowerCase() == "main_vital") {
                        // if (field == "Name") {
                        //     let workshopField = character?.Main_Resource.Name || "Main Resource Name"
                        //     returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: workshopField)
                        // }
                        // else if (field == "Current") {
                        //     let workshopField = character?.Main_Resource.Current || 0
                        //     returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: String(describing: workshopField))
                        // }
                        // else if (field == "Max") {
                        //     let workshopField = character?.Main_Resource.Max || 0
                        //     returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: String(describing: workshopField))
                        // }
                    }
                    else if (uid.toLowerCase() == "level") {
                        // if (field == "Value") {
                        //     let workshopField = character?.Level || 1
                        //     returnedDescription = returnedDescription.replacingOccurrences(of: "\(result)", with: String(describing: workshopField))
                        // }
                    }
                    break;
                default:
                    break;
            }



            //[|Name|Attribute|-MDwbQgInCsi_BtoS_T1|]
        }
    });

    return newStr;
}


// Define custom functions with types
const customFunctions: { [key: string]: (...args: any[]) => any } = {
    random: () => Math.random(),
    squart_root: (x: number) => Math.sqrt(x),
    log: (x: number) => Math.log10(x),
    ln: (x: number) => Math.log(x),
    abs: (x: number) => Math.abs(x),
    ceiling: (x: number) => Math.ceil(x),
    trunc: (x: number) => Math.trunc(x),
    floor: (x: number) => Math.floor(x),
    average: (arr: number[]) =>
        arr.reduce((acc, val) => acc + val, 0) / arr.length,
    sum: (arr: number[]) => arr.reduce((acc, val) => acc + val, 0),
    count: (arr: any[]) => arr.length,
    min: (arr: number[]) => Math.min(...arr),
    max: (arr: number[]) => Math.max(...arr),
    median: (arr: number[]) => {
        const sortedArr = [...arr].sort((a, b) => a - b);
        const middle = Math.floor(sortedArr.length / 2);
        if (sortedArr.length % 2 === 0) {
            return (sortedArr[middle - 1] + sortedArr[middle]) / 2;
        } else {
            return sortedArr[middle];
        }
    },
    mode: (arr: any[]) => {
        const countMap = new Map<any, number>();
        arr.forEach((item) => {
            countMap.set(item, (countMap.get(item) || 0) + 1);
        });

        let maxCount = 0;
        let mode = null;

        countMap.forEach((count, key) => {
            if (count > maxCount) {
                maxCount = count;
                mode = key;
            }
        });

        return mode;
    },
    standard_deviation: (arr: number[]) => {
        const mean = customFunctions.average(arr);
        const squaredDifferences = arr.map((value) => (value - mean) ** 2);
        const variance = customFunctions.average(squaredDifferences);
        return Math.sqrt(variance);
    },
};
