<template>
  <div class="ResourceView">
    <div>
      <UILabel v-show="experience.show_name" :text="experience.name" />
      <div v-show="experience.show_value">
        <span v-show="experience.show_current">{{ currentExperience }}</span
        ><span v-show="experience.show_current && experience.show_max"> / </span
        ><span v-show="experience.show_max">{{ maxExperience }}</span>
      </div>
      <UILabel
        v-show="experience.show_percentage"
        :text="experiencePercentage + '%'"
      />
    </div>
    <UICharacterActivityView
      :mainResource="mainResource"
      :experience="experience"
      :level="level"
      :hasImg="hasImg"
      :showLevel="showLevel"
    />
    <div class="vHealthContainer">
      <div class="vHealth">
        <UILabel v-show="mainResource.show_name" :text="mainResource.name" />
        <div v-show="mainResource.show_value">
          <span v-show="mainResource.show_current">{{ currentMainVital }}</span
          ><span v-show="mainResource.show_current && mainResource.show_max">
            / </span
          ><span v-show="mainResource.show_max">{{ maxMainVital }}</span>
        </div>
        <UILabel
          v-show="mainResource.show_percentage"
          :text="percentageMainVital + '%'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapGetters } from "vuex";

import UICharacterActivityView from "@/components/UI/galveon/UICharacterActivityView.vue";

import UIActivityView from "@/components/UI/UIActivityView.vue";
import UILabel from "@/components/UI/UILabel.vue";

import MainResource from "@/store/models/MainResource";
import Experience from "@/store/models/Experience";

export default defineComponent({
  name: "MainResourceView",
  components: {
    UICharacterActivityView,
    UIActivityView,
    UILabel,
  },
  props: {
    mainResource: {
      type: Object as () => MainResource,
      required: true,
      default: {},
    },
    experience: {
      type: Object as () => Experience,
      default: {},
    },
    level: {
      type: Number,
      default: 0,
    },
    hasImg: {
      type: Boolean,
      default: false,
    },
    showLevel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Character", ["getCharacter"]),
    currentMainVital(): string {
      const [hasBonus, current, max, percentage] =
        this.mainResource.getValueWithBonus(this.getCharacter);
      return current;
    },
    maxMainVital(): string {
      const [hasBonus, current, max, percentage] =
        this.mainResource.getValueWithBonus(this.getCharacter);
      return max;
    },
    percentageMainVital(): number {
      const [hasBonus, current, max, percentage] =
        this.mainResource.getValueWithBonus(this.getCharacter);
      let percent = percentage * 100;
      if (
        percent === Infinity ||
        percent === -Infinity ||
        isNaN(percent)
      ) {
        return 100;
      }
      return Math.trunc(percent);
    },
    currentExperience(): string {
      const [hasBonus, current, max, percentage] =
        this.experience.getValueWithBonus(this.getCharacter);
      return current;
    },
    maxExperience(): string {
      const [hasBonus, current, max, percentage] =
        this.experience.getValueWithBonus(this.getCharacter);
      return max;
    },
    experiencePercentage(): number {
      const [hasBonus, current, max, percentage] =
        this.experience.getValueWithBonus(this.getCharacter);
      let percent = percentage * 100;
      if (
        percent === Infinity ||
        percent === -Infinity ||
        isNaN(percent)
      ) {
        return 100;
      }
      return Math.trunc(percent);
    },
  },
});
</script>

<style lang="scss" scoped>
.ResourceView {
  position: relative;
  display: flex;
  padding: 0 32px;
}

.vHealthContainer {
  position: relative;
}

.vHealth {
  position: absolute;
  bottom: 0;
}
</style>