import IItem from "../interfaces/IItem";

export default class Bag {
    constructor(dictionary: Record<string, any>, key: string) {
        this.key = key;
        this.name = dictionary["name"] || "New Bag";
        if (this.name === "") {
            this.name = "New Bag"
        }
    }

    key: string;
    name: string;

    toDictionary = (): Record<string, any> => {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["name"] = this.name;
        returnedDictionary["key"] = this.key;

        return returnedDictionary
    }
}