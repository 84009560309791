import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07e13b86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "character-portrait" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIActivityView = _resolveComponent("UIActivityView")!
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UIActivityView, {
      radius: _ctx.radius,
      progress: _ctx.mainResourcePercentage,
      stroke: _ctx.stroke,
      color: _ctx.mainResourceColor,
      img: _ctx.img,
      hasImage: true
    }, null, 8, ["radius", "progress", "stroke", "color", "img"]),
    _createVNode(_component_UILabel, { text: _ctx.name }, null, 8, ["text"])
  ]))
}