<template>
  <UIDialogView
    :title="'Compendium'"
    :hasDone="true"
    @ui-dialog-submit="onCancel"
    @ui-dialog-cancel="onCancel"
  >
    <AssetView
      :title="title"
      :queryId="query"
      :id="id"
      :isCommunity="isCommunity"
      :selectOnly="true"
    />
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import AssetView from "@/components/compendium/AssetView.vue";

export default defineComponent({
  name: "UIDialogAssetView",
  components: {
    UIDialogView,
    AssetView,
  },
  props: {
    title: String,
    query: String,
    id: {
      type: String,
      default: "",
      require: true,
    },
    isCommunity: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCancel() {
      this.$emit("ui-dialog-asset-cancel");
    },
  },
});
</script>

<style lang="scss">
</style>