<template>
  <UIDialogView
    :title="`${action} Game System`"
    :canRemove="action == 'Edit'"
    :hasMenu="hasMenu"
    :menuItems="menuItems"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onGameSystemDialogMenuSelect"
  >
    <ManageAssetView
      :asset="selectedGameSystem"
      :hasCompendium="false"
      :hasAdvanced="false"
    >
      <template v-slot:basic>
        <UITextField
          :id="'txtName'"
          :title="'System name'"
          :textLabel="'System name'"
          v-model="selectedGameSystem.name"
          :placeholder="'System name'"
          :disabled="readOnly"
        />
        <UITextView
          :id="'txtDescription'"
          v-model="selectedGameSystem.description"
          :placeholder="'Description'"
          :disabled="readOnly"
        />
        <div
          class="flex"
          v-if="
            selectedGameSystem.currencies &&
            selectedGameSystem.currencies?.length > 0
          "
        >
          <UILabel :text="'Currencies'" :type="'h2'" />
          <img
            v-if="!readOnly"
            src="@/assets/icon_plus@x1.png"
            style="cursor: pointer"
            @click="onDisplayCurrencyDialog()"
          />
        </div>
        <UICollectionView :id="'cvCurrencies'">
          <UIOnboardView
            v-if="
              !selectedGameSystem.currencies ||
              selectedGameSystem.currencies?.length < 1
            "
            :title="'No Currencies'"
            :buttonTitle="'Add Currency'"
            @ui-onboard-view="onDisplayCurrencyDialog()"
          />
          <UILabel
            v-for="(currency, index) in selectedGameSystem.currencies"
            :key="index"
            :text="currency.name"
            @click="onDisplayCurrencyDialog(index, currency)"
          />
        </UICollectionView>
      </template>
      <template v-slot:advanced></template>
    </ManageAssetView>
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
    <UIDialogPublishView
      v-if="shouldShowPublishDialog"
      :asset="game_system"
      :body="'Submitting this Game System will share it with the entire community. Are you sure you want to submit this Game System?'"
      @ui-dialog-publish-cancel="onCancelPublishDialog"
    />
    <UIDialogMessageView
      v-if="shouldShowMessageDialog"
      :title="'Success'"
      :body="'Asset successfuly submitted for review.'"
      @ui-dialog-message-submit="onDismissMessageDialog"
    />
    <UIDialogManageCurrency
      v-if="shouldShowCurrencyDialog"
      :selectedCurrency="currencyDialogData.inputValue"
      @ui-dialog-currency-submit="onSubmitCurrencyDialog"
      @ui-dialog-currency-delete="onDeleteCurrencyDialog"
      @ui-dialog-currency-cancel="onHideCurrencyDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogView from "@/components/UI//dialogs/UIDialogView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";
import UIDialogMessageView from "@/components/UI/dialogs/UIDialogMessageView.vue";

import UIDialogPublishView from "@/components/compendium/UIDialogPublishView.vue";

import UIDialogManageCurrency from "@/components/character/dialogs/UIDialogManageCurrency.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";
import UIOnboardView from "@/components/UI/UIOnboardView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import GameSystem from "@/store/models/GameSystem";
import Currency from "@/store/models/Currency";
import { newKey } from "@/utility/database";

export default defineComponent({
  name: "UIDialogGameSystemView",
  props: {
    game_system: {
      type: Object as () => GameSystem,
      default: {},
    },
    isWorkshop: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedGameSystem: {} as GameSystem,
      shouldShowPromptDialog: false,
      shouldShowInputDialog: false,
      shouldShowMessageDialog: false,
      shouldShowPublishDialog: false,
      shouldShowCurrencyDialog: false,
      promptDialogData: {},
      currencyDialogData: {},
      menuItems: ["Publish Game System", "Remove Game System"],
      hasMenu: true,
      currencyIndex: -1,
    };
  },
  emits: ["ui-dialog-game-system-submit", "ui-dialog-game-system-cancel"],
  components: {
    UIButton,
    UILabel,
    UITextField,
    UITextView,
    ManageAssetView,
    UIDialogView,
    UIDialogPromptView,
    UIDialogMessageView,
    UICollectionView,
    UIOnboardView,
    UIDialogPublishView,
    UIDialogManageCurrency,
  },
  methods: {
    ...mapActions("Account", ["deleteWorkshopAssetGameSystem"]),
    onSubmit() {
      this.$emit("ui-dialog-game-system-submit", {
        game_system: this.selectedGameSystem,
        isNew: this.isNew,
      });
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-game-system-cancel");
    },
    onGameSystemDialogMenuSelect(index: number) {
      if (index === 0) {
        this.onShowPublishDialog();
      } else if (index === 1) {
        this.onRemoveDialog();
      }
    },
    onRemoveDialog() {
      let data = {
        name: `Delete ${this.selectedGameSystem.name}`,
        body: `Are you sure you want to delete ${this.selectedGameSystem.name} from your Compendium? This cannot be undone.`,
        submitTitle: "Continue",
        type: "deleteAsset",
      };

      this.promptDialogData = data;
      this.shouldShowPromptDialog = true;
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onCancelInputDialog() {
      this.shouldShowInputDialog = false;
    },
    onSubmitPromptDialog(data: any) {
      if (data.type === "deleteAsset") {
        this.deleteWorkshopAssetGameSystem(this.selectedGameSystem);
      }

      this.onCancel();
    },
    onShowPublishDialog() {
      this.shouldShowPublishDialog = true;
    },
    onCancelPublishDialog() {
      this.shouldShowPublishDialog = false;
    },
    onShowMessageDialog() {
      this.shouldShowMessageDialog = true;
    },
    onDismissMessageDialog() {
      this.shouldShowMessageDialog = false;
    },
    onDisplayCurrencyDialog(index: number = -1, data: Currency | undefined) {
      if (this.readOnly) return;
      this.currencyIndex = index;
      this.currencyDialogData = {
        title:
          index > -1
            ? `Edit ${this.selectedGameSystem.currencies[index].name}`
            : "Add Currency",
        body:
          index > -1
            ? `Edit the name of the Currency.`
            : "Set the name of the Currency.",
        inputTitle: `Save Currency`,
        placeholder: "Name",
        type: "currency",
        inputValue: data == undefined ? new Currency({}, "") : data,
      };

      this.shouldShowCurrencyDialog = true;
    },
    onHideCurrencyDialog() {
      this.shouldShowCurrencyDialog = false;
    },
    onSubmitCurrencyDialog(newCurrency: Currency) {
      if (this.currencyIndex === -1) {
        if (!this.selectedGameSystem.currencies) {
          this.selectedGameSystem.currencies = [];
        }
        newCurrency.key = newKey();
        this.selectedGameSystem.currencies.push(newCurrency);
      } else {
        const index = this.currencyIndex;
        let selectedCurrency: Currency =
          this.selectedGameSystem.currencies[index];
        selectedCurrency.name = newCurrency.name;

        this.selectedGameSystem.currencies[index] = selectedCurrency;
      }
    },
    onDeleteCurrencyDialog(currency: Currency) {
      const index: number = this.selectedGameSystem.currencies.findIndex(
        (c: Currency) => c.key == currency.key
      );
      if (index > -1) {
        this.selectedGameSystem.currencies.splice(this.currencyIndex, 1);
      }
    },
  },
  computed: {
    action(): string {
      if (this.isNew) {
        return "Create";
      }
      return "Edit";
    },
  },
  mounted() {
    this.selectedGameSystem = new GameSystem(
      this.game_system.toDictionary(),
      this.game_system.key
    );

    if (this.isWorkshop) {
      this.menuItems = ["Publish Game System", "Delete Game System"];
    }

    this.hasMenu = !this.isNew;
  },
});
</script>
