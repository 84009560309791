<template>
  <div class="DescriptionView">
    <UITextView :id="'txtPreview'" v-model="preview" :placeholder="'Preview'" :disabled="true" />
    <UITextView
      :id="'txtDescription'"
      :modelValue="newDescription"
      :placeholder="'Description'"
      @ui-text-view-change="onTextViewChange"
    />
    <UIButton
      :id="'btnChooseField'"
      :title="'Choose Field'"
      @click="btnChooseField()"
    />
  </div>

  <UIDialogChooseField
    v-if="shouldShowChooseFieldDialog"
    @ui-dialog-choose-field-submit="onChooseFieldSubmit"
    @ui-dialog-choose-field-cancel="onChooseFieldCancel"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UITextField from "@/components/UI/UITextField.vue";
import UITextView from "@/components/UI/UITextView.vue";

import UIButton from "@/components/UI/UIButton.vue";

import UIDialogChooseField from "@/components/character/dialogs/UIDialogChooseField.vue";

import { convertKeysLocalDescription } from "@/utility/tooltip";

export default defineComponent({
  name: "DescriptionView",
  components: {
    UITextField,
    UITextView,
    UIButton,
    UIDialogChooseField,
  },
  data() {
    return {
      newDescription: this.description,
      startPos: 0,
      endPos: 0,
      shouldShowChooseFieldDialog: false,
    };
  },
  emits: ["description-view-change"],
  props: {
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    preview(): string {
      return convertKeysLocalDescription(this.newDescription);
    },
  },
  methods: {
    onTextViewChange(
      str: string,
      selectionStart: number,
      selectionEnd: number
    ) {
      this.newDescription = str;
      this.startPos = selectionStart;
      this.endPos = selectionEnd;
      this.$emit("description-view-change", this.newDescription);
    },
    btnChooseField() {
      this.shouldShowChooseFieldDialog = true;
    },
    onChooseFieldSubmit(data: any) {
      const { type, asset, assetId, field } = data;
      
      this.newDescription += `[|${field}|${type}|${assetId}|]`;

      this.$emit("description-view-change", this.newDescription);
    },
    onChooseFieldCancel() {
      this.shouldShowChooseFieldDialog = false;
    }
  },
});
</script>

<style lang="scss" scoped>
.vTest,
.vButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>