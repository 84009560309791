import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f08f4aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "UITextViewContainer" }
const _hoisted_2 = {
  key: 1,
  class: "UITextViewError"
}
const _hoisted_3 = ["value", "placeholder", "has-error", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideLabel)
      ? (_openBlock(), _createBlock(_component_UILabel, {
          key: 0,
          text: _ctx.placeholder,
          justify: 'left'
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    (_ctx.hasError == true)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      class: "UITextView",
      value: _ctx.textViewPreview,
      placeholder: _ctx.placeholder,
      "has-error": _ctx.hasError,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, " \n    <!-- TODO: Will need to capture all events that could trigger a selection change so that it's accurate. -->\n    ", 40, _hoisted_3)
  ]))
}