import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35d7a6fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "UIDialogInsertView" }
const _hoisted_2 = { class: "InsertUICollectionViewCell" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: 'Insert Key',
    hasDone: true,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UICollectionView, { id: 'cvInsert' }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.type === 'math' ? _ctx.mathValues : _ctx.characterValues, (value, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "InsertUICollectionViewCellContainer",
                key: index
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_UILabel, {
                    text: value.section,
                    isSelected: true
                  }, null, 8, ["text"])
                ]),
                _createVNode(_component_UICollectionView, { id: 'cvOptions' }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.options, (option, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "InsertView",
                        key: index,
                        onClick: ($event: any) => (_ctx.onSelect(option.value))
                      }, [
                        _createElementVNode("div", null, _toDisplayString(option.label), 1)
                      ], 8, _hoisted_3))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["onUiDialogCancel"]))
}