<template>
  <div class="signup">
    <div class="login-logo">Torewin Login</div>
    <div class="vLoginForm">
      <UITextField
        :id="'txtEmail'"
        :placeholder="'Email'"
        :type="'email'"
        :textLabel="'Email'"
        :hasError="hasEmailError"
        :errorMessage="register"
        v-model="signupForm.email"
      />
      <UITextField
        :id="'txtPassword'"
        :placeholder="'Password'"
        :type="'password'"
        :textLabel="'Password'"
        :hasError="hasPasswordError"
        :errorMessage="register"
        v-model="signupForm.password"
      />
      <UITextField
        :id="'txtConfirmPassword'"
        :placeholder="'Confirm Password'"
        :type="'password'"
        :textLabel="'Confirm Password'"
        :hasError="hasPasswordError"
        :errorMessage="register"
        v-model="signupForm.confirmPassword"
      />
      <UIButton
        :id="'btnCreateAccount'"
        title="Create free account"
        @click="btnSignup()"
      />
      <UILink id="" :title="'Already have an account?'" :href="'/login'" />
      <div class="fine-print">
        <div>
          By selecting "Create free account", I agree to the
          <UILink
            id=""
            :title="'Privacy Policy'"
            :href="'/torewin-online-privacy-policy'"
          />.
        </div>
      </div>
    </div>
    <div class="footer is-login-page">
      <div class="footer-top">
        <!--EULA-->
        <!-- <a href="../torewin-end-user-license-agreement">torewin.com EULA</a> -->
        <!-- <span>|</span> -->
        <!--Privacy Policy-->
        <UILink
          id="asd"
          title="Privacy"
          href="/torewin-online-privacy-policy"
        />
        <!-- <span>|</span> -->
        <!--Terms-->
        <!-- <a href="../torewin-online-terms-of-use">Terms</a> -->
        <!-- <span>|</span> -->
        <!--Copyright-->
        <!-- <a href="../torewin-online-terms-of-use">Copyright Infringement</a> -->
        <div class="copy">©2007-2024 Torewin. All Rights Reserved</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

//Components
import UITextField from "@/components/UI/UITextField.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UILink from "@/components/UI/UILink.vue";
import UIListView from "@/components/UI/UIListView.vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "Signup",
  components: {
    UITextField,
    UIButton,
    UILink,
    UIListView,
  },
  data() {
    return {
      signupForm: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      hasEmailError: false,
      hasPasswordError: false,
      errorMessage: "",
    };
  },
  computed: {
    register(): string {
      var testRegex = ".*[A-Z]+.*[0-9]";
      var regex = RegExp(testRegex);
      if (this.signupForm.password != this.signupForm.confirmPassword) {
        return "Your passwords don't match!";
      } else if (this.signupForm.email == "") {
        return "Please add an email.";
      } else if (this.signupForm.password.length < 6 && confirm.length < 6) {
        return "Your password is too short. It must be at least 6 characters long.";
      } else if (
        this.signupForm.password === "" &&
        this.signupForm.confirmPassword === ""
      ) {
        return "You need to fill out your passwords.";
      } else if (
        !regex.test(this.signupForm.password) &&
        !regex.test(this.signupForm.confirmPassword)
      ) {
        return "Your password must include at least a capital letter, and a number.";
      } else {
        //Register
        return "";
      }
    },
  },
  methods: {
    ...mapActions("Account", [
      "signup",
    ]),
    btnSignup() {
      this.errorMessage = "";
      this.hasEmailError = false;
      this.hasPasswordError = false;

      if (this.register !== "") {
        this.hasPasswordError = true;
        return;
      }
      this.signup({
        email: this.signupForm.email,
        password: this.signupForm.password,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.login {
  min-height: 100%;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  text-align: center;
}

.login-logo {
  margin-top: 55px;
  margin-bottom: 40px;
  width: 200px;
  height: 200px;
  text-indent: -9999px;
  background: url("../assets/galveon.png") 0 0;
  background-size: contain;
  margin: 0 auto;
}

.vLoginForm {
  width: 100%;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.helpful-links {
  list-style-type: none;

  padding: 0;
}

.footer.is-login-page {
  position: absolute;
  bottom: 20px;
  left: 0;

  width: 100%;

  text-transform: uppercase;
  font-size: 14px;
}

.copy {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}
.fine-print {
  padding-top: 20px;
}
</style>