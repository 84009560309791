<template>
  <UIDialogView
    :title="'Submit for Review'"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <div class="vBody">
      <UILabel :text="body" :type="'body'" :justify="'left'" :font="14" />
    </div>
    <div class="vInput">
      <UITextField
        :id="'txtSubmission'"
        :type="'text'"
        v-model="description"
        :textLabel="'Description your submission'"
        :placeholder="'Submission description'"
        :hasError="submissionError !== ''"
        :errorMessage="submissionError"
      />
      <UIDropdownGameSystem
        :selectedValue="gameSystemKey"
        :hasError="gameSystemError !== ''"
        :errorMessage="gameSystemError"
        @ui-dropdown-game-system-select="onGameSystemCellDropdownMenuSelect"
      />
      <div class="flex">
        <UITextField
          :id="'txtMajor'"
          :type="'number'"
          v-model="major"
          :textLabel="'Major'"
          :placeholder="'Major'"
        />
        <UITextField
          :id="'txtMinor'"
          :type="'number'"
          v-model="minor"
          :textLabel="'Minor'"
          :placeholder="'Minor'"
        />
        <UITextField
          :id="'txtPatch'"
          :type="'number'"
          v-model="patch"
          :textLabel="'Patch'"
          :placeholder="'Patch'"
        />
      </div>
    </div>
  </UIDialogView>
  <div>
    <UIDialogMessageView
      v-if="shouldShowMessageDialog"
      :title="'Success'"
      :body="'Asset successfuly submitted for review.'"
      @ui-dialog-message-submit="onDismissMessageDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UIDialogMessageView from "@/components/UI/dialogs/UIDialogMessageView.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import UIDropdownGameSystem from "@/components/compendium/UIDropdownGameSystem.vue";

import UICellDropdownField from "@/components/UI/UICellDropdownField.vue";
import { mapActions } from "vuex";
import Version from "@/store/models/AssetBases/Version";
import CompendiumAssetBase from "@/store/models/Compendium/CompendiumAssetBase";

export default defineComponent({
  name: "UIDialogPublishView",
  components: {
    UIDialogView,
    UIDialogMessageView,
    UILabel,
    UITextField,
    UIButton,
    UISeparatorView,
    UICellDropdownField,
    UIDropdownGameSystem,
  },
  data() {
    return {
      shouldShowMessageDialog: false,
      description: "",
      gameSystemKey: "",
      gameSystemName: "",
      major: 0,
      minor: 0,
      patch: 0,
      submissionError: "",
      gameSystemError: "",
    };
  },
  emits: ["ui-dialog-publish-submit", "ui-dialog-publish-cancel"],
  props: {
    asset: {
      type: Object as () => CompendiumAssetBase,
      default: {},
    },
    body: String,
    inputValue: String,
    buttonTitle: {
      type: String,
      default: "Submit",
    },
  },
  methods: {
    ...mapActions("Publish", ["publish"]),
    onCancel() {
      this.$emit("ui-dialog-publish-cancel");
    },
    onSubmit() {
      this.submissionError = "";
      this.gameSystemError = "";
      if (this.description.length < 10) {
        this.submissionError =
          "Descriptions should be more than 10 characters.";
        return;
      }
      if (this.gameSystemKey === "") {
        this.gameSystemError =
          "You must choose a Game System that this asset is related to.";
        return;
      }
      let payload = {
        description: this.description,
        version: {
          major: this.major,
          minor: this.minor,
          patch: this.patch,
        },
        asset: this.asset,
        gameSystemKey: this.gameSystemKey,
        gameSystemName: this.gameSystemName,
      };

      this.publish(payload).then(() => {
        this.onShowMessageDialog();
      });
    },
    onShowMessageDialog() {
      this.shouldShowMessageDialog = true;
    },
    onDismissMessageDialog() {
      this.shouldShowMessageDialog = false;
      this.onCancel();
    },
    onGameSystemCellDropdownMenuSelect(key: string, name: string) {
      this.gameSystemKey = key;
      this.gameSystemName = name;
    },
  },
  mounted() {
    const version: Version = this.asset.version;
    this.major = version.major;
    this.minor = version.minor;
    this.patch = version.patch;

    this.gameSystemKey = this.asset.game_system_key;
    this.gameSystemName = this.asset.game_system_name;
  },
});
</script>

<style lang="scss">
</style>