import { categories } from "../definitions/forums"

const state = {

}

const getters = {
  getCategories: (state: any): Array<any> => {
    return categories;
  },
  getCategoryByKey: (state: any, getters: any) => (val: string) => {
    let result = categories.filter(obj => {
      return obj.key === val;
    });
    return result[0];
  }
}

const mutations = {

}

const actions = {

}



export const Forums = { namespaced: true, state, getters, mutations, actions }