import IOverview from "@/store/interfaces/IOverview";
import CharacterClass from "@/store/models/Character";
import Experience from "@/store/models/Experience";
import MainResource from "@/store/models/MainResource";
import Portrait from "@/store/models/Portrait";
import { database_save, getStorageFromPath, setDatabaseCharacter, setStorageWithPath } from "@/utility/database";
import { Auth, getAuth } from "firebase/auth";
import { Timestamp } from "firebase/firestore";

export const overviewGetters = {
  getMainVital: (state: any): MainResource => {
    if (state.character === null) return new MainResource({});
    return state.character.main_vital || new MainResource({});
  },
  getExperience: (state: any): Experience => {
    return state.character.experience || new Experience({});
  },
  getLevel: (state: any): number => {
    if (state.character === null) return 0;
    return state.character.level;
  },
  getName: (state: any): string => {
    return state.character.name;
  },
  getCharacterKey: (state: any): string => {
    return state.character.key;
  },
  getPortrait: (state: any): string => {
    return state.character.portrait;
  },
};

export const overviewMutations = {
  setCharacterName: (state: any, value: string) => {
    state.character.name = value !== "" ? value : "Character name";
  },
  setPortrait: (state: any, value: Portrait) => {
    state.character.portrait = value;
  },
  setJournal: (state: any, value: string) => {
    state.character.journal = value;
  },
  setCharacterStory: (state: any, value: string) => {
    state.character.story = value;
  },
  setMainResource: (state: any, value: MainResource) => {
    state.character.main_vital = value;
  },
  setExperience: (state: any, value: Experience) => {
    state.character.experience = value;
  },
  setLevel: (state: any, value: number) => {
    state.character.level = value;
  },
};

export const overviewActions = {
  setCharacterVersion: async ({ commit, state, dispatch }: any) => {
    return new Promise((resolve, reject) => {
      const characterVersion = 4;
      commit("setCharacterVersion", characterVersion);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setCharacter: ({ commit, state, dispatch }: any, val: CharacterClass) => {
    return new Promise((resolve, reject) => {
      commit("setCharacter", val);
      if (val !== null) {
        dispatch("refreshBonuses").then((result: boolean) => {
          setDatabaseCharacter(state.character, database_save.overwrite);
          resolve(true);
        });
      }
    });
  },
  setOverview: async ({ commit, dispatch, state }: any, val: IOverview) => {
    return new Promise((resolve, reject) => {
      const { Character_Name, Main_Resource, Experience, Level, Portrait } = val;

      commit("setCharacterName", Character_Name);
      commit("setMainResource", Main_Resource);
      commit("setExperience", Experience);
      commit("setLevel", Level);
      dispatch("setPortrait", Portrait);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setCharacterName: async ({ commit, state, dispatch }: any, val: string) => {
    return new Promise((resolve, reject) => {
      commit("setCharacterName", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setJournal: async ({ commit, state, dispatch }: any, val: string) => {
    return new Promise((resolve, reject) => {
      commit("setJournal", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setCharacterStory: async ({ commit, state, dispatch }: any, val: string) => {
    return new Promise((resolve, reject) => {
      commit("setCharacterStory", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setLevel: async ({ commit, state, dispatch }: any, val: number) => {
    return new Promise((resolve, reject) => {
      commit("setLevel", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setPortrait: async ({ commit, state, dispatch }: any, url: string) => {
    return new Promise((resolve, reject) => {
      const auth: Auth = getAuth();
      const uid = auth.currentUser?.uid;
      if (!url.includes("data")) {
        return resolve(true);
      }
      return setStorageWithPath([uid!, state.character.key, "characterPortrait"], url)
        .then((url: string) => {
          const newPortrait = new Portrait({ url, last_updated: Timestamp.fromDate(new Date()) });
          commit("setPortrait", newPortrait);

          dispatch("setCharacter", state.character).then((result: boolean) => {
            resolve(true);
          });
        });
    });
  },
  setMainResource: async ({ commit, state, dispatch }: any, val: MainResource) => {
    return new Promise((resolve, reject) => {
      commit("setMainResource", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setExperience: async ({ commit, state, dispatch }: any, val: Experience) => {
    return new Promise((resolve, reject) => {
      commit("setExperience", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
};
