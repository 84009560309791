import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_plus@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-02095334"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DiceView" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_DieUICollectionViewCell = _resolveComponent("DieUICollectionViewCell")!
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UIDialogManageDie = _resolveComponent("UIDialogManageDie")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: 'Dice',
    hasDone: true,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UITextField, {
            id: 'txtCount',
            textLabel: 'Count',
            modelValue: _ctx.numOfDice,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.numOfDice) = $event)),
            saveOldValue: true,
            placeholder: 'Count',
            width: _ctx.textFieldWidth
          }, null, 8, ["modelValue", "width"]),
          _createVNode(_component_UILabel, {
            text: 'd',
            type: 'h2'
          }),
          _createVNode(_component_UITextField, {
            id: 'txtSides',
            textLabel: 'Sides',
            modelValue: _ctx.numOfSides,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.numOfSides) = $event)),
            saveOldValue: true,
            placeholder: 'Sides',
            width: _ctx.textFieldWidth
          }, null, 8, ["modelValue", "width"]),
          _createVNode(_component_UILabel, {
            text: '+',
            type: 'h2'
          }),
          _createVNode(_component_UITextField, {
            id: 'txtMode',
            textLabel: 'Modifier',
            modelValue: _ctx.mod,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mod) = $event)),
            saveOldValue: true,
            placeholder: 'Modifier',
            width: _ctx.textFieldWidth
          }, null, 8, ["modelValue", "width"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_UILabel, {
            id: 'lblRoll',
            text: _ctx.currentRoll,
            type: 'h1'
          }, null, 8, ["text"]),
          _createVNode(_component_UIButton, {
            id: 'btnRoll',
            title: 'Roll',
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.roll(this.numOfDice, this.numOfSides, this.mod)))
          })
        ]),
        _createVNode(_component_UISeparatorView),
        _createVNode(_component_UITabView, {
          id: 'tvPresets',
          tabs: _ctx.preset_tabs,
          selectedTab: _ctx.selectedTab,
          onTabSelected: _ctx.btnSelectTab
        }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
        (_ctx.selectedTab === 'presets')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_UICollectionView, {
                id: 'cvDie',
                hasItems: _ctx.presets.length > 0
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.presets, (die, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "die",
                      onClick: ($event: any) => (_ctx.roll(1, die.value, 0))
                    }, [
                      _createVNode(_component_UILabel, {
                        text: die.text
                      }, null, 8, ["text"])
                    ], 8, _hoisted_4))
                  }), 128))
                ]),
                _: 1
              }, 8, ["hasItems"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 'custom')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.getDices && _ctx.getDices?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      style: {"cursor":"pointer","display":"block","margin-left":"auto"},
                      width: "22",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onDiceMenuSelect(0)))
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_UICollectionView, {
                id: 'cvCustomDie',
                hasItems: _ctx.getDices?.length > 0
              }, {
                default: _withCtx(() => [
                  (!_ctx.getDices || _ctx.getDices?.length < 1)
                    ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                        key: 0,
                        title: 'No custom dice',
                        buttonTitle: 'Add Dice',
                        onUiOnboardView: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDiceMenuSelect(0)))
                      }))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDices, (die, index) => {
                    return (_openBlock(), _createBlock(_component_DieUICollectionViewCell, {
                      key: index,
                      die: die,
                      onClick: _withModifiers(($event: any) => (_ctx.roll(die.count, die.sides, die.modifier)), ["stop"]),
                      onDieUiCollectionViewCellEdit: ($event: any) => (_ctx.onDisplayManageDiceDialog(die)),
                      onDieUiCollectionViewCellDelete: _ctx.onDisplayPromptDialog
                    }, null, 8, ["die", "onClick", "onDieUiCollectionViewCellEdit", "onDieUiCollectionViewCellDelete"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["hasItems"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_UISeparatorView),
        _createVNode(_component_UITextView, {
          id: 'txtHistory',
          modelValue: _ctx.log,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.log) = $event)),
          placeholder: 'Output',
          disabled: true
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.shouldShowManageDiceDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogManageDie, {
            key: 0,
            selectedDice: _ctx.selectedDice,
            onUiDialogDiceCancel: _ctx.onDismissManageDiceDialog
          }, null, 8, ["selectedDice", "onUiDialogDiceCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 1,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            onUiDialogPromptSubmit: _ctx.onSavePromptDialog,
            type: _ctx.promptDialogData.type,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle
          }, null, 8, ["onUiDialogPromptCancel", "onUiDialogPromptSubmit", "type", "title", "body", "submitTitle"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onUiDialogCancel"]))
}