import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4a8d318"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "hasItems"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UICollectionViewCell = _resolveComponent("UICollectionViewCell")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "UICollectionView",
    hasItems: _ctx.hasItems
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createVNode(_component_UICollectionViewCell)
    ], true)
  ], 8, _hoisted_1))
}