import IFormulaItem from "../interfaces/IFormulaItem";

export default class FormulaItem implements IFormulaItem {
  constructor(dictionary: Record<string, any>) {
    this.value = dictionary["value"];
    this.result = dictionary["result"];
}
  
  value: string;
  result: string;

  toDictionary(): Record<string, any> {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["value"] = this.value;
    returnedDictionary["result"] = this.result;

    return returnedDictionary;
}
}