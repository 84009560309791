<template>
  <button class="UIButton" :class="{ disabled }" :style="{ width: `${width}px` }">{{ title }}</button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UIButton",
  props: {
    title: {
      type: String,
      default: "New Button",
    },
    width: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
});
</script>

<style lang="scss" scoped>
.UIButton {
  font: 600 16px / 24px "Helvetica Neue", "Helvetica", 'Open Sans', sans-serif;
  color: var(--galveonTextColor);
  border: 2px solid var(--galveonTextColor);
  border-radius: 8px;
  background: var(--btn_norm);
  cursor: pointer;
  display: inline-block;
  padding: 6px 12px;
  box-sizing: border-box;
  height: 44px;

  font-size: 16px;
  line-height: 24px;

  // width: 100%;
  min-width: 44px;

  &:hover {
    background: var(--btn_click);
  }
}
</style>