import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-417b22f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "UIListView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIListViewItem = _resolveComponent("UIListViewItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfItems, (item) => {
      return (_openBlock(), _createBlock(_component_UIListViewItem, {
        key: item.id
      }))
    }), 128))
  ]))
}