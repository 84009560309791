import IDice from "@/store/interfaces/IDice";

export default class Dice implements IDice {
    constructor(dictionary: Record<string, any>, key: string) {
        this.key = key;

        this.name = dictionary["name"] || "New Dice";
        this.count = +dictionary["count"] ?? 0;
        this.sides = +dictionary["sides"] ?? 1;
        this.modifier = +dictionary["modifier"] ?? 0;
    }

    name: string;
    key: string;
    count: number;
    sides: number;
    modifier: number;

    toDictionary = (): Record<string, any> => {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["key"] = this.key;

        returnedDictionary["name"] = this.name;
        returnedDictionary["count"] = +this.count;
        returnedDictionary["sides"] = +this.sides;
        returnedDictionary["modifier"] = +this.modifier;

        return returnedDictionary
    }
}