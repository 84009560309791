import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_menu@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-37dbece1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"space-between","position":"relative"} }
const _hoisted_3 = { class: "section-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIMenuView = _resolveComponent("UIMenuView")!
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_AttributeGroupUICollectionViewCell = _resolveComponent("AttributeGroupUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogPurchaseChenShop = _resolveComponent("UIDialogPurchaseChenShop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.attribute_tabs.length < 1)
      ? (_openBlock(), _createBlock(_component_UIOnboardView, {
          key: 0,
          title: 'You have no Attribute Tabs',
          body: 'Add different stat groupings to Tabs',
          buttonTitle: 'Add Tab',
          onUiOnboardView: _ctx.newSection
        }, null, 8, ["onUiOnboardView"]))
      : _createCommentVNode("", true),
    (_ctx.attribute_tabs.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_UILabel, {
              text: 'Attributes',
              type: 'h1'
            }),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_0,
                  style: {"cursor":"pointer","margin-right":"8px"},
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAttributeMenu && _ctx.onAttributeMenu(...args)))
                })
              ]),
              (_ctx.shouldShowAttributeMenu)
                ? (_openBlock(), _createBlock(_component_UIMenuView, {
                    key: 0,
                    items: [
              'Add Group',
              'Rename Tab',
              this.attribute_tabs.length < 1 || this.hasBlackMarket
                ? 'Add Tab'
                : 'Unlock unlimited tabs',
              'Delete Tab',
            ],
                    onMenuItemSelectedAt: _ctx.onMenuSelect,
                    onMenuViewDismiss: _ctx.dismissAttributeMenu
                  }, null, 8, ["items", "onMenuItemSelectedAt", "onMenuViewDismiss"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_UITabView, {
            id: 'tvSections',
            tabs: _ctx.attribute_tabs,
            selectedTab: _ctx.selected_section_name,
            onTabSelected: _ctx.btnSection
          }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_UICollectionView, { id: 'cvSections' }, {
              default: _withCtx(() => [
                (_ctx.selected_attribute_groups.length < 1)
                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                      key: 0,
                      title: 'You have no Attribute Groups',
                      body: 'Group similar attributes such as your stats, proficiencies, or skills',
                      buttonTitle: 'Add Attribute Group',
                      onUiOnboardView: _ctx.addAttributeTab
                    }, null, 8, ["onUiOnboardView"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected_attribute_groups, (attribute_group, index) => {
                  return (_openBlock(), _createBlock(_component_AttributeGroupUICollectionViewCell, {
                    key: index,
                    group: attribute_group
                  }, null, 8, ["group"]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowInputDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
          key: 2,
          onUiDialogInputCancel: _ctx.onCancelInputDialog,
          onUiDialogInputSubmit: _ctx.onSaveInputDialog,
          type: _ctx.inputDialogData.type,
          title: _ctx.inputDialogData.name,
          body: _ctx.inputDialogData.body,
          inputPlaceholder: _ctx.inputDialogData.placeholder,
          inputTitle: _ctx.inputDialogData.field,
          inputValue: _ctx.inputDialogData.value,
          buttonTitle: _ctx.inputDialogData.input
        }, null, 8, ["onUiDialogInputCancel", "onUiDialogInputSubmit", "type", "title", "body", "inputPlaceholder", "inputTitle", "inputValue", "buttonTitle"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowPromptDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
          key: 3,
          type: _ctx.promptDialogData.type,
          title: _ctx.promptDialogData.name,
          body: _ctx.promptDialogData.body,
          submitTitle: _ctx.promptDialogData.submitTitle,
          onUiDialogPromptSubmit: _ctx.onSavePromptDialog,
          onUiDialogPromptCancel: _ctx.onCancelPromptDialog
        }, null, 8, ["type", "title", "body", "submitTitle", "onUiDialogPromptSubmit", "onUiDialogPromptCancel"]))
      : _createCommentVNode("", true),
    (_ctx.shouldShowChenDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogPurchaseChenShop, {
          key: 4,
          onUiDialogPurchaseCancel: _ctx.dismissChenDialog
        }, null, 8, ["onUiDialogPurchaseCancel"]))
      : _createCommentVNode("", true)
  ]))
}