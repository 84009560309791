<template>
    <div class="UICollectionViewCell">
        <span></span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";


export default defineComponent({
  name: "UICollectionViewCell",
});
</script>

<style lang="scss" scoped>
    .UICollectionViewCell {
        
    }
</style>