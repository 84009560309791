import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icon_plus@x1.png'


const _hoisted_1 = { class: "FormulaTableView" }
const _hoisted_2 = {
  class: "flex",
  style: {"justify-content":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormulaItemCollectionViewCell = _resolveComponent("FormulaItemCollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogValueView = _resolveComponent("UIDialogValueView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _imports_0,
          width: "32",
          style: {"cursor":"pointer"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.btnAdd()))
        })
      ]),
      _createVNode(_component_UICollectionView, {
        id: 'cvModification',
        hasItems: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.newFormulaItems, (value, key) => {
            return (_openBlock(), _createBlock(_component_FormulaItemCollectionViewCell, {
              key: key,
              formulaItem: value,
              onClick: ($event: any) => (_ctx.btnAdd(value, key))
            }, null, 8, ["formulaItem", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowValueDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogValueView, {
            key: 0,
            type: 'string',
            title: _ctx.dialogValueData.title,
            body: _ctx.dialogValueData.body,
            inputTitle1: _ctx.dialogValueData.inputTitle1,
            inputValue1: _ctx.dialogValueData.inputValue1,
            inputPlaceholder1: _ctx.dialogValueData.inputPlaceholder1,
            inputTitle2: _ctx.dialogValueData.inputTitle2,
            inputValue2: _ctx.dialogValueData.inputValue2,
            inputPlaceholder2: _ctx.dialogValueData.inputPlaceholder2,
            removeTitle: _ctx.dialogValueData.removeTitle,
            onUiDialogValueSubmit: _ctx.onDialogValueSubmit,
            onUiDialogValueRemove: _ctx.onDialogValueRemove,
            onUiDialogValueCancel: _ctx.onDialogValueCancel
          }, null, 8, ["title", "body", "inputTitle1", "inputValue1", "inputPlaceholder1", "inputTitle2", "inputValue2", "inputPlaceholder2", "removeTitle", "onUiDialogValueSubmit", "onUiDialogValueRemove", "onUiDialogValueCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}