<template>
  <div class="SecondaryAttributeUICollectionViewCell">
    <UILabel :id="'lblExtraName'" :text="extra.name" />
    <UILabel :id="'lblExtraValue'" :text="extra.value" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

export default defineComponent({
  name: "SecondaryAttributeUICollectionViewCell",
  components: {
    UILabel,
    UISeparatorView,
  },
  props: {
    extra: {
      type: Object,
      default: {},
    },
  },
});
</script>

<style lang="scss" scoped>
.SecondaryAttributeUICollectionViewCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;
  height: 44px;
  cursor: pointer;

  box-sizing: border-box;

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(odd) {
    background: #ffffff;
  }
}

</style>