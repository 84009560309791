<template>
  <div class="UICharacterActivityView">
    <UIActivityView
      :radius="60"
      :progress="resourcePercentage"
      :stroke="8"
      :color="resourceColor"
      :img="getPortrait.url"
      :hasImage="hasImg"
    />
    <div class="avExperience">
      <UIActivityView
        :radius="30"
        :progress="experiencePercentage"
        :stroke="4"
        :color="experienceColor"
        :fill="'white'"
        :hasImage="false"
        :label="showLevel ? level : ''"
        :labelSize="18"
      />
      <UILabel style="position: absolute; font-size: 14px; bottom: -8px; left: 20%;" :font="14" :text="'Level'" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions, mapGetters } from "vuex";

import UIActivityView from "@/components/UI/UIActivityView.vue";
import UILabel from "@/components/UI/UILabel.vue";

import MainResource from "@/store/models/MainResource";
import Experience from "@/store/models/Experience";
import { componentToHex } from "@/utility/global";

export default defineComponent({
  name: "UICharacterActivityView",
  components: {
    UIActivityView,
    UILabel,
  },
  props: {
    mainResource: {
      type: Object as () => MainResource,
      required: true,
      default: {},
    },
    experience: {
      type: Object as () => Experience,
      default: {},
    },
    level: {
      type: Number,
      default: 0,
    },
    hasImg: {
      type: Boolean,
      default: false,
    },
    showLevel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Character", ["getBonus", "getPortrait", "getCharacter"]),
    current(): number {
      const [values, result] = this.getCharacter.getBonuses(["vital_maximum_bonus", "vital_maximum_fraction"]);
      const bonus = (Number.isNaN(values[0]) ? 0 : +values[0]);
      const multiple = values[1];

      return (this.mainResource.current + bonus) + (this.mainResource.current * multiple);
    },
    max(): number {
      const [values, result] = this.getCharacter.getBonuses(["vital_maximum_bonus", "vital_maximum_fraction"]);
      const bonus = (Number.isNaN(values[0]) ? 0 : +values[0]);
      const multiple = values[1];

      return (this.mainResource.max + bonus) + (this.mainResource.max * multiple);
    },
    resourcePercentage(): number {
      let percentage = (this.current / this.max) * 100;
      if (
        percentage === Infinity ||
        percentage === -Infinity ||
        isNaN(percentage)
      ) {
        return 100;
      }
      return Math.trunc(percentage);
    },
    resourceColor(): string {
      const red = this.mainResource.red;
      const green = this.mainResource.green;
      const blue = this.mainResource.blue;

      return componentToHex(red, green, blue);
    },
    experiencePercentage(): number {
      let percentage = (this.experience.current / this.experience.max) * 100;
      if (percentage === Infinity || isNaN(percentage)) {
        return 100;
      }
      return Math.trunc(percentage);
    },
    experienceColor(): string {
      const red = this.experience.red;
      const green = this.experience.green;
      const blue = this.experience.blue;

      return componentToHex(red, green, blue);
    },
  },
});
</script>

<style lang="scss" scoped>
.avExperience {
  position: absolute;
  bottom: 0;
}
</style>