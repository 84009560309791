import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModificationView = _resolveComponent("ModificationView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!

  return (_openBlock(), _createBlock(_component_UIDialogView, {
    title: 'Modifications',
    onUiDialogSubmit: _ctx.onSubmit,
    onUiDialogCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ModificationView, {
        modification: _ctx.modification,
        onModificationsUpdate: _ctx.onModificationUpdate
      }, null, 8, ["modification", "onModificationsUpdate"])
    ]),
    _: 1
  }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]))
}