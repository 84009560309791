import Bag from "@/store/models/Bag";
import Item from "@/store/models/Item";
import { database_save, newKey, setDatabaseCharacter } from "@/utility/database";

export const itemGetters = {
  getBags: (state: any): Array<Bag> => {
    return state.character.bags;
  },
  getItems: (state: any): Array<Item> => {
    return state.character.items;
  },
  getSelectedItems: (state: any): Array<Item> => {
    return state.character.items.filter((item: Item) => item.key_bag == state.character.selected_bag_key);
  },
  getSelectedBagKey: (state: any): string => {
    return state.character.selected_bag_key;
  },
};

export const itemMutations = {
  setCurrentBag: (state: any, key: string) => {
    state.character.selected_bag_key = key;
  },
  setBag: (state: any, value: Bag) => {
    if (!value.key) {
      value.key = newKey();
    }

    const index = state.character.bags.findIndex((c: Bag) => c.key == value.key);

    if (index > -1) {
      //Bag exists

      state.character.bags[index] = value;
    }
    else {
      //Bag doesn't exist

      state.character.bags.push(value);
      state.character.selected_bag_key = value.key;
    }
  },
  deleteBag: (state: any, index: number) => {
    state.character.bags.splice(index, 1);
  },
  setItem: (state: any, value: Item) => {
    const index = state.character.items.findIndex((c: Item) => c.key == value.key);
    if (index > -1) {
      state.character.items.splice(index, 1, value);
    }
    else {
      state.character.items.push(value);
    }
  },
  addItem: (state: any, value: Item) => {
    state.character.items.push(value);
  },
  removeItem: (state: any, index: number) => {
    state.character.items.splice(index, 1);
  },
};

export const itemActions = {
  setCurrentBag: ({ commit, state, dispatch }: any, key: string) => {
    return new Promise((resolve, reject) => {
      commit('setCurrentBag', key);
      console.log(key);
      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setBag: async ({ commit, state, dispatch }: any, val: Bag) => {
    return new Promise((resolve, reject) => {
      commit("setBag", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  deleteBag: async ({ commit, state, dispatch }: any) => {
    return new Promise((resolve, reject) => {
      const index = state.character.bags.findIndex((b: Bag) => b.key == state.character.selected_bag_key);

      if (index > -1) {
        const oldBag: Bag = state.character.bags[index];

        commit('deleteBag', index);
        let removedItemIndex: Array<number> = [];
        state.character.items.forEach((item: Item, itemIndex: number) => {
          if (item.key_bag === oldBag.key) {
            removedItemIndex.push(itemIndex);
          }
        });

        removedItemIndex.reverse();

        removedItemIndex.forEach((n: number) => {
          commit("removeItem", n);
        });

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
  addItem: async ({ commit, state, dispatch }: any, val: Item) => {
    return new Promise((resolve, reject) => {
      commit("addItem", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  setItem: async ({ commit, state, dispatch }: any, val: Item) => {
    return new Promise((resolve, reject) => {
      commit("setItem", val);

      dispatch("setCharacter", state.character).then((result: boolean) => {
        resolve(true);
      });
    });
  },
  removeItem: async ({ commit, state, dispatch }: any, val: Item) => {
    return new Promise((resolve, reject) => {
      const index = state.character.items.findIndex((i: Item) => i.key == val.key);

      if (index > -1) {
        commit("removeItem", index);

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
  removeAllItems: async ({ commit, state, dispatch }: any) => {
    return new Promise((resolve, reject) => {
      const index = state.character.bags.findIndex((b: Bag) => b.key == state.character.selected_bag_key);

      if (index > -1) {
        const oldBag: Bag = state.character.bags[index];

        let removedItemIndex: Array<number> = [];
        state.character.items.forEach((item: Item, itemIndex: number) => {
          if (item.key_bag === oldBag.key) {
            removedItemIndex.push(itemIndex);
          }
        });

        removedItemIndex.reverse();

        removedItemIndex.forEach((n: number) => {
          commit("removeItem", n);
        });

        dispatch("setCharacter", state.character).then((result: boolean) => {
          resolve(true);
        });
      }
      resolve(true);
    });
  },
};
