import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ModificationView" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITabView = _resolveComponent("UITabView")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UIDialogFormulaView = _resolveComponent("UIDialogFormulaView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UITabView, {
        id: 'tvTabs',
        tabs: _ctx.tabs,
        selectedTab: _ctx.selectedTab,
        onTabSelected: _ctx.btnSelectTab
      }, null, 8, ["tabs", "selectedTab", "onTabSelected"]),
      _createVNode(_component_UICollectionView, { id: 'cvItems' }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableOptions, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              onClick: ($event: any) => (_ctx.onDisplayFormulaDialog(option, index))
            }, _toDisplayString(option.label) + " " + _toDisplayString(this.localModification[option.key]), 9, _hoisted_2))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowFormulaDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogFormulaView, {
            key: 0,
            formula: _ctx.selectedBonus.formula,
            onUiDialogFormulaSubmit: _ctx.onSubmitFormulaDialog,
            onUiDialogFormulaCancel: _ctx.onCancelFormulaDialog
          }, null, 8, ["formula", "onUiDialogFormulaSubmit", "onUiDialogFormulaCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}