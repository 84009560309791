<template>
  <div class="character-portrait">
    <UIActivityView
      :radius="radius"
      :progress="mainResourcePercentage"
      :stroke="stroke"
      :color="mainResourceColor"
      :img="img"
      :hasImage="true"
    />
    <UILabel :text="name" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { componentToHex } from "@/utility/global";

import UILabel from "@/components/UI/UILabel.vue";
import UIActivityView from "@/components/UI/UIActivityView.vue";

export default defineComponent({
  name: "CharacterPortrait",
  components: {
    UILabel,
    UIActivityView,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    mainVital: {
      type: Object,
      default: {},
    },
    radius: {
      type: Number,
      default: 35,
    },
    stroke: {
      type: Number,
      default: 8,
    },
    img: {
      type: String,
      default: "",
    },
  },
  computed: {
    mainResourcePercentage(): number {
      let percentage =
        (this.mainVital.current / this.mainVital.max) * 100;
      if (percentage === Infinity || isNaN(percentage)) {
        return 100;
      }
      return Math.trunc(
        (this.mainVital.current / this.mainVital.max) * 100
      );
    },
    mainResourceColor(): string {
      const red = this.mainVital.red;
      const green = this.mainVital.green;
      const blue = this.mainVital.blue;

      return componentToHex(red, green, blue);
    },
  }
});
</script>

<style lang="scss" scoped>
.character-portrait {
  display: flex;
  align-items: center;
  padding: 0 8px;
  white-space: nowrap;
}
</style>