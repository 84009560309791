<template>
  <div class="inv-stats">
    <!-- <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      "
    >
      <UILabel :text="'Placeholder'" :type="'h1'" />
      <div>
        <div>
          <img
            id="btnPlaceholderMenu"
            style="cursor: pointer; margin-right: 8px"
            src="@/assets/icon_menu@x2.png"
            @click="onItemMenu"
          />
        </div>
        <UIMenuView
          v-if="shouldShowItemMenu"
          :items="[]"
          @menu-item-selected-at="onMenuSelect"
          @menu-view-dismiss="dismissItemMenu"
        />
      </div>
    </div>
    <UISeparatorView /> -->
    <div
      style="display: flex; justify-content: flex-end; margin: 16px;"
    >
      <CharacterCurrency
        @click="showCurrencyDialog"
      />
    </div>

    <UIDialogCurrency
      v-if="shouldShowCurrencyDialog"
      @ui-dialog-currency-cancel="hideCurrencyDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIMenuView from "@/components/UI/UIMenuView.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import CharacterCurrency from "@/components/character/CharacterCurrency.vue";

import UIDialogCurrency from "@/components/character/dialogs/UIDialogCurrency.vue";

export default defineComponent({
  name: "CharacterInventoryStatistics",
  components: {
    UILabel,
    UIMenuView,
    CharacterCurrency,
    UISeparatorView,
    UIDialogCurrency,
  },
  data() {
    return {
      shouldShowItemMenu: false,
      shouldShowCurrencyDialog: false,
    };
  },
  methods: {
    showCurrencyDialog() {
      this.shouldShowCurrencyDialog = true;
    },
    hideCurrencyDialog() {
      this.shouldShowCurrencyDialog = false;
    }
  }
});
</script>