require('@/database');

import { createApp } from "vue";
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import { getAuth, onAuthStateChanged } from "firebase/auth";

let app: any
const auth = getAuth();
onAuthStateChanged(auth, async (user) => {
  if (!app) {
    app = createApp(App)
      .use(store)
      .use(router)
      .mount('#app')

    // app.config.errorHandler = (err) => { //GLOBAL ERROR???
    //   /* handle error */
    // }
  }

  if (user) {
    await store.dispatch('Account/fetchUserAccount', user);
    await store.dispatch('Account/setUserRoles');
  }
});
