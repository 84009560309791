<template>
  <div class="overlay" @click.self="onCancel"></div>
  <div class="character-selection">
    <div class="character-selection-container">
      <div
        v-for="character of getCharacters"
        class="character"
        :key="character.key"
      >
        <CharacterPortrait
          :key="character.key"
          :name="character.name"
          :mainVital="character.main_vital"
          :radius="32"
          :stroke="4"
          :img="character.portrait.url"
          @click="onSelectCharacter(character)"
        />
        <UILabel
          :text="character.campaign_id ? 'Campaign' : character.type"
          :justify="'left'"
          :font="12"
        />
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; padding: 0 8px">
      <UILink
        :id="'lAdd'"
        :title="'Add New Character'"
        :href="'/galveon/create/character'"
      />
      <a href="/galveon/characters">View all Characters</a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";

import CharacterClass from "@/store/models/Character";

import CharacterPortrait from "@/components/character/CharacterPortrait.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILink from "@/components/UI/UILink.vue";
import UILabel from "@/components/UI/UILabel.vue";

export default defineComponent({
  name: "CharacterSelection",
  components: {
    CharacterPortrait,
    UIButton,
    UILink,
    UILabel,
  },
  emits: ["dismiss-character-selection"],
  methods: {
    ...mapActions("Account", [
      "setSelectedCharacter",
    ]),
    onCancel() {
      this.$emit("dismiss-character-selection");
    },
    onSelectCharacter(char: CharacterClass) {
      this.setSelectedCharacter(char.key);
      this.onCancel();
    },
  },
  computed: {
    ...mapGetters("Account", [
      "hasBlackMarket",
      "countCharacter",
      "getCharacters",
      "getTemplateCharacters",
      "getCampaignCharacters",
    ]),
  },
});
</script>

<style lang="scss" scoped>
.character-selection {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-top: 0;
  z-index: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.character-selection-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;

  padding: 24px 0;

  height: 100%;
  width: 100%;

  box-sizing: border-box;

  margin: auto;
  max-width: var(--maxWidth);

  overflow: auto;
}
</style>