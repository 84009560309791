import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15d316b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "UITextFieldError"
}
const _hoisted_2 = ["type", "value", "placeholder", "has-error", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["UITextFieldView", { disabled: _ctx.disabled }])
  }, [
    (_ctx.shouldShowLabel)
      ? (_openBlock(), _createBlock(_component_UILabel, {
          key: 0,
          text: _ctx.textLabel,
          justify: 'left'
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    (_ctx.hasError == true)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "UITextField",
      style: _normalizeStyle({ width: `${_ctx.width}px` }),
      type: _ctx.type,
      value: _ctx.modelValue,
      placeholder: _ctx.saveOldValue ? _ctx.originalValue : _ctx.placeholder,
      "has-error": _ctx.hasError,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event))),
      onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.verifyValue($event))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearValue($event.target.value))),
      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.returnValue && _ctx.returnValue(...args)))
    }, null, 44, _hoisted_2)
  ], 2))
}