import { Auth, getAuth } from "firebase/auth";
import ICharacter from "../interfaces/ICharacter";
import IWorkshop from "../interfaces/IWorkshop";
import Attribute from "./Attribute";
import AttributeGroup from "./AttributeGroup";
import AttributeTab from "./AttributeTab";
import Bag from "./Bag";
import Bonus from "./Bonus";
import Currency from "./Currency";
import Dice from "./Dice";
import Experience from "./Experience";
import Item from "./Item";
import MainResource from "./MainResource";
import Resource from "./Resource";
import Spell from "./Spell";
import Spellbook from "./Spellbook";
import SpellTable from "./SpellTable";
import { getStorageFromPath } from "@/utility/database";
import Portrait from "./Portrait";
import { convertKeysLocalFormula } from "@/utility/tooltip";
import CompendiumAssetBase from "./Compendium/CompendiumAssetBase";
import { getRandomHexColor } from "@/utility/global";
import AttributeBase from "./AssetBases/AttributeBase";

export default class CharacterClass implements ICharacter {
    constructor(dictionary: Record<string, any>, key: string, workshop: IWorkshop) {
        this.galveon_user_id = dictionary["galveon_user_id"] ?? null;
        this.key = key;

        this.name = dictionary["name"] || "New Character Name";
        if (this.name === "") {
            this.name = "New Character Name";
        }
        this.story = dictionary["story"] || "";
        this.experience = new Experience(dictionary["experience"] || {});
        const inventoryValue = dictionary["bags"];
        if (inventoryValue) {
            for (const bagKey in inventoryValue) {
                const bagValue = inventoryValue[bagKey];
                this.bags.push(new Bag(bagValue, bagKey));
            }
        }

        this.type = dictionary["type"] || "none";
        this.party_id = dictionary["party_id"] || null;
        this.campaign_id = dictionary["campaign_id"] || null;
        this.campaign_owner_id = dictionary["campaign_owner_id"] || null;
        this.galveon_user_id = dictionary["galveon_user_id"] || null;
        this.game_system_key = dictionary["game_system_key"] || "";
        this.game_system_name = dictionary["game_system_name"] || "";
        this.user_id = dictionary["user_id"] || null;
        this.journal = dictionary["journal"] || "";
        this.level = dictionary["level"] ?? 1;
        this.main_vital = new MainResource(dictionary["main_vital"] || {});
        this.portrait = new Portrait(dictionary["portrait"] || {});

        this.height = dictionary["height"] || "";
        this.weight = +dictionary["weight"] || 0;
        this.size = dictionary["size"] || "";
        this.age = +dictionary["age"] || 0;
        this.eye_color = dictionary["eye_color"] || getRandomHexColor();
        this.hair_color = dictionary["hair_color"] || getRandomHexColor();

        const resourcesValue = dictionary["resources"];
        if (resourcesValue) {
            for (const resourceKey in resourcesValue) {
                const resourceValue = resourcesValue[resourceKey];
                this.resources.push(new Resource(resourceValue, resourceKey));
            }
        }
        const sectionsValue = dictionary["attribute_tabs"];
        if (sectionsValue) {
            for (const sectionKey in sectionsValue) {
                const sectionValue = sectionsValue[sectionKey];
                this.attribute_tabs.push(new AttributeTab(sectionValue, sectionValue.key));
            }
        }
        this.selected_bag_key = dictionary["selected_bag_key"] || "";
        this.selected_spell_book_key = dictionary["selected_spell_book_key"] || "";
        this.selected_attribute_tab_key = dictionary["selected_attribute_tab_key"] || "";

        const spellbooksValue = dictionary["spell_books"];
        if (spellbooksValue) {
            for (const bookKey in spellbooksValue) {
                const bookValue = spellbooksValue[bookKey];
                this.spell_books.push(new Spellbook(bookValue, bookValue.key));
            }
        }
        const attributeGroupsValue = dictionary["attribute_groups"];
        if (attributeGroupsValue) {
            for (const attributeGroupKey in attributeGroupsValue) {
                const attributeGroupValue = attributeGroupsValue[attributeGroupKey];
                this.attribute_groups.push(new AttributeGroup(attributeGroupValue, attributeGroupValue.key));
            }
        }
        const attributesValue = dictionary["attributes"];
        if (attributesValue) {
            for (const attributeKey in attributesValue) {
                const attributeValue = attributesValue[attributeKey];
                const workshopAttribute: AttributeBase | undefined = workshop?.attribute?.find((a: CompendiumAssetBase) => a.key === attributeValue.key);

                const value = {
                    ...workshopAttribute?.toDictionary(),
                    value: attributeValue.value,
                    show_modifier: attributeValue.show_modifier,
                    key_attribute_group: attributeValue.key_attribute_group,
                }
                this.attributes.push(new Attribute(this.campaign_id != null ? attributeValue : value, attributeKey));
            }
        }
        const itemsValue = dictionary["items"];
        if (itemsValue) {
            for (const itemKey in itemsValue) {
                const itemValue = itemsValue[itemKey];
                this.items.push(new Item(itemValue, itemKey));
            }
        }
        const spellTablesValue = dictionary["spell_tables"];
        if (spellTablesValue) {
            for (const spellTableKey in spellTablesValue) {
                const spellTableValue = spellTablesValue[spellTableKey];
                this.spell_tables.push(new SpellTable(spellTableValue, spellTableValue.key));
            }
        }
        const spellsValue = dictionary["spells"];
        if (spellsValue) {
            for (const spellKey in spellsValue) {
                const spellValue = spellsValue[spellKey];
                this.spells.push(new Spell(spellValue, spellKey));
            }
        }
        this.version = dictionary["version"] ?? 4;
        const currenciesValue = dictionary["currencies"];
        if (currenciesValue) {
            for (const currencyKey in currenciesValue) {
                const currencyValue = currenciesValue[currencyKey];
                this.currencies.push(new Currency(currencyValue, currencyKey));
            }
        }

        const diceValue = dictionary["dices"];
        if (diceValue) {
            for (const dieKey in diceValue) {
                const dieValue = diceValue[dieKey];
                this.dices.push(new Dice(dieValue, dieKey));
            }
        }
    }
    getAttributeWithId(id: string): Attribute | undefined {
        return this.attributes.find((a: Attribute) => a.key === id);
    }
    getSpellWithId(id: string): Spell | undefined {
        return this.spells.find((s: Spell) => s.key === id);
    }
    getResourceWithId(id: string): Resource | undefined {
        return this.resources.find((r: Resource) => r.key === id);
    }
    getCurrencyWithId(id: string): Currency | undefined {
        return this.currencies.find((r: Currency) => r.key === id);
    }
    getItemWithId(id: string): Item | undefined {
        return this.items.find((i: Item) => i.key === id);
    }

    bonuses: Bonus[] = [];
    name: string;
    story: string;
    experience: Experience;
    bags: Bag[] = [];
    journal: string;
    level: number;
    portrait: Portrait;
    main_vital: MainResource;
    resources: Resource[] = [];
    attribute_tabs: AttributeTab[] = [];
    selected_bag_key: string;
    selected_spell_book_key: string;
    selected_attribute_tab_key: string;
    spell_books: Spellbook[] = [];
    attribute_groups: AttributeGroup[] = [];
    attributes: Attribute[] = [];
    items: Item[] = [];
    spell_tables: SpellTable[] = [];
    spells: Spell[] = [];
    key: string;
    version: number;
    currencies: Currency[] = [];
    dices: Dice[] = [];
    type: string;
    party_id?: string;
    campaign_id?: string;
    user_id?: string;
    campaign_owner_id?: string;
    galveon_user_id?: string;

    game_system_key: string;
    game_system_name: string;

    //MARK: Movement
    movement_speed: number = 0;
    movement_speed_maximum: number = 0;
    movement_speed_minimum: number = 0;

    //MARK: Combat
    kill_experience: number = 0;
    //MARK: Behavior
    //MARK: Unit
    sight_maximum: number = 0;
    sight_minimum: number = 0;
    time_scale: number = 0;
    height: string;
    weight: number;
    size: string;
    age: number;
    eye_color: string;
    hair_color: string;

    //NYI
    sight_radius: number = 0;
    radius: number = 0;
    separation_radius: number = 0;
    deceleration: number = 0;
    acceleration: number = 0;

    loadImage() {
        const auth: Auth = getAuth();
        const uid = auth.currentUser?.uid;

        getStorageFromPath([uid!, this.key, 'characterPortrait'])
            .then((url: string) => {
                if (url === '') {
                    this.portrait.url = require("@/assets/icon_overview.png");
                    return;
                }
                this.portrait.url = url;
            });
    }

    getBonuses(keys: string[]): [number[], boolean] {
        var returnedValues: number[] = [];
        keys.forEach((key: string, index: number) => {
            let bonuses = this.bonuses.filter((b: Bonus) => b.key === key);
            const [value, result] = this.calculateBonus(bonuses);
            if (value !== undefined) {
                returnedValues[index] = +value;
            }
        });
        return [returnedValues, true];
    }

    calculateBonus(bonuses: Bonus[] | undefined): [number | undefined, boolean] {
        if (bonuses) {
            let returnedValue = 0;
            for (const bonus of bonuses) {
                if (bonus.attribute_key === 'veterency') {
                    const tests = {
                        level: this.level,
                        value: 0,
                        main_resource: this.main_vital.max
                    };
                    const [newValue, result] = convertKeysLocalFormula(bonus.formula, tests);
                    if (newValue) {
                        returnedValue += +newValue;
                    }
                }
                else {
                    const attribute: Attribute | undefined = this.attributes.find((a: Attribute) => a.key == bonus.attribute_key);
                    
                    if (attribute && +attribute?.value) {
                        
                        const tests = {
                            level: this.level,
                            value: +attribute.value,
                            main_resource: this.main_vital.max
                        };
                        const [newValue, result] = convertKeysLocalFormula(bonus.formula, tests);
                        if (newValue) {
                            returnedValue += +newValue;
                        }
                    }
                }
            }
            return [+returnedValue, true];
        }

        return [undefined, false];
    }

    toDictionary(): Record<string, any> {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["key"] = this.key;

        returnedDictionary["galveon_user_id"] = this.galveon_user_id;

        returnedDictionary["name"] = this.name;
        returnedDictionary["story"] = this.story;
        returnedDictionary["experience"] = this.experience.toDictionary();

        var bagsDictionary: Record<string, any> = {};
        for (const bag of this.bags) {
            bagsDictionary[bag.key] = bag.toDictionary();
        }
        returnedDictionary["bags"] = bagsDictionary;

        returnedDictionary["type"] = this.type;
        returnedDictionary["party_id"] = this.party_id;
        returnedDictionary["campaign_id"] = this.campaign_id;
        returnedDictionary["campaign_owner_id"] = this.campaign_owner_id;
        returnedDictionary["game_system_key"] = this.game_system_key;
        returnedDictionary["game_system_name"] = this.game_system_name;
        returnedDictionary["user_id"] = this.user_id;
        returnedDictionary["journal"] = this.journal;
        returnedDictionary["level"] = this.level;

        returnedDictionary["portrait"] = this.portrait.toDictionary();

        returnedDictionary["main_vital"] = this.main_vital.toDictionary();

        var resourcesDictionary: Record<string, any> = {};
        for (const resource of this.resources) {
            resourcesDictionary[resource.key] = resource.toDictionary();
        }
        returnedDictionary["resources"] = resourcesDictionary;

        var attributeTabsDictionary: Record<string, any> = {};
        for (const tab of this.attribute_tabs) {
            attributeTabsDictionary[tab.key] = tab.toDictionary();
        }
        returnedDictionary["attribute_tabs"] = attributeTabsDictionary;

        returnedDictionary["selected_bag_key"] = this.selected_bag_key;
        returnedDictionary["selected_spell_book_key"] = this.selected_spell_book_key;
        returnedDictionary["selected_attribute_tab_key"] = this.selected_attribute_tab_key;

        var spellBooksADictionary: Record<string, any> = {};
        for (const book of this.spell_books) {
            spellBooksADictionary[book.key] = book.toDictionary();
        }
        returnedDictionary["spell_books"] = spellBooksADictionary;

        var attributeGroupsDictionary: Record<string, any> = {};
        for (const group of this.attribute_groups) {
            attributeGroupsDictionary[group.key] = group.toDictionary();
        }
        returnedDictionary["attribute_groups"] = attributeGroupsDictionary;

        var attributesDictionary: Record<string, any> = {};
        for (const attribute of this.attributes) {
            attributesDictionary[attribute.key] = attribute.toDictionary();
        }
        returnedDictionary["attributes"] = attributesDictionary;

        var itemsDictionary: Record<string, any> = {};
        for (const item of this.items) {
            itemsDictionary[item.key] = item.toDictionary();
        }
        returnedDictionary["items"] = itemsDictionary;

        var spellTablesDictionary: Record<string, any> = {};
        for (const table of this.spell_tables) {
            spellTablesDictionary[table.key] = table.toDictionary();
        }
        returnedDictionary["spell_tables"] = spellTablesDictionary;

        var spellsDictionary: Record<string, any> = {};
        for (const spell of this.spells) {
            spellsDictionary[spell.key] = spell.toDictionary();
        }
        returnedDictionary["spells"] = spellsDictionary;

        returnedDictionary["version"] = this.version;

        var currenciesDictionary: Record<string, any> = {};
        for (const currency of this.currencies) {
            currenciesDictionary[currency.key] = currency.toDictionary();
        }
        returnedDictionary["currencies"] = currenciesDictionary;

        var dicesDictionary: Record<string, any> = {};
        for (const die of this.dices) {
            dicesDictionary[die.key] = die.toDictionary();
        }
        returnedDictionary["dices"] = dicesDictionary;

        const bioAttributes: Record<string, any> = this.getBioAttributes();
        
        returnedDictionary = { ...returnedDictionary, ...bioAttributes };

        return returnedDictionary;
    }

    getBioAttributes(): Record<string, any> {
        var returnedDictionary: Record<string, any> = {};

        returnedDictionary["movement_speed"] = this.movement_speed;
        returnedDictionary["movement_speed_maximum"] = this.movement_speed_maximum;
        returnedDictionary["movement_speed_minimum"] = this.movement_speed_minimum;
        returnedDictionary["kill_experience"] = this.kill_experience;
        returnedDictionary["sight_maximum"] = this.sight_maximum;
        returnedDictionary["sight_minimum"] = this.sight_minimum;
        returnedDictionary["time_scale"] = this.time_scale;

        returnedDictionary["height"] = this.height;
        returnedDictionary["weight"] = this.weight;
        returnedDictionary["size"] = this.size;
        returnedDictionary["age"] = this.age;
        returnedDictionary["eye_color"] = this.eye_color;
        returnedDictionary["hair_color"] = this.hair_color;

        return returnedDictionary;
    }

}