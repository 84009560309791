<template>
  <div class="ModificationView">
    <UILabel
      v-if="!canAddCost"
      :text="'You must add this Item to a Character with Currencies or add a Game System with currencies to add a Cost. To add a Game System select the Compendium tab.'"
      :justify="'left'"
    />
    <UICollectionView
      v-for="(option, index) in costOptions"
      :key="index"
      :hasItems="getCostResourceByKey(option.key).length > 0"
    >
      <UILabel
        v-if="getCostResourceByKey(option.key).length < 1 && canAddCost"
        :text="`+ Add ${option.text}`"
        :justify="'left'"
        @click="onShowChangesDialog(option.key, option.type)"
      />
      <UILabel
        v-for="(item, index) in getCostResourceByKey(option.key)"
        :key="index"
        :text="getCostResourceNameByKey(item.key, option.key)"
        :justify="'left'"
        @click="onShowChangesDialog(option.key, option.type)"
      />
    </UICollectionView>
  </div>
  <div>
    <UIDialogAssetSelectionView
      v-if="shouldShowChangesDialog"
      :type="assetSelectionSubTitle"
      :fields="assetSelectionData"
      :allowedFields="costSelectionData"
      @ui-dialog-asset-selection-submit="onSubmitChangesDialog"
      @ui-dialog-asset-selection-cancel="onCancelChangesDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapState, mapGetters } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import UIDialogAssetSelectionView from "@/components/character/dialogs/UIDialogAssetSelectionView.vue";

import QuickView from "@/store/models/QuickView";
import Cost from "@/store/models/Cost";
import CostResource from "@/store/models/CostResource";
import GameSystem from "@/store/models/GameSystem";
import Currency from "@/store/models/Currency";

import { isEmpty } from "@/utility/global";

export default defineComponent({
  name: "CostView",
  components: {
    UILabel,
    UICollectionView,
    UIDialogAssetSelectionView,
  },
  emits: ["cost-update"],
  props: {
    cost: {
      type: Object as () => Cost,
      required: true,
    },
    gameSystem: {
      type: Object as () => GameSystem,
      default: {},
      required: true,
    },
    isCompendium: {
      type: Boolean,
      default: true,
      required: true,
    },
    assetType: {
      type: String,
      default: "",
      required: true,
    },
    campaignId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      canAddCost: true,
      shouldShowChangesDialog: false,
      costOptions: [] as any[],
      selectedKey: "",
      selectedAssetGroup: "",
      assetSelectionData: [] as QuickView[],
      costSelectionData: {} as Record<string, string>,
      selectedCost: {} as Cost,
    };
  },
  computed: {
    ...mapGetters("Account", ["getWorkshopResources"]),
    ...mapGetters("Character", ["getCharacter"]),
    ...mapState("Account", ["workshop"]),
    assetSelectionSubTitle(): string {
      const filteredOptions = this.costOptions.find(
        (co: any) => co.key === this.selectedKey
      );
      if (filteredOptions) {
        return filteredOptions.text.toLowerCase();
      }
      return "";
    },
  },
  methods: {
    getCostResourceByKey(key: string): Record<string, any>[] {
      switch (key) {
        case "resources":
          return this.selectedCost.resources;
        case "resource_fractions":
          return this.selectedCost.resourceFractions;
        case "currencies":
          return this.selectedCost.currencies;
        default:
          return [];
      }
    },
    getCostResourceNameByKey(key: string, type: string): string {
      const costResources = this.selectedCost.toDictionary()[type];
      switch (type) {
        case "resources":
        case "resource_fractions":
        case "currencies":
          const resource = this.assetSelectionData.find(
            (r: any) => r.key === key
          );
          if (resource) {
            return `${resource.name}: ${costResources[key]}`;
          }
          return "";
        default:
          return "";
      }
    },
    onShowChangesDialog(key: string, type: string) {
      this.selectedKey = key;

      this.costSelectionData = this.selectedCost.toDictionary()[key];
      this.setAssetSelectionData(type);
      this.shouldShowChangesDialog = true;
    },
    setAssetSelectionData(key: string) {
      this.canAddCost = true;
      if (this.campaignId === null) {
        if (key === "resources") {
          this.assetSelectionData = this.getWorkshopResources;
        }
        else if (key === "currencies") {
          if (!isEmpty(this.gameSystem)) {
            console.log(this.gameSystem);
            this.assetSelectionData = this.gameSystem.currencies.map(
              (currency: Currency) => {
                const quickView: QuickView = new QuickView(
                  {
                    name: currency.name,
                  },
                  currency.key
                );
                return quickView;
              }
            );
          } else if (!this.isCompendium) {
            console.log("Out");
            let currencies: Currency[] = this.getCharacter.currencies;

            this.assetSelectionData = currencies.map((currency: Currency) => {
              const quickView: QuickView = new QuickView(
                {
                  name: currency.name,
                },
                currency.key
              );
              return quickView;
            });
          } else {
            this.canAddCost = false;
          }
        }

        return;
      }
    },
    onSubmitChangesDialog(fields: Record<string, string>) {
      let costResources: CostResource[] = [];
      for (const key in fields) {
        costResources.push(new CostResource(fields[key], key));
      }
      switch (this.selectedKey) {
        case "resources":
          this.selectedCost.resources = costResources;
          break;
        case "resource_fractions":
          this.selectedCost.resourceFractions = costResources;
          break;
        case "currencies":
          this.selectedCost.currencies = costResources;
          break;
      }
      this.$emit("cost-update", this.selectedCost);
    },
    onCancelChangesDialog() {
      this.shouldShowChangesDialog = false;
    },
  },
  mounted() {
    if (this.assetType === "spell") {
      this.costOptions = [
        {
          key: "resources",
          text: "Resources",
          type: "resources",
        },
        {
          key: "resource_fractions",
          text: "Resource Fractions",
          type: "resources",
        },
      ];
    } else if (this.assetType === "item") {
      this.costOptions = [
        {
          key: "currencies",
          text: "Currencies",
          type: "currencies",
        },
      ];
    }
  },
  beforeMount() {
    this.selectedCost = new Cost(this.cost.toDictionary());
    if (this.assetType === "spell") {
      this.selectedAssetGroup = "resource";
      this.setAssetSelectionData("resources");
    } else if (this.assetType === "item") {
      this.selectedAssetGroup = "resource";
      this.setAssetSelectionData("currencies");
    }
  },
});
</script>

<style lang="scss" scoped>
</style>