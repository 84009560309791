import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icon_plus@x1.png'
import _imports_1 from '@/assets/icon_pencil@x2.png'


const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_SecondaryAttributeUICollectionViewCell = _resolveComponent("SecondaryAttributeUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UITextView = _resolveComponent("UITextView")!
  const _component_UISwitchView = _resolveComponent("UISwitchView")!
  const _component_FormulaItemUICollectionViewCell = _resolveComponent("FormulaItemUICollectionViewCell")!
  const _component_ManageAssetView = _resolveComponent("ManageAssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!
  const _component_UIDialogSecondaryAttributeView = _resolveComponent("UIDialogSecondaryAttributeView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogMessageView = _resolveComponent("UIDialogMessageView")!
  const _component_UIDialogDescription = _resolveComponent("UIDialogDescription")!
  const _component_UIDialogPublishView = _resolveComponent("UIDialogPublishView")!
  const _component_UIDialogFormulaView = _resolveComponent("UIDialogFormulaView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: `${_ctx.action} Attribute`,
      canRemove: _ctx.action == 'Edit',
      hasMenu: _ctx.hasMenu,
      menuItems: _ctx.menuItems,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onAttributeDialogMenuSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ManageAssetView, {
          asset: _ctx.selectedAttribute,
          hasModifications: true,
          isCompendium: _ctx.isWorkshop
        }, {
          basic: _withCtx(() => [
            _createVNode(_component_UITextField, {
              id: 'txtName',
              placeholder: 'Name',
              textLabel: 'Name',
              modelValue: _ctx.selectedAttribute.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAttribute.name) = $event)),
              disabled: _ctx.readOnly
            }, null, 8, ["modelValue", "disabled"]),
            _createVNode(_component_UITextField, {
              id: 'txtValue',
              placeholder: 'Value',
              textLabel: 'Value',
              modelValue: _ctx.selectedAttribute.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedAttribute.value) = $event)),
              disabled: _ctx.isWorkshop || _ctx.readOnly
            }, null, 8, ["modelValue", "disabled"]),
            _createVNode(_component_UISeparatorView),
            (_ctx.selectedAttribute.extra && _ctx.selectedAttribute.extra?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_UILabel, {
                    text: 'Secondary',
                    type: 'h2'
                  }),
                  (!_ctx.readOnly)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _imports_0,
                        style: {"cursor":"pointer"},
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDisplayValueDialog()))
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_UICollectionView, { id: 'cvExtra' }, {
              default: _withCtx(() => [
                (
              !_ctx.selectedAttribute.extra || _ctx.selectedAttribute.extra?.length < 1
            )
                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                      key: 0,
                      title: 'No Secondary Attributes',
                      buttonTitle: 'Add Secondary Attribute',
                      onUiOnboardView: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDisplayValueDialog()))
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedAttribute.extra, (extra, index) => {
                  return (_openBlock(), _createBlock(_component_SecondaryAttributeUICollectionViewCell, {
                    key: index,
                    extra: extra,
                    onClick: ($event: any) => (_ctx.onDisplayValueDialog(index, extra))
                  }, null, 8, ["extra", "onClick"]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_UISeparatorView),
            _createVNode(_component_UITextView, {
              id: 'txtDescription',
              modelValue: _ctx.descriptionPreview,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.descriptionPreview) = $event)),
              disabled: true,
              placeholder: 'Description',
              onClick: _ctx.onDisplayDescriptionDialog
            }, null, 8, ["modelValue", "onClick"])
          ]),
          advanced: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_UILabel, {
                text: 'Primary Attribute',
                disabled: _ctx.readOnly
              }, null, 8, ["disabled"]),
              _createVNode(_component_UISwitchView, {
                checked: _ctx.selectedAttribute.is_primary_attribute,
                disabled: _ctx.readOnly,
                onUiSwitchViewToggle: _ctx.onTogglePrimaryAttribute
              }, null, 8, ["checked", "disabled", "onUiSwitchViewToggle"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_UILabel, {
                text: 'Modifier Formula',
                type: 'h2',
                justify: 'left'
              }),
              _createElementVNode("img", {
                src: _imports_1,
                style: {"cursor":"pointer"},
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onDisplayFormulaDialog && _ctx.onDisplayFormulaDialog(...args)))
              })
            ]),
            (_ctx.modifierFormula.length > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass({ disabled: _ctx.isWorkshop }),
                  style: {"display":"flex","justify-content":"space-between","align-items":"center"}
                }, [
                  _createVNode(_component_UILabel, { text: 'Show Modifier' }),
                  _createVNode(_component_UISwitchView, {
                    checked: _ctx.selectedAttribute.show_modifier,
                    onUiSwitchViewToggle: _ctx.onModifierFormulaToggle
                  }, null, 8, ["checked", "onUiSwitchViewToggle"])
                ], 2))
              : _createCommentVNode("", true),
            _createVNode(_component_UICollectionView, {
              id: 'cvFormulas',
              hasItems: _ctx.modifierFormula.length > 0
            }, {
              default: _withCtx(() => [
                (_ctx.modifierFormula.length < 1)
                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                      key: 0,
                      title: 'No modifier formula',
                      buttonTitle: 'Add Formula',
                      onUiOnboardView: _ctx.onDisplayFormulaDialog
                    }, null, 8, ["onUiOnboardView"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modifierFormula, (formula, index) => {
                  return (_openBlock(), _createBlock(_component_FormulaItemUICollectionViewCell, {
                    key: index,
                    formulaItem: formula,
                    onClick: _ctx.onDisplayFormulaDialog
                  }, null, 8, ["formulaItem", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["hasItems"]),
            _createElementVNode("div", {
              class: "flex",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onDisplayFormulaDialog && _ctx.onDisplayFormulaDialog(...args)))
            }),
            _createVNode(_component_UISeparatorView)
          ]),
          _: 1
        }, 8, ["asset", "isCompendium"])
      ]),
      _: 1
    }, 8, ["title", "canRemove", "hasMenu", "menuItems", "onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 0,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowValueDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogSecondaryAttributeView, {
            key: 1,
            extra: _ctx.extraDialogData,
            onUiDialogSecondaryAttributeSubmit: _ctx.onSubmitValueDialog,
            onUiDialogSecondaryAttributeCancel: _ctx.onCancelValueDialog,
            onUiDialogSecondaryAttributeRemove: _ctx.onRemoveValueDialog
          }, null, 8, ["extra", "onUiDialogSecondaryAttributeSubmit", "onUiDialogSecondaryAttributeCancel", "onUiDialogSecondaryAttributeRemove"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowInputDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
            key: 2,
            type: 'text',
            title: _ctx.submissionDialogData.title,
            body: _ctx.submissionDialogData.body,
            inputTitle: _ctx.submissionDialogData.inputTitle,
            inputPlaceholder: 'Description of game system',
            onUiDialogInputSubmit: _ctx.onPublish,
            onUiDialogInputCancel: _ctx.onDismissPublishDialog
          }, null, 8, ["title", "body", "inputTitle", "onUiDialogInputSubmit", "onUiDialogInputCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowMessageDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogMessageView, {
            key: 3,
            title: 'Success',
            body: 'Asset successfuly submitted for review.',
            onUiDialogMessageSubmit: _ctx.onDismissMessageDialog
          }, null, 8, ["body", "onUiDialogMessageSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowDescriptionDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogDescription, {
            key: 4,
            description: _ctx.description,
            onUiDialogDescriptionSubmit: _ctx.onSubmitDescriptionDialog,
            onUiDialogDescriptionCancel: _ctx.onDismissDescriptionDialog
          }, null, 8, ["description", "onUiDialogDescriptionSubmit", "onUiDialogDescriptionCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPublishDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPublishView, {
            key: 5,
            asset: _ctx.attribute,
            body: 'Submitting this Attribute will share it with the entire community. Are you sure you want to submit this Attribute?',
            onUiDialogPublishCancel: _ctx.onDismissPublishDialog
          }, null, 8, ["asset", "body", "onUiDialogPublishCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowFormulaDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogFormulaView, {
            key: 6,
            formula: _ctx.selectedAttribute.modifier_formula,
            formulaTable: _ctx.selectedAttribute.table_modifier_formula,
            canHaveTable: true,
            onUiDialogFormulaSubmit: _ctx.onSubmitFormulaDialog,
            onUiDialogFormulaCancel: _ctx.onCancelFormulaDialog
          }, null, 8, ["formula", "formulaTable", "onUiDialogFormulaSubmit", "onUiDialogFormulaCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}