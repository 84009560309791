import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ResourceView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIActivityView = _resolveComponent("UIActivityView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UIActivityView, {
      radius: _ctx.radius,
      progress: _ctx.resourcePercentage,
      stroke: _ctx.stroke,
      color: _ctx.resourceColor,
      label: _ctx.label,
      labelTitle: _ctx.labelTitle,
      labelSize: _ctx.labelSize
    }, null, 8, ["radius", "progress", "stroke", "color", "label", "labelTitle", "labelSize"]),
    _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.resource.name), 513), [
      [_vShow, _ctx.resource.show_name]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.current), 513), [
        [_vShow, _ctx.resource.show_current]
      ]),
      _withDirectives(_createElementVNode("span", null, " / ", 512), [
        [_vShow, _ctx.resource.show_current && _ctx.resource.show_max]
      ]),
      _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.max), 513), [
        [_vShow, _ctx.resource.show_max]
      ])
    ], 512), [
      [_vShow, _ctx.resource.show_value]
    ]),
    _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.resourcePercentage) + "%", 513), [
      [_vShow, _ctx.resource.show_percentage]
    ])
  ]))
}