import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icon_plus@x3.png'
import _imports_1 from '@/assets/icon_minus@x3.png'


const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = ["src"]
const _hoisted_3 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_4 = { style: {"width":"44px","overflow":"hidden"} }
const _hoisted_5 = { style: {"width":"44px","overflow":"hidden"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_ResourceView = _resolveComponent("ResourceView")!
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogMainResourceView = _resolveComponent("UIDialogMainResourceView")!
  const _component_UIDialogExperienceView = _resolveComponent("UIDialogExperienceView")!
  const _component_UIDialogInputView = _resolveComponent("UIDialogInputView")!
  const _component_UIDialogPromptView = _resolveComponent("UIDialogPromptView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: 'Overview',
      canRemove: true,
      hasMenu: true,
      menuItems: ['Save as Template', 'Duplicate Character', 'Delete Character'],
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel,
      onUiDialogMenuSelect: _ctx.onDialogMenuSelect
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.portrait,
            width: "75",
            height: "75",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPickPortrait && _ctx.onPickPortrait(...args)))
          }, null, 8, _hoisted_2),
          _createElementVNode("input", {
            type: "file",
            style: {"display":"none"},
            id: "fileInput",
            accept: "image/png, image/jpeg",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPortraitPicked && _ctx.onPortraitPicked(...args)))
          }, null, 32),
          _createElementVNode("div", null, [
            _createVNode(_component_UILabel, {
              text: _ctx.curCharacterName,
              type: 'h2',
              onClick: _ctx.onDisplayInputDialog
            }, null, 8, ["text", "onClick"])
          ])
        ]),
        _createVNode(_component_UISeparatorView),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ResourceView, {
            resource: _ctx.mainResource,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDisplayMainResourceDialog()))
          }, null, 8, ["resource"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _imports_0,
              width: "32",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.btnIncreaseMainResourceCurrent && _ctx.btnIncreaseMainResourceCurrent(...args)))
            }),
            _createVNode(_component_UITextField, {
              id: 'txtMain',
              type: 'number',
              textLabel: '',
              saveOldValue: true,
              modelValue: _ctx.txtMax,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.txtMax) = $event)),
              placeholder: '0',
              shouldShowLabel: false
            }, null, 8, ["modelValue"]),
            _createElementVNode("img", {
              src: _imports_1,
              width: "32",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.btnDecreaseMainResourceCurrent && _ctx.btnDecreaseMainResourceCurrent(...args)))
            })
          ]),
          _createVNode(_component_ResourceView, {
            resource: _ctx.characterExperience,
            label: _ctx.curCharacterLevel,
            labelTitle: 'Level',
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onDisplayExperienceDialog()))
          }, null, 8, ["resource", "label"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _imports_0,
              width: "32",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.btnIncreaseExperienceCurrent && _ctx.btnIncreaseExperienceCurrent(...args)))
            }),
            _createVNode(_component_UITextField, {
              id: 'txtExperience',
              type: 'number',
              textLabel: '',
              saveOldValue: true,
              modelValue: _ctx.txtMaxXp,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.txtMaxXp) = $event)),
              placeholder: '0',
              shouldShowLabel: false
            }, null, 8, ["modelValue"]),
            _createElementVNode("img", {
              src: _imports_1,
              width: "32",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.btnDecreaseExperienceCurrent && _ctx.btnDecreaseExperienceCurrent(...args)))
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["onUiDialogSubmit", "onUiDialogCancel", "onUiDialogMenuSelect"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowResourceDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogMainResourceView, {
            key: 0,
            mainResource: _ctx.mainResource,
            onUiDialogMainResourceSubmit: _ctx.onSubmitResourceDialog,
            onUiDialogMainResourceCancel: _ctx.onCancelResourceDialog
          }, null, 8, ["mainResource", "onUiDialogMainResourceSubmit", "onUiDialogMainResourceCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowExperienceDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogExperienceView, {
            key: 1,
            experience: _ctx.characterExperience,
            level: _ctx.curCharacterLevel,
            onUiDialogExperienceSubmit: _ctx.onSubmitExperienceDialog,
            onUiDialogExperienceCancel: _ctx.onCancelExperienceDialog
          }, null, 8, ["experience", "level", "onUiDialogExperienceSubmit", "onUiDialogExperienceCancel"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowInputDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogInputView, {
            key: 2,
            onUiDialogInputCancel: _ctx.onCancelInputDialog,
            onUiDialogInputSubmit: _ctx.onSaveInputDialog,
            type: _ctx.inputDialogData.type,
            title: _ctx.inputDialogData.name,
            body: _ctx.inputDialogData.body,
            inputTitle: _ctx.inputDialogData.field,
            inputValue: _ctx.inputDialogData.value,
            inputPlaceholder: _ctx.inputDialogData.placeholder,
            buttonTitle: _ctx.inputDialogData.input
          }, null, 8, ["onUiDialogInputCancel", "onUiDialogInputSubmit", "type", "title", "body", "inputTitle", "inputValue", "inputPlaceholder", "buttonTitle"]))
        : _createCommentVNode("", true),
      (_ctx.shouldShowPromptDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogPromptView, {
            key: 3,
            onUiDialogPromptSubmit: _ctx.onSubmitPromptDialog,
            onUiDialogPromptCancel: _ctx.onCancelPromptDialog,
            title: _ctx.promptDialogData.name,
            body: _ctx.promptDialogData.body,
            submitTitle: _ctx.promptDialogData.submitTitle,
            type: _ctx.promptDialogData.type
          }, null, 8, ["onUiDialogPromptSubmit", "onUiDialogPromptCancel", "title", "body", "submitTitle", "type"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}