export enum AssetType {
  attribute = "attribute",
  spell = "spell",
  resource = "resource",
  item = "item",
  game_system = "game_system",
  race = "race",
  char_class = "char_class",
  effect = "effect",
  behavior = "behavior",
  character_stats = "character_stats"
}