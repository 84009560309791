<template>
  <UIDialogView
    :title="`${newCurrency.key === '' ? 'New' : 'Edit'} Currency`"
    :hasMenu="newCurrency.key !== ''"
    :menuItems="['Delete Currency']"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onCurrencyMenuSelect"
  >
    <UITextField
      :id="'txtName'"
      :placeholder="'Name'"
      :textLabel="'Name'"
      v-model="newCurrency.name"
    />
    <UITextField
      :id="'txtValue'"
      :placeholder="'Value'"
      :textLabel="'Value'"
      :type="'number'"
      v-model="newCurrency.value"
    />
  </UIDialogView>
  <div>
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UITextField from "@/components/UI/UITextField.vue";
import Currency from "@/store/models/Currency";

export default defineComponent({
  name: "UIDialogManageCurrency",
  components: {
    UIDialogView,
    UIDialogPromptView,
    UITextField,
  },
  emits: ["ui-dialog-currency-submit", "ui-dialog-currency-delete", "ui-dialog-currency-cancel"],
  props: {
    selectedCurrency: {
      type: Object as () => Currency,
      default: {},
    },
  },
  data() {
    return {
      newCurrency: {} as Currency,
      shouldShowPromptDialog: false,
      promptDialogData: {},
    };
  },
  methods: {
    onSubmit() {
      const newCurrency: Currency = new Currency(
        this.newCurrency,
        this.newCurrency.key
      );
      
      this.$emit("ui-dialog-currency-submit", newCurrency);
      this.onCancel();
    },
    onCancel() {
      this.$emit("ui-dialog-currency-cancel");
    },
    onCurrencyMenuSelect(index: number) {
      if (index === 0) {
        this.onShowDeleteDialog();
      }
    },
    onShowDeleteDialog() {
      this.shouldShowPromptDialog = true;

      this.promptDialogData = {
        name: "Delete Currency",
        body: `Are you sure you want to delete ${this.newCurrency.name}? It cannot be undone?`,
        submitTitle: "Continue",
      };
    },
    onSubmitPromptDialog() {
      this.$emit("ui-dialog-currency-delete", this.newCurrency);

      this.onCancel();
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
  },
  mounted() {
    this.newCurrency = new Currency(
      this.selectedCurrency.toDictionary(),
      this.selectedCurrency.key
    );
  },
});
</script>