<template>
  <UIDialogView
    :title="'Overview'"
    :canRemove="true"
    :hasMenu="true"
    :menuItems="['Save as Template', 'Duplicate Character', 'Delete Character']"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onDialogMenuSelect"
  >
    <div style="display: flex">
      <img :src="portrait" width="75" height="75" @click="onPickPortrait" />
      <input
        type="file"
        style="display: none"
        id="fileInput"
        accept="image/png, image/jpeg"
        @change="onPortraitPicked"
      />
      <div>
        <UILabel
          :text="curCharacterName"
          :type="'h2'"
          @click="onDisplayInputDialog"
        />
      </div>
    </div>
    <UISeparatorView />
    <div style="display: flex; justify-content: space-between">
      <ResourceView
        :resource="mainResource"
        @click="onDisplayMainResourceDialog()"
      />
      <div style="width: 44px; overflow: hidden">
        <img
          src="@/assets/icon_plus@x3.png"
          width="32"
          @click="btnIncreaseMainResourceCurrent"
        />
        <UITextField
          :id="'txtMain'"
          :type="'number'"
          :textLabel="''"
          :saveOldValue="true"
          v-model="txtMax"
          :placeholder="'0'"
          :shouldShowLabel="false"
        />
        <img
          src="@/assets/icon_minus@x3.png"
          width="32"
          @click="btnDecreaseMainResourceCurrent"
        />
      </div>
      <ResourceView
        :resource="characterExperience"
        :label="curCharacterLevel"
        :labelTitle="'Level'"
        @click="onDisplayExperienceDialog()"
      />
      <div style="width: 44px; overflow: hidden">
        <img
          src="@/assets/icon_plus@x3.png"
          width="32"
          @click="btnIncreaseExperienceCurrent"
        />
        <UITextField
          :id="'txtExperience'"
          :type="'number'"
          :textLabel="''"
          :saveOldValue="true"
          v-model="txtMaxXp"
          :placeholder="'0'"
          :shouldShowLabel="false"
        />
        <img
          src="@/assets/icon_minus@x3.png"
          width="32"
          @click="btnDecreaseExperienceCurrent"
        />
      </div>
    </div>
  </UIDialogView>
  <div>
    <UIDialogMainResourceView
      v-if="shouldShowResourceDialog"
      :mainResource="mainResource"
      @ui-dialog-main-resource-submit="onSubmitResourceDialog"
      @ui-dialog-main-resource-cancel="onCancelResourceDialog"
    />
    <UIDialogExperienceView
      v-if="shouldShowExperienceDialog"
      :experience="characterExperience"
      :level="curCharacterLevel"
      @ui-dialog-experience-submit="onSubmitExperienceDialog"
      @ui-dialog-experience-cancel="onCancelExperienceDialog"
    />
    <UIDialogInputView
      v-if="shouldShowInputDialog"
      @ui-dialog-input-cancel="onCancelInputDialog"
      @ui-dialog-input-submit="onSaveInputDialog"
      :type="inputDialogData.type"
      :title="inputDialogData.name"
      :body="inputDialogData.body"
      :inputTitle="inputDialogData.field"
      :inputValue="inputDialogData.value"
      :inputPlaceholder="inputDialogData.placeholder"
      :buttonTitle="inputDialogData.input"
    />
    <UIDialogPromptView
      v-if="shouldShowPromptDialog"
      @ui-dialog-prompt-submit="onSubmitPromptDialog"
      @ui-dialog-prompt-cancel="onCancelPromptDialog"
      :title="promptDialogData.name"
      :body="promptDialogData.body"
      :submitTitle="promptDialogData.submitTitle"
      :type="promptDialogData.type"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UIDialogInputView from "@/components/UI/dialogs/UIDialogInputView.vue";
import UIDialogPromptView from "@/components/UI/dialogs/UIDialogPromptView.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";

import ResourceView from "@/components/character/ResourceView.vue";
import MainResourceView from "@/components/character/MainResourceView.vue";

import UIDialogMainResourceView from "@/components/character/dialogs/UIDialogMainResourceView.vue";
import UIDialogExperienceView from "@/components/character/dialogs/UIDialogExperienceView.vue";
import IOverview from "@/store/interfaces/IOverview";
import MainResource from "@/store/models/MainResource";
import Experience from "@/store/models/Experience";
import Veterancy from "@/store/models/Veterancy";

export default defineComponent({
  name: "UIDialogOverview",
  components: {
    UIDialogView,
    UIDialogInputView,
    UIDialogPromptView,
    UIButton,
    UITextField,
    UILabel,
    UITextView,
    UISeparatorView,
    ResourceView,
    MainResourceView,
    UIDialogMainResourceView,
    UIDialogExperienceView,
  },
  emits: ["ui-dialog-overview-submit", "ui-dialog-overview-cancel"],
  props: {
    resource: {
      type: Object as () => MainResource,
      required: true,
      default: {},
    },
    experience: {
      type: Object as () => Experience,
      required: true,
      default: {},
    },
    characterName: {
      type: String,
      require: true,
    },
    characterLevel: {
      type: Number,
      require: true,
    },
  },
  computed: {
    ...mapGetters("Character", [
      "getCharacterId",
      "getCharacter",
      "getPortrait",
    ]),
  },
  data() {
    return {
      shouldShowResourceDialog: false,
      shouldShowExperienceDialog: false,
      shouldShowInputDialog: false,
      shouldShowPromptDialog: false,
      curCharacterName: this.characterName,
      mainResource: {} as MainResource,
      characterExperience: {} as Experience,
      curCharacterLevel: this.characterLevel,
      inputDialogData: {
        type: Object,
        default: {},
      },
      promptDialogData: {
        type: Object,
        default: {},
      },
      portrait: undefined as string | undefined,
      txtMax: 0,
      txtMaxXp: 0,
    };
  },
  methods: {
    ...mapActions("Account", [
      "createCharacter",
      "deleteCharacter",
      "setCharacterTemplateWithName",
    ]),
    ...mapActions("Character", ["levelUp"]),
    calculateExperience(level: number) {
      //TODO: This is the same as the levelUp function in the character - can we combine these things?
      let veterancies: Veterancy[] = this.characterExperience.veterancies;
      if (veterancies.length < 1) {
        return;
      }
      let maxLevel: number =
        (this.curCharacterLevel! <= veterancies.length - 1
          ? this.curCharacterLevel
          : veterancies.length - 1) ?? 0;
      let maxVeterency: Veterancy = veterancies[maxLevel];
      if (this.curCharacterLevel! <= maxLevel) {
        var newLevel: number = level;
        let veterency: Veterancy = veterancies[level];
        this.characterExperience.max = veterency.minimum_experience;
        if (this.characterExperience.current >= veterency.minimum_experience) {
          newLevel = newLevel + 1;
          this.characterExperience.current =
            this.characterExperience.current - veterency.minimum_experience;
          this.curCharacterLevel = +newLevel;

          this.calculateExperience(level);
        }
      } else {
        this.characterExperience.max = maxVeterency.minimum_experience;
      }
    },
    btnIncreaseMainResourceCurrent() {
      const newValue = +this.txtMax;
      this.mainResource.current += newValue;

      this.calculateExperience(this.curCharacterLevel!);
    },
    btnDecreaseMainResourceCurrent() {
      const newValue = +this.txtMax;
      this.mainResource.current += -Math.abs(newValue);

      this.calculateExperience(this.curCharacterLevel!);
    },
    btnIncreaseExperienceCurrent() {
      const newValue = +this.txtMaxXp;
      this.characterExperience.current += newValue;

      this.calculateExperience(this.curCharacterLevel!);
    },
    btnDecreaseExperienceCurrent() {
      const newValue = +this.txtMaxXp;
      this.characterExperience.current += -Math.abs(newValue);

      this.calculateExperience(this.curCharacterLevel!);
    },
    onCancel() {
      this.$emit("ui-dialog-overview-cancel");
    },
    onSubmit() {
      let overview: IOverview = {
        Character_Name: this.curCharacterName!,
        Main_Resource: this.mainResource,
        Experience: this.characterExperience,
        Level: this.curCharacterLevel!,
        Portrait: this.portrait,
      };
      this.$emit("ui-dialog-overview-submit", overview);
      this.onCancel();
    },
    onDialogMenuSelect(index: number) {
      if (index === 0) {
        let data: any = {
          name: "Character Template",
          body: "Saving this Character as a template will allow Attributes, Spells, Resources, Experience, Main Vital, and other statistics to be copied to another Character. Create a name for this Template.",
          submitTitle: "Continue",
          type: "saveTemplate",
          field: "Template Name",
          placeholder: "Name",
          value: this.characterName,
          input: "Save",
        };

        this.inputDialogData = data;
        this.shouldShowInputDialog = true;
      } else if (index === 1) {
        let data: any = {
          name: "Duplicate Character",
          body: "Duplicating this Character will create a new Character with all the same Assets, statistics, and all other information. This does not duplicate the individual assets.",
          submitTitle: "Continue",
          type: "duplicateCharacter",
        };

        this.promptDialogData = data;
        this.shouldShowPromptDialog = true;
      } else if (index === 2) {
        let data: any = {
          name: "Delete Character",
          body: "Are you sure you want to delete this Character? This cannot be undone.",
          submitTitle: "Continue",
          type: "deleteCharacter",
        };

        this.promptDialogData = data;
        this.shouldShowPromptDialog = true;
      }
    },
    onCancelPromptDialog() {
      this.shouldShowPromptDialog = false;
    },
    onSubmitPromptDialog(data: any) {
      const type = data.type;
      if (type === "duplicateCharacter") {
        const newChar = this.getCharacter.toDictionary();
        newChar.name = newChar.name + " Copy";
        this.createCharacter(newChar);
      } else if (type == "deleteCharacter") {
        this.deleteCharacter(this.getCharacterId);
      }

      this.onCancel();
    },
    onDisplayMainResourceDialog() {
      this.shouldShowResourceDialog = true;
    },
    onSubmitResourceDialog(data: MainResource) {
      this.mainResource = data;
    },
    onCancelResourceDialog() {
      this.shouldShowResourceDialog = false;
    },
    onDisplayExperienceDialog() {
      this.shouldShowExperienceDialog = true;
    },
    onSubmitExperienceDialog(data: any) {
      this.characterExperience = data.experience;
      this.curCharacterLevel = data.level;

      this.calculateExperience(data.level);
    },
    onCancelExperienceDialog() {
      this.shouldShowExperienceDialog = false;
    },
    onCancelInputDialog() {
      this.shouldShowInputDialog = false;
    },
    onSaveInputDialog(returnedData: any) {
      if (returnedData.type === "renameCharacter") {
        const data = returnedData;
        const newName = data.value;

        this.curCharacterName = newName;
      } else if (returnedData.type === "saveTemplate") {
        this.setCharacterTemplateWithName(returnedData.value);
        this.onCancelInputDialog();
      }
    },
    onDisplayInputDialog() {
      let data: any = {
        name: "Edit Character name",
        body: "Type the new name of the Character.",
        field: "Edit Character name",
        placeholder: this.characterName,
        value: this.characterName,
        input: "Save",
        type: "renameCharacter",
      };

      this.inputDialogData = data;
      this.shouldShowInputDialog = true;
    },
    onPickPortrait() {
      document.getElementById("fileInput")?.click();
    },
    onPortraitPicked(event: any) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        if (fileReader.result?.toString() !== "") {
          this.portrait = fileReader.result?.toString();
        }
      });
      fileReader.readAsDataURL(files[0]);
    },
  },
  mounted() {
    this.mainResource = new MainResource(this.resource.toDictionary());
    this.characterExperience = new Experience(this.experience.toDictionary());
    this.portrait = this.getPortrait.url;
  },
});
</script>

<style lang="scss" scoped>
</style>