import { getDatabase, ref, push, get, update, remove, child, DataSnapshot, DatabaseReference } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage, ref as storageRef, uploadString, getDownloadURL, FirebaseStorage, UploadResult } from 'firebase/storage';
import { getFirestore, query, doc, where, collection, addDoc, setDoc, deleteDoc, getDocs, onSnapshot, Timestamp, updateDoc, DocumentData, Query } from "firebase/firestore";

import CharacterClass from "@/store/models/Character";
import GameSystem from "@/store/models/GameSystem";
import ResourceBase from "@/store/models/AssetBases/ResourceBase";
import ItemBase from "@/store/models/AssetBases/ItemBase";
import AttributeBase from "@/store/models/AssetBases/AttributeBase";
import SpellBase from "@/store/models/AssetBases/SpellBase";
import { AssetType } from "@/store/models/Compendium/AssetType";


export const newKey = (): string => {
  const database = ref(getDatabase());
  let newKey = push(child(database, 'Users')).key
  return newKey!;
}

export const newFirestoreKey = (): string => {
  const db = getFirestore();
  return doc(collection(db, "temp")).id;
}

export const returnPartyKey = async (): Promise<string | undefined> => {
  const uniqueCode = generatePartyKey(6);
  const isUnique = await isPartyKeyValid(uniqueCode);

  if (isUnique) {
    return new Promise((resolve, reject) => {
      resolve(uniqueCode);
    });

  }

  returnPartyKey();
}

const generatePartyKey = (length: number): string => {
  let uniqueCode = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    uniqueCode += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }

  return uniqueCode;
}
const isPartyKeyValid = async (checkingCode: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    const db = getFirestore();
    const partiesRef = collection(db, "parties");
    const q = query(partiesRef, where("party_id", "==", checkingCode));
    const querySnapshot = await getDocs(q);

    resolve(querySnapshot.empty);
  });

}
export const getCommunityAssets = (asset: AssetType, queryKey: string | null, queryValue: string | null): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (asset === AssetType.game_system) {
      queryKey = null;
      queryValue = null;
    }
    getFirestoreWithPath([`community_${asset}s`], queryKey, queryValue)
      .then((snapshot: Record<string, Record<string, any>>) => {
        switch (asset) {
          case AssetType.attribute:
            let returnedValue_Attribute: AttributeBase[] = [];

            for (var assetKey in snapshot) {
              let selectedAsset = snapshot[assetKey];
              const returnedAsset: AttributeBase = new AttributeBase(selectedAsset, assetKey);
              returnedValue_Attribute.push(returnedAsset);
            }
            returnedValue_Attribute = returnedValue_Attribute.sort((a, b) => a.name.localeCompare(b.name))
            resolve(returnedValue_Attribute);
            break;
          case AssetType.spell:
            let returnedValue_Spell: SpellBase[] = [];

            for (var assetKey in snapshot) {
              let selectedAsset = snapshot[assetKey];
              const returnedAsset: SpellBase = new SpellBase(selectedAsset, assetKey);
              returnedValue_Spell.push(returnedAsset);
            }
            returnedValue_Spell = returnedValue_Spell.sort((a, b) => a.name.localeCompare(b.name))
            resolve(returnedValue_Spell);
            break;
          case AssetType.resource:
            let returnedValue_Resource: ResourceBase[] = [];

            for (var assetKey in snapshot) {
              let selectedAsset = snapshot[assetKey];
              const returnedAsset: ResourceBase = new ResourceBase(selectedAsset, assetKey);
              returnedValue_Resource.push(returnedAsset);
            }
            returnedValue_Resource = returnedValue_Resource.sort((a, b) => a.name.localeCompare(b.name))
            resolve(returnedValue_Resource);
            break;
          case AssetType.item:
            let returnedValue_Item: ItemBase[] = [];

            for (var assetKey in snapshot) {
              let selectedAsset = snapshot[assetKey];
              const returnedAsset: ItemBase = new ItemBase(selectedAsset, assetKey);
              returnedValue_Item.push(returnedAsset);
            }
            returnedValue_Item = returnedValue_Item.sort((a, b) => a.name.localeCompare(b.name))
            resolve(returnedValue_Item);
            break;
          case AssetType.game_system:
            let returnedValue_GameSystem: GameSystem[] = [];

            for (var assetKey in snapshot) {
              let selectedAsset = snapshot[assetKey];
              const returnedAsset: GameSystem = new GameSystem(selectedAsset, assetKey);
              returnedValue_GameSystem.push(returnedAsset);
            }
            returnedValue_GameSystem = returnedValue_GameSystem.sort((a, b) => a.name.localeCompare(b.name))
            resolve(returnedValue_GameSystem);
            break;
        }
      });
  })
}

export const getCommunityGameSystems = (): Promise<GameSystem[]> => {
  return new Promise((resolve, reject) => {
    getFirestoreWithPath(["community_game_systems"], null, null)
      .then((snapshot: Record<string, Record<string, any>>) => {
        let returnedValue: GameSystem[] = [];

        for (var systemKey in snapshot) {
          let selectedAsset = snapshot[systemKey];
          const returnedAsset: GameSystem = new GameSystem(selectedAsset, systemKey);
          returnedValue.push(returnedAsset);
        }
        returnedValue = returnedValue.sort((a, b) => a.name.localeCompare(b.name))
        resolve(returnedValue);
      });
  })
}

export enum database_save {
  overwrite,
  firestoreAdd,
  firestoreSet,
  firestoreRemove,
  remove,
};

export enum database_option {
  player_name = 'player_name',
  dungeon_master = 'dungeon_master',
  characters = 'characters',
  character_key = 'SelectedCharacter',
  character_name = 'name',
  character_story = 'character_story',
  coin = 'coin',
  experience = 'experience',
  inventory = 'bags',
  journal = 'journal',
  level = 'level',
  main_resource = 'main_resource',
  resources = 'resources',
  portrait = 'portrait',
  section = 'attribute_tabs',
  selected_bag_name = 'selected_bag_name',
  selected_book_name = 'selected_book_name',
  selected_section_name = 'selected_section_name',
  spellbook = 'spell_books',
  items = 'items',
  attributes = 'attributes',
  attribute_groups = 'attribute_groups',
  spell_tables = 'spell_tables',
  spells = 'spells',
  workshop_resources = 'workshop_resources',
  workshop_items = 'workshop_items',
  workshop_attributes = 'workshop_attributes',
  workshop_spells = 'workshop_spells',
  character_version = 'character_version',
  currencies = 'currencies',
  dices = 'dices',
  character_template = 'character_template',
  character_campaign = 'character_campaign',
  attribute_campaign = 'attribute_campaign',
  item_campaign = 'item_campaign',
  spell_campaign = 'spell_campaign',
  resource_campaign = 'resource_campaign',
};

// export const setDatabaseAt = async (key: string, value: Record<string, any>, option: database_option, save: database_save) => {
//   return new Promise(async (resolve, reject) => {
//     const database = ref(getDatabase());
//     const userUID: string | undefined = getAuth().currentUser?.uid;
//     const characterID: string = Account.state.Selected_Character;
//     const selectedCharacter: CharacterClass = Character.state.character;

//     if (userUID) {
//       let userRef = `Users/${userUID}/`;
//       let ref: DatabaseReference = database;

//       switch (option) {
//         case database_option.attribute_campaign:
//           await setFirestore(['campaigns', selectedCharacter.campaign_id!, 'attributes'], value, save);
//           break;
//         case database_option.spell_campaign:
//           await setFirestore(['campaigns', selectedCharacter.campaign_id!, 'spells'], value, save);
//           break;
//         case database_option.resource_campaign:
//           await setFirestore(['campaigns', selectedCharacter.campaign_id!, 'resources'], value, save);
//           break;
//         case database_option.item_campaign:
//           await setFirestore(['campaigns', selectedCharacter.campaign_id!, 'items'], value, save);
//           break;
//         case database_option.character_key: {
//           await setDatabaseAccount(null, value, save);
//           break;
//         }
//         case database_option.characters:
//           await setDatabaseAccount('Characters', value, save);
//           break;
//         case database_option.attributes:
//         case database_option.attribute_groups:
//         case database_option.inventory:
//         case database_option.resources:
//         case database_option.section:
//         case database_option.spell_tables:
//         case database_option.spellbook:
//         case database_option.spells:
//         case database_option.currencies:
//         case database_option.dices:
//           if (selectedCharacter.campaign_id !== null) {
//             await setFirestore(['characters', selectedCharacter.key], selectedCharacter.toDictionary(), database_save.firestoreSet);
//           } else {
//             await setDatabaseCharacter(selectedCharacter, option, value, selectedCharacter, save);
//           }

//           break;
//         case database_option.items:
//         case database_option.character_name:
//         case database_option.character_story:
//         case database_option.coin:
//         case database_option.experience:
//         case database_option.journal:
//         case database_option.level:
//         case database_option.main_resource:
//         case database_option.player_name:
//         case database_option.selected_bag_name:
//         case database_option.selected_book_name:
//         case database_option.selected_section_name:
//         case database_option.character_version:
//           if (selectedCharacter.campaign_id !== null) {
//             await setFirestore(['characters', selectedCharacter.key], selectedCharacter.toDictionary(), database_save.firestoreSet);
//           } else {
//             await setDatabaseCharacter('', value, selectedCharacter, save);
//           }
//           break
//         case database_option.portrait:
//           // Create a root reference
//           const storage = getStorage();

//           // Create a reference to 'user/character/characterPortrait.png'
//           const portraitImagesRef = storageRef(storage, `${userUID}/${characterID}/characterPortrait.png`);

//           await uploadString(portraitImagesRef, value.Portrait.url, 'data_url')
//             .then(async (snapshot) => {
//               await getDownloadURL(portraitImagesRef)
//                 .then(async (url) => {
//                   value.Portrait.url = url;
//                 });
//             });

//           ref = child(database, userRef + `Characters/${characterID}`);
//           break;
//         case database_option.workshop_resources:
//         case database_option.workshop_attributes:
//         case database_option.workshop_items:
//         case database_option.workshop_spells:
//           await setDatabaseWorkshop(option, value, save);
//           break;
//         case database_option.character_template:
//           value.type = 'template';
//           if (value.campaign_id) {
//             await setFirestore(['campaigns', selectedCharacter.campaign_id!, 'characters'], value, save);
//           } else {
//             value.name = key
//             await setFirestore(['characters'], value, save);
//           }
//           break;
//         case database_option.character_campaign:
//           await setFirestore(['characters'], value, save);
//           break;
//         default: break;
//       }

//       if (save == database_save.remove) {
//         const deleteRef: DatabaseReference = child(ref, '/' + key);

//         remove(deleteRef).catch(error => {
//           reject(error);
//           console.log(`Data could not be deleted: ${error}.`)
//         });
//         resolve(true);
//       }
//     }
//   });
// }

export const setListenerAt = async (database: string, collection: string, document: string) => {
  return new Promise((resolve, reject) => {
    if (database === 'firestore') {
      const db = getFirestore();

      const ref = doc(db, collection, document);
      onSnapshot(ref, (doc) => {
        if (doc.exists()) {
          console.log("Resolve", doc);
          resolve(doc);
        }
      });
    }

  });
}

export const getFirestoreWithPath = async (collectionKeys: Array<string>, queryKey: string | null, queryValue: string | null): Promise<Record<string, Record<string, any>>> => {
  return new Promise(async (resolve, reject) => {
    const firestore = getFirestore();
    const collectionRef = collection(firestore, collectionKeys.join("/"));
    let q: Query<DocumentData, DocumentData>;
    if (queryKey === null && queryValue === null) {
      q = query(collectionRef);
    } else {
      q = query(collectionRef, where(queryKey!, "==", queryValue));
    }

    const querySnapshot = await getDocs(q);
    var returnedData: Record<string, Record<string, any>> = {};
    querySnapshot.forEach((doc) => {
      returnedData[doc.id] = doc.data();
    });
    resolve(returnedData);

  });
};

export const setFirestore = async (collectionKeys: Array<string>, value: Record<string, any>, save: database_save): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    const firestore = getFirestore();
    const userUID: string | undefined = getAuth().currentUser?.uid;

    const today = new Date();

    let docRef: any = {};

    if (save === database_save.firestoreAdd) {
      docRef = {
        document: collectionKeys.join("/"),
        data: {
          last_updated: Timestamp.fromDate(today),
          creation_date: value.creation_date === undefined ? Timestamp.fromDate(today) : value.creation_date,
          user_id: userUID,
          ...value,
        }
      };
      const createdDoc = await addDoc(collection(firestore, docRef.document), docRef.data);
      resolve(createdDoc.id);

    } else if (save === database_save.firestoreSet) {
      docRef = {
        document: collectionKeys.join("/"),
        data: {
          last_updated: Timestamp.fromDate(today),
          creation_date: Timestamp.fromDate(today),
          ...value,
        }
      };
      await setDoc(doc(firestore, docRef.document, value.key), docRef.data);
      resolve(value.key);
    } else if (save === database_save.firestoreRemove) {
      docRef = {
        document: collectionKeys.join("/"),
        data: {
          last_updated: Timestamp.fromDate(today),
          creation_date: Timestamp.fromDate(today),
          ...value,
        }
      };
      console.log(docRef.document, value.key);
      await deleteDoc(doc(firestore, docRef.document))
        .then(() => {
          resolve("");
        });
    }
  });
}

export const setDatabaseCharacter = async (selectedCharacter: CharacterClass, save: database_save) => {
  const firestore = getFirestore();
  const database = ref(getDatabase());
  const userUID: string | undefined = getAuth().currentUser?.uid;
  const characterID: string = selectedCharacter.key;
  let userRef = `Users/${userUID}/`;
  let dbRef: DatabaseReference = database;
  let docRef: any = {};

  const today = new Date();
  const isCampaignCharacter = selectedCharacter.type === 'campaign';

  return new Promise(async (resolve, reject) => {
    if (save === database_save.overwrite) {
      if (isCampaignCharacter) {
        docRef = {
          collection: "characters",
          document: selectedCharacter.key,
          data: {
            last_updated: Timestamp.fromDate(today),
            ...selectedCharacter.toDictionary(),
          }
        };

        await updateDoc(doc(firestore, docRef.collection, docRef.document), docRef.data);
        resolve(true);
      } else {
        dbRef = child(database, userRef + `Characters/${characterID}`);

        update(dbRef, selectedCharacter.toDictionary()).catch(error => {
          console.log(`Data could not be saved: ${error}.`)
          resolve(true);
        });
      }
    }
  });
}

export const setStorageWithPath = (path: string[], data: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const portraitImagesRef = storageRef(storage, `${path.join('/')}.png`);

    return uploadString(portraitImagesRef, data, 'data_url')
      .then(async (snapshot: UploadResult) => {
        return await getDownloadURL(portraitImagesRef)
          .then(async (url) => {
            return resolve(url);
          });
      });
  });
};

export const getStorageFromPath = (path: string[]): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Create a reference to the file we want to download
    const storage = getStorage();
    const ref = storageRef(storage, `${path.join('/')}.png`);

    // Get the download URL
    return getDownloadURL(ref)
      .then((url) => {
        return resolve(url);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
        }
        return resolve('');
      });
  });
};

export const setDatabaseWorkshop = async (key: string, value: Record<string, any>, save: database_save) => {
  const database = ref(getDatabase());
  const userUID: string | undefined = getAuth().currentUser?.uid;
  let userRef = `Users/${userUID}/`;
  let dbRef: DatabaseReference = database;

  return new Promise(async (resolve, reject) => {
    if (save === database_save.overwrite) {
      const keyPath = `${key ? `/${key}` : ''}`;
      dbRef = child(database, userRef + `Workshop${keyPath}`);

      update(dbRef, value).catch(error => {
        console.log(`Data could not be saved: ${error}.`)
        resolve(true);
      });
    }
    else if (save == database_save.remove) {
      const keyPath = `${key ? `/${key}` : ''}`;
      dbRef = child(database, userRef + `Workshop${keyPath}`);

      remove(dbRef).catch(error => {
        console.log(`Data could not be deleted: ${error}.`)
        resolve(true);
      });
    }
  });
}

export const setDatabaseAccount = async (key: string | null, value: Record<string, any>, save: database_save) => {
  const database = ref(getDatabase());
  const userUID: string | undefined = getAuth().currentUser?.uid;
  let userRef = `Users/${userUID}`;
  let dbRef: DatabaseReference = database;

  return new Promise(async (resolve, reject) => {
    if (save === database_save.overwrite) {
      const keyPath = `${key !== null ? `/${key}` : ''}`;
      dbRef = child(database, userRef + `${keyPath}`);

      update(dbRef, value)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          console.log(`Data could not be saved: ${error}.`)
          resolve(true);
        });
    }
    else if (save === database_save.firestoreAdd || save === database_save.firestoreSet) {
      value.user_id = userUID;

      setFirestore([key ?? ""], value, save).then((key: string) => {
        resolve(key)
      });
    }
    else if (save === database_save.remove) {
      const keyPath = `${key !== null ? `/${key}` : ''}`;
      dbRef = child(database, userRef + `${keyPath}`);

      update(dbRef, value)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          console.log(`Data could not be saved: ${error}.`);
        });
    }
  });
}
