<template>
    <UIDialogView
    :title="'Choose Field'"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <ChooseField 
        @choose-field-change="onFieldsChange"
    />
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import ChooseField from "@/components/character/ChooseField.vue";

import UIButton from "@/components/UI/UIButton.vue";

export default defineComponent({
    name: "UIDialogChooseField",
    components: {
        UIDialogView,
        ChooseField,
        UIButton,
    },
    emits: ["ui-dialog-choose-field-submit", "ui-dialog-choose-field-cancel"],
    data() {
      return {
        newFields: {},
      }
    },
    methods: {
      onFieldsChange(fields: any) {
        this.newFields = fields;
      },
      onSubmit() {
        this.$emit("ui-dialog-choose-field-submit", this.newFields);
        this.onCancel();
      },
      onCancel() {
        this.$emit("ui-dialog-choose-field-cancel");
      }
    },
});
</script>