<template>
  <div class="login">
    <div class="login-logo">Torewin Login</div>
    <div class="vLoginForm" @keyup.enter="btnLogin">
      <UITextField
        :id="'txtEmail'"
        :placeholder="'Email'"
        :type="'email'"
        :hasError="hasEmailError"
        :errorMessage="errorMessage"
        :textLabel="'Email'"
        v-model="user.email"
      />
      <UITextField
        :id="'txtPassword'"
        :placeholder="'Password'"
        :type="'password'"
        :hasError="hasPasswordError"
        :errorMessage="errorMessage"
        :textLabel="'Password'"
        v-model="user.password"
      />
      <UIButton
        :id="'btnLogin'"
        title="Log in to Torewin"
        @click="btnLogin"
      />
    </div>
    <!-- <UIListView /> -->
    <ul class="helpful-links">
      <li>
        <UILink
          id=""
          :title="'Create a free Torewin Account'"
          :href="'/signup'"
        />
      </li>
      <li>
        <UILink id="" title="Forgot Password" href="/login/forgot_password/" />
      </li>
    </ul>
    <div class="footer is-login-page">
      <div class="footer-top">
        <!--EULA-->
        <!-- <a href="../torewin-end-user-license-agreement">torewin.com EULA</a> -->
        <!-- <span>|</span> -->
        <!--Privacy Policy-->
        <UILink
          id="asd"
          title="Privacy"
          href="/torewin-online-privacy-policy"
        />
        <!-- <span>|</span> -->
        <!--Terms-->
        <!-- <a href="../torewin-online-terms-of-use">Terms</a> -->
        <!-- <span>|</span> -->
        <!--Copyright-->
        <!-- <a href="../torewin-online-terms-of-use">Copyright Infringement</a> -->
        <div class="copy">©2007-2024 Torewin. All Rights Reserved</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions } from "vuex";

//Components
import UITextField from "@/components/UI/UITextField.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UILink from "@/components/UI/UILink.vue";
import UIListView from "@/components/UI/UIListView.vue";

export default defineComponent({
  name: "Login",
  components: {
    UITextField,
    UIButton,
    UILink,
    UIListView,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      hasEmailError: false,
      hasPasswordError: false,
      errorMessage: ""
    };
  },
  methods: {
    ...mapActions('Account', [
      'login',
    ]),
    btnLogin() {
      this.hasEmailError = false;
      this.hasPasswordError = false;

      this.login({
        email: this.user.email,
        password: this.user.password,
      })
      .catch(error => {
        if (error.code.includes("password")) {
          this.hasPasswordError = true;
        }
        else {
          this.hasEmailError = true;
        }
        this.errorMessage = "The email or password you tried is incorrect. Please try again.";
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.login {
  min-height: 100%;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  max-width: 1200px;
  text-align: center;
}

.login-logo {
  margin-top: 55px;
  margin-bottom: 40px;
  width: 200px;
  height: 200px;
  text-indent: -9999px;
  background: url("../assets/galveon.png") 0 0;
  background-size: contain;
  margin: 0 auto;
}

.vLoginForm {
  width: 100%;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}





.helpful-links {
  list-style-type: none;

  padding: 0;
}

.footer.is-login-page {
  position: absolute;
  bottom: 20px;
  left: 0;

  width: 100%;

  text-transform: uppercase;
  font-size: 14px;
}

.copy {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}
</style>
