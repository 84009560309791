<template>
  <div class="ResourceView">
    <UIActivityView :radius="radius" :progress="resourcePercentage" :stroke="stroke" :color="resourceColor" :label="label" :labelTitle="labelTitle" :labelSize="labelSize" />
    <div v-show="resource.show_name">{{ resource.name }}</div>
    <div v-show="resource.show_value">
      <span v-show="resource.show_current">{{ current }}</span><span v-show="resource.show_current && resource.show_max"> / </span><span v-show="resource.show_max">{{ max }}</span>
    </div>
    <div v-show="resource.show_percentage">{{ resourcePercentage }}%</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapGetters } from "vuex";

import UIActivityView from "@/components/UI/UIActivityView.vue";
import { componentToHex, delimit } from "@/utility/global";
import Resource from "@/store/models/Resource";

export default defineComponent({
  name: "ResourceView",
  components: {
    UIActivityView,
  },
  props: {
    resource: {
      type: Object as () => Resource,
      default: {},
    },
    label: {
      type: Number,
      default: null,
    },
    labelTitle: {
      type: String,
      default: '',
    },
    radius: {
      type: Number,
      default: 60,
    },
    stroke: {
      type: Number,
      default: 8,
    },
    labelSize: {
      type: Number,
      default: 36,
    }
  },
  computed: {
    ...mapGetters("Character", ["getCharacter"]),
    current(): number {
      if (Object.keys(this.resource).length === 0) {
        return 0;
      }
      const [hasBonus, current, max, percentage] = this.resource.getValueWithBonus(this.getCharacter);
      return +current;
    },
    max(): number {
      if (Object.keys(this.resource).length === 0) {
        return 0;
      }
      const [hasBonus, current, max, percentage] = this.resource.getValueWithBonus(this.getCharacter);
      return +max;
    },
    resourcePercentage(): number {
      let percentage = (+this.current / +this.max) * 100;
      if (percentage === Infinity || percentage === -Infinity || isNaN(percentage)) {
        return 100;
      }
      return Math.trunc((this.resource.current / this.resource.max) * 100);
    },
    resourceColor(): string {
      const red = this.resource.red;
      const green = this.resource.green;
      const blue = this.resource.blue;

      return componentToHex(red, green, blue);
    },
  },
});
</script>

<style>
.ResourceView {
  cursor: pointer;
}

.vLabel {
  position: absolute;
}
</style>