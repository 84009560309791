<template>
  <div class="quick-view">
    <div class="flex">
      <img id="btnAttributeMenu" style="cursor: pointer" :src="imageName" />
      <UILabel
        style="width: 100%; padding: 0 8px"
        :text="quickView.name"
        :font="18"
        :justify="'left'"
      />
      <img
        id="btnAttributeMenu"
        style="cursor: pointer"
        src="@/assets/icon_pencil@x2.png"
        @click="onMenu"
      />
    </div>
    <template v-if="quickView.secondaryAttributes.length > 0">
      <UISeparatorView />
      <UICollectionView
        :id="'cvSecondaryAttributes'"
        :hasItems="quickView.secondaryAttributes.length > 0"
      >
        <div v-if="quickView.secondaryAttributes.length < 1"></div>
        <QuickViewUICollectionViewCell
          v-for="(extra, index) in quickView.secondaryAttributes"
          :key="index"
          :extra="extra"
        />
      </UICollectionView>
    </template>
    <template v-if="quickView.description != ''">
      <UISeparatorView />
      <UITextView
        :modelValue="description"
        :hideLabel="true"
        :placeholder="'Description'"
        :disabled="true"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { convertKeysLocalDescription } from "@/utility/tooltip";

import UISeparatorView from "@/components/UI/UISeparatorView.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextView from "@/components/UI/UITextView.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import QuickViewUICollectionViewCell from "@/components/character/QuickViewUICollectionViewCell.vue";

import QuickView from "@/store/models/QuickView";

export default defineComponent({
  name: "QuickViewView",
  components: {
    UISeparatorView,
    UILabel,
    UITextView,
    UIButton,
    UICollectionView,
    QuickViewUICollectionViewCell,
  },
  emits: ["quick-view-menu"],
  props: {
    quickView: {
      type: Object as () => QuickView,
    },
  },
  computed: {
    imageName(): String {
      const icon = require("@/assets/icon_" +
        this.quickView?.imageName +
        "@x2.png");
      return icon;
    },
    description(): String {
      return convertKeysLocalDescription(this.quickView?.description ?? "");
    },
  },
  methods: {
    onMenu() {
      this.$emit("quick-view-menu");
    },
  },
});
</script>

<style lang="scss" scoped>
#cvSecondaryAttributes {
  grid-template-columns: repeat(auto-fill, 150px);
  gap: 0;
  margin-top: 0;
  margin-bottom: 0;

  height: 200px;
  overflow: auto;
}
</style>