import IEffectBase from "@/store/interfaces/AssetBases/IEffectBase";
import CompendiumAssetBase from "../Compendium/CompendiumAssetBase";
import { AssetType } from "../Compendium/AssetType";
import { EffectType } from "@/store/interfaces/types/EffectType";

export default class EffectBase extends CompendiumAssetBase implements IEffectBase {
  constructor(dictionary: Record<string, any>, key: string, effect_type: EffectType) {
    super(dictionary, key, AssetType.effect);
    this.effect_type = effect_type;

  }

  effect_type: EffectType;

  getEffectToString(): string {
    return this.name + " " + this.effect_type;
  }

  toDictionary(): Record<string, any> {
    var returnedDictionary = super.toDictionary();

    returnedDictionary["effect_type"] = this.effect_type;

    return returnedDictionary;
  }
}