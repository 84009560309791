import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a023a06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ResourceView" }
const _hoisted_2 = { class: "vHealthContainer" }
const _hoisted_3 = { class: "vHealth" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UICharacterActivityView = _resolveComponent("UICharacterActivityView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _withDirectives(_createVNode(_component_UILabel, {
        text: _ctx.experience.name
      }, null, 8, ["text"]), [
        [_vShow, _ctx.experience.show_name]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.currentExperience), 513), [
          [_vShow, _ctx.experience.show_current]
        ]),
        _withDirectives(_createElementVNode("span", null, " / ", 512), [
          [_vShow, _ctx.experience.show_current && _ctx.experience.show_max]
        ]),
        _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.maxExperience), 513), [
          [_vShow, _ctx.experience.show_max]
        ])
      ], 512), [
        [_vShow, _ctx.experience.show_value]
      ]),
      _withDirectives(_createVNode(_component_UILabel, {
        text: _ctx.experiencePercentage + '%'
      }, null, 8, ["text"]), [
        [_vShow, _ctx.experience.show_percentage]
      ])
    ]),
    _createVNode(_component_UICharacterActivityView, {
      mainResource: _ctx.mainResource,
      experience: _ctx.experience,
      level: _ctx.level,
      hasImg: _ctx.hasImg,
      showLevel: _ctx.showLevel
    }, null, 8, ["mainResource", "experience", "level", "hasImg", "showLevel"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createVNode(_component_UILabel, {
          text: _ctx.mainResource.name
        }, null, 8, ["text"]), [
          [_vShow, _ctx.mainResource.show_name]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.currentMainVital), 513), [
            [_vShow, _ctx.mainResource.show_current]
          ]),
          _withDirectives(_createElementVNode("span", null, " / ", 512), [
            [_vShow, _ctx.mainResource.show_current && _ctx.mainResource.show_max]
          ]),
          _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.maxMainVital), 513), [
            [_vShow, _ctx.mainResource.show_max]
          ])
        ], 512), [
          [_vShow, _ctx.mainResource.show_value]
        ]),
        _withDirectives(_createVNode(_component_UILabel, {
          text: _ctx.percentageMainVital + '%'
        }, null, 8, ["text"]), [
          [_vShow, _ctx.mainResource.show_percentage]
        ])
      ])
    ])
  ]))
}