<template>
  <div class="FormulaTableView">
    <div class="flex" style="justify-content: right">
      <img
        src="@/assets/icon_plus@x1.png"
        width="32"
        style="cursor: pointer"
        @click="btnAdd()"
      />
    </div>
    <UICollectionView :id="'cvModification'" :hasItems="true">
      <FormulaItemCollectionViewCell
        v-for="(value, key) in this.newFormulaItems"
        :key="key"
        :formulaItem="value"
        @click="btnAdd(value, key)"
      >
      </FormulaItemCollectionViewCell>
    </UICollectionView>
  </div>
  <div>
    <UIDialogValueView
      v-if="shouldShowValueDialog"
      :type="'string'"
      :title="dialogValueData.title"
      :body="dialogValueData.body"
      :inputTitle1="dialogValueData.inputTitle1"
      :inputValue1="dialogValueData.inputValue1"
      :inputPlaceholder1="dialogValueData.inputPlaceholder1"
      :inputTitle2="dialogValueData.inputTitle2"
      :inputValue2="dialogValueData.inputValue2"
      :inputPlaceholder2="dialogValueData.inputPlaceholder2"
      :removeTitle="dialogValueData.removeTitle"
      @ui-dialog-value-submit="onDialogValueSubmit"
      @ui-dialog-value-remove="onDialogValueRemove"
      @ui-dialog-value-cancel="onDialogValueCancel"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import UIDialogValueView from "@/components/UI/dialogs/UIDialogValueView.vue";

import UICollectionView from "@/components/UI/UICollectionView.vue";

import FormulaItemCollectionViewCell from "@/components/character/collectionViewCells/FormulaItemUICollectionViewCell.vue";
import FormulaItem from "@/store/models/FormulaItem";

export default defineComponent({
  name: "FormulaTableView",
  components: {
    UIDialogValueView,
    UICollectionView,
    FormulaItemCollectionViewCell,
  },
  props: {
    formulaItems: {
      type: Array<FormulaItem>,
      default: [],
      required: true,
    },
  },
  emits: ["formula-table-view-change"],
  data() {
    return {
      dialogValueData: {},
      shouldShowValueDialog: false,
      newFormulaItems: this.formulaItems,
      selectedFormulaIndex: -1,
    };
  },
  methods: {
    btnAdd(formulaItem: FormulaItem, index: number = -1) {
      this.shouldShowValueDialog = true;
      this.selectedFormulaIndex = index;
      this.dialogValueData = {
        title: `Add Formula`,
        body: `Add an entry to this Attribute's formula table.`,
        inputTitle1: `Add Formula`,
        inputValue1: formulaItem === undefined ? "" : formulaItem.value,
        inputPlaceholder1: "Name",
        inputTitle2: ``,
        inputValue2: formulaItem === undefined ? "" : formulaItem.result,
        inputPlaceholder2: "Value",
        removeTitle: index > -1 ? "Remove" : "",
      };
    },
    onDialogValueSubmit(returnedData: any) {
      const { type, value1, value2 } = returnedData;

      const formulaItemResult = new FormulaItem({
        value: value1,
        result: value2,
      });

      if (this.selectedFormulaIndex === -1) {
        this.newFormulaItems.push(formulaItemResult);
      }
      else {
        this.newFormulaItems[this.selectedFormulaIndex] = formulaItemResult;
      }

      this.$emit("formula-table-view-change", this.newFormulaItems);
    },
    onDialogValueCancel() {
      this.shouldShowValueDialog = false;
    },
    onDialogValueRemove() {
      if (this.selectedFormulaIndex > -1) {
        this.newFormulaItems.splice(this.selectedFormulaIndex, 1);
      }
    },
  },
});
</script>
