import ISource from "@/store/interfaces/AssetBases/ISource";
import { BookRule } from "./BookRule";

export default class Source implements ISource {
  constructor(book: string, rule: BookRule) {
    this.book = book;
    this.rule = rule;
  }

  book: string;
  rule: BookRule;
  
  toDictionary(): Record<string, any> {
    var returnedDictionary: Record<string, any> = {};

    returnedDictionary["book"] = this.book;
    returnedDictionary["rule"] = this.rule;

    return returnedDictionary;
  }
}