<template>
  <div class="ChooseField">
    <UILabel :text="'Type'" :type="'h1'" :justify="'left'" />
    <div style="position: relative">
      <UILabel :text="selectedType" @click="btnShowTypeMenu" />
      <UIMenuView
        v-if="shouldShowDataMenu"
        :items="typeData"
        @menu-item-selected-at="onMenuTypeSelect"
        @menu-view-dismiss="dismissTypeMenu"
      />
    </div>
    <UILabel :text="'Asset'" :type="'h1'" :justify="'left'" />
    <div style="position: relative">
      <UILabel :text="selectedAsset" @click="btnShowAssetMenu" />
      <UIMenuView
        v-if="shouldShowAssetMenu"
        :items="assetMenuData"
        @menu-item-selected-at="onMenuAssetSelect"
        @menu-view-dismiss="dismissAssetMenu"
      />
    </div>
    <UILabel :text="'Field'" :type="'h1'" :justify="'left'" />
    <div style="position: relative">
      <UILabel :text="selectedField" @click="btnShowFieldMenu" />
      <UIMenuView
        v-if="shouldShowFieldMenu"
        :items="fieldData"
        @menu-item-selected-at="onMenuFieldSelect"
        @menu-view-dismiss="dismissFieldMenu"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapState } from "vuex";

import UILabel from "@/components/UI/UILabel.vue";

import UIMenuView from "@/components/UI/UIMenuView.vue";
import Attribute from "@/store/models/Attribute";
import Spell from "@/store/models/Spell";
import Item from "@/store/models/Item";
import Resource from "@/store/models/Resource";

export default defineComponent({
  name: "ChooseField",
  components: {
    UILabel,
    UIMenuView,
  },
  emits: ["choose-field-change"],
  data() {
    return {
      shouldShowDataMenu: false,
      shouldShowAssetMenu: false,
      shouldShowFieldMenu: false,
      typeData: ["Attribute", "Spell", "Resource", "Item", "Character_Stats"],
      assetData: [] as any[],
      assetIdData: [] as string[],
      assetMenuData: [] as string[],
      fieldData: [] as string[],
      selectedAsset: "",
      selectedAssetId: "",
      selectedType: "Attribute",
      selectedField: "",
      attributeFields: [
        "Name",
        "Value",
        "Description",
        "Modifier_Formula",
        "Show_Modifier",
      ],
      spellFields: ["Name", "Description"],
      resourceFields: ["Name", "Current", "Max"],
      itemFields: ["Name", "Count", "Description"],
      mainResourceFields: ["Name", "Current", "Max"],
      levelFields: ["Value"],
    };
  },
  computed: {
    ...mapState('Account', ['workshop']),
  },
  methods: {
    chooseFieldData(): any {
      return {
        type: this.selectedType,
        asset: this.selectedAsset,
        assetId: this.selectedAssetId,
        field: this.selectedField,
      };
    },
    onMenuTypeSelect(index: number) {
      this.selectedType = this.typeData[index];
      this.reloadAsset();
    },
    onMenuAssetSelect(index: number) {
      this.selectedAsset = this.assetMenuData[index];
      this.selectedAssetId = this.assetIdData[index];

      this.$emit("choose-field-change", this.chooseFieldData());
    },
    onMenuFieldSelect(index: number) {
      this.selectedField = this.fieldData[index];

      this.$emit("choose-field-change", this.chooseFieldData());
    },
    btnShowTypeMenu() {
      this.shouldShowDataMenu = true;
    },
    dismissTypeMenu() {
      this.shouldShowDataMenu = false;
    },
    btnShowAssetMenu() {
      this.shouldShowAssetMenu = true;
    },
    dismissAssetMenu() {
      this.shouldShowAssetMenu = false;
    },
    btnShowFieldMenu() {
      this.shouldShowFieldMenu = true;
    },
    dismissFieldMenu() {
      this.shouldShowFieldMenu = false;
    },
    reloadAsset() {
      this.selectedAsset = "";
      this.assetData = [];
      this.assetMenuData = [];
      this.fieldData = [];
      this.selectedAssetId = "";

      switch (this.selectedType) {
        case "Attribute":
          this.assetData = Object.values(
            this.workshop.Attribute
          );
          this.assetData.forEach((a) => {
            const attr: Attribute = new Attribute(a, a.key);
            this.assetMenuData.push(attr.name);
            this.assetIdData.push(attr.key);
          });
          let attrAsset: Attribute = new Attribute(
            this.assetData[0],
            this.assetData[0].key
          );
          this.selectedAsset = attrAsset.name;
          this.selectedAssetId = attrAsset.key;

          this.fieldData = this.attributeFields;
          break;
        case "Spell":
          this.assetData = Object.values(
            this.workshop.Spell
          );
          this.assetData.forEach((a) => {
            const attr: Spell = new Spell(a, a.key);
            this.assetMenuData.push(attr.name);
            this.assetIdData.push(attr.key);
          });
          let spellAsset: Spell = new Spell(
            this.assetData[0],
            this.assetData[0].key
          );
          this.selectedAsset = spellAsset.name;
          this.selectedAssetId = spellAsset.key;

          this.fieldData = this.spellFields;
          break;
        case "Item":
          this.assetData = Object.values(
            this.workshop.Item
          );
          this.assetData.forEach((a) => {
            const attr: Item = new Item(a, a.key);
            this.assetMenuData.push(attr.name);
            this.assetIdData.push(attr.key);
          });
          let itemAsset: Item = new Item(
            this.assetData[0],
            this.assetData[0].key
          );
          this.selectedAsset = itemAsset.name;
          this.selectedAssetId = itemAsset.key;

          this.fieldData = this.itemFields;
          break;
        case "Resource":
          this.assetData = Object.values(
            this.workshop.Resource
          );
          this.assetData.forEach((a) => {
            const attr: Resource = new Resource(a, a.key);
            this.assetMenuData.push(attr.name);
            this.assetIdData.push(attr.key);
          });
          let resourceAsset: Resource = new Resource(
            this.assetData[0],
            this.assetData[0].key
          );
          this.selectedAsset = resourceAsset.name;
          this.selectedAssetId = resourceAsset.key;

          this.fieldData = this.resourceFields;
          break;
        default:
          break;
      }
      this.selectedField = this.fieldData[0];

      this.$emit("choose-field-change", this.chooseFieldData());
    },
  },
  mounted() {
    this.reloadAsset();
  },
});
</script>