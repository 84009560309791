import IInsertKeys from "@/store/interfaces/IInsertKeys";

export const nonCategoryModification: Array<IInsertKeys> = [
  {label: "", value: "", key: ""}
];

export const spellModification: Array<IInsertKeys> = [

];

export const behaviorModifcation: Array<IInsertKeys> = [

];

export const combatModification: Array<IInsertKeys> = [

];

export const unitModification: Array<IInsertKeys> = [
  {label: "Vital Maximum Bonus", value: "[@vital_maximum_bonus]", key: "vital_maximum_bonus"},
  {label: "Vital Maximum Fraction", value: "[@vital_maximum_fraction]", key: "vital_maximum_fraction"}
];


export const convertToTitleCase = (input: string): string => {
  const words = input.split("_");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  const output = capitalizedWords.join(" ");
  return output;
}