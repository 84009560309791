import IAttributeGroup from "@/store/interfaces/IAttributeGroup";
import Attribute from "@/store/models/Attribute";

export default class AttributeGroup implements IAttributeGroup {
    constructor(dictionary: Record<string, any>, key: string) {
        this.key = key;
        this.name = dictionary["name"] || "New Attribute Group";
        if (this.name === "") {
            this.name = "New Attribute Group"
        }
        this.key_attribute_tab = dictionary["key_attribute_tab"] || "";
    }

    key: string;
    name: string;
    key_attribute_tab: string;

    toDictionary(): Record<string, any> {
        let returnedDictionary: Record<string, any> = {};

        returnedDictionary["name"] = this.name
        returnedDictionary["key_attribute_tab"] = this.key_attribute_tab;
        returnedDictionary["key"] = this.key;

        return returnedDictionary
    }

}