import { User, Auth, getAuth, reauthenticateWithCredential, EmailAuthProvider, updateEmail, updateProfile, updatePassword, deleteUser, sendEmailVerification, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, child, DatabaseReference, get } from 'firebase/database';
import CompendiumAssetBase from '../models/Compendium/CompendiumAssetBase';
import { database_save, setFirestore } from '@/utility/database';
import AssetSubmit from '../models/AssetBases/AssetSubmit';
import GameSystem from '../models/GameSystem';
import SpellBase from '../models/AssetBases/SpellBase';
import AttributeBase from '../models/AssetBases/AttributeBase';
import ItemBase from '../models/AssetBases/ItemBase';
import ResourceBase from '../models/AssetBases/ResourceBase';

const state = {
    assets: [],
}

const getters = {
    getAssets: (state: any): Array<CompendiumAssetBase> => {
        return state.assets;
    },
}

const mutations = {
    setAsset: (state: any, val: CompendiumAssetBase) => {
        state.assets.push(val);
    },
}

const actions = {
    async fetchPendingPublishing({ commit }: any) {
        const database: DatabaseReference = ref(getDatabase());

        return new Promise((resolve, reject) => {
            return get(child(database, 'pending_publication')).then(snapshot => {
                const data = snapshot.val();

                for (const asset in data) {
                    const assetType: "attribute" | "spell" | "resource" | "item" | "game_system" = asset.toLowerCase() as "attribute" | "spell" | "resource" | "item" | "game_system";
                    const array = data[asset];
                    if (array) {
                        for (const key in array) {
                            let additionalAssetsToPublish: CompendiumAssetBase[] = [];
                            const additionalAssets = array[key].assets;
                            for (const additionalAssetKey in additionalAssets) {
                                const additionalAsset: CompendiumAssetBase = additionalAssets[additionalAssetKey];
                                switch (assetType) {
                                    case "game_system":
                                        additionalAssetsToPublish.push(new GameSystem(additionalAsset, additionalAssetKey));
                                    break;
                                    case "resource":
                                        additionalAssetsToPublish.push(new ResourceBase(additionalAsset, additionalAssetKey));
                                    break;
                                    case "item":
                                        additionalAssetsToPublish.push(new ItemBase(additionalAsset, additionalAssetKey));
                                    break;
                                    case "attribute":
                                        additionalAssetsToPublish.push(new AttributeBase(additionalAsset, additionalAssetKey));
                                    break;
                                    case "spell":
                                        additionalAssetsToPublish.push(new SpellBase(additionalAsset, additionalAssetKey));
                                    break;
                                }
                            }

                            const returnedAsset = new AssetSubmit(array[key], additionalAssetsToPublish, key);

                            commit("setAsset", returnedAsset);
                            
                        }
                    }
                }
                resolve(true);
            });
        });
    },
    async publishAsset({ }: any, asset: AssetSubmit) {
        return new Promise((resolve, reject) => {
            const additionalAssets: CompendiumAssetBase[] = asset.assets;
            for (const additionalAsset of additionalAssets) {
                return setFirestore([`community_${additionalAsset.type + "s"}`], additionalAsset.toDictionary(), database_save.firestoreSet).then(() => {
                    resolve(true);
                });
            }
        });
    }
}



export const Admin = { namespaced: true, state, getters, mutations, actions }