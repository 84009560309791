import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon_pencil@x2.png'


const _withScopeId = n => (_pushScopeId("data-v-5197348f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quick-view" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UISeparatorView = _resolveComponent("UISeparatorView")!
  const _component_QuickViewUICollectionViewCell = _resolveComponent("QuickViewUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_UITextView = _resolveComponent("UITextView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        id: "btnAttributeMenu",
        style: {"cursor":"pointer"},
        src: _ctx.imageName
      }, null, 8, _hoisted_3),
      _createVNode(_component_UILabel, {
        style: {"width":"100%","padding":"0 8px"},
        text: _ctx.quickView.name,
        font: 18,
        justify: 'left'
      }, null, 8, ["text"]),
      _createElementVNode("img", {
        id: "btnAttributeMenu",
        style: {"cursor":"pointer"},
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMenu && _ctx.onMenu(...args)))
      })
    ]),
    (_ctx.quickView.secondaryAttributes.length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_UISeparatorView),
          _createVNode(_component_UICollectionView, {
            id: 'cvSecondaryAttributes',
            hasItems: _ctx.quickView.secondaryAttributes.length > 0
          }, {
            default: _withCtx(() => [
              (_ctx.quickView.secondaryAttributes.length < 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickView.secondaryAttributes, (extra, index) => {
                return (_openBlock(), _createBlock(_component_QuickViewUICollectionViewCell, {
                  key: index,
                  extra: extra
                }, null, 8, ["extra"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["hasItems"])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.quickView.description != '')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_UISeparatorView),
          _createVNode(_component_UITextView, {
            modelValue: _ctx.description,
            hideLabel: true,
            placeholder: 'Description',
            disabled: true
          }, null, 8, ["modelValue"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}