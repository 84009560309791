<template>
  <UIDialogView
    :title="'Currency'"
    :hasMenu="true"
    :menuItems="['Add Currency']"
    :hasDone="true"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
    @ui-dialog-menu-select="onCurrencyMenuSelect"
  >
  <div class="onboard-currency">
    
    <UICollectionView :id="'cvCurrency'">
      <UIOnboardView
        v-if="getCurrencies.length < 1"
        :title="'No Currencies'"
        :body="'Add a currency to keep track of things like gold, silver, or other countable things you might find in your pocket.'"
        :buttonTitle="'Add Currency'"
        @ui-onboard-view="btnAddCurrency"
      />
      <div
        v-for="(currency, index) in getCurrencies"
        :key="index"
      >
        <div
          class="CurrencyUICollectionViewCell"
          @click="onShowCurrencyDialog(currency)"
        >
          <UISwitchView
            :disabled="!currency.is_favorite && currenctFavorites >= 3"
            :checked="currency.is_favorite"
            @ui-switch-view-toggle="onCurrencyFavoriteToggle(index, $event)"
          />
          <UILabel :id="'lblExtraName'" :text="currency.name" />
          <UILabel :id="'lblExtraValue'" :text="currency.value" />
        </div>
      </div>
    </UICollectionView>
  </div>
  </UIDialogView>
  <div>
    <UIDialogManageCurrency
      v-if="shouldShowCurrencyDialog"
      :selectedCurrency="selectedCurrency"
      @ui-dialog-currency-submit="onSubmitCurrencyDialog"
      @ui-dialog-currency-delete="onDeleteCurrencyDialog"
      @ui-dialog-currency-cancel="onHideCurrencyDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from 'vuex';

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";
import UIDialogManageCurrency from "@/components/character/dialogs/UIDialogManageCurrency.vue";

import UILabel from "@/components/UI/UILabel.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UISwitchView from "@/components/UI/UISwitchView.vue";

import UIOnboardView from "@/components/UI/UIOnboardView.vue";
import UICollectionView from "@/components/UI/UICollectionView.vue";

import SecondaryAttributeUICollectionViewCell from "@/components/character/SecondaryAttributeUICollectionViewCell.vue";
import Currency from "@/store/models/Currency";

export default defineComponent({
  name: "UIDialogCurrency",
  components: {
    UIDialogView,
    UIDialogManageCurrency,
    UILabel,
    UIButton,
    UIOnboardView,
    UICollectionView,
    SecondaryAttributeUICollectionViewCell,
    UISwitchView,
  },
  emits: ["ui-dialog-currency-cancel"],
  data() {
    return {
      shouldShowCurrencyDialog: false,
      selectedCurrency: {} as Currency,
      maxFavorites: 3,
      currenctFavorites: 0,
    }
  },
  computed: {
    ...mapGetters('Character', [
      'getCurrencies',
    ]),
  },
  methods: {
    ...mapActions('Character', [
      'setCurrency',
      "deleteCurrency",
    ]),
    onSubmit() {
      
    },
    onCancel() {
      this.$emit("ui-dialog-currency-cancel");
    },
    onCurrencyMenuSelect(index: number) {
      if (index === 0) {
        const data: Currency = new Currency({}, "");
        this.onShowCurrencyDialog(data);
      }
    },
    onShowCurrencyDialog(data: Currency) {
      this.selectedCurrency = data;
      this.shouldShowCurrencyDialog = true;
    },
    onHideCurrencyDialog() {
      this.shouldShowCurrencyDialog = false;
    },
    onSubmitCurrencyDialog(newCurrency: Currency) {
      this.setCurrency(newCurrency);
    },
    onDeleteCurrencyDialog(currency: Currency) {
      this.deleteCurrency(currency);
    },
    btnAddCurrency() {
      this.onCurrencyMenuSelect(0);
    },
    onCurrencyFavoriteToggle(index: number, value: boolean) {
      if (value && this.currenctFavorites < 3) {
        this.currenctFavorites++;
      }
      else if (!value) {
        this.currenctFavorites--;
      }
      else {
        return;
      }

      this.getCurrencies[index].is_favorite = value;

      this.setCurrency(this.getCurrencies[index]);
    }
  },
  mounted() {
    this.getCurrencies.forEach((c: Currency) => {
      if (c.is_favorite) {
        this.currenctFavorites++;
      }
    });
  }
});
</script>

<style lang="scss" scoped>
.CurrencyUICollectionViewCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;
  height: 44px;
  cursor: pointer;

  box-sizing: border-box;

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(odd) {
    background: #ffffff;
  }
}
</style>
