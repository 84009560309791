import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icon_plus@x1.png'


const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!
  const _component_UILabel = _resolveComponent("UILabel")!
  const _component_UISwitchView = _resolveComponent("UISwitchView")!
  const _component_UIOnboardView = _resolveComponent("UIOnboardView")!
  const _component_SecondaryAttributeUICollectionViewCell = _resolveComponent("SecondaryAttributeUICollectionViewCell")!
  const _component_UICollectionView = _resolveComponent("UICollectionView")!
  const _component_ManageAssetView = _resolveComponent("ManageAssetView")!
  const _component_UIDialogView = _resolveComponent("UIDialogView")!
  const _component_UIDialogVeterancyView = _resolveComponent("UIDialogVeterancyView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UIDialogView, {
      title: 'Experience',
      canRemove: false,
      hasMenu: false,
      onUiDialogSubmit: _ctx.onSubmit,
      onUiDialogCancel: _ctx.onCancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ManageAssetView, {
          asset: _ctx.getExperience,
          hasCompendium: false
        }, {
          basic: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_UITextField, {
                id: 'txtName',
                textLabel: 'Experience name',
                modelValue: _ctx.getExperience.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getExperience.name) = $event)),
                placeholder: 'Experience name'
              }, null, 8, ["modelValue"]),
              _createVNode(_component_UITextField, {
                id: 'txtCurrent',
                textLabel: 'Current',
                modelValue: _ctx.getExperience.current,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getExperience.current) = $event)),
                placeholder: 'Current'
              }, null, 8, ["modelValue"]),
              _createVNode(_component_UITextField, {
                id: 'txtMax',
                textLabel: 'Max',
                modelValue: _ctx.getExperience.max,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getExperience.max) = $event)),
                placeholder: 'Max'
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_UITextField, {
                id: 'txtLevel',
                textLabel: 'Current level',
                modelValue: _ctx.characterLevel,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.characterLevel) = $event)),
                placeholder: 'Current level'
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_UILabel, { text: 'Color' }),
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(["color-picker", { disabled: _ctx.readOnly}]),
                type: "color",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.assetColor) = $event))
              }, null, 2), [
                [_vModelText, _ctx.assetColor]
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_UILabel, { text: 'Show Name' }),
                _createVNode(_component_UISwitchView, {
                  checked: _ctx.resource.show_name,
                  onUiSwitchViewToggle: _ctx.onToggleShowName
                }, null, 8, ["checked", "onUiSwitchViewToggle"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_UILabel, { text: 'Show Value' }),
                _createVNode(_component_UISwitchView, {
                  checked: _ctx.resource.show_value,
                  onUiSwitchViewToggle: _ctx.onToggleShowValue
                }, null, 8, ["checked", "onUiSwitchViewToggle"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_UILabel, { text: 'Show Percentage' }),
                _createVNode(_component_UISwitchView, {
                  checked: _ctx.resource.show_percentage,
                  onUiSwitchViewToggle: _ctx.onToggleShowPercentage
                }, null, 8, ["checked", "onUiSwitchViewToggle"])
              ])
            ])
          ]),
          advanced: _withCtx(() => [
            (_ctx.resource.veterancies && _ctx.resource.veterancies?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_UILabel, {
                    text: 'Veterancy',
                    type: 'h2'
                  }),
                  (!_ctx.readOnly)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _imports_0,
                        style: {"cursor":"pointer"},
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDisplayVeterancyDialog()))
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_UICollectionView, { id: 'cvExtra' }, {
              default: _withCtx(() => [
                (!_ctx.resource.veterancies || _ctx.resource.veterancies?.length < 1)
                  ? (_openBlock(), _createBlock(_component_UIOnboardView, {
                      key: 0,
                      title: 'No Veterancies',
                      buttonTitle: 'Add Veterancy',
                      onUiOnboardView: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onDisplayVeterancyDialog()))
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resource.veterancies, (veterancy, index) => {
                  return (_openBlock(), _createBlock(_component_SecondaryAttributeUICollectionViewCell, {
                    key: index,
                    extra: _ctx.getVeterancyLabel(veterancy, index),
                    onClick: ($event: any) => (_ctx.onDisplayVeterancyDialog(index, veterancy))
                  }, null, 8, ["extra", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["asset"])
      ]),
      _: 1
    }, 8, ["onUiDialogSubmit", "onUiDialogCancel"]),
    _createElementVNode("div", null, [
      (_ctx.shouldShowVeterancyDialog)
        ? (_openBlock(), _createBlock(_component_UIDialogVeterancyView, {
            key: 0,
            veterancy: _ctx.veterancyDialogData,
            onUiDialogVeterancySubmit: _ctx.onSubmitVeterancyDialog,
            onUiDialogVeterancyRemove: _ctx.onRemoveVeterancyDialog,
            onUiDialogVeterancyCancel: _ctx.onCancelVeterancyDialog
          }, null, 8, ["veterancy", "onUiDialogVeterancySubmit", "onUiDialogVeterancyRemove", "onUiDialogVeterancyCancel"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}