import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inv-stats" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"flex-end","margin":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CharacterCurrency = _resolveComponent("CharacterCurrency")!
  const _component_UIDialogCurrency = _resolveComponent("UIDialogCurrency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CharacterCurrency, { onClick: _ctx.showCurrencyDialog }, null, 8, ["onClick"])
    ]),
    (_ctx.shouldShowCurrencyDialog)
      ? (_openBlock(), _createBlock(_component_UIDialogCurrency, {
          key: 0,
          onUiDialogCurrencyCancel: _ctx.hideCurrencyDialog
        }, null, 8, ["onUiDialogCurrencyCancel"]))
      : _createCommentVNode("", true)
  ]))
}