import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bbbc662"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["is-selected", "justify", "type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "UILabel",
    style: _normalizeStyle({ 'font-size': `${_ctx.font}px` }),
    "is-selected": _ctx.isSelected,
    justify: _ctx.justify,
    type: _ctx.type,
    disabled: _ctx.disabled
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
  ], 12, _hoisted_1))
}