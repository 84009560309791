import { createStore } from 'vuex';

import { Account } from '@/store/modules/account';
import { Character } from '@/store/modules/character';
import { Publish } from '@/store/modules/publish';
import { Campaigns } from '@/store/modules/campaigns';
import { Admin } from '@/store/modules/admin';
import { Forums } from '@/store/modules/forums';
import { Dialog } from '@/store/modules/dialog';

const store = createStore({
  modules: {
    Account,
    Character,
    Publish,
    Campaigns,
    Admin,
    Forums,
    Dialog,
  },
  state: {
    //...
  },
  getters: {
    //...
  },
  mutations: {
    //...
  },
  actions: {
    //...
  }
});

export default store;
