import IGameSystem from "../interfaces/IGameSystem";
import { AssetType } from "./Compendium/AssetType";
import CompendiumAssetBase from "./Compendium/CompendiumAssetBase";
import Currency from "./Currency";

export default class GameSystem extends CompendiumAssetBase implements IGameSystem {
    constructor(dictionary: Record<string, any>, key: string) {
        super(dictionary, key, AssetType.game_system);
        
        const currenciesValue = dictionary["currencies"];
        if (currenciesValue) {
            for (const currencyKey in currenciesValue) {
                const currencyValue = currenciesValue[currencyKey];

                this.currencies.push(new Currency(currencyValue, currencyKey));
            }
        }
    }

    currencies: Currency[] = [];

    getCurrencyWithId(id: string): Currency | undefined {
        return this.currencies.find((r: Currency) => r.key === id);
    }

    toDictionary(): Record<string, any> {
        var returnedDictionary: Record<string, any> = super.toDictionary();

        var currenciesDictionary: Record<string, any> = {};
        for (const currency of this.currencies) {
            currenciesDictionary[currency.key] = currency.toDictionary();
        }
        returnedDictionary["currencies"] = currenciesDictionary;

        return returnedDictionary;
    }
}