<template>
  <UIDialogView
    :title="'Main Resource'"
    :canRemove="false"
    :hasMenu="false"
    @ui-dialog-submit="onSubmit"
    @ui-dialog-cancel="onCancel"
  >
    <ManageAssetView :asset="getMainResource" :hasCompendium="false">
      <template v-slot:basic>
        <div>
          <UITextField
            :id="'txtName'"
            :textLabel="'Experience name'"
            v-model="getMainResource.name"
            :placeholder="'Experience name'"
          />
          <UITextField
            :id="'txtCurrent'"
            :textLabel="'Current'"
            v-model="getMainResource.current"
            :placeholder="'Current'"
          />
          <UITextField
            :id="'txtMax'"
            :textLabel="'Max'"
            v-model="getMainResource.max"
            :placeholder="'Max'"
          />
        </div>
        <div class="flex">
          <UILabel :text="'Color'" />
          <input
            class="color-picker"
            type="color"
            v-model="assetColor"
            :class="{ disabled: readOnly }"
          />
        </div>
        <div>
          <div class="flex">
            <UILabel :text="'Show Name'" />
            <UISwitchView
              :checked="resource.show_name"
              @ui-switch-view-toggle="onToggleShowName"
            />
          </div>
          <div class="flex">
            <UILabel :text="'Show Value'" />
            <UISwitchView
              :checked="resource.show_value"
              @ui-switch-view-toggle="onToggleShowValue"
            />
          </div>
          <div class="flex">
            <UILabel :text="'Show Percentage'" />
            <UISwitchView
              :checked="resource.show_percentage"
              @ui-switch-view-toggle="onToggleShowPercentage"
            />
          </div>
        </div>
      </template>
    </ManageAssetView>
  </UIDialogView>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { componentToHex, hexToRgb } from "@/utility/global";

import UIDialogView from "@/components/UI/dialogs/UIDialogView.vue";

import UIButton from "@/components/UI/UIButton.vue";
import UILabel from "@/components/UI/UILabel.vue";
import UITextField from "@/components/UI/UITextField.vue";
import UISeparatorView from "@/components/UI/UISeparatorView.vue";
import UISwitchView from "@/components/UI/UISwitchView.vue";

import ManageAssetView from "@/components/compendium/ManageAssetView.vue";

import MainResource from "@/store/models/MainResource";

export default defineComponent({
  name: "UIDialogResourceView",
  components: {
    UIDialogView,
    UIButton,
    UILabel,
    UITextField,
    UISeparatorView,
    UISwitchView,
    ManageAssetView,
  },
  emits: ["ui-dialog-main-resource-submit", "ui-dialog-main-resource-cancel"],
  data() {
    return {
      resource: {} as MainResource,
    };
  },
  props: {
    mainResource: {
      type: Object as () => MainResource,
      required: true,
      default: {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onToggleShowName() {
      this.resource.show_name = !this.resource.show_name;
    },
    onToggleShowValue() {
      this.resource.show_value = !this.resource.show_value;
    },
    onToggleShowPercentage() {
      this.resource.show_percentage = !this.resource.show_percentage;
    },
    onCancel() {
      this.$emit("ui-dialog-main-resource-cancel");
    },
    onSubmit() {
      this.$emit("ui-dialog-main-resource-submit", this.resource);
      this.onCancel();
    },
  },
  computed: {
    getMainResource(): MainResource {
      return this.resource;
    },
    assetColor: {
      get(): string {
        return componentToHex(
          this.resource.red,
          this.resource.green,
          this.resource.blue
        );
      },
      set(hexColor: string): void {
        const { red, green, blue } = hexToRgb(hexColor);
        this.resource.red = red;
        this.resource.green = green;
        this.resource.blue = blue;
      },
    },
  },
  mounted() {
    this.resource = this.mainResource;
  },
});
</script>